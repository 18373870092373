import React from "react";
import { Button, ButtonGroup } from "reactstrap";
import PropTypes from "prop-types";

const ButtonGroupEntry = ({ onChange, feature, attributes, omitTitle }) => {
  const title = feature.feature;
  const anySelected = feature.attributes.length !== 0;
  attributes = attributes || [];
  const onClick = (newValue) => () => {
    let as = feature.attributes.filter((v) => !attributes.includes(v));
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'newValue' implicitly has an 'any' type.
    as.push(newValue);
    onChange(feature, as);
  };
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'v' implicitly has an 'any' type.
  const sClass = (v) => {
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    return feature.attributes.includes(v) ? "selected" : "";
  };
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'v' implicitly has an 'any' type.
  const groupClass = anySelected ? "item-selected" : "";
  return (
    <div className="attribute-container">
      {!omitTitle && <h1>{title}</h1>}
      <ButtonGroup className={groupClass}>
        {attributes.map((v, idx) => (
          <Button outline key={idx} className={sClass(v)} onClick={onClick(v)}>
            {v}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
};

ButtonGroupEntry.propTypes = {
  onChange: PropTypes.func,
  feature: PropTypes.object,
  attributes: PropTypes.array,
  omitTitle: PropTypes.bool,
};
export default ButtonGroupEntry;
