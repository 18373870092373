import React, { Component, Dispatch, FC, useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Form, FormGroup, Input } from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";

import api from "../../api";
import CenteredForm from "../CenteredForm";
import SubmitButton from "../SubmitButton";
import ErrorList from "../ErrorList";
import FlashMessage from "../FlashMessage";

import { AUTHENTICATE, CAPTCHA_LOAD, TOKEN_LOAD } from "../../constants/actionTypes";
import { IAppState } from "../../reducer";
import { toast } from "react-toastify";
import { isBetaSite } from "../../utils";

const mapStateToProps = (state: IAppState) => {
  return {
    inProgress: state.progress[AUTHENTICATE] || state.progress[TOKEN_LOAD],
    captchaConfig: state.common.captchaConfig,
    errors: state.common.errors,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  onSubmit: (data, error = false) =>
    dispatch({
      type: AUTHENTICATE,
      payload: data,
      error: error,
    }),
  onCaptchaConfigLoad: (data) =>
    dispatch({
      type: CAPTCHA_LOAD,
      payload: data,
    }),
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const Login: FC<Props> = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  useEffect(() => {
    if (!props.captchaConfig?.isEnable) {
      api.Authorization.captchaConfig().then((data) => {
        props.onCaptchaConfigLoad(data);
      }).catch(err=>{console.log(err)});
    }
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let token
    try {
      token = await recaptchaRef.current?.executeAsync();
    } catch(err) {
      console.log(err)
    }
    
    api.Authorization.login(email, password, token || "emr")
      .then((data) => {
        if (!data?.isBetaTester && isBetaSite) {
          toast.error("Permission denied! You do not have access to the Beta EMR");
          return;
        }
        if (data?.isAPI) {
          toast.error("Permission denied! You can't use api provider credentials for emr login");
          const environment = API_ROOT.split(".")[0].slice(15)
          recaptchaRef.current?.reset()
          api.Providers.saveLog(`API provider - https://emr${environment}.reliantid.com/providers/edit/${data.providerID}, tried to login`, null, data.token!).catch(err=>console.log(err))
          return;
        }
        props.onSubmit(data);
      })
      .catch((error) => {
        props.onSubmit(error.response.body, true);
      });
  };
  return (
    <CenteredForm>
      <div>
        <h4> Sign in to your account!</h4>
        <Form className="form-signin" onSubmit={handleSubmit}>
          <FlashMessage />
          <FormGroup>
            <Input
              type="email"
              name="email"
              value={email}
              placeholder="provider@gmail.com"
              disabled={props.inProgress}
              onChange={handleEmailChange}
              id="loginEmail"
            />
          </FormGroup>
          <FormGroup>
            <Input
              placeholder="********"
              type="password"
              name="password"
              value={password}
              disabled={props.inProgress}
              onChange={handlePasswordChange}
              id="loginPassword"
            />
          </FormGroup>
          {props.captchaConfig?.isEnable && (
            <ReCAPTCHA size="invisible" ref={recaptchaRef} sitekey={props.captchaConfig?.siteKey} />
          )}
          <ErrorList errors={props.errors} />
          <SubmitButton inProgress={props.inProgress}>Sign in</SubmitButton>
        </Form>
        <div className="reset-container">
          <Link className="reset-link" to="/request-password-reset">
            Forgot your password?
          </Link>
        </div>
      </div>
    </CenteredForm>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
