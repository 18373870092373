import React, { Component } from "react";
import isEqual from "react-fast-compare";

import CurrentSymptomsEditor from "./CurrentSymptomsEditor";
import CurrentSymptomsView from "./CurrentSymptomsView";
import { visitNameLookup } from "../../constants/Encounter";
import {
  ICatalogItem,
  IReviewOfSystems,
  ISymptomsDetail,
  IUserMedicalIntake,
  IUserReviewOfSystemsKeys,
} from "../../constants/Types";

const GetItemOrder = (
  visitType: Props["visitType"],
  catalog: Props["catalog"],
  allergyType: string | null,
) => {
  if (!catalog || !catalog.relevantSystemsKeys) {
    return { itemOrder: [] };
  }
  const { relevantSystemsKeys } = catalog;
  const visitNameKey: string = visitNameLookup[visitType] || "other";
  const order = relevantSystemsKeys[visitNameKey];

  const mapGlobalDetails = (items: ICatalogItem[]) => {
    const globals = catalog.globalDetails!.filter((detail) => {
      return (
        !detail.conditions ||
        detail.conditions.indexOf(visitType) > -1 ||
        detail.conditions.indexOf(String(allergyType)) > -1
      );
    });

    items.forEach((item) => {
      if (!item.global) {
        if (item.details) {
          item.details = item.details.concat(globals);
        } else {
          item.details = globals;
        }
        item.global = true;
      }
    });
  };

  const flattenItem = (systemKey: number) => {
    const items: ICatalogItem[] = JSON.parse(
      JSON.stringify(catalog.items![catalog.systems![systemKey]]),
    );
    mapGlobalDetails(items);
    return {
      systemKey: systemKey,
      systemName: catalog.systems![systemKey],
      items: items,
    };
  };
  let flatItemOrder = order.map(flattenItem);
  return { itemOrder: flatItemOrder, visitNameKey: visitNameKey };
};

type Props = {
  reviewOfSystems: IUserMedicalIntake["currentSymptomsBySystem"];
  symptomDetails: IUserMedicalIntake["currentSymptoms"];
  pertinentNegatives?: number[];
  catalog: Partial<IReviewOfSystems>;
  visitType: string;
  allergyType?: string;
  onChanged: (updateObj: IUserMedicalIntake[keyof IUserMedicalIntake]) => void;
  isEditing?: boolean;
  readOnly?: boolean;
  restoreData?: IUserReviewOfSystemsKeys;
  title?: string;
  symptomsDetailField: keyof IUserMedicalIntake;
  symptomByStystemField: keyof IUserMedicalIntake;
  perSystemTitles: boolean;
};

type State = {
  currentSymptoms: ISymptomsDetail[];
  itemOrder: ReturnType<typeof GetItemOrder>;
};

export type HandleChangeFuncCurrentSymptomsType = <field extends keyof IUserMedicalIntake>(
  key: field,
  newValue: IUserMedicalIntake[field],
) => void;

class CurrentSymptoms extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      currentSymptoms: [],
      itemOrder: { itemOrder: [], visitNameKey: "" },
    };
  }

  componentDidMount() {
    let currentSymptomWithGroup: State["currentSymptoms"] = [];

    const currentSymptomsKeys = this.props.reviewOfSystems.systems
      ? Object.keys(this.props.reviewOfSystems.systems)
      : [];

    this.props.symptomDetails.symptoms &&
      this.props.symptomDetails.symptoms.forEach((symptom) => {
        currentSymptomsKeys.forEach((key) => {
          if (this.props.reviewOfSystems?.systems![key].indexOf(symptom.key) > -1) {
            currentSymptomWithGroup.push({
              key: symptom.key,
              details: symptom.details,
              group: key,
            });
          }
        });
      });
    this.setState({
      currentSymptoms: this.state.currentSymptoms.concat(currentSymptomWithGroup),
    });
  }

  componentDidUpdate(prevProps: Props) {
    if (!isEqual(prevProps.catalog, this.props.catalog)) {
      const visitType = this.props.visitType;
      const catalog = this.props.catalog || {};
      const allergyType = this.props.allergyType ? this.props.allergyType : null;
      let itemOrder = GetItemOrder(visitType, catalog, allergyType);

      this.setState({ itemOrder: itemOrder });
    }
  }

  handleChanged: HandleChangeFuncCurrentSymptomsType = (key, newValue) => {
    this.props.onChanged({ [key]: newValue });
  };

  render() {
    const ros = this.props.reviewOfSystems || { systems: [] };
    const symptomDetails = this.props.symptomDetails || {};
    const catalog = this.props.catalog || {};
    const allergyType = this.props.allergyType ? this.props.allergyType : null;
    let itemOrder = this.state.itemOrder;
    if (this.props.isEditing && !this.props.readOnly) {
      const oros = this.props.restoreData || {};
      return (
        <CurrentSymptomsEditor
          visitType={itemOrder.visitNameKey}
          ros={ros}
          itemOrder={itemOrder.itemOrder}
          details={symptomDetails}
          catalog={catalog}
          title={this.props.title}
          onChange={this.handleChanged}
          allergyType={allergyType}
          symptomsDetailField={this.props.symptomsDetailField}
          symptomByStystemField={this.props.symptomByStystemField}
        />
      );
    } else {
      return (
        <CurrentSymptomsView
          ros={ros}
          pertinentNegatives={this.props.pertinentNegatives}
          itemOrder={itemOrder.itemOrder}
          details={symptomDetails}
          catalog={catalog}
          title={this.props.title || ""}
          perSystemTitles={this.props.perSystemTitles}
        />
      );
    }
  }
}

export default CurrentSymptoms;
