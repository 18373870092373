import React, { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import Octicon, { ChevronRight } from "@githubprimer/octicons-react";
import {
  Table,
  Button,
} from "reactstrap";
import Select from "react-select";

import { store } from "../../store";
import { NAVIGATE_TO } from "../../constants/actionTypes";
import { STATES, defaultColumns, defaultSearchParameters } from "../../constants/Providers";
import AuditInfo from "./AuditInfo";
import {
  hasPermission,
  PERMISSION_MANAGE,
  PERMISSION_MEDICAL_DIRECTOR,
  PERMISSION_SUPERVISING_PHYSICIAN,
} from "../../constants/Permissions";
import { IProviderFilterListItem, ICurrentUser, IProviderSearchParameters } from "../../constants/Types";
import Search from "./Search";
import TableColumnsModal from "./TableModal";
import PageLoadSpinner from "../PageLoadSpinner";

const EmptyComponent = () => null;

const statusFilterOptions = [
  { value: "active", label: "Active" },
  { value: "disabled", label: "Disabled" },
];

const stateChoices = Object.keys(STATES).map((state) => `${state} - ${STATES[state]}`);

export const calculatePercentage = (audited, finished) => {
  if (!audited && !finished) {
    return 0;
  } else {
    return Math.floor((audited / finished) * 100);
  }
};

type ProviderItemProps = {
  provider: IProviderFilterListItem;
  currentUser: ICurrentUser;
  // onChange: Function;
  index: number;
  isDirector: boolean;
  seesAuditProgress: boolean;
  hasEdit: boolean;
  columns: string[];
};

const ProviderItem: FC<ProviderItemProps> = ({
  provider,
  currentUser,
  // onChange,
  index,
  isDirector,
  seesAuditProgress,
  hasEdit,
  columns,
}) => {
  const canManage = currentUser && hasPermission(currentUser.permissions, PERMISSION_MANAGE);
  const hasSupervisor = !!provider?.MedicalDirectors;

  const edit = (id: string) => () => {
    store.dispatch({ type: NAVIGATE_TO, targetPath: `/providers/edit/${id}` });
  };
  const audit = (id: string) => () => {
    store.dispatch({ type: NAVIGATE_TO, targetPath: `/providers/audit/${id}` });
  };

 
  const location = provider.Capabilities.geographic
    ? provider.Capabilities.geographic.join(", ")
    : provider.state;

  // A supervising physician should only see the “audit” button for providers assigned to them
  // medical director can audit any provider
  const currentUserId = currentUser?.providerID || "";

  let canAuditProvider = false;
  if (provider.MedicalDirectors) {
    canAuditProvider = Object.values(provider.MedicalDirectors).reduce((accumulator, id) => {
      return accumulator || id === currentUserId;
    }, false as boolean);
  }

  const showAuditButton = (isDirector || canAuditProvider) && provider?.auditStatistics;
  return (
    <tr>
      {columns.includes("providerName") && <td className="provider-name-td">
        {" "}
        {provider.Prefix} {provider.FirstName} {provider.LastName} {provider.Suffix}{" "}
      </td>}
      {columns.includes("location") && <td > {location} </td>}
      {columns.includes("phone") && <td className="provider-phone-td">{provider.Phone} </td>}
      {columns.includes("status") && <td  style={{textTransform: "capitalize"}}>
            <div className="mt-1"> {provider.Active} </div>
      </td>}
      {columns.includes("practice") && <td  style={{textTransform: "capitalize"}}>
        <div className="mt-1">{provider?.MedicalPracticeName?.toString()}</div>
      </td>}
      {columns.includes("demographic") && <td  style={{textTransform: "capitalize"}}>
        <div className="mt-1">{provider?.Capabilities?.demographic?.toString()}</div>
      </td>}
      {columns.includes("language") &&  <td  style={{textTransform: "capitalize"}}>
        <div className="mt-1">{provider?.Capabilities?.linguistic?.toString()}</div>
      </td>}
      {columns.includes("permissions") &&  <td  style={{textTransform: "capitalize"}}>
        <div className="mt-1">{provider?.Permissions?.toString()}</div>
      </td>}

      { columns.includes("auditStatistics") && 
      <>
      { seesAuditProgress && hasSupervisor ? (
        <td className="col-md-2 audit-progress">
          {provider.auditStatistics && provider.Capabilities?.geographic ? (
            <div className="d-flex flex-wrap">
              {provider?.Capabilities?.geographic.map((state, index) => {
                const stateAudited = provider?.auditStatistics?.stateAudited || {};
                const stateFinished = provider?.auditStatistics?.stateFinished || {};
                const visitsAudited = stateAudited[state] ? stateAudited[state] : 0;
                const visitsFinished = stateFinished[state] ? stateFinished[state] : 0;

                // only show audit percentage for states where a provider has a supervising physician assigned to that state
                const show =
                  !!provider?.MedicalDirectors &&
                  provider?.MedicalDirectors[state] !== undefined &&
                  (provider?.MedicalDirectors[state] === currentUserId ||
                    (currentUser?.permissions?.includes(PERMISSION_MEDICAL_DIRECTOR) &&
                      currentUser?.capabilities?.geographic.includes(state)));

                return show ? (
                  <AuditInfo
                    className="mt-1 audit-percent"
                    threshold={20}
                    percentage={calculatePercentage(visitsAudited, visitsFinished)}
                    key={`audit-${index}`}
                  >
                    <div>
                      <div className="audit-state">{STATES[state]}</div>
                      <div>{calculatePercentage(visitsAudited, visitsFinished)}%</div>
                    </div>
                  </AuditInfo>
                ) : null;
              })}
            </div>
          ) : (
            "N/A"
          )}
        </td>
      ) : (
        <td className="col-md-2 audit-progress">N/A</td>
      )}
      </>}
      <td >
        {hasEdit && (
          <Button color="link" size="sm" onClick={edit(provider.providerID)} className="qa-providerEdit">
            Edit
          </Button>
        )}
        {showAuditButton && (
          <Button
            color="primary"
            disabled={provider.id === currentUser.providerID}
            className="audit-button qa-providerAuditButton"
            onClick={audit(provider.providerID)}
          >
            Audit
            <Octicon size="small" icon={ChevronRight} className="chevron" />
          </Button>
        )}
      </td>
    </tr>
  );
};

type Props = {
  isLoading: boolean;
  items: IProviderFilterListItem[];
  permissions: string[];
  currentUser: ICurrentUser;
  // onProviderStateChange: Function;
  dropSearch: Function;
  allProviders: IProviderFilterListItem[];
  filters: { name: string; location: string; status: string };
  handleSearchParams: Function;
  handleCancel: Function;
  onPageChange: Function;
  page: number;
  pageStart: number;
  pageSize: number;
  totalCount: number;
};




const List: FC<Props> = ({
  isLoading,
  items,
  permissions,
  currentUser,
  // onProviderStateChange,
  dropSearch,
  allProviders,
  filters,
  handleSearchParams,
  handleCancel,
  onPageChange,
  page,
  pageStart,
  pageSize,
  totalCount,
  
}) => {
  const [showModal, setShowModal] = useState(false);
  const [columns, setColumns] = useState(defaultColumns);
  const [searchParams, setSearchParams] = useState<IProviderSearchParameters>(defaultSearchParameters);
  
  const providers = items || [];

  const theProviders = allProviders || [];
  const hasEdit = hasPermission(permissions, PERMISSION_MANAGE);
  const seesAuditProgress =
    hasPermission(permissions, PERMISSION_MEDICAL_DIRECTOR) ||
    hasPermission(permissions, PERMISSION_SUPERVISING_PHYSICIAN) ||
    hasPermission(permissions, PERMISSION_MANAGE);
  const isDirector = hasPermission(permissions, PERMISSION_MEDICAL_DIRECTOR);


  useEffect(() => {
    handleSearchParams({...searchParams, pageSize: pageSize, page: page, pageStart: pageStart});
  }, [searchParams])

  // handle setting columns from modal
  const handleColumnsChange = (columns: string[]) => {
    setColumns(columns);
  }

  // onClick search
  const handleSearch = (params: IProviderSearchParameters) => {
    setSearchParams(params);
  } 
  // onClick cancel
  const handleCancelSearch = () => {
    handleCancel();
  }

  // pagination handler
  const handlePageChange = (pageStart, pageSize, page) => {
    onPageChange(page, pageStart, pageSize);
  };

  return (
    <>
    {showModal && <TableColumnsModal showModal={showModal} setShowModal={setShowModal} selectedColumns={columns} setColumns={handleColumnsChange}  />}

    <Search  setShowModal={setShowModal} showModal={showModal} searchParams={searchParams} handleSearch={handleSearch} cancelSearch={handleCancelSearch} page={page} pageSize={pageSize} pageStart={pageStart} totalCount={totalCount} handlePageChange={handlePageChange}/>

    {isLoading ? ( <PageLoadSpinner inProgress={isLoading} /> ) : (
<div className="table-wrapper" style={{overflowX: "auto"}}>
  
    <Table className="provider-list table-container" >
      <thead>
        <tr className="provider-header">
         {columns.includes("providerName") && <th >
         Provider
          </th>}
          {columns.includes("location") && <th >
          Location
          </th>}
          {columns.includes("phone") && <th >
            <span className="qa-providerHeaderPhone">Phone</span>
          </th>}
          {columns.includes("status") && <th >
            Status
          </th>}
          {columns.includes("practice") && <th >
                  <span>Practice</span>
            </th>}
          {columns.includes("demographic") && <th >
                  <span>Demographic(s)</span>
            </th>}
            {columns.includes("language") && <th >
                  <span>Language(s)</span>
            </th>}
            {columns.includes("permissions") && <th >
                  <span>Permissions(s)</span>
            </th>}
          {seesAuditProgress && columns.includes("auditStatistics") && (
            <th className="audit-progress-head">
              <span className="qa-providerHeaderAuditProgress" >Audit progress this month</span>
            </th>
          )}
          <th ></th>
        </tr>
      </thead>
      {items.length > 0 &&
      <tbody>
        {providers.map((provider, idx) => (
          <ProviderItem
            currentUser={currentUser}
            hasEdit={hasEdit}
            isDirector={isDirector}
            seesAuditProgress={seesAuditProgress}
            key={`item-${idx}`}
            index={idx}
            provider={provider}
            // onChange={onProviderStateChange}
            columns={columns}
          />
        ))}
      </tbody> 
}
    </Table>
    {!items.length && <div className="no-results-container">
                <div className="no-results-text">No results fit these search criteria.</div>
                <div className="no-results-sub-text">
                  Please try removing some search terms or <br /> start a new search to see results
                  here.
                </div>
    </div>}
              
    </div>
    )}
    </>
  );
};

const mapStateToProps = (state) => ({
  permissions: state.common.permissions,
  currentUser: state.common.currentUser,
});

export default connect(mapStateToProps, {})(List);
