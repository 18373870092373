import React, { FC, useState } from "react";
import "react-dates/initialize";
import { SingleDatePicker } from "react-dates";

import "react-dates/lib/css/_datepicker.css";
import "./EMDatePicker.scss";

type Props = {
  handleDateChange: (data: any) => void;
  date: any;
  placeholder: string;
};

const DatePicker: FC<Props> = ({ date, handleDateChange, placeholder }) => {
  const [focusInput, setFocusInput] = useState(false);

  return (
    <div className="date-range-picker-wrapper ">
      <SingleDatePicker
        date={date}
        onDateChange={(date) => handleDateChange(date)}
        focused={focusInput}
        onFocusChange={() => setFocusInput(!focusInput)}
        displayFormat="MM/DD/YYYY"
        hideKeyboardShortcutsPanel
        numberOfMonths={1}
        id="singleDatePicker"
        placeholder={placeholder}
        readOnly
        isOutsideRange={() => false}
      />
    </div>
  );
};

export default DatePicker;
