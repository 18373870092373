import React from "react";
import PropTypes from "prop-types";
import Spinner from "../images/Spinner.svg";

import { Row, Col } from "reactstrap";

const PageLoadSpinner = ({ inProgress }) => {
  if (!inProgress) {
    return null;
  }
  return (
    <Row>
      <Col md={{ size: 2, offset: 5 }}>
        <Spinner />
      </Col>
    </Row>
  );
};

PageLoadSpinner.propTypes = {
  inProgress: PropTypes.bool,
};

export default PageLoadSpinner;
