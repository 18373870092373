import React, { Component, ReactText } from "react";
import { connect } from "react-redux";
import debounce from "lodash.debounce";
import isEqual from "react-fast-compare";
import pick from "lodash.pick";
import update from "immutability-helper";
import moment from "moment";
import momentz from "moment-timezone";
import { Button, Input, Label } from "reactstrap";
import { RouteComponentProps } from "react-router-dom";
import { RequisitionVisitTypes } from "../../constants/RequisitionTypes";

import "../../stylesheets/encounter.scss";

import PatientHeader, {
  PATIENT_INFO_TAB,
  DIAGNOSIS_TAB,
  PHYSICAL_EXAM_TAB,
  SKIN_ALLERGY_TEST_TAB,
  TREATMENT_PLAN,
  REQ_PDF,
} from "./PatientHeader";
import Diagnosis from "./Diagnosis";
import PatientInfo from "./PatientInfo";
import SkinAllergyTest from "./SkinAllergyTest";
import TreatmentPlan from "./TreatmentPlan";
import { isPCCUser } from "../../constants/Providers";
import {
  ENCOUNTER_DETAILS,
  ENCOUNTER_ENGAGEMENT_STATUS,
  ENCOUNTER_COMPLETE,
  ENCOUNTER_LOAD_NOTE,
  ENCOUNTER_NOTE_FIELD_UPDATE,
  ENCOUNTER_SAVE_NOTE,
  ENCOUNTER_UPDATE_PROFILE,
  ENCOUNTER_UPDATE_INTAKE,
  NAVIGATE_TO,
  ENCOUNTER_SAVE_PROFILE,
  ENCOUNTER_SAVE_INTAKE,
  ENCOUNTER_UPDATE_TEST_RESULT,
  ENCOUNTER_SAVE_PROVIDER_TEST_RESULT,
  ENCOUNTER_GET_PRESCRIPTION_LINK,
  SET_IDLE_LOGOUT_TIME,
  ENCOUNTER_ABANDON,
  ENCOUNTER_VIDEO_STOP,
  WAITING_ROOM_MESSAGE,
  ENCOUNTER_SAVED,
  ENCOUNTER_LOAD_BLANK_LTC_LINKS,
  SWITCH_PRACTICE,
  COMPONENT_LOAD,
  COMPONENT_UNLOAD,
  ENCOUNTER_SAVE_MESSAGE,
  ENCOUNTER_ADDITIONAL_STATUS,
  ENCOUNTER_UNLOAD_NOTE_PDF,
  ENCOUNTER_CHAT_DATA,
  ENCOUNTER_IMAGES, ENCOUNTER_CHAT_TEMPLATE,
} from "../../constants/actionTypes";
import { IN_ENCOUNTER, visitNameLookup } from "../../constants/Encounter";
import {
  ltcRequiredDiagnosisFields,
  defaultRequiredDiagnosisFields,
  coronavirusRequiredDiagnosisFields,
} from "../../constants/Diagnosis";
import { heartSoundRequiredFeatures } from "../../constants/PhysicalExams";
import api from "../../api";
import PageLoadSpinner from "../PageLoadSpinner";
import VideoSession from "./VideoSession";
import ResultConfirmation from "./ResultConfirmation";
import NoteReview from "./NoteReview";
import PhysicalExam from "./PhysicalExam";
import ErrorList from "../ErrorList";
import ModalMessage from "../ModalMessage";
import ModalPopup from "../ModalPopup";
import PrescriptionPoller from "./PrescriptionPoller";
import PatientEndModal from "./PatientEndModal";
import AbandonConfirmModal from "./AbandonConfirmModal";
import CertifyReviewOfIntakeModal from "./CertifyReviewOfIntakeModal";
import EncounterPoller from "./EncounterPoller";
import TtyocareResultsConfirmation from "./TytocareResults";
import ChartResultsConfirmation from "./ChartResultsConfirmation";
import DigitalReview from "./DigitalReview";
import BlankVisit from "./BlankVisit";
import DeleteBin from "../../images/DeleteBin.svg";
import StrikeOutNote from "../StrikeOutNote";
import bugsnagClient from "../../services/bugsnag";
import { IAppState } from "../../reducer";
import {
  ICurrentUser,
  IEncounterDetailsResponse,
  IProviderNote,
  IProviderTestResult,
  IAssignableProviderItem,
  ITestResponse,
  IPatient,
  IChartEntry,
  IUserMedicalIntake,
  IMedicalProfile,
  IEncounterImages,
  IPreviousPDFNotes,
  IPhysicalExam,
} from "../../constants/Types";
import ChatHistory from "../EncounterMonitor/ChatHistory";
import { hasPermission, PERMISSION_SCRIBE } from "../../constants/Permissions";
import RxRequestVisit, { CURRENT } from "./RxRequestVisit";
import ReqPDFView from "./ReqPDFView";
import ImageGalleryModal, { IImageGalleryObject } from "../ImageGalleryModal";
import CheckToggle from "../CheckToggle";
import { toast } from "react-toastify";
import { checkIsJSON, errorLogger, showInIntakeNotification, showInWRNotification } from "../../utils";

export const getTitle = (
  initial: boolean,
  chiefComplaint: string,
  IPreviousPDFNotesDate?: IPreviousPDFNotes["finishDate"],
) => {
  return `${moment(IPreviousPDFNotesDate).format("MM/DD/YYYY")} - ${initial ? "Initial Visit for" : "Follow Up Visit for"
    } ${chiefComplaint}`;
};

const mapStateToProps = (state: IAppState) => {
  return {
    chatHistory: state.componentData[ENCOUNTER_CHAT_DATA],
    chatTemplates: state.componentData[ENCOUNTER_CHAT_TEMPLATE],
    currentUser: state.common.currentUser,
    permissions: state.common.permissions,
    encounterID: state.encounter.encounterID || "",
    engagementStatus: state.encounter.engagementStatus,
    details: !!state.encounter.details
      ? {
        ...{ medicalProfile: { providerNotes: "" } as Partial<IMedicalProfile> },
        ...state.encounter.details,
      }
      : undefined,
    storedProfile: state.encounter.storedProfile || {},
    storedIntake: state.encounter.storedIntake || {},
    note: { noNewTreatmentPlan: false, ...state.encounter.note }, // default the noNewTreatmentPlan, if not set
    noteLoaded: state.encounter.noteLoaded,
    inProgress: state.encounter.inProgress,
    prescriptionLink: state.encounter.prescriptionLink || { link: "" },
    resuming: state.encounter.resuming,
    userVideoState: state.encounter.userVideoState || "",
    providerVideoComplete: state.encounter.providerVideoComplete,
    videoStarted: state.encounter.videoStarted,
    videoWasStarted: state.encounter.videoWasStarted,
    userConnected: state.encounter.userVideoConnected,
    ejectMessage: state.waitingRoom.message,
    providerWasEjected: state.encounter.providerWasEjected,
    referenceID: state.encounter.referenceID,
    message: state.encounter.message,
    encounterSaved: state.encounter.encounterSaved,
    encounterExplicitResume: state.encounter.encounterExplicitResume,
    ltcLinkMappings: state.encounter.ltcLinkMappings || { templates: [] },
    encounterImages: state.componentData[ENCOUNTER_IMAGES],
    availablePatients: state.waitingRoom.available,
    availableLoaded: !!state.waitingRoom.availableLoaded,
    pendingPatients: state.waitingRoom.pending,
    pendingLoaded: !!state.waitingRoom.pendingLoaded,
  };
};

export const imagesToIImageGalleryObject = (
  imageBlock: string | null,
  images?: IEncounterImages,
) => {
  let imagesObjects: IImageGalleryObject[] = [];
  if (imageBlock === null || images === undefined) {
    return imagesObjects;
  }
  if (imageBlock === CURRENT) {
    imagesObjects = images.images!.map((el) => ({
      imageSrc: el.path,
      date: el.uploadedDate,
      thumbnailSrc: el.thumbnailPath,
      rawPath: el.rawImagePath,
    }));
  } else {
    imagesObjects = images.previousEncountersImages[+imageBlock]!.map((el) => ({
      imageSrc: el.path,
      date: el.uploadedDate,
      thumbnailSrc: el.thumbnailPath,
      rawPath: el.rawImagePath,
    }));
  }

  return imagesObjects;
};

const mapDispatchToProps = (dispatch: Function) => ({
  onClearChat: () => dispatch({ type: COMPONENT_UNLOAD, subtype: ENCOUNTER_CHAT_DATA }),
  onChatUpdate: (payload) =>
    dispatch({ type: COMPONENT_LOAD, subtype: ENCOUNTER_CHAT_DATA, payload }),
  loadDetails: (payload) => dispatch({ type: ENCOUNTER_DETAILS, payload }),
  loadNote: (payload) => dispatch({ type: ENCOUNTER_LOAD_NOTE, payload }),
  loadBlankLTCLinks: (payload) => dispatch({ type: ENCOUNTER_LOAD_BLANK_LTC_LINKS, payload }),
  encounterEngagementStatus: (payload) => dispatch({ type: ENCOUNTER_ENGAGEMENT_STATUS, payload }),
  completeEncounter: (payload, path: string) =>
    dispatch({
      type: ENCOUNTER_COMPLETE,
      targetPath: path,
      payload,
    }),
  updateNoteField: (field, value) => dispatch({ type: ENCOUNTER_NOTE_FIELD_UPDATE, field, value }),
  saveNote: (payload) => dispatch({ type: ENCOUNTER_SAVE_NOTE, payload }),
  returnToEncounterList: () => dispatch({ type: NAVIGATE_TO, targetPath: "/waiting-room" }),
  returnToPatientList: () => dispatch({ type: NAVIGATE_TO, targetPath: "/patients" }),
  returnToOverview: () => dispatch({ type: NAVIGATE_TO, targetPath: "/" }),
  updateProfile: (values) => dispatch({ type: ENCOUNTER_UPDATE_PROFILE, values: values }),
  saveProfile: (newProfile, payload) =>
    dispatch({ type: ENCOUNTER_SAVE_PROFILE, newProfile, payload }),
  updateIntake: (values) => dispatch({ type: ENCOUNTER_UPDATE_INTAKE, values: values }),
  saveIntake: (newIntake, payload) => dispatch({ type: ENCOUNTER_SAVE_INTAKE, newIntake, payload }),
  updateTestResult: (values) => dispatch({ type: ENCOUNTER_UPDATE_TEST_RESULT, values }),
  saveProviderTestResult: (payload) =>
    dispatch({ type: ENCOUNTER_SAVE_PROVIDER_TEST_RESULT, payload }),
  getPrescriptionLink: (payload) => dispatch({ type: ENCOUNTER_GET_PRESCRIPTION_LINK, payload }),
  setIdleTime: () => dispatch({ type: SET_IDLE_LOGOUT_TIME }),
  abandonEncounter: (payload) => dispatch({ type: ENCOUNTER_ABANDON, payload }),
  stopVideo: (payload) => dispatch({ type: ENCOUNTER_VIDEO_STOP, payload }),
  waitingRoomMessage: (message) => dispatch({ type: WAITING_ROOM_MESSAGE, message }),
  savedEncounter: (flag) => dispatch({ type: ENCOUNTER_SAVED, flag }),
  saveAddtionalStatus: (payload) => dispatch({ type: ENCOUNTER_ADDITIONAL_STATUS, payload }),
  setMessage: (message) => dispatch({ type: ENCOUNTER_SAVE_MESSAGE, message }),
  switchPracticeNoRefresh: (practiceID) =>
    dispatch({
      type: SWITCH_PRACTICE,
      payload: api.Authorization.switchPractice(practiceID),
      noRedirect: true,
    }),
  unloadNotePDF: () => dispatch({ type: ENCOUNTER_UNLOAD_NOTE_PDF }),
  onEncounterImagesUpdate: (payload) =>
    dispatch({ type: COMPONENT_LOAD, subtype: ENCOUNTER_IMAGES, payload }),
});

const openInNewTab = (url: string) => {
  var win = window.open(url, "_blank");
  if (win) {
    win.focus();
  }
};

type EncounterState = {
  selectedTab?: string;
  detailsLoaded: boolean;
  showNoteReview: boolean;
  unsavedSections: { [key: string]: boolean };
  missingDiagnosisFields: { [key in keyof (IProviderNote & IEncounterDetailsResponse)]?: boolean };
  missingRXRequestFields: { [key in keyof (IProviderNote & IEncounterDetailsResponse)]?: boolean };
  missingInHomeHealthFields: {
    [key in keyof (IProviderNote & IEncounterDetailsResponse)]?: boolean;
  };
  physicalExamUnselectedFields: boolean;
  chartTestResults: Partial<ITestResponse> | IChartEntry;
  chartResultsReviewed: boolean;
  exitToDashboard: boolean;
  exitToPatientChart: boolean;
  prescriptionError: string;
  chiefComplaint: string;
  vitals: string;
  explicitResume: boolean;
  providerNotes: string;
  prevProviderNotes: string;
  oldProviderNote: string;
  HPIChecked: boolean;
  unchangedHPI: boolean;
  treatmentPlan: string;
  isProviderConfirmed: boolean;
  providerConfirmedName: string;
  invalidPracticeMessage: string;
  deletingModal: boolean;
  medications: string[];
  effectiveDate: string;
  requisitionStatus: boolean;
  storedIntake: Partial<IUserMedicalIntake>;
  noNewTreatmentPlan: boolean;
  encounterFinishError: string;
  patientData: Partial<IPatient>;
  emCodesError: boolean;
  selectedProvider: Partial<IAssignableProviderItem>;
  requiredDiagnosisFields?: string[];
  isExistingUser?: boolean;
  openPrescriptionLink?: boolean;
  continueOrAbandon?: boolean;
  covid19Protocol?: boolean;
  pertinentMedicationsNotes?: string;
  exiting?: boolean;
  nextPage?: string;
  random?: number;
  confirmAbandon?: boolean;
  attemptReconnect?: boolean;
  userVideoFinished?: boolean;
  showReqPDF: boolean;
  imageModalOpened: boolean;
  imagesBlockContent: IImageGalleryObject[];
  imageBlockTitle: string;
  prescriptionValidated: boolean;
  imageIndex: number;
  peTabCheckboxDisabled: boolean;
  peTabCheckboxisChecked: boolean;
  pendingLoaded: boolean;
  availableLoaded: boolean;
};

class Encounter extends Component<EncounterProps, EncounterState> {
  tabContainerRef: any;
  myRef: any;

  constructor(props: EncounterProps) {
    super(props);
    this.state = {
      selectedTab: PATIENT_INFO_TAB,
      detailsLoaded: false,
      showNoteReview: false,
      unsavedSections: {},
      missingDiagnosisFields: {},
      missingRXRequestFields: {},
      missingInHomeHealthFields: {},
      physicalExamUnselectedFields: false,
      chartTestResults: {},
      chartResultsReviewed: true,
      exitToDashboard: false,
      exitToPatientChart: false,
      prescriptionError: "",
      chiefComplaint: "",
      vitals: "",
      explicitResume: false,
      providerNotes: "",
      prevProviderNotes: "",
      oldProviderNote: "",
      HPIChecked: false,
      unchangedHPI: true,
      treatmentPlan: "",
      isProviderConfirmed: false,
      providerConfirmedName: "",
      invalidPracticeMessage: "",
      deletingModal: false,
      medications: [],
      effectiveDate: "",
      requisitionStatus: false,
      storedIntake: {},
      noNewTreatmentPlan: false,
      encounterFinishError: "",
      patientData: {},
      emCodesError: false,
      selectedProvider: {},
      prescriptionValidated: true,
      showReqPDF: false,
      imageModalOpened: false,
      imageBlockTitle: "",
      imagesBlockContent: [],
      imageIndex: 0,
      peTabCheckboxDisabled: false,
      peTabCheckboxisChecked: false,
      pendingLoaded: false,
      availableLoaded: false,
    };
    this.tabContainerRef = React.createRef();
    this.onComplete = this.onComplete.bind(this);
    this.onNoteUpdate = this.onNoteUpdate.bind(this);
    this.onProfileChanged = this.onProfileChanged.bind(this);
    this.onIntakeChanged = this.onIntakeChanged.bind(this);
    this.saveNote = debounce(this.saveNote.bind(this), 500);
    this.onProfileSave = this.onProfileSave.bind(this);
    this.onIntakeSave = this.onIntakeSave.bind(this);
    this.onResultConfirmationChanged = this.onResultConfirmationChanged.bind(this);
    this.onResultConfirmationSave = this.onResultConfirmationSave.bind(this);
    this.onReviewNote = this.onReviewNote.bind(this);
    this.onCancelReview = this.onCancelReview.bind(this);
    this.onPrescriptionLink = this.onPrescriptionLink.bind(this);
    this.onCancelAbandon = this.onCancelAbandon.bind(this);
    this.onAbandon = this.onAbandon.bind(this);
    this.onConfirmAbandon = this.onConfirmAbandon.bind(this);
    this.assignProvider = this.assignProvider.bind(this);
    this.myRef = React.createRef();
  }
  updateChat = async (referenceID?: string) => {
    this.props.onChatUpdate(
      api.Encounters.encounterHistoryChat(referenceID || this.props.referenceID!),
    );
  };

  loadEncounterImages = async (referenceID?: string) => {
    this.props.onEncounterImagesUpdate(
      api.Encounters.encounterImages(referenceID || this.props.referenceID!),
    );
  };
  checkIfpeTabDeferred = (isDisabled) => {
    this.setState({
      peTabCheckboxDisabled: isDisabled,
    });
    if (isDisabled) {
      this.setState({
        peTabCheckboxisChecked: false,
      });
    }
  };
  peTabisNotEmpty = (physicalExam: Partial<IPhysicalExam>) => {
    const groupNotes = Object.values(physicalExam?.groupNotes || {}).filter((item) => item !== "");
    const checkFeatures = physicalExam?.features?.length! > 0;
    const physicalExamIsChanged = checkFeatures || groupNotes.length > 0;
    this.checkIfpeTabDeferred(physicalExamIsChanged);
  };

  componentDidMount() {
    if (!this.state.detailsLoaded) {
      if (this.props.encounterID) {
        try {
          this.props.loadDetails(api.Encounters.details(this.props.encounterID));
        } catch (e: any) {
          errorLogger(e)
        }
        try {
          this.props.loadNote(api.Encounters.note(this.props.encounterID));
        } catch (e: any) {
          errorLogger(e)
        }
        this.setState({ detailsLoaded: true });
      }
      this.props.encounterEngagementStatus(
        api.Encounters.engagementStatus().then((data) => {
          this.updateChat(data.referenceID);
          this.loadEncounterImages(data.referenceID);
          return data;
        }),
      );

      if (!this.props.resuming && this.props.message) {
        //clear message from a previous encounter
        this.props.setMessage("");
      }
    }

    this.setState({ explicitResume: !!this.props.encounterExplicitResume });
  }

  componentDidUpdate(prevProps: EncounterProps) {
    if (prevProps.encounterID !== this.props.encounterID && this.props.encounterID) {
      try {
        this.props.loadDetails(api.Encounters.details(this.props.encounterID));
      } catch (e: any) {
        errorLogger(e)
      }
      try {
        this.props.loadNote(api.Encounters.note(this.props.encounterID));
      } catch (e: any) {
        bugsnagClient.notify(e);
      }
      this.setState({ detailsLoaded: true });

      // we don't need to continue updating since the above "load" calls will initiate a future update
      return;
    }
    if (!isEqual(prevProps.storedIntake, this.props.storedIntake)) {
      // update the stored intake
      this.setState({ storedIntake: this.props.storedIntake });
    }
    if (
      !!this.props.note.physicalExam &&
      !isEqual(this.props.note.physicalExam, prevProps.note.physicalExam)
    ) {
      this.peTabisNotEmpty(this.props.note.physicalExam);
    }
    // if the peTabCheckboxisChecked add to the note
    if (!isEqual(this.props.note.physicalExam?.noPhysicalExamCheck, this.state.peTabCheckboxisChecked)) {
      this.onNoteUpdate("physicalExam", { ...this.props.note.physicalExam, noPhysicalExamCheck: this.state.peTabCheckboxisChecked });
    }

    const prevDetails = prevProps.details || ({} as Partial<IEncounterDetailsResponse>);
    const details = this.props.details || ({} as Partial<IEncounterDetailsResponse>);
    if (!!details.effectiveDate && prevDetails.effectiveDate !== details.effectiveDate) {
      // update the effective date
      let effectiveMoment = momentz.parseZone(
        this.props.details?.effectiveDate === "0001-01-01T00:00:00Z"
          ? this.getDefaultEffectiveDate()
          : this.props.details?.effectiveDate,
        ["YYYY-MM-DDTHH:mmZ", "YYYY-MM-DDTHH:mm:ss.SSSSZ"],
      );
      // check to see if the date is not valid
      let isValid = true;
      if (!effectiveMoment || !effectiveMoment.isValid()) {
        effectiveMoment = momentz.parseZone(this.getDefaultEffectiveDate());
        isValid = false;
      }

      const effectiveDate = effectiveMoment.isUTC()
        ? effectiveMoment.local().format("YYYY-MM-DDTHH:mmZ")
        : effectiveMoment.format("YYYY-MM-DDTHH:mmZ");
      this.onEffectiveDateChange(effectiveDate);
    }

    if (!prevProps.details?.visitType && this.props.details?.visitType) {
      let requiredDiagnosisFields: string[] = [];

      if (visitNameLookup[this.props.details.visitType] === "Coronavirus") {
        requiredDiagnosisFields = coronavirusRequiredDiagnosisFields;
      } else if (
        ["nursing_home", "rxrenewal", "blank_ltc"].includes(this.props.details.visitType!)
      ) {
        requiredDiagnosisFields = ltcRequiredDiagnosisFields;
      } else {
        requiredDiagnosisFields = defaultRequiredDiagnosisFields;
      }

      this.setState({ requiredDiagnosisFields: requiredDiagnosisFields });

      if (this.props.details.visitType === "blank_ltc") {
        this.props.loadBlankLTCLinks(api.Encounters.getBlankLTCMapping());
      }
    }

    if (
      this.props.details &&
      this.props.details.medicalIntake &&
      !prevProps.details?.medicalIntake
    ) {
      this.updateMedicalIntake(this.props.details.medicalIntake);
    }

    if (
      this.props.details &&
      this.props.details.medicalProfile &&
      prevProps.details &&
      prevProps.details.medicalProfile &&
      prevProps.details.medicalProfile.providerNotes !==
      this.props.details.medicalProfile.providerNotes
    ) {
      if (this.state.providerNotes === this.state.prevProviderNotes) {
        this.setState({ providerNotes: this.props.details.medicalProfile.providerNotes || "" });
      }

      this.setState({ prevProviderNotes: this.props.details.medicalProfile.providerNotes || "" });
      this.loadOldProviderNotes();
    }
    if (!prevProps.details?.patient && this.props.details?.patient) {
      api.UserRecords.chart(this.props.details.patient.userID, "").then((data) => {
        const lastVisit = data.items[1] ? moment(data.items[1].createdAt) : false;
        const existingUser = lastVisit && lastVisit > moment().subtract(2, "years"); // less than 2 years ago
        this.setState({ isExistingUser: existingUser });

        if (this.props.details?.visitType === "coronavirus_consult_and_testing") {
          const payload = JSON.parse(atob(this.props.encounterID.split(".")[1]));
          const pcrResults = data.items.filter((i) => {
            return (
              i.labStatus &&
              i.labStatus == "complete" &&
              i.referenceKey &&
              i.referenceKey.Kind == payload.kind
            );
          });
          if (pcrResults.length > 0) {
            this.setState({ chartTestResults: pcrResults[0] });
            this.setState({ chartResultsReviewed: false });
          }
        }

        if (this.props.details?.visitType === "covid_at_home_pcr") {
          api.Encounters.pcrTestResults(this.props.encounterID).then((data) => {
            this.setState({ chartTestResults: data });
            this.setState({ chartResultsReviewed: false });
          });
        }
      });
    }

    if (
      this.props.details &&
      this.props.details.medicalPractice !== prevProps.details?.medicalPractice
    ) {
      this.validateActivePractice();
    }

    if (this.props.engagementStatus && this.props.engagementStatus != IN_ENCOUNTER) {
      // encounter no longer engaged, exit the screen now
      if (!!this.state.exitToDashboard) {
        // encounter is not longer engaged and the request is to exit to the dashboard
        const dashPath = !!this.state.explicitResume ? "/" : "/patients";
        this.props.history.push(dashPath);
      } else {
        if (!this.props.ejectMessage && this.props.providerWasEjected) {
          this.props.waitingRoomMessage(
            "The encounter has been lost; you may accept another patient.",
          );
        }
        if (!this.state.exitToPatientChart) {
          this.props.returnToEncounterList();
        }
        // this.props.returnToEncounterList();
      }

      // exiting, return immediately
      return;
    }

    if (
      this.state.openPrescriptionLink &&
      this.props.prescriptionLink.link &&
      this.props.prescriptionLink.link != prevProps.prescriptionLink.link
    ) {
      this.setState({ openPrescriptionLink: false });
      openInNewTab(this.props.prescriptionLink.link);
    }

    if (
      !this.state.continueOrAbandon &&
      prevProps.userConnected &&
      !this.props.userConnected &&
      !this.props.providerVideoComplete
    ) {
      this.setState({ continueOrAbandon: true });
    }

    const prevPendingPatients: any[] = prevProps.pendingPatients || [];
    const pendingPatients: any[] = this.props.pendingPatients || [];
    // filter only new patients that appear in the pending list
    const newPendingPatients = pendingPatients.filter((patient) => {
      return (
        prevPendingPatients.findIndex(
          (prevPatient) => prevPatient.referenceID === patient.referenceID,
        ) < 0
      );
    });
    const wrPendingLength = localStorage.getItem("wrPendingLength");

    if (Number(wrPendingLength) && (Number(wrPendingLength) < pendingPatients.length)) {
      if (newPendingPatients.length > 0 && this.props?.userVideoState != "started") {
        showInIntakeNotification(this.props.currentUser)
      }
    }
    localStorage.setItem("wrPendingLength", String(pendingPatients.length))

    if (prevProps.pendingLoaded !== this.props.pendingLoaded) {
      this.setState({ pendingLoaded: this.props.pendingLoaded });
    }

    const prevAvailablePatients: any[] = prevProps.availablePatients || [];
    const availablePatients: any[] = this.props.availablePatients || [];

    const newAvailablePatients = availablePatients.filter((patient) => {
      return (
        prevAvailablePatients.findIndex(
          (prevPatient) => prevPatient.referenceID === patient.referenceID,
        ) < 0
      );
    });
    const wrAvailableLength = localStorage.getItem("wrAvailableLength");

    if (Number(wrAvailableLength) && (Number(wrAvailableLength) < availablePatients.length)) {
      if (newAvailablePatients.length > 0 && this.props?.userVideoState != "started") {
        showInWRNotification(this.props.currentUser)
      }
    }
    localStorage.setItem("wrAvailableLength", String(availablePatients.length))

    if (prevProps.availableLoaded !== this.props.availableLoaded) {
      this.setState({ availableLoaded: this.props.availableLoaded });
    }
  }

  updateMedicalIntake = (medicalIntake: Partial<IUserMedicalIntake> = {}) => {
    const chiefComplaint = medicalIntake.chiefComplaint || {};
    const complaint = chiefComplaint.complaint || "";

    const pertinentMedications =
      medicalIntake.pertinentMedications ||
      ({} as Partial<IUserMedicalIntake["pertinentMedications"]>);
    this.setState({
      chiefComplaint: complaint,
      covid19Protocol: pertinentMedications.covid19Protocol,
      pertinentMedicationsNotes: pertinentMedications.notes,
      vitals: medicalIntake.freeformVitals || "",
    });
  };

  validateActivePractice = () => {
    const details = this.props.details || ({} as Partial<IEncounterDetailsResponse>);
    const medicalPractice = details.medicalPractice || "";
    const currentUser: Partial<ICurrentUser> = this.props.currentUser || {};

    if (!!medicalPractice) {
      if ((currentUser.medicalPractices || [])?.includes(medicalPractice)) {
        // current user contians the medical practice, check practice switch is needed
        if (currentUser.currentPracticeID !== medicalPractice) {
          // a switch to a medical practice is required
          this.props.switchPracticeNoRefresh(medicalPractice);
        }
      } else {
        // current user does not contain the medical practice associated with this encounter, show error
        const practiceName = details.medicalPracticeName || "saved encounter's";
        const invalidPracticeMessage = `You are no longer a member of the ${practiceName} practice.  You are unable to complete this encounter.\n\nPlease contact support.`;
        this.setState({ invalidPracticeMessage });
      }
    }
  };

  setContinueOrAbandon = (value: boolean) => {
    this.setState({ continueOrAbandon: value });
  };

  onRemoveUnsavedSection = (section: string) => {
    let temp = Object.assign({}, this.state.unsavedSections);
    delete temp[section];
    this.setState({ unsavedSections: temp });
  };

  getDefaultEffectiveDate = () => {
    return (
      momentz()
        //use Central Standard Time by default
        .tz("America/Chicago")
        .format("YYYY-MM-DDTHH:mmZ")
    );
  };

  onEffectiveDateChange = async (date: string) => {
    try {
      await api.Encounters.updateEffectiveDate({ date }, this.props.encounterID);
      this.setState({ effectiveDate: date });
    } catch (err: any) {
      bugsnagClient.notify(err);
    }
  };

  imageArchiveHandler = async (rawImagePath: string) => {
    if (this.props.referenceID === null) {
      return;
    }

    try {
      await api.Encounters.archiveEncounterImage(this.props.referenceID, rawImagePath);
      this.loadEncounterImages(this.props.referenceID);
    } catch (err: any) {
      bugsnagClient.notify(err);
    }
  };

  handleImageIndexChange = (index: number) => {
    this.setState({ imageIndex: index });
  };

  imageModalOpenHandler = (title: string, imageBlock: ReactText, index?: number) => {
    this.setState({
      imageBlockTitle: title,
      imageModalOpened: true,
      imagesBlockContent: imagesToIImageGalleryObject(
        String(imageBlock),
        this.props.encounterImages?.data,
      ),
    });
    if (!!index) {
      this.setState({ imageIndex: index });
    }
  };

  openImageModalFromChatHandler = (rawPath: string) => {
    rawPath = rawPath.replace("thumbnail", "full-size");
    const imagesObj = this.props.encounterImages?.data;
    if (!imagesObj) {
      return;
    }
    let block: ReactText = CURRENT;

    if (imagesObj.images) {
      for (let [index, image] of imagesObj.images.entries()) {
        let img1 = image?.rawImagePath?.split(".");
        let img2 = rawPath?.split(".");
        if (img1[0] === img2[0]) {
          return this.imageModalOpenHandler("Current Visit Uploads", block, index);
        }
      }
    }
    for (let i = 0; i < imagesObj?.previousEncountersImages.length; i++) {
      for (let [index, image] of imagesObj?.previousEncountersImages[i].entries()) {
        let img1 = image?.rawImagePath?.split(".");
        let img2 = rawPath?.split(".");

        if (img1[0] === img2[0]) {
          block = i;
          const title = getTitle(
            i === 0,
            this.state.chiefComplaint,
            this.props.details?.previousPDFNotes &&
            this.props.details.previousPDFNotes[i].finishDate,
          );
          return this.imageModalOpenHandler(title, block, index);
        }
      }
    }

    return this.imageModalOpenHandler("Current Visit Uploads", block);
  };

  onProfileChanged = (section: string) => (newValues: any) => {
    if (section && !this.state.unsavedSections[section]) {
      this.setState({
        unsavedSections: { ...this.state.unsavedSections, [section]: false },
      });
    }
    //The if statement fixes this bug https://reliantid.atlassian.net/browse/MDBOX-5943
    //TODO- Clean up the logic
    if (newValues.providerNotes) {
      this.setState({ providerNotes: newValues.providerNotes || "" });
    } else {
      this.setState({ providerNotes: this.state.providerNotes });
    }

    this.props.setIdleTime();
    this.props.updateProfile(newValues);
  };

  saveIntakeThenRefresh = (encounterID: string, newValue: Partial<IUserMedicalIntake>) => {
    return new Promise((resolve, reject) => {
      // perform the save of the intake values
      api.Encounters.saveIntake(encounterID, newValue)
        .then((response) => {
          // resolve the save
          resolve(response);
        })
        .catch((error) => {
          reject(error);
          errorLogger(error)
        })
        .finally(() => {
          // now that the save has completed, refresh the details of the page (in case the save failed)
          this.props.loadDetails(api.Encounters.details(encounterID));
        });
    });
  };

  debounceCovidChange = debounce(
    async (pertinentMedications: Partial<IUserMedicalIntake["pertinentMedications"]>) => {
      // save the pertient covid changes, after a debounce check
      const newValue = update(this.state.storedIntake, {
        $merge: { pertinentMedications },
      });

      this.props.saveIntake(newValue, this.saveIntakeThenRefresh(this.props.encounterID, newValue));
    },
    500,
  );

  covidProtocolChange = (newValue: boolean) => {
    this.props.setIdleTime();
    this.setState({ covid19Protocol: !!newValue });
    this.debounceCovidChange({
      covid19Protocol: !!newValue,
      notes: this.state.pertinentMedicationsNotes,
    });
  };
  covidNoteChange = (newValue: string) => {
    this.props.setIdleTime();
    this.setState({ pertinentMedicationsNotes: newValue });
    this.debounceCovidChange({
      covid19Protocol: !!this.state.covid19Protocol,
      notes: newValue,
    });
  };

  onProviderNoteUpdate = (providerNotes: any) => {
    const pcc = isPCCUser(this.props.currentUser);
    // this section is save providerNotes if we in edit note, now its deprecated
    // if (pcc && !this.state.unsavedSections["providerNotesSection"]) {
    //   this.setState({
    //     unsavedSections: { ...this.state.unsavedSections, providerNotesSection: false },
    //   });
    // }

    this.props.setIdleTime();
    this.setState({ providerNotes });
    const changes = { providerNotes };
    const newValue = update(this.props.storedProfile, { $merge: changes });

    debounce(() => {
      this.props.saveProfile(
        newValue,
        api.Encounters.saveProfile(this.props.encounterID, newValue),
      );
    }, 1500)();
    // this.props.saveProfile(newValue, api.Encounters.saveProfile(this.props.encounterID, newValue));
  };

  onClosePracticeError = () => {
    this.onSaveAndExit();
  };

  onIntakeChanged = (section?: string) => (newValues: Partial<IUserMedicalIntake>) => {
    if (section && !this.state.unsavedSections[section] && section !== "ROS") {
      this.setState({
        unsavedSections: { ...this.state.unsavedSections, [section]: false },
      });
    }
    this.props.setIdleTime();
    this.props.updateIntake(newValues);
  };

  onROSIntakeChanged = (section?: string) => (newValues: any) => {
    if (section && !this.state.unsavedSections[section]) {
      this.setState({
        unsavedSections: { ...this.state.unsavedSections, [section]: false },
      });
    }
    this.props.setIdleTime();
    this.props.updateIntake(newValues);
  };

  onSaveAndExit = () => {
    this.setState({ exiting: true }, async () => {
      this.setState({ exitToDashboard: true });
      await api.Encounters.saveAndExit(this.props.encounterID);
      setTimeout(() => {
        this.props.savedEncounter(true);
      }, 1000);
    });
    this.props.onClearChat();
  };

  onToggleDeletingModal = async () => {
    this.setState({ deletingModal: !this.state.deletingModal });
  };
  onDeleteEnc = async () => {
    try {
      this.setState({ exitToDashboard: true });
      this.props.abandonEncounter(api.Encounters.deleteEncounter({}));
      this.props.onClearChat();
    } catch (e) {
      console.log(e);
    }
  };

  saveAddtionalStatus = async (status: string | string[], prescribeOutsideFormulary?: Boolean) => {
    try {
      const statuses = status === "" ? [] : Array.isArray(status) ? status : [status];

      if (prescribeOutsideFormulary != undefined) {
        await api.Encounters.setRequisitionStatus(
          { status: statuses, prescribeOutsideFormulary },
          this.props.encounterID,
        );
        this.props.saveAddtionalStatus(statuses);
      } else {
        await api.Encounters.setRequisitionStatus({ status: statuses }, this.props.encounterID);
        this.props.saveAddtionalStatus(statuses);
      }
    } catch (e: any) {
      bugsnagClient.notify(e);
    }
  };

  onProfileSave(parts: string[], details: EncounterProps["details"]) {
    if (!parts || parts.length === 0) {
      return;
    }

    const mp = details?.medicalProfile || this.props.details?.medicalProfile;
    let nd = { ...mp, providerNotes: this.state.providerNotes };
    const changes = pick(nd, parts);
    const newValue = update(this.props.storedProfile, { $merge: changes });

    //This check ensures backward compatibility
    if (!!details) {
      this.props.saveProfile(
        newValue,
        api.Encounters.saveProfile(this.props.encounterID, newValue),
      );
    } else {
      debounce(() => {
        this.props.saveProfile(
          newValue,
          api.Encounters.saveProfile(this.props.encounterID, newValue),
        );
      }, 1500)();
    }
    this.props.setIdleTime();
  }

  debounceIntakeSave = debounce(() => {
    this.props.saveIntake(
      this.state.storedIntake,
      this.saveIntakeThenRefresh(this.props.encounterID, this.state.storedIntake),
    );
  }, 1500);

  async onIntakeSave(parts?: string[], details?: any) {
    if (!parts || parts.length === 0) {
      return;
    }

    if (!!details) {
      const changes = pick(details.medicalIntake, parts);
      const newValue = update(this.state.storedIntake, { $merge: changes });
      this.setState({ storedIntake: newValue });
      try {
        await api.Encounters.saveIntake(this.props.encounterID, newValue);
      } catch (err: any) {
        errorLogger(err)
      }
    } else {
      const changes = pick(this.props.details?.medicalIntake, parts);
      const newValue = update(this.state.storedIntake, { $merge: changes });
      this.setState({ storedIntake: newValue }, () => {
        this.debounceIntakeSave();
      });
    }

    this.props.setIdleTime();
  }

  /*   async onROSIntakeSave(parts?: string[], details?: any) {
    if (!parts || parts.length === 0) {
      return;
    }
    const changes = pick(details.medicalIntake, parts);
    const newValue = update(this.state.storedIntake, { $merge: changes });
    this.setState({ storedIntake: newValue });
    await api.Encounters.saveIntake(this.props.encounterID, newValue);

    this.props.setIdleTime();
  } */

  async loadOldProviderNotes() {
    try {
      let result = await api.UserRecords.profile(this.props.details?.patient?.userID);
      const medicalProfile = result.medicalProfile || {};
      const patientData = result.patient || {};
      const oldProviderNote = medicalProfile.providerNotes || "";
      this.setState({ oldProviderNote, patientData });
    } catch (err: any) {
      console.log("error", err, err.details);
    }
  }

  onResultConfirmationChanged(newValues: IProviderTestResult) {
    const v = update(this.props.details?.testResult, {
      providerResult: { $set: newValues },
    });
    this.props.updateTestResult(v);
  }

  onChartResultsConfirmation = () => {
    this.setState({ chartResultsReviewed: true });
  };

  onResultConfirmationSave(data: IProviderTestResult) {
    this.props.saveProviderTestResult(
      api.Encounters.saveProviderTestResult(this.props.encounterID, {
        ...this.props.details?.testResult?.providerResult,
        ...data,
      }),
    );
  }

  validatepeTabRequiredFields = () => {
    let flag = true;
    const note = this.props.note;
    const features = note.physicalExam ? note.physicalExam.features || [] : [];
    const feature = features.find((e: any) => e.key === "heartSounds");

    if (feature) {
      if (feature.attributes?.includes("Abnormal")) {
        //check the required fields
        const featkeys = features.map((e: any) => e.key);
        flag = heartSoundRequiredFeatures.every((field) => featkeys?.includes(field));
        //validate note
        const sampleNote = "Describe all abnormalities";
        const note = this.props.note;

        const groupNote = note.physicalExam!.groupNotes["Heart"];
        flag = flag ? groupNote.trim() !== "" && !groupNote.includes(sampleNote) : flag;
        this.setState({ physicalExamUnselectedFields: !flag });
        return flag;
      }
    }

    this.setState({ physicalExamUnselectedFields: !flag });
    return true;
  };

  validatepiTabRequiredFields = () => {
    const pcc = isPCCUser(this.props.currentUser);
    let flag: boolean;
    const { providerNotes, oldProviderNote, HPIChecked } = this.state;
    if (
      (pcc && providerNotes === oldProviderNote && HPIChecked === false) ||
      Object.keys(this.state.unsavedSections).length > 0
    ) {
      flag = false;
    } else {
      flag = true;
    }
    if (pcc && providerNotes === oldProviderNote && HPIChecked === false) {
      this.setState({ unchangedHPI: false });
    } else {
      this.setState({ unchangedHPI: true });
    }

    return flag;
  };

  checkForHPIUpdate = (checkedValue: boolean) => {
    this.setState({ unchangedHPI: checkedValue, HPIChecked: checkedValue });
  };

  checkForErrors = (nextPage?: string) => {
    const pcc = isPCCUser(this.props.currentUser);
    const temp = {};
    for (let section in this.state.unsavedSections) {
      temp[section] = true;
    }
    this.setState({
      unsavedSections: temp,
    });
    if (
      (nextPage === PHYSICAL_EXAM_TAB && pcc == true) ||
      (nextPage === DIAGNOSIS_TAB && pcc == true)
    ) {
      //left it here just in case
      // return this.validatepiTabRequiredFields();
      return true;
    }
    if (nextPage === DIAGNOSIS_TAB) {
      return this.validatepeTabRequiredFields();
    }
    this.tabContainerRef.current.scrollTo(0, 0);
    this.props.setIdleTime();
    for (let section in this.state.unsavedSections) {
      return null;
    }

    return true;
  };

  onPageNavigation = (pageName?: string) => {
    window.scrollTo(0, 0);
    this.tabContainerRef.current.scrollTo(0, 0);
    this.props.setIdleTime();
    if (this.checkForErrors(pageName)) {
      if (this.props.details?.visitType === "nursing_home") {
        this.setState({
          nextPage: pageName,
        });
      }
      if (
        this.props.details?.visitType !== "nursing_home" &&
        this.props.details?.visitType !== "in_home_health"
      ) {
        this.setState({
          selectedTab: pageName,
          nextPage: pageName,
        });
      } else {
        let flag = true;
        if (pageName === TREATMENT_PLAN) {
          flag = this.validateDiagnosisFields();
        }
        if (flag && pageName === REQ_PDF) {
          this.setState({ selectedTab: pageName, showReqPDF: true });
        }
        if (flag) {
          this.setState({ selectedTab: pageName });
        } else {
          this.setState({ selectedTab: DIAGNOSIS_TAB }, () => {
            try {
              this.myRef.current.scrollIntoView({
                block: "end",
                inline: "nearest",
              });
            } catch (e) {
              console.log(e);
            }
          });
        }
      }
    }
  };

  saveNote() {
    this.props.setIdleTime();
    if (this.props.encounterID) {
      this.props.saveNote(api.Encounters.saveNote(this.props.encounterID, this.props.note));
    }
  }

  onNoteUpdate(field: string, value: any) {
    const setMissingDiagnosis = (missing) => {
      setTimeout(() => {
        this.setState({
          missingDiagnosisFields: {
            ...this.state.missingDiagnosisFields,
            ...missing,
          },
        });
      }, 0);
    };

    // check for missing "required" note fields
    if (!!this.state.requiredDiagnosisFields) {
      if (this.state.requiredDiagnosisFields.indexOf(field) > -1) {
        // set the missing status based on the value (null, or undefined) are considered missing
        setMissingDiagnosis({ [field]: value == null });
      }
    }

    if (
      ["nursing_home", "online_digital_review", "blank_ltc"].includes(
        this.props.details?.visitType!,
      )
    ) {
      // check the missing status for progress notes
      const note = this.props.note || {};
      const checkProviderConfirmationStatus = (
        treatmentPlan,
        isProviderConfirmed,
        providerConfirmedName,
        noNewTreatmentPlan,
      ) => {
        const plan = treatmentPlan || "";
        const name = providerConfirmedName || "";
        if (plan.length > 0) {
          if (!!noNewTreatmentPlan) {
            setMissingDiagnosis({ noNewTreatmentPlan: false });
          } else {
            // treatment plan available, check the confirmation checkbox and provider name status
            setMissingDiagnosis({
              isProviderConfirmed: !isProviderConfirmed,
              providerConfirmedName: name.length === 0,
            });
          }
        } else {
          // no treatment plan, no confirmation details should be missing
          setMissingDiagnosis({ providerConfirmedName: false, isProviderConfirmed: false });
        }
      };

      if (field === "noNewTreatmentPlan") {
        checkProviderConfirmationStatus(
          note.treatmentPlan,
          !!note.isProviderConfirmed,
          note.providerConfirmedName,
          !!value,
        );

        if (!!value) {
          // checking the no new updates should reset the provider confirmation and name
          this.props.updateNoteField("isProviderConfirmed", false);
          this.props.updateNoteField("providerConfirmedName", "");
        }
      } else if (field === "isProviderConfirmed") {
        checkProviderConfirmationStatus(
          note.treatmentPlan,
          !!value,
          note.providerConfirmedName,
          note.noNewTreatmentPlan,
        );

        if (!!value) {
          // checking the provider confirmation should reset the no new updates
          this.props.updateNoteField("noNewTreatmentPlan", false);
        }
      } else if (field === "providerConfirmedName") {
        checkProviderConfirmationStatus(
          note.treatmentPlan,
          !!note.isProviderConfirmed,
          value || "",
          note.noNewTreatmentPlan,
        );
      } else if (field === "treatmentPlan") {
        if ((value || "").length === 0) {
          // clear all confirmation selections
          this.props.updateNoteField("isProviderConfirmed", false);
          this.props.updateNoteField("providerConfirmedName", "");
          this.props.updateNoteField("noNewTreatmentPlan", false);
        }

        checkProviderConfirmationStatus(
          value,
          note.isProviderConfirmed,
          note.providerConfirmedName,
          note.noNewTreatmentPlan,
        );
      }
    }

    if (field === "timeSpent") {
      // update time spent immediately
      api.Encounters.updateTimeSpent({ timeSpent: value }, this.props.encounterID);
    }

    if (field === "chiefComplaint") {
      this.setState({
        chiefComplaint: value,
      });
    } else if (field === "vitals") {
      this.setState({
        vitals: value,
      });
    } else if (field === "emCodesError") {
      this.setState({
        emCodesError: value,
      });
    } else if (field === "noNewTreatmentPlan") {
      // update the field, but don't save
      this.props.updateNoteField(field, value);
      this.saveNote();
    } else {
      this.props.updateNoteField(field, value);
      this.saveNote();
    }
  }

  onDateRangePick = (startDate: string, endDate: string) => {
    api.Encounters.updateDateRange(
      {
        start: startDate,
        end: endDate,
      },
      this.props.encounterID,
    );
  };
  validateDiagnosisFields = () => {
    let flag = true;
    let temp: { [key in keyof (IProviderNote & IEncounterDetailsResponse)]?: boolean } = {};

    //for RX_reuests visits
    if (
      this.props.details?.visitType === "rx_request" &&
      (!this.props.details.additionalStatus || this.props.note.prescriptionDetails?.length === 0)
    ) {
      flag = false;
      temp["prescriptionStatus"] = true;
    }
    this.setState({ missingRXRequestFields: temp });

    for (const field of this.state.requiredDiagnosisFields || []) {
      if (this.props.note[field] == undefined) {
        flag = false;
        temp[field] = true;
      }
    }

    if (["nursing_home", "rxrenewal", "blank_ltc"].includes(this.props.details?.visitType || "")) {
      if (!this.props.note.emCodes || (this.props.note.emCodes || []).length === 0) {
        flag = false;
        temp.emCodes = true;
      } else if (this.state.emCodesError) {
        flag = false;
      }
    }

    if (RequisitionVisitTypes.includes(this.props.details?.visitType!)) {
      if (
        this.props.details &&
        !this.props.details.additionalStatus &&
        this.state.requisitionStatus === false
      ) {
        flag = false;
        temp.additionalStatus = true;
      }
    }
    if (
      this.props.note.coronavirusFollowup &&
      Object.keys(this.props.note.coronavirusFollowupDetails!).length < 1
    ) {
      flag = false;
      temp.coronavirusFollowup = true;
    }

    if (
      ["nursing_home", "online_digital_review", "blank_ltc"].includes(
        this.props.details?.visitType!,
      )
    ) {
      // check the validity of the treatment plan and provider confirmation
      if (!this.props.note.diagnosis && this.props.details?.visitType !== "online_digital_review") {
        flag = false;
        temp.diagnosis = true;
      }
      if (!!this.props.note.treatmentPlan && this.props.note.treatmentPlan.length > 0) {
        // treatment plan exists, verify the confirmations are present
        if (!this.props.note.isProviderConfirmed && !this.props.note.noNewTreatmentPlan) {
          flag = false;
          temp.isProviderConfirmed = true;
          temp.noNewTreatmentPlan = true;
        } else if (!this.props.note.noNewTreatmentPlan) {
          // "no new treatment plan" checkbox is not checked, check the provider check
          if (!this.props.note.isProviderConfirmed) {
            flag = false;
            temp.isProviderConfirmed = true;
          }
          if (
            (!this.props.note.providerConfirmedName ||
              this.props.note.providerConfirmedName.length === 0) &&
            this.props.details?.visitType !== "online_digital_review"
          ) {
            flag = false;
            temp.providerConfirmedName = true;
          }
        }
      }
    }

    if (["rx_request", "in_home_health"].includes(this.props.details?.visitType!)) {
      flag = true;
      if (!this.props.details?.additionalStatus?.length) {
        flag = false;
        temp.additionalStatus = true;
      }
    }

    this.setState({ missingDiagnosisFields: temp });
    return flag;
  };

  onReviewNote() {
    const flag = this.validateDiagnosisFields();
    if (flag) {
      if (this.props.details?.visitType === "nursing_home") {
        api.Encounters.updateChiefComplaint(
          { chiefComplaint: this.state.chiefComplaint },
          this.props.encounterID,
        ).then(() => this.setState({ showNoteReview: true, random: Math.random() }));
      } else if (
        RequisitionVisitTypes.includes(this.props.details?.visitType!) &&
        !!this.state.showReqPDF
      ) {
        this.setState({ showReqPDF: false, showNoteReview: true, random: Math.random() });
      } else {
        this.setState({ showNoteReview: true, random: Math.random() });
      }

      this.props.setIdleTime();
      window.scrollTo(0, 0);
    } else {
      this.setState({ selectedTab: DIAGNOSIS_TAB }, () => {
        if (this.myRef.current === null) {
          return;
        }
        try {
          this.myRef.current.scrollIntoView({
            block: "end",
            inline: "nearest",
          });
        } catch (e) {
          console.log(e);
        }
      });
    }
  }

  async assignProvider() {
    if (!this.state.selectedProvider.id) {
      return;
    }

    try {
      await api.Encounters.assignProvider(
        { providerID: this.state.selectedProvider.id },
        this.props.encounterID,
      );
      this.props.returnToPatientList();
    } catch (e: any) {
      bugsnagClient.notify(e);
      const status = e.response.status;
      if (status === 422) {
        errorLogger(e)
      }
    }
  }

  onCancelReview() {
    if (
      RequisitionVisitTypes.includes(this.props.details?.visitType!) &&
      !!this.props.details?.additionalStatus &&
      this.props.details?.additionalStatus.includes("approved") &&
      this.props.details?.additionalStatus.includes("labRequisition")
    ) {
      this.setState({ selectedTab: REQ_PDF, showReqPDF: true, showNoteReview: false });
    } else {
      this.setState({ selectedTab: DIAGNOSIS_TAB });
    }
    this.setState({ showNoteReview: false });
  }

  async onComplete() {
    const visitType = this.props.details?.visitType!;
    const userID: string = this.props.details?.patient?.userID || "";
    const chartVisits = ["nursing_home", "blank_ltc"];
    let path = "waiting-room";

    if (chartVisits.includes(visitType)) {
      this.setState({ exitToPatientChart: true });
      path = `/patients/${userID}`;
    }
    try {
      this.props.completeEncounter(await api.Encounters.finish(this.props.encounterID), path);

      //delete any state stored in the local storage,for compled encounter
      let prescriptionStatus = window.localStorage.getItem(
        "prescriptionStatus" + this.props.referenceID,
      );
      if (prescriptionStatus) {
        window.localStorage.removeItem("prescriptionStatus" + this.props.referenceID);
      }
      if (this.props.details?.asyncChat) {
        this.props.onClearChat();
      }
    } catch (e: any) {
      if (this.props.currentUser?.isBetaTester) {
        errorLogger(e)
      }
      this.setState({
        encounterFinishError: e.response.body.status,
      });
    }
  }

  onAbandon() {
    this.setState({ continueOrAbandon: false, confirmAbandon: true });
  }

  onReconnect = () => {
    this.setState({
      continueOrAbandon: false,
      confirmAbandon: false,
      attemptReconnect: !this.state.attemptReconnect,
    });
  };

  onCancelAbandon() {
    this.props.stopVideo(api.Encounters.videoComplete(this.props.encounterID));
    this.setState({ continueOrAbandon: false, confirmAbandon: false });
  }

  onResumeEncounter = () => {
    this.setState({
      continueOrAbandon: false,
      confirmAbandon: false,
    });
  };

  onSelectProvider = (provider: IAssignableProviderItem) => {
    this.setState({ selectedProvider: provider });
  };

  onConfirmAbandon(selectedReason: string) {
    const reason = selectedReason ? selectedReason : "Patient disappeared from video connection.";
    this.props.abandonEncounter(api.Encounters.abandon(this.props.encounterID, reason));
    this.setState({ continueOrAbandon: false, confirmAbandon: false });
  }

  async onPrescriptionLink() {
    this.setState({ openPrescriptionLink: true });
    this.onNoteUpdate("prescriptionStarted", true);
    try {
      this.props.getPrescriptionLink(api.Encounters.prescriptionLink(this.props.encounterID));
      this.setState({
        prescriptionError: "",
      });
    } catch (err: any) {
      console.log(err.response);
      this.setState({
        prescriptionError: err.response.body.status,
      });
      bugsnagClient.notify(err);
    }
  }
  displayButton = () => { };

  onUserVideoFinished = () => {
    this.setState({ userVideoFinished: true });
  };

  onPrescribtionValidatedWithDosespot = (status) => {
    this.setState({ prescriptionValidated: status });
  };

  onRequisitionStatusSelected = (status: { status: string[] }) => {
    this.setState({ requisitionStatus: true });
    const statuses = status.status ? status.status : [];
    api.Encounters.setRequisitionStatus({ status: statuses }, this.props.encounterID);
  };

  onBackReqPDF = () => {
    this.setState({ selectedTab: DIAGNOSIS_TAB, showReqPDF: false });
    this.props.unloadNotePDF();
  };

  render() {
    const details = this.props.details as Partial<IEncounterDetailsResponse>;
    const effectiveDate = this.state.effectiveDate;

    const patient = this.props.details?.patient;

    const profile = this.props.details?.medicalProfile;
    const testResult = this.props.details?.testResult;
    const attachments = this.props.details?.attachments;
    const intake = this.props.details?.medicalIntake;
    const usedTytoCare = this.props.details?.usedTytoCare || null;
    const telemedExtender = this.props.details?.telemedExtender;
    const currentUser = this.props.currentUser;
    const note = this.props.note;
    const exiting = this.state.exiting;
    const chiefComplaint = this.state.chiefComplaint;
    const vitals = this.state.vitals;
    const timeout = usedTytoCare ? 240.0 : 120.0;
    const visitType = this.props.details?.visitType;

    const isAllergySkinTest = visitType === "rash_and_allergy" && telemedExtender;
    if (!this.props.encounterID || !patient || !profile || !intake || exiting) {
      // TODO: This spins forever if there was an error loading things...
      return <PageLoadSpinner inProgress={true} />;
    }
    const providerResult: Partial<IProviderTestResult> = testResult
      ? testResult.providerResult || {}
      : {};
    const showConfirmation =
      testResult &&
      testResult.result &&
      !providerResult.recorded &&
      testResult.state === "complete";
    const showNoteReview = this.state.showNoteReview;

    const dxTab = this.state.selectedTab == DIAGNOSIS_TAB;
    const piTab = this.state.selectedTab == PATIENT_INFO_TAB;
    const peTab = this.state.selectedTab == PHYSICAL_EXAM_TAB;
    const SaTab = this.state.selectedTab == SKIN_ALLERGY_TEST_TAB;
    const ttTab = this.state.selectedTab == TREATMENT_PLAN;

    const isPCCPatient = isPCCUser(currentUser);
    const isNursingHomeSite = visitType === "nursing_home";

    const isAsyncVisit = this.props.details?.asyncChat;
    const previousPDFNotes = this.props.details?.previousPDFNotes;
    const isScribe = hasPermission(this.props.permissions || [], PERMISSION_SCRIBE);
    // const requisitionVisit = RequisitionVisitTypes.includes(visitType!);
    const isSinglePage = ["online_digital_review", "blank_ltc"].includes(visitType!);
    const showttTab = !isNursingHomeSite && !isAsyncVisit;
    const showttTabtwo = visitType !== "rxrenewal" && visitType !== "in_home_health";
    const reqStatusApproved =
      this.props.details?.additionalStatus?.includes("approved") ||
      this.props.details?.additionalStatus?.includes("labRequisition");
    const videoFinished = this.state.userVideoFinished || this.props.providerVideoComplete;
    const supressVideoModal = this.state.continueOrAbandon || this.state.confirmAbandon;
    let submitDisabled = videoFinished
      ? false
      : !this.props.videoWasStarted || this.props.videoStarted;
    const showErrorMsg = !!Object.values(this.state.unsavedSections)[0];
    const testResultProps = {
      testResult: testResult,
      onSave: this.onResultConfirmationSave,
      onChange: this.onResultConfirmationChanged,
      timeout,
      telemedExtender: telemedExtender,
    };
    const showReqPDFTab = reqStatusApproved && dxTab;
    const statusCheck =
      visitType === "rx_request" &&
        this.props.details?.additionalStatus &&
        this.props.details?.additionalStatus.length === 0
        ? true
        : false;

    const setPhysicalExamDeffered = () => {
      this.setState({
        peTabCheckboxisChecked: !this.state.peTabCheckboxisChecked,
      });

    }

    return (
      <div className="encounter-view d-flex flex-column">
        <EncounterPoller />
        <PrescriptionPoller
          note={note}
          onUpdate={this.onNoteUpdate}
          encounterID={this.props.encounterID}
        />

        <ModalPopup
          message={this.state.invalidPracticeMessage}
          visible={
            !!this.state.invalidPracticeMessage && this.state.invalidPracticeMessage.length > 0
          }
          onClose={this.onClosePracticeError}
        />
        <StrikeOutNote
          visible={this.state.deletingModal}
          onCancel={this.onToggleDeletingModal}
          onDelete={this.onDeleteEnc}
        />
        {!videoFinished && this.state.continueOrAbandon && (
          <PatientEndModal
            onFinish={this.onCancelAbandon}
            onAbandon={this.onAbandon}
            onReconnect={this.onReconnect}
          />
        )}
        {!videoFinished && this.state.confirmAbandon && (
          <AbandonConfirmModal onAbandon={this.onConfirmAbandon} onReturn={this.onCancelAbandon} />
        )}
        {this.state.confirmAbandon && (
          <AbandonConfirmModal
            onAbandon={this.onConfirmAbandon}
            onReturn={this.onResumeEncounter}
          />
        )}
        {!this.state.chartResultsReviewed && (
          <ChartResultsConfirmation
            testResults={this.state.chartTestResults}
            onConfirm={this.onChartResultsConfirmation}
          />
        )}
        {!this.props.encounterExplicitResume && this.props.resuming && (
          <ModalMessage
            message="You have an unsigned note from your last patient that needs to be completed before proceeding"
            delay={3000}
          />
        )}
        {usedTytoCare ? (
          <div className="tytocare-results-view">
            <TtyocareResultsConfirmation testResultProps={testResultProps} onlyShowPopUp />
          </div>
        ) : (
          <ResultConfirmation {...testResultProps} />
        )}
        {!!this.props.referenceID && this.state.showReqPDF && (
          <ReqPDFView
            referenceID={this.props.referenceID || ""}
            visitType={visitType || ""}
            onBack={this.onBackReqPDF}
            onReviewNote={this.onReviewNote}
          />
        )}
        {this.state.showNoteReview && (
          <NoteReview
            onCancel={this.onCancelReview}
            onComplete={this.onComplete}
            encounterFinishError={this.state.encounterFinishError}
          />
        )}
        <div className="d-flex w-100">
          {isAsyncVisit && (
            <div className="chatContainer">
              <ChatHistory
                updateChat={this.updateChat}
                chatHistoryItems={this.props.chatHistory?.data?.chatHistoryItems || []}
                prevEncountersMessages={this.props.chatHistory?.data?.previousEncounterHistory}
                visitClosed={this.props.chatHistory?.data?.closed}
                encounterID={this.props.encounterID}
                location={patient.state}
                isInEncounter={true}
                setIdleTime={this.props.setIdleTime}
                message={this.props.message}
                onOpenImageModal={this.openImageModalFromChatHandler}
              />
            </div>
          )}

          {!videoFinished && (
            <div className="video-panel">
              {!showConfirmation && !videoFinished && (
                <VideoSession
                  onUserVideoFinished={this.onUserVideoFinished}
                  timeout={timeout}
                  videoStatus={this.props.userVideoState}
                  supressConfirmation={supressVideoModal}
                  telemedExtender={telemedExtender}
                  attemptReconnect={this.state.attemptReconnect}
                  onReconnect={this.onReconnect}
                  setContinueOrAbandon={this.setContinueOrAbandon}
                />
              )}
            </div>
          )}
          <div className="flex-grow-1">
            <div className="d-flex flex-column justify-content-start">
              <PatientHeader
                healthGorillaIsEnable={this.props.currentUser?.healthGorillaIsEnable}
                userID={this.props.details?.patient?.userID}
                isAsyncVisit={isAsyncVisit}
                patientData={this.state.patientData}
                isPCCPatient={isPCCPatient}
                isSinglePage={isSinglePage}
                videoFinished={videoFinished}
                facilityName={details?.medicalPracticeName}
                patient={patient}
                selected={this.state.selectedTab}
                showttTab={showttTab}
                showttTabtwo={showttTabtwo}
                testResult={testResult}
                visitType={visitType!}
                telemedExtender={telemedExtender}
                emergencyContact={profile.emergencyContact}
                preferredPharmacy={profile.preferredPharmacy}
                showErrorMsg={showErrorMsg}
                onPageNavigation={this.onPageNavigation}
                currentUser={currentUser}
              />
              <div className="encounter-tab-wrapper" ref={this.tabContainerRef}>
                <ErrorList errors={this.props.prescriptionLink.errors} />
                {peTab && visitType === "rx_request" && (
                  <div className="peTabDeferred">
                    <Label check>
                      <Input
                        type="checkbox"
                        onChange={() =>
                          setPhysicalExamDeffered()
                        }
                        checked={this.state.peTabCheckboxisChecked}
                        disabled={this.state.peTabCheckboxDisabled}
                      />
                      Physical exam deferred - None conducted at this time
                    </Label>
                  </div>
                )}
                <div className="encounter-tab">
                  {visitType === "online_digital_review" ? (
                    <DigitalReview
                      prescriptionError={this.state.prescriptionError}
                      onDateRangePick={this.onDateRangePick}
                      note={note}
                      profile={profile}
                      testResult={testResult}
                      visitType={visitType}
                      patient={patient}
                      encounterID={this.props.encounterID}
                      intake={intake}
                      noteLoaded={this.props.noteLoaded}
                      onUpdate={this.onNoteUpdate}
                      onPrescriptionLink={this.onPrescriptionLink}
                      isExistingUser={this.state.isExistingUser}
                      effectiveDate={effectiveDate}
                      isNursingHomeSite={isNursingHomeSite}
                      missingDiagnosisFields={this.state.missingDiagnosisFields}
                      onEffectiveDateChange={this.onEffectiveDateChange}
                      myref={this.myRef}
                    />
                  ) : visitType === "blank_ltc" ? (
                    <BlankVisit
                      isScribe={isScribe}
                      referenceID={this.props.referenceID}
                      onSelectProvider={this.onSelectProvider}
                      selectedProvider={this.state.selectedProvider}
                      currentUser={currentUser}
                      prescriptionError={this.state.prescriptionError}
                      onDateRangePick={this.onDateRangePick}
                      chiefComplaint={chiefComplaint}
                      note={note}
                      profile={profile}
                      testResult={testResult}
                      visitType={visitType}
                      patient={patient}
                      encounterID={this.props.encounterID}
                      intake={intake}
                      noteLoaded={this.props.noteLoaded}
                      onUpdate={this.onNoteUpdate}
                      onIntakeSave={this.onIntakeSave}
                      onPrescriptionLink={this.onPrescriptionLink}
                      isExistingUser={!!this.state.isExistingUser}
                      isNursingHomeSite={isNursingHomeSite}
                      missingDiagnosisFields={this.state.missingDiagnosisFields}
                      effectiveDate={effectiveDate}
                      onEffectiveDateChange={this.onEffectiveDateChange}
                      ltcLinkMappings={this.props.ltcLinkMappings}
                      myref={this.myRef}
                    />
                  ) : (
                    <>
                      {piTab && (
                        <PatientInfo
                          setImageIndex={this.handleImageIndexChange}
                          previousPDFNotes={previousPDFNotes}
                          encounterImages={this.props.encounterImages?.data}
                          imageModalOpenHandler={this.imageModalOpenHandler}
                          missingInHomeHealthFields={this.state.missingInHomeHealthFields}
                          readOnly={false}
                          vitals={vitals}
                          chiefComplaint={chiefComplaint}
                          medicalProfile={profile}
                          medicalIntake={intake}
                          testResult={testResult}
                          currentUser={currentUser}
                          visitType={visitType || ""}
                          details={details}
                          patient={patient}
                          note={note}
                          onNoteUpdate={this.onNoteUpdate}
                          onProfileChanged={this.onProfileChanged}
                          onProfileSave={this.onProfileSave}
                          onIntakeChanged={this.onIntakeChanged}
                          onIntakeSave={this.onIntakeSave}
                          onRemoveUnsavedSection={this.onRemoveUnsavedSection}
                          unsavedSections={this.state.unsavedSections}
                          providerNotes={this.state.providerNotes}
                          prevProviderNotes={this.state.oldProviderNote}
                          onProviderNoteUpdate={this.onProviderNoteUpdate}
                          HPIChecked={this.state.HPIChecked}
                          encounterID={this.props.encounterID}
                          covid19Protocol={this.state.covid19Protocol}
                          pertinentMedicationsNotes={this.state.pertinentMedicationsNotes}
                          covidProtocolChange={this.covidProtocolChange}
                          covidNoteChange={this.covidNoteChange}
                          checkForHPIUpdate={this.checkForHPIUpdate}
                          validateHPI={this.state.unchangedHPI}
                          referenceID={this.props.referenceID ? this.props.referenceID : ""}
                        />
                      )}
                      {peTab && (
                        <PhysicalExam
                          peTabisNotEmpty={this.peTabisNotEmpty}
                          note={note}
                          visitType={visitType!}
                          onUpdate={this.onNoteUpdate}
                          attachments={attachments}
                          usedTytoCare={usedTytoCare}
                          intake={intake}
                          onIntakeChanged={this.onIntakeChanged}
                          onIntakeSave={this.onIntakeSave}
                          onRemoveUnsavedSection={this.onRemoveUnsavedSection}
                          unsavedSections={this.state.unsavedSections}
                          heartSoundRequiredFeatures={heartSoundRequiredFeatures}
                          unselectedFields={this.state.physicalExamUnselectedFields}
                          isClinicalServices={telemedExtender}
                          testResultProps={testResultProps}
                          isNursingHomeSite={isNursingHomeSite}
                        />
                      )}
                      {SaTab && (
                        <SkinAllergyTest
                          details={details}
                          medicalIntake={intake}
                          onIntakeChanged={this.onIntakeChanged}
                          onIntakeSave={this.onIntakeSave}
                          onRemoveUnsavedSection={this.onRemoveUnsavedSection}
                          unsavedSections={this.state.unsavedSections}
                        />
                      )}
                      {dxTab && (
                        <Diagnosis
                          chiefComplaint={chiefComplaint}
                          onPrescriptionValidated={this.onPrescribtionValidatedWithDosespot}
                          currentUser={currentUser}
                          effectiveDate={effectiveDate}
                          prescriptionError={this.state.prescriptionError}
                          note={note}
                          details={details}
                          profile={profile}
                          testResult={testResult}
                          visitType={visitType!}
                          patient={patient}
                          saveAdditionalStatus={this.saveAddtionalStatus}
                          encounterID={this.props.encounterID}
                          intake={intake}
                          noteLoaded={!!this.props.noteLoaded}
                          onUpdate={this.onNoteUpdate}
                          onPrescriptionLink={this.onPrescriptionLink}
                          isExistingUser={this.state.isExistingUser}
                          isNursingHomeSite={isNursingHomeSite}
                          missingDiagnosisFields={this.state.missingDiagnosisFields}
                          onEffectiveDateChange={this.onEffectiveDateChange}
                          myref={this.myRef}
                          requisitionStatus={this.onRequisitionStatusSelected}
                          referenceID={this.props.referenceID || ""}
                          onSelectProvider={this.onSelectProvider}
                          selectedProvider={this.state.selectedProvider}
                          isScribe={isScribe}
                        />
                      )}
                      {ttTab && showttTab && showttTabtwo && (
                        <TreatmentPlan
                          note={note}
                          visitType={visitType}
                          currentSymptoms={intake.currentSymptoms}
                          patient={patient}
                          intake={intake}
                          onUpdate={this.onNoteUpdate}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.imageModalOpened && (
          <ImageGalleryModal
            isOpen={this.state.imageModalOpened}
            onClose={() =>
              this.setState({ imageModalOpened: false, imagesBlockContent: [], imageIndex: 0 })
            }
            onDelete={(currentImageIdx) => {
              this.setState((state) => ({
                imagesBlockContent: state.imagesBlockContent.filter(
                  (_, i) => i !== currentImageIdx,
                ),
              }));
              this.imageArchiveHandler(this.state.imagesBlockContent[currentImageIdx].rawPath);
            }}
            images={this.state.imagesBlockContent}
            title={this.state.imageBlockTitle}
            imageIdx={this.state.imageIndex}
          />
        )}

        <div className="encounter-bottom-container d-flex justify-content-end align-content-center pr-1 mt-4">
          {!videoFinished && (
            <Button onClick={this.onAbandon} className="visit-not-complete-btn" color="link">
              {" "}
              Visit Not Completed
            </Button>
          )}
          {dxTab && isScribe && !this.state.selectedProvider?.id && (
            <div className="submit-warning">
              Select Provider conducting encounter before proceeding
            </div>
          )}

          {videoFinished && (
            <div className="d-flex align-content-center">
              {!isAsyncVisit && (
                <button
                  onClick={this.onToggleDeletingModal}
                  className="delete-btn medium-size-btn mr-3"
                  id="qa-encounter-delete-btn"
                >
                  <DeleteBin />
                </button>
              )}
              <Button
                onClick={this.onSaveAndExit}
                className="encounter-submit-button save-exit mr-3"
              >
                {" "}
                {"Save & Exit"}
              </Button>
            </div>
          )}
          {piTab && !isSinglePage && (
            <div>
              <Button
                onClick={
                  isAllergySkinTest
                    ? () => this.onPageNavigation(SKIN_ALLERGY_TEST_TAB)
                    : () => this.onPageNavigation(PHYSICAL_EXAM_TAB)
                }
                className="encounter-submit-button"
              >
                {" "}
                Next <span className="next-arrow">&rarr;</span>
              </Button>
            </div>
          )}
          {SaTab && !isSinglePage && (
            <div>
              <Button
                onClick={() => this.onPageNavigation(PHYSICAL_EXAM_TAB)}
                className="encounter-submit-button"
              >
                {" "}
                Next <span className="next-arrow">&rarr;</span>
              </Button>
            </div>
          )}

          {peTab && !isSinglePage && (
            <Button
              onClick={() => this.onPageNavigation(DIAGNOSIS_TAB)}
              className="encounter-submit-button"
            >
              {" "}
              Next <span className="next-arrow">&rarr;</span>
            </Button>
          )}
          {dxTab &&
            !isSinglePage &&
            !["in_home_health", "nursing_home", "rx_request"].includes(visitType!) && (
              <Button
                onClick={() => this.onPageNavigation(TREATMENT_PLAN)}
                className="encounter-submit-button"
              >
                {" "}
                Next <span className="next-arrow">&rarr;</span>
              </Button>
            )}
          {showReqPDFTab && visitType === "in_home_health" && (
            <Button
              onClick={() => this.onPageNavigation(REQ_PDF)}
              className="encounter-submit-button"
            >
              {" "}
              Next <span className="next-arrow">&rarr;</span>
            </Button>
          )}
          {ttTab && submitDisabled && (
            <div className="submit-warning">Must complete video session before signing note</div>
          )}
          {!this.state.prescriptionValidated && (
            <div className="submit-warning">Must fix prescription errors before signing note</div>
          )}

          {(ttTab ||
            (!showttTab && dxTab) ||
            (!showttTabtwo && dxTab) ||
            isSinglePage ||
            (!reqStatusApproved &&
              dxTab &&
              (visitType == "in_home_health" || visitType == "rx_request"))) &&
            !showReqPDFTab && (
              <>
                {!isScribe ? (
                  <Button
                    onClick={this.onReviewNote}
                    disabled={submitDisabled || !this.state.prescriptionValidated || statusCheck}
                    className="encounter-submit-button"
                  >
                    Sign Note
                  </Button>
                ) : (
                  <Button
                    onClick={this.assignProvider}
                    disabled={!this.state.selectedProvider?.id}
                    className="encounter-submit-button"
                  >
                    Send to Provider
                  </Button>
                )}
              </>
            )}
        </div>
        <div className="encounter-bottom-container-error mt-2">
          <span className="d-flex justify-content-end mb-0 save-error-msg">
            {showErrorMsg ? "You must save any edits to patient information before continuing" : ""}
          </span>
        </div>
      </div>
    );
  }
}
export type EncounterProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps;

export default connect(mapStateToProps, mapDispatchToProps)(Encounter);
