import React, { FC, useState } from "react";
import Select from "react-select";
import { Button } from "reactstrap";

import SymptomDetails from "./SymptomDetails";
import PlusBlue from "../../images/PlusBlue.svg";
import XGray from "../../images/XGray.svg";
import {
  ICatalogItem,
  ISymptomsDetail,
  ISymptomsDetailDetail,
  IUserMedicalIntake,
  UserSymptomDetailsKeys,
} from "../../constants/Types";
import { HandleChangeFuncCurrentSymptomsType } from "./CurrentSymptoms";
import { SelectComponents } from "react-select/lib/components";

type Props = {
  order: ICatalogItem[];
  system: number[];
  details: ISymptomsDetail[] | null;
  onChange: (value: any) => void;
  components: Partial<SelectComponents<any>>;
  options: ICatalogItem[];
  symptomsDetailField: keyof IUserMedicalIntake;
  symptomByStystemField: keyof IUserMedicalIntake;
  systems?: { [key: string]: number[] };
  ros: { [key: string]: number[] };
};

const SymptomEditorWithDetails: FC<Props> = ({
  order,
  system,
  details,
  onChange,
  components,
  options,
  symptomsDetailField,
  symptomByStystemField,
  systems,
  ros,
}) => {
  const [addNewSelect, setAddNewSelect] = useState(false);
  const toggleAddNew = () => {
    setAddNewSelect(!addNewSelect);
  };
  let relevantDetails: (ICatalogItem | undefined)[] = [];
  if (system && details) {
    relevantDetails = details.map((detail) => {
      if (system.find((i) => i == detail.key)) {
        return order.find((item) => {
          if (detail.details && item.key === detail.key) {
            detail.details.map((sympDetail) => {
              item.details?.find((sympInfo) => {
                if (
                  sympInfo.key === sympDetail.key &&
                  (sympInfo.type === "temperature" ||
                    sympInfo.type === "integer" ||
                    sympInfo.type === "duration")
                ) {
                  sympDetail.answersAreKeys = false;
                } else if (sympInfo.key === sympDetail.key) {
                  sympDetail.answersAreKeys = true;
                }
              });
            });
            return detail;
          } else if (item.key === detail.key) {
            return detail;
          }
        });
      }
    });
  }
  relevantDetails = relevantDetails.filter((i) => i);
  let relevantOptions = system
    ? options.filter((option) => system.indexOf(option.key) < 0)
    : options;
  const onSelectChange = (oldItem: Partial<ICatalogItem> | null) => (item: any) => {
    let tempDetails = details ? details.slice() : [];
    let newDetails: ISymptomsDetail[] = [];
    let sympDetails = item.details || null;
    let newKeys: { [key: string]: number[] } = systems ? JSON.parse(JSON.stringify(systems)) : {};
    if (oldItem !== null) {
      newDetails = tempDetails.map((detail) => {
        if (oldItem.key === detail.key) {
          return { key: item?.key, details: sympDetails, group: item.group };
        } else {
          return detail;
        }
      });
      newKeys[oldItem.group!].splice(newKeys[oldItem.group!].indexOf(oldItem.key!), 1);
      if (newKeys[oldItem.group!].length < 1) {
        delete newKeys[oldItem.group!];
      }
    } else {
      tempDetails.push({
        key: item.key,
        details: sympDetails,
        group: item.group,
      });
      newDetails = tempDetails;
      toggleAddNew();
    }
    if (newKeys && newKeys[item.group!]) {
      newKeys[item.group!].push(item.key);
    } else {
      newKeys[item.group!] = [item.key];
    }

    //onChange(symptomsDetailField, { symptoms: newDetails });
    //onChange(symptomByStystemField, { systems: newKeys });
    onChange({
      [symptomByStystemField]: { systems: newKeys },
      [symptomsDetailField]: { symptoms: newDetails },
    });
  };

  const onRemoveSelect = (item: Partial<ICatalogItem>) => {
    let temp: ISymptomsDetail[] = JSON.parse(JSON.stringify(details));
    let newDetails = temp?.filter((detail) => detail.key !== item.key);
    let newKeys: { [key: string]: number[] } = JSON.parse(JSON.stringify(ros));
    newKeys[item.group!].splice(systems![item.group!].indexOf(item.key!), 1);
    if (newKeys[item.group!].length < 1) {
      delete newKeys[item.group!];
    }

    onChange({
      [symptomByStystemField]: { systems: newKeys },
      [symptomsDetailField]: { symptoms: newDetails },
    });
    //onChange(symptomsDetailField, { symptoms: newDetails });
  };

  return (
    <div>
      {relevantDetails.map((k, index) => {
        let item: Partial<ICatalogItem> = order.find((i) => i.key === k?.key) || { details: [] };
        return (
          <div key={index} className="d-flex flex-column">
            <div className="d-flex">
              <div className="add-symptom-select">
                <Select
                  isClearable={false}
                  hideSelectedOptions={true}
                  className="info-select"
                  classNamePrefix="info-select"
                  placeholder={item.label}
                  onChange={onSelectChange(item)}
                  components={components}
                  options={relevantOptions}
                  value={item.label}
                />
              </div>
              <XGray
                className="close-select-button align-self-center"
                onClick={() => onRemoveSelect(item)}
              />
            </div>
            {item.details &&
              item.details.map((detail, index) => {
                let getAnswer = () => {
                  try {
                    let tryAnswer = JSON.parse(JSON.stringify(details))
                      .find((savedDetails: ISymptomsDetail) => savedDetails.key === k?.key)
                      .details.find(
                        (savedDetail: ISymptomsDetailDetail) => savedDetail.key === detail.key,
                      );
                    return tryAnswer as ISymptomsDetailDetail;
                  } catch {}
                };
                let answerReuslt = getAnswer();
                let answer = answerReuslt ? answerReuslt : ({} as ISymptomsDetailDetail);
                answer.answerAsLabel = [];
                let questionOptions: { label: string; value: string; key: number }[] = [];
                let mapDetailKeysToLabels = () => {
                  detail.options
                    ? detail.options.forEach((answerKey) => {
                        let answerKeyAsString = answerKey.key + "";
                        if (!answer.answer) {
                          answer.answer = [];
                        }
                        if (answer.answer.indexOf(answerKeyAsString) > -1) {
                          answer.answerAsLabel!.push(answerKey.label);
                        } else {
                          questionOptions.push({
                            label: answerKey.label,
                            value: answerKey.label,
                            key: answerKey.key,
                          });
                        }
                      })
                    : [];
                };
                mapDetailKeysToLabels();
                return (
                  <div key={index}>
                    <SymptomDetails
                      details={details}
                      symptomsDetailField={symptomsDetailField}
                      detailType={detail.type}
                      placeholder={detail.question}
                      options={questionOptions}
                      components={components}
                      isClearable={false}
                      hideSelectedOptions={true}
                      answer={answer}
                      detail={detail}
                      symptom={k}
                      onChange={onChange}
                    />
                  </div>
                );
              })}
          </div>
        );
      })}
      {addNewSelect && (
        <div className="d-flex">
          <div className="add-symptom-select">
            <Select
              isClearable={false}
              hideSelectedOptions={true}
              className="info-select"
              classNamePrefix="info-select"
              placeholder="Type to search / add..."
              onChange={onSelectChange(null)}
              components={components}
              options={relevantOptions}
            />
          </div>
          <XGray className="close-select-button align-self-center" onClick={toggleAddNew} />
        </div>
      )}
      <div className="add-new-symptom-container d-flex">
        <div>
          <Button
            className="plus-symptom-button ros-add"
            disabled={addNewSelect}
            onClick={toggleAddNew}
          >
            <PlusBlue />
          </Button>
        </div>
        <div className="add-symptom-label align-self-center">Add new symptom</div>
      </div>
    </div>
  );
};

export default SymptomEditorWithDetails;
