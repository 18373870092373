import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import PropTypes from "prop-types";

import PDFView from "./PDFView";
import CheckMark from "../../images/CheckMarkWhite.svg";
import { FAX_COMPLETE, DISMISS_FAX } from "../../constants/actionTypes";
import api from "../../api";

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
  };
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
const mapDispatchToProps = (dispatch) => ({
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'faxID' implicitly has an 'any' type.
  completeFax: (faxID, payload) => dispatch({ type: FAX_COMPLETE, faxID, payload }),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'faxID' implicitly has an 'any' type.
  dismissFax: (faxID) => dispatch({ type: DISMISS_FAX, faxID }),
});

class CompleteFax extends Component {
  constructor(props) {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
    super(props);
    this.state = { assignedProvider: null };
    this.completeFax = this.completeFax.bind(this);
  }

  completeFax() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'faxID' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const payload = api.Faxes.completeFax(this.props.faxID, this.state.assignedProvider);
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
    this.props.completeFax(this.props.faxID, payload);
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'faxID' does not exist on type 'Readonly<... Remove this comment to see the full error message
    setTimeout(() => this.props.dismissFax(this.props.faxID), 3000);
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'dismissFax' does not exist on type 'Read... Remove this comment to see the full error message
    this.props.onClose();
  }

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'faxID' does not exist on type 'Readonly<... Remove this comment to see the full error message
  render() {
    const { url, onClose } = this.props;
    return (
      <div>
        {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'url' does not exist on type 'Readonly<{}... Remove this comment to see the full error message */}
        <PDFView url={url} onClose={onClose}>
          {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; url: any; onClose: any;... Remove this comment to see the full error message */}
          <div className="w-100 d-flex justify-content-center">
            <Button className="complete-fax-button" onClick={this.completeFax}>
              Mark as complete <CheckMark />
            </Button>
          </div>
        </PDFView>
      </div>
    );
  }
}

CompleteFax.propTypes = {
  url: PropTypes.string,
  onClose: PropTypes.func,
};
// @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompleteFax);
