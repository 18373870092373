import React from "react";
import { Label } from "reactstrap";
import moment from "moment";
import { DayPickerSingleDateController } from "react-dates";

import CheckToggle from "../CheckToggle";
import BlockSection from "./BlockSection";
import YesNoToggle from "./YesNoToggle";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";

const Editor = ({
  health,
  oldHealth,
  onFieldChange,
  onLmpChange,
  onToggle,
  onAnswerCheck,
  ...props
}) => {
  const onDateFieldChanged = (field) => (m) => {
    if (m.isValid()) {
      m = m.toISOString();
    } else {
      m = "invalid";
    }
    if (field === "lmp") {
      onLmpChange(m);
    } else {
      onFieldChange(field)(moment(m));
    }
  };

  const isAfterToday = (d) => {
    return d.isAfter(moment());
  };

  const isBeforeToday = (d) => {
    return d.isBefore(moment().subtract(1, "days"));
  };

  let lmpDate = health.lmpDate ? health.lmpDate.utc() : null;
  let dueDate = health.dueDate ? moment(health.dueDate).utc() : null;
  return (
    <div>
      <div className="d-flex">
        <div className="mr-5">
          <Label> Could be pregnant? </Label>
          <YesNoToggle
            onChange={onAnswerCheck("pregnant", "dueDate")}
            value={health.pregnant}
            oldValue={oldHealth.pregnant}
          />
        </div>
      </div>
      <div className="d-flex mt-2">
        {health.pregnant && (
          <>
            <div className="mr-3">
              <Label>Due date:</Label>
              <DayPickerSingleDateController
                focused
                numberOfMonths={1}
                hideKeyboardShortcutsPanel={true}
                daySize={39}
                horizontalMonthPadding={0}
                verticalHeight={200}
                date={dueDate}
                transitionDuration={0}
                isOutsideRange={isBeforeToday}
                onDateChange={onDateFieldChanged("dueDate")}
              />
            </div>
          </>
        )}
        <div>
          <Label>Date of last menstrual period:</Label>
          {!health.lmpNotApplicable && (
            <DayPickerSingleDateController
              focused
              numberOfMonths={1}
              hideKeyboardShortcutsPanel={true}
              daySize={39}
              horizontalMonthPadding={0}
              verticalHeight={200}
              date={lmpDate}
              transitionDuration={0}
              isOutsideRange={isAfterToday}
              onDateChange={onDateFieldChanged("lmp")}
            />
          )}
        </div>
        <div className="d-flex align-items-start">
          <span className="h-50 pl-2 pr-1">N/A:</span>
          <CheckToggle checked={health.lmpNotApplicable} onChange={onToggle} />
        </div>
      </div>
      <>
        {health.breastfeedingAnswered && (
          <div className="d-flex mt-2">
            <div className="mr-5">
              <Label>Breastfeeding? </Label>
              <YesNoToggle
                onChange={onFieldChange("breastfeeding")}
                value={health.breastfeeding}
                oldValue={oldHealth.breastfeeding}
              />
            </div>
          </div>
        )}
        {health.sexuallyActiveAnswered && (
          <div className="d-flex mt-2">
            <div className="mr-5">
              <Label>Sexually active? </Label>
              <YesNoToggle
                onChange={onFieldChange("sexuallyActive")}
                value={health.sexuallyActive}
                oldValue={oldHealth.sexuallyActive}
                className="qa-womens-sexactive-toggle"
              />
            </div>
          </div>
        )}
      </>
    </div>
  );
};

const View = ({ health }) => {
  const pregnant = health.pregnant ? "Could be pregnant" : "No, couldn't be pregnant";
  const dueDate = health.dueDate ? moment(health.dueDate).utc().format("MM/DD/YYYY") : "N/A";
  const lmp = health.lmpDate ? health.lmpDate.utc().format("MM/DD/YYYY") : "N/A";
  const p = `Last menstrual period: ${lmp}`;
  const breastfeeding = health.breastfeeding ? "Yes" : "No";
  const sexuallyActive = health.sexuallyActive ? "Yes" : "No";
  return (
    <div>
      <ul>
        <li>{pregnant}</li>
        {health.pregnant && (
          <ul>
            <li>{`Due date: ${dueDate}`}</li>
          </ul>
        )}
        <li>{p}</li>
        <>
          {health.breastfeedingAnswered && <li>{`Breastfeeding: ${breastfeeding}`}</li>}
          {health.sexuallyActiveAnswered && <li>{`Sexually active: ${sexuallyActive}`}</li>}
        </>
      </ul>
    </div>
  );
};

const ExplodeDate = (womensHealth) => {
  let d = moment(womensHealth.lmp);
  if (womensHealth.lmp && d.isValid()) {
    return {
      lmpDate: d,
      lmpDay: womensHealth.lmpDay || d.date().toString(),
      lmpMonth: womensHealth.lmpMonth || (d.month() + 1).toString(),
      lmpYear: womensHealth.lmpYear || d.year().toString(),
    };
  }
  return {
    lmpDate: null,
    lmpDay: womensHealth.lmpDay || "",
    lmpMonth: womensHealth.lmpMonth || "",
    lmpYear: womensHealth.lmpYear || "",
  };
};

export default ({
  womensHealth,
  oldWomensHealth,
  isEditing,
  onChange,
  visitType,
  readOnly,
  ...props
}) => {
  if (womensHealth.lmp && womensHealth.lmp == "0001-01-01T00:00:00Z") {
    womensHealth.lmp = null;
  }
  womensHealth = { ...womensHealth, ...ExplodeDate(womensHealth) };
  if (oldWomensHealth) {
    oldWomensHealth = { ...oldWomensHealth, ...ExplodeDate(oldWomensHealth) };
  }
  const handleFieldChange = (field) => (newValue) => {
    onChange({ ...womensHealth, [field]: newValue });
  };

  const handleLmpChange = (date) => {
    const { lmpDate, ...wh } = womensHealth;
    onChange({ ...wh, lmp: date, lmpDate });
  };

  const handleNotApplicableChange = (val) => {
    onChange({ ...womensHealth, lmpNotApplicable: val, lmp: null });
  };

  const handleDidntAnswerCheck = (field, fieldToFalse) => (val) => {
    const newVal = fieldToFalse === "dueDate" ? null : false;
    onChange({ ...womensHealth, [field]: val, [fieldToFalse]: newVal });
  };

  let content;
  if (isEditing && !readOnly) {
    content = (
      <Editor
        health={womensHealth}
        oldHealth={oldWomensHealth}
        onLmpChange={handleLmpChange}
        onFieldChange={handleFieldChange}
        onToggle={handleNotApplicableChange}
        onAnswerCheck={handleDidntAnswerCheck}
      />
    );
  } else {
    content = <View health={womensHealth} />;
  }
  return (
    <BlockSection title="Women's Health" bodyClassName="mt-3">
      {content}
    </BlockSection>
  );
};
