import React, { Component, FC } from "react";
import update from "immutability-helper";
import { Form, Input, FormGroup, Label } from "reactstrap";

import {
  RESULT_ID_COVID_CONTROL,
  RESULT_ID_COVID,
  findResult,
  covidLabel,
} from "../../constants/TestConstants";
import { IProviderTestResult } from "../../constants/Types";

type PropsCheckOption = {
  onChange: (newValue: any) => void;
  label: string;
  current: any;
  result: string;
  value: { inconclusive?: boolean; value?: boolean };
};

const CheckOption: FC<PropsCheckOption> = (props) => {
  const onChange = () => {
    if (props.onChange) {
      props.onChange(props.value);
    }
  };

  let checked = false;
  if (props.current && props.value && props.current.value == props.value.value) {
    checked = true;
  }

  return (
    <FormGroup check>
      <Label check>
        <Input type="radio" name={props.result} checked={checked} onChange={onChange} />
        &nbsp;
        {props.label}
      </Label>
    </FormGroup>
  );
};

type PropsControlFormGroup = {
  legend: string;
  value: any;
  onChange: (newValue: any) => void;
  result: string;
};

const ControlFormGroup: FC<PropsControlFormGroup> = (props) => {
  return (
    <FormGroup>
      <legend>{props.legend}</legend>
      <div className="test-confirmation-options">
        <CheckOption
          label="Positive"
          current={props.value}
          result={props.result}
          value={{ value: true }}
          onChange={props.onChange}
        />
        <CheckOption
          label="Negative"
          current={props.value}
          result={props.result}
          value={{ value: false }}
          onChange={props.onChange}
        />
        <CheckOption
          label="Inconclusive"
          current={props.value}
          result={props.result}
          value={{ inconclusive: true }}
          onChange={props.onChange}
        />
      </div>
    </FormGroup>
  );
};

type Props = {
  result?: object[];
  providerResult: IProviderTestResult;
  onChange: (newValues: any) => void;
  showAlgorithmResult: boolean;
};

type State = {
  results: any[];
  invalid: boolean;
  set: boolean;
};

class CoronavirusConfirmation extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      results: [],
      invalid: false,
      set: false,
    };
    this.onResultChange = this.onResultChange.bind(this);
  }

  onResultChange(result: string) {
    return (newValue: object) => {
      const idx = this.state.results.findIndex((r) => r.name === result);
      let newState;
      let nv = { name: result, ...newValue };
      if (idx > -1) {
        newState = update(this.state, { results: { [idx]: { $set: nv } } });
      } else {
        newState = update(this.state, { results: { $push: [nv] } });
      }
      newState.set = newState.results.length >= 2;
      newState.invalid = false;
      this.setState(newState);

      this.props.onChange(newState);
    };
  }

  render() {
    const algValue = covidLabel(this.props.result);
    const onInvalid = () => {
      const iv = { set: true, invalid: true, results: [] };
      this.setState(iv);
      this.props.onChange({ set: true, invalid: true, results: [] });
    };

    let c = this.state.invalid ? null : findResult(this.state.results, RESULT_ID_COVID_CONTROL);

    let t = this.state.invalid ? null : findResult(this.state.results, RESULT_ID_COVID);

    return (
      <div className="test-confirmation-form covid-form">
        {this.props.showAlgorithmResult && (
          <div className="algorithm-result">
            <div className="alg-title">Algorithm returned:</div>
            <div className="alg-value">{algValue}</div>
          </div>
        )}
        <div>
          <Label className="test-title">COVID-19 (SARS_CoV-2) Rapid Test:</Label>
          <Form className="d-flex flex-column justify-content-center align-items-center">
            <div className="d-flex w-100">
              <div className="w-50">
                <ControlFormGroup
                  legend="Control Line (C)"
                  value={c}
                  onChange={this.onResultChange(RESULT_ID_COVID_CONTROL)}
                  result={RESULT_ID_COVID_CONTROL}
                />
              </div>
              <div className="w-50">
                <ControlFormGroup
                  legend="Control Line (T)"
                  value={t}
                  onChange={this.onResultChange(RESULT_ID_COVID)}
                  result={RESULT_ID_COVID}
                />
              </div>
            </div>

            <FormGroup check className="mt-4">
              <Label check>
                <Input
                  type="radio"
                  name="invalid"
                  checked={this.state.invalid}
                  onChange={onInvalid}
                />
                &nbsp; Image Invalid
              </Label>
            </FormGroup>
          </Form>
        </div>
      </div>
    );
  }
}

export default CoronavirusConfirmation;
