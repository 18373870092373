import React from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";

const FeatureEntryItem = ({ index, value, attributes, onClick, style,id }) => {
  let c = "mr-2 mb-1"; //index === 0 ? "mr-3" : "ml-3"
  if (attributes.includes(value)) {
    c = c + " selected";
  }
  return (
    <Button onClick={onClick(value)} className={c} outline style={style} id={id}>
      {value}
    </Button>
  );
};

const FeatureEntry = ({ onChange, feature, values, className, omitTitle, style, id }) => {
  const anySelected = values.some((v) => feature.attributes.includes(v));
  const onClick = (newValue) => () => {
    let as = [];
    const idx = feature.attributes.indexOf(newValue);
    if (idx > -1) {
      feature.attributes.splice(idx, 1);
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'newValue' implicitly has an 'any' type.
      as = [...feature.attributes];
    } else {
      as = feature.attributes.filter((v) => !values.includes(v));
      as.push(newValue);
    }
    onChange(feature, as);
  };
  let parentClass = `${className || ""} button-entry d-flex flex-wrap`;
  if (anySelected) {
    parentClass += " item-selected";
  }

  return (
    <div className="attribute-container">
      {!omitTitle && <h1>{feature.feature}</h1>}
      <div className={parentClass}>
        {values.map((v, idx) => (
          <FeatureEntryItem
            id={`${id}-${idx}`}
            onClick={onClick}
            key={idx}
            index={idx}
            value={v}
            attributes={feature.attributes}
            style={style}
          />
        ))}
      </div>
    </div>
  );
};

FeatureEntry.propTypes = {
  onChange: PropTypes.func,
  feature: PropTypes.object,
  values: PropTypes.array,
  className: PropTypes.string,
  omitTitle: PropTypes.bool,
  style: PropTypes.object,
  id:PropTypes.string
};

export default FeatureEntry;
