import React, { FC, Fragment, useState } from "react";
import moment from "moment";

import { Table } from "reactstrap";
import { Row, Col, Button } from "reactstrap";
import styled from "styled-components";
import { ICondition, IFamilyMedicalHistory } from "../../../constants/Types";

import UsersIcon from "../../../images/UsersIcon.svg";
import PaperIcon from "../../../images/PaperIcon.svg";
import MedicationsIcon from "../../../images/medications.svg";
import AllergiesIcon from "../.././../images/allergies.svg";
import NeedleIcon from "../.././../images/needle.svg";
import UserIcon from "../.././../images/user.svg";
import AssessmentsIcon from "../.././../images/AssessmentsIcon.svg";
import { IAssessment } from "../../../constants/Types";
const Card = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
  position: relative;
  margin: 5px 0;

  .italic {
    font-style: italic;
  }
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  background: #76adf1;
  height: 38px;
  border-radius: 10px 10px 0 0;
  padding-left: 15px;
  color: FFFFFF;
  font-weight: 700;
  font-size: 14px;

  span {
    margin-left: 10px;
  }

  .sub {
    margin-left: 10px;
    font-size: 12px;
    font-style: italic;
  }
`;

const CardBody = styled.div`
  &::-webkit-scrollbar {
    width: 6px;
    background-color: #efefef;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #bbbbbb;
    border-radius: 15px;
  }
  max-height: 245px;
  overflow-y: auto;
  padding: 20px;
  font-size: 12px;
  line-height: 19px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin-top: 1rem;

  h6 {
    font-weight: 600;
    font-size: 12px;
    color: #000000;
  }
`;

const StyledCardBody = styled(CardBody)`
  max-height: ${(props) => (props.height ? props.height : "550px")};

  .list-item {
  }
`;

const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border-bottom: 1px solid rgba(0, 53, 119, 0.2);
  height: auto;
  overflow-y: auto;
  padding: 0.7rem;
  font-size: 12px;
  cursor: pointer;

  .name {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .description {
      width: 70%;
      font-weight: 600;
      color: #000000;

      .sub {
        color: #4a4a4a;
        font-style: italic;
        margin-left: 5px;
      }
    }
    .rank {
      margin: auto;
      text-align: end;
      width: 30%;
      color: #4a4a4a;
      font-weight: 400;
    }
  }

  .content {
    margin-top: 10px;
    height: 70px;
    display: none;
    transition: height 0.5s ease;
    color: #000000;
    font-style: italic;
    th,
    td {
      border: none;
      font-size: 12px;
      padding: 0.2rem;
      font-style: italic;
      color: #000000;
      max-width: 50px;
    }

    h2 {
      color: #000000;
      font-style: italic;
      font-weight: 600;
      font-size: 12px;
    }

    .directions {
      margin-top: 20px;
      font-style: italic;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #000000;
    }
  }

  ${(props) =>
    props.active == true &&
    `
    background: rgba(117, 178, 255, 0.05);
    .name {
      padding-top: 0;
      .description,.sub {
        color: #4a8fe7;

      }
    }
    .content {
      display: block;
    }
`}

  :hover {
    background: rgba(117, 178, 255, 0.05);
    .rank,
    .description {
      color: #4a8fe7;
    }
  }
`;

const ColumnWrapper = ({ children }) => {
  return (
    <Col md={{ size: 12 }} style={{ padding: "0 0 5px 0" }}>
      {children}
    </Col>
  );
};

const isEven = (value) => value % 2 === 0;

const isActive = (item) =>
  (item.clinicalStatus &&
    item.clinicalStatus.toLowerCase &&
    item.clinicalStatus.toLowerCase() === "active") ||
  (item.status && item.status.toLowerCase && item.status.toLowerCase() === "active");

const unescapeXMLChars = (text) => text.replace("&apos;", "'");

const countActive = (items) =>
  items.reduce((length, item) => (isActive(item) ? ++length : length), 0);

type Props = {
  activeDiagnosis: ICondition[];
  selectedDiagnosis: number;
  activeDiagnosisLength: number;
  onDiagnosisItemClick: (idx: number) => void;
};
const DiagnosisSection: FC<Props> = ({
  activeDiagnosis,
  selectedDiagnosis,
  activeDiagnosisLength,
  ...props
}) => {
  return (
    <Card>
      <CardHeader>
        <PaperIcon />
        <span>Diagnosis {`(${activeDiagnosisLength})`} </span>
        <span className="sub"> (active)</span>
      </CardHeader>
      <StyledCardBody>
        {activeDiagnosis.map((item, idx) => (
          <ListItem
            active={idx === selectedDiagnosis}
            onClick={() => props.onDiagnosisItemClick(idx)}
            key={`activeDiagnosis-${idx}`}
          >
            <div className="name">
              <span className="description">
                {item.icd10} {item.icd10Description ? item.icd10Description : ""}
              </span>
              <span>{item.rankDescription}</span>
            </div>
            <div className="content">
              <Table>
                <thead>
                  <tr>
                    <th>Classification:</th>
                    <th>Status:</th>
                    <th>Comments:</th>
                    <th>Onset date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {!!item.classificationDescription ? item.classificationDescription : ""}
                    </td>
                    <td>{!!item.clinicalStatus ? item.clinicalStatus : ""}</td>
                    <td>{!!item.comments ? item.comments : ""}</td>
                    <td>{!!item.onsetDate ? item.onsetDate : ""}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </ListItem>
        ))}
      </StyledCardBody>
    </Card>
  );
};

const MedicationSection = ({
  activeMedications,
  activeMedicationsLength,
  selectedMedication,
  ...props
}) => {
  return (
    <Card>
      <CardHeader>
        <MedicationsIcon />
        <span>Medications {`(${activeMedicationsLength})`}</span>
        <span className="sub"> (active)</span>
      </CardHeader>
      <StyledCardBody height="250px">
        {activeMedications.map((item, idx) => (
          <ListItem
            active={idx === selectedMedication}
            onClick={() => props.onMedicationItemClick(idx)}
            key={`activeMedications-${idx}`}
          >
            <div className="name">
              <span className="description">
                {!!item.description ? item.description : item.generic}
                {!!item.strength ? ` - ${item.strength}` : ""}
                {!!item.strengthUOM ? item.strengthUOM : ""}
              </span>
              <span>
                {`${!!item.startDate ? moment(item.startDate).format("MM/DD/YYYY") : ""} -
                  ${!!item.endDate ? moment(item.endDate).format("MM/DD/YYYY") : ""}`}
              </span>
            </div>
            <div className="content">
              <div>{!!item.generic ? `${item.generic} (generic name)` : ""} </div>
              <div className="directions">
                <h2>Directions:</h2>
                {!!item.directions ? item.directions : ""}
              </div>
            </div>
          </ListItem>
        ))}
      </StyledCardBody>
    </Card>
  );
};

const FamilyHealthHistorySection: FC<{ familyHealthHistory: IFamilyMedicalHistory[] }> = ({
  familyHealthHistory,
}) => {
  return (
    <Card>
      <CardHeader>
        <UsersIcon />
        <span className="qa-chartMedicalProfileFamilyHealthHistoryCard">
          Family Health History ({familyHealthHistory?.length || 0})
        </span>
      </CardHeader>
      <CardBody>
        <div className="d-flex flex-wrap">
          {familyHealthHistory?.map((item, index) => {
            const historyString = [
              item.relationshipToPatient,
              item.maternalOrPaternal,
              item.ageAtDX,
            ]
              .filter((e) => e !== "")
              .join(", ");

            return (
              <FormGroup className="w-50" key={`familyHealthHistoryCard-${index}`}>
                <h6>{item.familyHealthCondition}</h6>
                <span className="qa-chartFamilyHealthHistoryMaritalStatus">{historyString}</span>
              </FormGroup>
            );
          })}
        </div>
      </CardBody>
    </Card>
  );
};
const AllergiesSection = ({
  activeAllergies,
  selectedAllergy,
  activeAllergiesLength,
  ...props
}) => {
  return (
    <Card>
      <CardHeader>
        <AllergiesIcon />
        <span>Allergies {`(${activeAllergiesLength})`} </span>
        <span className="sub"> (active)</span>
      </CardHeader>
      <StyledCardBody height="250px">
        {activeAllergies.map((item, idx) => (
          <ListItem
            active={idx === selectedAllergy}
            onClick={() => props.onAllergyItemClick(idx)}
            key={`activeAllergies-${idx}`}
          >
            <div className="name">
              <div className="description">
                <span>{item.allergen}</span>
                <span className="sub">{`(${!!item.severity && item.severity})`}</span>
              </div>
              <span>{!!item.reactionType ? item.reactionType : "none"}</span>
            </div>
            <div className="content">
              <div>Category: {item.category}</div>
              <div className="directions">
                <h2>Reaction Note:</h2>
                {!!item.reactionNote ? unescapeXMLChars(item.reactionNote) : "none"}
                <h2>Onset Date:</h2>
                {!!item.onsetDate ? item.onsetDate : "none"}
              </div>
            </div>
          </ListItem>
        ))}
      </StyledCardBody>
    </Card>
  );
};
const AssessmentsSection = ({
  completedAssessments,
  selectedAssessment,
  onAssessmentItemClick,
}) => {
  return (
    <Card>
      <CardHeader>
        <AssessmentsIcon />
        <span>Recent Assessments (past 14 days)</span>
      </CardHeader>
      <StyledCardBody height="250px">
        {completedAssessments.map((item, idx) => {
          const allResponses = [];
          item.sections.forEach((section) =>
            section.assessmentQuestionGroups.forEach((group) =>
              group.assessmentResponses.forEach((response) => allResponses.push(response)),
            ),
          );
          return (
            <ListItem
              active={idx === selectedAssessment}
              onClick={() => onAssessmentItemClick(idx)}
              key={`assessments-${idx}`}
            >
              <div className="name">
                <div className="description">
                  <span>
                    {item.assessmentDescription
                      ? item.assessmentDescription
                      : moment(item.assessmentRefDate).format("MM/DD/YYYY")}
                  </span>
                </div>
                <span>
                  {!!item.assessmentRefDate
                    ? moment(item.assessmentRefDate).format("MM/DD/YYYY")
                    : ""}
                </span>
              </div>
              <div className="content">
                {allResponses.map((question, idx) => (
                  <div className="mb-2" key={`assessmentQuestion-${idx}`}>
                    <h2>{question.questionText || "N/A"} :</h2>
                    {!!question.responses ? (
                      (question.responses || []).map((val) => (
                        <>
                          <span>{val.responseText || val.responseValue || "N/A"}</span> <br />
                        </>
                      ))
                    ) : (
                      <span>{"N/A"}</span>
                    )}
                  </div>
                ))}
              </div>
            </ListItem>
          );
        })}
      </StyledCardBody>
    </Card>
  );
};
const ImmunizationSection = ({ immunizations, selectedImm, ...props }) => {
  return (
    <Card>
      <CardHeader>
        <NeedleIcon />
        <span>Immunizations</span>
      </CardHeader>
      <StyledCardBody>
        {immunizations.map((item, idx) => (
          <ListItem active={idx === selectedImm} onClick={() => props.onImmunizationItemClick(idx)}>
            <div className="name">
              <div className="description">
                {item.consentStatus.toLowerCase() === "refused"
                  ? `${item.immunization} - Refused`
                  : item.consentStatus.toLowerCase() === "consented" &&
                    !!!item.administrationDateTime
                  ? `${item.immunization} - Pending`
                  : `${item.immunization} - ${
                      //TODO - Use lodash
                      !!item.amountAdministered && !!item.amountAdministered.value
                        ? item.amountAdministered.value
                        : "none"
                    }${" "}${
                      !!item.amountAdministered && !!item.amountAdministered.unit
                        ? item.amountAdministered.unit
                        : " none"
                    }`}
              </div>
              <span>
                {item.consentStatus.toLowerCase() === "refused" ||
                (item.consentStatus.toLowerCase() === "consented" && !!!item.administrationDateTime)
                  ? ""
                  : moment(item.administrationDateTime).format("MM/DD/YYYY")}
              </span>
            </div>
            <div className="content">
              <div className="directions">
                <h2>Route of Administration:</h2>
                <span>{!!item.routeOfAdministration ? item.routeOfAdministration : "none"}</span>
              </div>
              <div>
                <h2>Location Given:</h2>
                <span>{!!item.locationGiven ? item.locationGiven : "none"}</span>
              </div>
              <div>
                <h2>Notes:</h2>
                <span>{!!item.notes ? item.notes : "none"}</span>
              </div>
              <div>
                <h2>MultiSteps:</h2>
                <span>{item.multiStep ? "Yes" : "No"}</span>
              </div>
              <div>
                <h2>Next Step Required:</h2>
                <span>{item.nextStepRequired ? "Yes" : "No"}</span>
              </div>
              <div>
                <h2>Results:</h2>
                <span>{item.results ? "Yes" : "No"}</span>
              </div>
            </div>
          </ListItem>
        ))}
      </StyledCardBody>
    </Card>
  );
};

type MedicalProfilePCCProps = {
  profile?: any;
  allergies?: any[];
  medications?: any[];
  diagnosis?: any[];
  immunizationPCC?: any[];
  assessmentsPCC: IAssessment[];
  familyMedicalHistory: IFamilyMedicalHistory[];
};
const MedicalProfilePCC = ({
  profile,
  allergies,
  medications,
  diagnosis,
  immunizationPCC,
  assessmentsPCC,
  familyMedicalHistory,
}: MedicalProfilePCCProps) => {
  const [selectedDiagnosis, selectDiagnosis] = useState(-1);
  const [selectedMedication, selectMedication] = useState(-1);
  const [selectedAllergy, selectAllergy] = useState(-1);
  const [selectedImm, selectImmunization] = useState(-1);
  const [selectedAssessment, selectAssessment] = useState(-1);

  const onDiagnosisItemClick = (idx) => {
    if (idx === selectedDiagnosis) {
      selectDiagnosis(-1);
    } else {
      selectDiagnosis(idx);
    }
  };

  const onMedicationItemClick = (idx) => {
    if (idx === selectedMedication) {
      selectMedication(-1);
    } else {
      selectMedication(idx);
    }
  };

  const onAllergyItemClick = (idx) => {
    if (idx === selectedAllergy) {
      selectAllergy(-1);
    } else {
      selectAllergy(idx);
    }
  };
  const onImmunizationItemClick = (idx) => {
    if (idx === selectedImm) {
      selectImmunization(-1);
    } else {
      selectImmunization(idx);
    }
  };
  const onAssessmentItemClick = (idx) => {
    if (idx === selectedAssessment) {
      selectAssessment(-1);
    } else {
      selectAssessment(idx);
    }
  };

  const activeAllergiesLength = countActive(allergies);
  const activeMedicationsLength = countActive(medications);
  const activeDiagnosisLength = countActive(diagnosis);

  const activeAllergies = (allergies && allergies.filter((item) => isActive(item))) || [];

  const activeMedications = (medications && medications.filter((item) => isActive(item))) || [];
  const activeMedications2 = activeMedications.slice(0, 2);

  const activeDiagnosis = (diagnosis && diagnosis.filter((item) => isActive(item))) || [];
  const activeDiagnosis5 = activeDiagnosis.slice(0, 5);

  const completedAssessments = assessmentsPCC
    .filter((item) => item.assessmentStatus === "Complete")
    .sort(
      (a: any, b: any) =>
        new Date(b.assessmentRefDate).valueOf() - new Date(a.assessmentRefDate).valueOf(),
    ); // valueOf() is fix ts error here

  return (
    <Row style={{ padding: "0" }}>
      <Col md={{ size: 6 }} style={{ padding: "0" }}>
        <ColumnWrapper>
          <DiagnosisSection
            activeDiagnosis={activeDiagnosis}
            selectedDiagnosis={selectedDiagnosis}
            activeDiagnosisLength={activeDiagnosisLength}
            onDiagnosisItemClick={onDiagnosisItemClick}
          />
        </ColumnWrapper>
        <ColumnWrapper>
          <Card>
            <CardHeader>
              <UsersIcon />
              <span>Social History</span>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md={{ size: 6 }}>
                  <FormGroup>
                    <h6>Marital Status:</h6>
                    <span>{profile.maritalStatus}</span>
                  </FormGroup>

                  <FormGroup>
                    <h6>Tobacco Smoker?</h6>
                    <span>{!!profile.smokingStatusDesc ? profile.smokingStatusDesc : "none"}</span>
                  </FormGroup>
                  <FormGroup>
                    <h6>Currently Smokes Everyday?</h6>
                    <span>{!!profile.smokingFrequency ? profile.smokingFrequency : "none"}</span>
                  </FormGroup>
                </Col>

                <Col md={{ size: 6 }}>
                  <FormGroup>
                    <h6>Ethnicity</h6>
                    <span>{!!profile.ethnicityDesc ? profile.ethnicityDesc : "none"}</span>
                  </FormGroup>

                  <FormGroup>
                    <h6>Religion</h6>
                    <span>{!!profile.religion ? profile.religion : "none"}</span>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </ColumnWrapper>

        <ColumnWrapper>
          <Card>
            <CardHeader>
              <UserIcon />
              <span>Contact Information</span>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md={{ size: 6 }}>
                  <FormGroup>
                    <h6>Phone Number</h6>
                    <span>{!!profile.homePhone ? profile.homePhone : "none"}</span>
                  </FormGroup>

                  <FormGroup>
                    <h6>Email Address</h6>
                    <span>{!!profile.email ? profile.email : "none"}</span>
                  </FormGroup>
                  <FormGroup>
                    <h6>Emergency Contact</h6>
                    <div className="d-flex flex-column">
                      {!profile.emergencyContact.name && !profile.emergencyContact.contactNumber ? (
                        <span>none</span>
                      ) : (
                        <>
                          <span>{profile.emergencyContact.name || "none"}</span>
                          <span>{profile.emergencyContact.contactNumber || "none"}</span>
                        </>
                      )}
                    </div>
                  </FormGroup>
                </Col>

                <Col md={{ size: 6 }}>
                  <FormGroup>
                    <h6>Pharmacy</h6>
                    <span>{!!profile.preferredPharmacy ? profile.preferredPharmacy : "none"}</span>
                  </FormGroup>

                  <FormGroup>
                    <h6>Home Address</h6>
                    <div className="d-flex flex-column">
                      {!profile.city && !profile.state && !profile.postalCode && !profile.street ? (
                        "none"
                      ) : (
                        <>
                          <span>{profile.street || "none"} </span>
                          <span>
                            {profile.city || "none"}, {profile.state || "none"}{" "}
                            {profile.postalCode || "none"}
                          </span>
                        </>
                      )}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </ColumnWrapper>
      </Col>

      <Col md={{ size: 6 }}>
        <Row>
          <Col md={{ size: 12 }}>
            <MedicationSection
              activeMedications={activeMedications}
              selectedMedication={selectedMedication}
              activeMedicationsLength={activeMedicationsLength}
              onMedicationItemClick={onMedicationItemClick}
            />
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 12 }}>
            <AllergiesSection
              activeAllergies={activeAllergies}
              selectedAllergy={selectedAllergy}
              activeAllergiesLength={activeAllergiesLength}
              onAllergyItemClick={onAllergyItemClick}
            />
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 12 }}>
            <ImmunizationSection
              activeAllergies={activeAllergies}
              immunizations={immunizationPCC}
              selectedImm={selectedImm}
              onImmunizationItemClick={onImmunizationItemClick}
            />
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 12 }}>
            <AssessmentsSection
              completedAssessments={completedAssessments}
              selectedAssessment={selectedAssessment}
              onAssessmentItemClick={onAssessmentItemClick}
            />
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 12 }}>
            <FamilyHealthHistorySection familyHealthHistory={familyMedicalHistory} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default MedicalProfilePCC;
