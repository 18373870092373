import React from "react";
import { Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import NavBar from "../Provider/NavBar";
import { connect } from "react-redux";
import { PERMISSION_ISSUE } from "../../constants/actionTypes";
import Octicon, { EyeClosed } from "@githubprimer/octicons-react";
import "../.././main.scss";

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload) =>
    dispatch({
      type: PERMISSION_ISSUE,
    }),
});

const PermissionsIssues = ({}) => {
  return (
    <div>
      <NavBar />
      <div
        className="container d-flex flex-column"
        style={{ alignItems: "center", position: "fixed", top: "30%", left: "10%" }}
      >
        <span style={{ color: "#4a8fe7" }}>
          <Octicon icon={EyeClosed} size={96} />
        </span>
        <h4 style={{ fontSize: "40px" }}>Permissions Error:</h4>
        <p style={{ fontSize: "20px" }}>Sorry, you do not have permission to see this page.</p>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PermissionsIssues);
