import React, { useState, useEffect } from "react";
import ContentBLock from "../Encounter/ContentBlock";
import { findFeature, featureSetAttributes } from "../PhysicalExam/Features";
import EntryBlock from "../PhysicalExam/EntryBlock";
import { GroupNoteEntry } from "../PhysicalExam/Notes";
import FeatureEntry from "../PhysicalExam/FeatureEntry";
import FeatureCheckBoxes from "./FeatureCheckBoxes";
import PropTypes from "prop-types";

const PsychEntry = ({
  features,
  onChange,
  note,
  onNoteUpdate,
  onCheckboxUpdate,
  isNursingHomeSite,
  onRemoveSection,
}) => {
  const [hideContent, setHideContent] = useState(true);

  const na = findFeature(features, "psychNormalAffect", "Normal affect", "Psychological");
  const nm = findFeature(features, "psychNormalMood", "Normal mood", "Psychological");
  const si = findFeature(features, "suicialIdeation", "Suicidal ideation", "Psychological");
  const baseline = findFeature(
    features,
    "psychbaselineChange",
    "Changes from baseline",
    "Psychological",
  );

  const setAttribute = featureSetAttributes({ onChange });

  const setCheckBox = (target) => {
    onCheckboxUpdate(features, "Psychological", target.checked, target.value);
  };

  const anyChecked = (val) => {
    const foundIdx = (features || []).findIndex((e) => e.group === "Psychological");
    if (foundIdx < 0) {
      return false;
    }
    return features[foundIdx][val];
  };

  const hasEntires = () => {
    const foundIdx = (features || []).findIndex((e) => e.group === "Psychological");
    if (foundIdx < 0) {
      return false;
    }
    return !!features[foundIdx].attributes;
  };

  const handleRemoveSection = () => {
    onRemoveSection("Psychological");
    setHideContent(true);
  };

  useEffect(() => {
    setHideContent(!hasEntires());
  }, []);

  if (hideContent) {
    return (
      <ContentBLock
        title="Psychological"
        titleOnly={true}
        className="hide-content"
        onClick={() => setHideContent(false)}
      />
    );
  }

  return (
    <EntryBlock title="Psychological"  className="large">
      <div>
        <FeatureEntry
          {...{
            feature: na,
            values: ["Yes", "No"],
            onChange: setAttribute,
            id: "qa-psych-entry-na",
          }}
        />
        <FeatureEntry
          {...{
            feature: nm,
            values: ["Yes", "No"],
            onChange: setAttribute,
            id: "qa-psych-entry-nm",
          }}
        />
        <FeatureEntry
          {...{
            feature: si,
            values: ["No", "Yes"],
            onChange: setAttribute,
            id: "qa-psych-entry-si",
          }}
        />
        {isNursingHomeSite && (
          <FeatureEntry
            {...{
              feature: baseline,
              values: ["No", "Yes"],
              onChange: setAttribute,
              id: "qa-psych-entry-baseline",
            }}
          />
        )}
        <FeatureCheckBoxes {...{ setCheckBox, anyChecked, isNursingHomeSite }} />
        <div className="remove" onClick={handleRemoveSection}>
          Remove section -
        </div>
      </div>
      <div>
        <GroupNoteEntry
          {...{
            groupName: "Psychological",
            onChange: onNoteUpdate,
            note,
            id: "qa-psychological-group-entry",
          }}
        />
      </div>
    </EntryBlock>
  );
};

PsychEntry.propTypes = {
  features: PropTypes.array,
  onChange: PropTypes.func,
  note: PropTypes.string,
  onNoteUpdate: PropTypes.func,
  onCheckboxUpdate: PropTypes.func,
  isNursingHomeSite: PropTypes.bool,
};

export default PsychEntry;
