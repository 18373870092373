import React, { FC } from "react";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import { Input } from "reactstrap";
import ContentBlock from "./ContentBlock";

import {
  IUserMedicalIntake,
} from "../../constants/Types";
import "./DatePicker.scss";


export const InputError = ({ message, flag }) => (
  <h1 className="input-error">{flag ? message : ""}</h1>
);

type Props = {
  intake: IUserMedicalIntake;
  chiefComplaint: string;
  onChiefComplaintChanged: Function;
};

type Conditions = {
  value: string;
  label: string;
};

const InHomeHealthVisit: FC<Props> = ({
  chiefComplaint,
  intake,
  children,
  onChiefComplaintChanged,
}) => {

  const otherIntake =
    !!intake.otherIntake && !!intake.otherIntake.otherIntake ? intake.otherIntake.otherIntake : [];

  return (
    <>
      <ContentBlock title="Chief Complaint">
        <Input
          style={{ height: "70px" }}
          type="textarea"
          name="chiefComplaint"
          onChange={(e) => onChiefComplaintChanged(e)}
          value={chiefComplaint}
          id="qa-encounter-chiefcomplaint-freetext"
          placeholder="Enter Chief Complaint"
        />
      </ContentBlock>
      <ContentBlock title="Patient Intake Responses">
        {!!otherIntake &&
          otherIntake.map((item, idx) => (
            <>
              <div className="rxIntakeResponses">
                <span key={idx} className="rxQuestion">
                  {`${item.question}:`}
                </span>
                <span className="rxAnswer">{item.answer}</span>
              </div>
            </>
          ))}
      </ContentBlock>
      {children}
    </>
  );
};
export default InHomeHealthVisit;
