import React, { useState, useEffect } from "react";

import { findFeature, featureSetAttributes } from "../PhysicalExam/Features";
import EntryBlock from "../PhysicalExam/EntryBlock";
import { GroupNoteEntry } from "../PhysicalExam/Notes";
import FeatureEntry from "../PhysicalExam/FeatureEntry";
import FeatureCheckBoxes from "../PhysicalExam/FeatureCheckBoxes";
import PropTypes from "prop-types";
import ContentBLock from "../Encounter/ContentBlock";

const HeadEntry = ({
  features,
  onChange,
  note,
  onNoteUpdate,
  onCheckboxUpdate,
  isNursingHomeSite,
  onRemoveSection,
}) => {
  const [hideContent, setHideContent] = useState(true);
  const normocephalic = findFeature(features, "headNormocephalic", "Normocephalic", "Head");
  const atraumatic = findFeature(features, "headAtraumatic", "Atraumatic", "Head");
  const setAttribute = featureSetAttributes({ onChange });
  const setCheckBox = (target) => {
    onCheckboxUpdate(features, "Head", target.checked, target.value);
  };

  const anyChecked = (val) => {
    const foundIdx = (features || []).findIndex((e) => e.group === "Head");
    if (foundIdx < 0) {
      return false;
    }
    return features[foundIdx][val];
  };

  const hasEntires = () => {
    const foundIdx = (features || []).findIndex((e) => e.group === "Head");
    if (foundIdx < 0) {
      return false;
    }
    return !!features[foundIdx].attributes;
  };

  const handleRemoveSection = () => {
    onRemoveSection("Head");
    setHideContent(true);
  };

  useEffect(() => {
    setHideContent(!hasEntires());
  }, []);

  if (hideContent) {
    return (
      <ContentBLock
        title="Head"
        titleOnly={true}
        className="hide-content"
        onClick={() => setHideContent(false)}
      />
    );
  }

  return (
    <EntryBlock title="Head" className="large">
      <div>
        <FeatureEntry
          {...{
            feature: normocephalic,
            values: ["Yes", "No"],
            onChange: setAttribute,
            className: "yes-no-toggle",
            id: "qa-head-entry-normocephalic",
          }}
        />
        <FeatureEntry
          {...{
            feature: atraumatic,
            values: ["Yes", "No"],
            onChange: setAttribute,
            className: "yes-no-toggle",
            id: "qa-head-entry-atraumatic",
          }}
        />
        <FeatureCheckBoxes {...{ setCheckBox, anyChecked, isNursingHomeSite }} />
        <div className="remove" onClick={handleRemoveSection}>
          Remove section -
        </div>
      </div>
      <div>
        <GroupNoteEntry
          {...{ groupName: "Head", onChange: onNoteUpdate, note, id: "qa-head-group-entry" }}
        />
      </div>
    </EntryBlock>
  );
};

HeadEntry.propTypes = {
  features: PropTypes.array,
  onChange: PropTypes.func,
  note: PropTypes.string,
  onNoteUpdate: PropTypes.func,
  onCheckboxUpdate: PropTypes.func,
  isNursingHomeSite: PropTypes.bool,
};

export default HeadEntry;
