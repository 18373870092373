import React from "react";

import { findFeature, featureSetAttributes } from "../PhysicalExam/Features";
import EntryBlock from "../PhysicalExam/EntryBlock";
import { GroupNoteEntry } from "../PhysicalExam/Notes";
import FeatureEntry from "../PhysicalExam/FeatureEntry";
import PropTypes from "prop-types";

const RxOtherMeds = ({ features, onChange, note, onNoteUpdate }) => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'features' does not exist on type 'Props'... Remove this comment to see the full error message
  const rxOtherMeds = findFeature(features, "rxOtherMeds", "RxOtherMeds", "RxOtherMeds");

  const setAttribute = featureSetAttributes({ onChange });

  return (
    <EntryBlock title="Are there any other medications you are supposed to take but are currently not taking?">
      <div>
        <FeatureEntry
          {...{
            feature: rxOtherMeds,
            values: ["Yes", "No"],
            onChange: setAttribute,
            omitTitle: true,
            id:"qa-rxOtherMeds-entry"
          }}
        />
      </div>
      <div>
        <GroupNoteEntry {...{ groupName: "RxOtherMeds", onChange: onNoteUpdate, note, id:"qa-rxOtherMeds-group-entry" }} />
      </div>
    </EntryBlock>
  // @ts-expect-error ts-migrate(2739) FIXME: Type '{ groupName: string; onChange: ((...args: an... Remove this comment to see the full error message
  );
};

RxOtherMeds.propTypes = {
  feature: PropTypes.array,
  onChange: PropTypes.func,
  note: PropTypes.string,
  onNoteUpdate: PropTypes.func,
};
export default RxOtherMeds;
