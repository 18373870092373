import React from "react";
import PropTypes from "prop-types";
import ErrorScreen from "./ErrorScreen";

class ErrorHandler extends React.Component {
  state = { hasError: false };
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'error' implicitly has an 'any' type.
  componentDidCatch(error, info) {
    this.setState({ hasError: true });
  }
  render() {
    return this.state.hasError ? <ErrorScreen /> : this.props.children;
  }
}

ErrorHandler.propTypes = {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
  children: PropTypes.element
}

export default ErrorHandler;
