import React, { FC } from "react";

import BlockSection from "./BlockSection";
import ListEditor from "./ListEditor";
import { IAllergySocialHistory } from "../../constants/Types";

type Props = {
  socialHistory: IAllergySocialHistory;
  oldSocialHistory: IAllergySocialHistory;
  onAllergyHistoryChange: (field: string) => (newValue: any) => void;
  searchableItemListSource: (value: any) => { items: any };
};

const AllergyPetsHistoryEditor: FC<Props> = ({
  socialHistory,
  oldSocialHistory,
  searchableItemListSource,
  onAllergyHistoryChange,
}) => {
  return (
    <BlockSection title={"Pets"}>
      <div className="form-row mt-0">
        <ListEditor
          items={socialHistory.allergyPetsHistory.pets}
          oldItems={oldSocialHistory.allergyPetsHistory.pets || []}
          itemListSource={searchableItemListSource}
          onChange={onAllergyHistoryChange("pets")}
        />
      </div>
    </BlockSection>
  );
};

export default AllergyPetsHistoryEditor;
