import React from "react";
import DOB from "../../components/Inputs/DOB";
import moment from "moment";
import InfoSelect from "../../components/Encounter/InfoSelect";
import PropTypes from "prop-types";
const organizations = ["InjureFree", "Creighton", "JBS", "FAU", "RSS", "none"];

const UploadDetails = ({ details, onChange, isEditing }) => {
  const handleDateChange = (val) => {
    const date = moment(val.target.value).utc();
    onChange("labTestDate")(date);
  };
  if (!isEditing) {
    return (
      <div className="uploadDetails">
        <h1>Test Results</h1>
        {details.testResult || "-"}
        <h1>Lab Test Date (mm/dd/yyyy)</h1>
        {moment(details.labTestDate).format("MM/DD/YYYY") || "-"}
        <h1>Payment Type</h1>
        {details.payment || "-"}
        <h1>Organization</h1>
        {details.organization || "-"}
        <h1>Text Message to Patient</h1>
        {details.patientTextMessage || "-"}
      </div>
    );
  }

  return (
    <div className="uploadDetails">
      <h1>Test Results</h1>
      <input
        type="radio"
        onChange={() => onChange("testResult")("Not Detected")}
        checked={details.testResult === "Not Detected"}
      />
      <label>Not Detected</label>
      <input
        type="radio"
        onChange={() => onChange("testResult")("Detected")}
        checked={details.testResult === "Detected"}
      />
      <label>Detected</label>
      <input
        type="radio"
        onChange={() => onChange("testResult")("Rejected")}
        checked={details.testResult === "Rejected"}
      />
      <label>Rejected</label>
      <h1>Lab Test Date (mm/dd/yyyy)</h1>
      <DOB value={details.labTestDate} onChange={handleDateChange} />
      <h1>Payment Type</h1>
      <input
        type="radio"
        onChange={() => onChange("payment")("Insurance")}
        checked={details.payment === "Insurance"}
      />
      <label>Insurance</label>
      <input
        type="radio"
        onChange={() => onChange("payment")("Cash Pay")}
        checked={details.payment === "Cash Pay"}
      />
      <label>Cash Pay</label>
      <h1>Organization</h1>
      <div className="org-select">
        <InfoSelect
          value={details.organization || "Type to search / add..."}
          onChange={(val) => onChange("organization")(val)}
          options={organizations}
        />
      </div>
      <h1>Text Message to Patient</h1>
      <input
        type="radio"
        onChange={() => onChange("patientTextMessage")("PCROnly")}
        checked={details.patientTextMessage === "PCROnly"}
      />
      <label>PCR Only</label>
      <input
        type="radio"
        onChange={() => onChange("patientTextMessage")("PCRPlus")}
        checked={details.patientTextMessage === "PCRPlus"}
      />
      <label>PCR+Telehealth Bundle</label>
    </div>
  );
};

UploadDetails.propTypes = {
  details: PropTypes.object,
  onChange: PropTypes.func,
  isEditing: PropTypes.bool,
};
export default UploadDetails;
