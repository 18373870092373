import { Button, Input } from "reactstrap";
import { DayPickerSingleDateController } from "react-dates";
import moment, { MomentInput, Moment } from "moment-timezone";
import React, { FC, useCallback, useEffect, useState } from "react";
import debounce from "lodash.debounce";

const buttonsText = {
  oneWeek: "1 week",
  twoWeeks: "2 weeks",
  oneMonth: "1 month",
  twoMonth: "2 months",
  threeMonth: "3 months",
};

const buttonsRange = {
  notRequired: () => false,
  oneWeek: () => moment().add(1, "weeks"),
  twoWeeks: () => moment().add(2, "weeks"),
  oneMonth: () => moment().add(1, "months"),
  twoMonth: () => moment().add(2, "months"),
  threeMonth: () => moment().add(3, "months"),
};
const dateObj = {
  year: (prevDate, year) => moment(prevDate).year(20 + year),
  day: (prevDate, date) => moment(prevDate).date(date),
  month: (prevDate, month) => moment(prevDate).month(month - 1), // month starts from 0 in this lib
};
type Props = {
  effectiveDate?: string;
  onChange: (value?: string | null) => void;
};

const DateWithRangeButtons: FC<Props> = ({ onChange, effectiveDate }) => {
  const [date, setDate] = useState({ month: "", day: "", year: "" });
  const [error, setError] = useState(false);
  const [calendarDate, setCalendarDate] = useState<Moment | null>(moment(effectiveDate) || null);
  const [rangeButton, setRange] = useState("notRequired");

  const handleFocus = (e) => {
    e.target.select();
  };
  const debounceCallback = useCallback(
    debounce((d: Moment | null) => {
      if (moment(d).isValid() || d === null) {
        handleButtonsState(d);
        onChange(d?.format());
        setCalendarDate(d);
        setError(false);
      } else {
        setError(true);
      }
    }, 600),
    [],
  );

  useEffect(() => {
    if (!!effectiveDate) {
      setDateIntoInputs(moment(effectiveDate));
      const effectiveMoment = moment(effectiveDate);
      setCalendarDate(effectiveMoment);
      if (rangeButton && buttonsRange[rangeButton]()) {
        const test =
          buttonsRange[rangeButton]().format("MM/DD/YY") !== effectiveMoment.format("MM/DD/YY")
            ? ""
            : rangeButton;
        setRange(test);
      }
    }

    if (!effectiveDate) {
      setDateIntoInputs(null);
    }
  }, [effectiveDate]);

  const onDateChange = (field: string) => (e: any) => {
    let fieldIndex = e.target.name || 0;
    fieldIndex = Number(fieldIndex);

    let value = e.target.value < 2 ? `0${e.target.value}` : e.target.value.slice(-2);

    if (e.target.value.length === e.target.maxLength) {
      if (fieldIndex < 3) {
        const nextfield = document.querySelector(
          `input[name='${fieldIndex + 1}']`,
        ) as HTMLInputElement;

        if (nextfield !== null) {
          nextfield.focus();
        }
      }
    }

    const newDate = dateObj[field](calendarDate || moment(), value);

    setDateIntoInputs(newDate);
    debounceCallback(newDate);
  };
  const setDateIntoInputs = (date: Moment | null) => {
    if (date) {
      const [month, day, year] = date.format("MM/DD/YY").split("/");
      setDate({ day: day, month: month, year: year });
    } else {
      setDate({ day: "", month: "", year: "" });
    }
  };

  const onButtonClick = (e) => {
    setRange(e.target.name);
    const newDate = buttonsRange[e.target.name]();
    if (newDate) {
      setDateIntoInputs(newDate);
      debounceCallback(newDate);
    } else {
      setDateIntoInputs(null);
      debounceCallback(null);
    }
  };
  const handleButtonsState = (time) => {
    if (time === null) {
      return;
    }
    for (let item in buttonsRange) {
      if (
        buttonsRange[item]() &&
        buttonsRange[item]().format("MM/DD/YY") === time.format("MM/DD/YY")
      ) {
        setRange(item);
      }
    }
  };

  const handleDateChange = (d: Moment | null) => {
    if (!d) {
      return;
    }
    setDateIntoInputs(d);
    debounceCallback(d);
  };

  return (
    <div className="d-flex flex-column">
      <span>Recommend follow up visit</span>
      <div className="d-flex">
        <div className="d-flex flex-column">
          <Button
            color=""
            className="rangeDateBtn mt-3"
            name={"notRequired"}
            onClick={onButtonClick}
            active={!effectiveDate}
          >
            Not Required
          </Button>
          {Object.keys(buttonsText).map((item) => (
            <Button
              key={`rangeBtn-${item}`}
              color=""
              className="rangeDateBtn mt-3"
              name={item}
              onClick={onButtonClick}
              active={rangeButton === item}
            >
              {buttonsText[item]}
            </Button>
          ))}
        </div>
        <div className="d-flex flex-column">
          <div className="nh_date-time">
            <div className="nh-date">
              <Input
                onChange={onDateChange("month")}
                value={date.month}
                className={`nh-mon ${error && "error"}`}
                name="1"
                id="mon"
                maxLength={3}
                type="number"
                selectTextOnFocus={true}
                onFocus={handleFocus}
                placeholder=""
              />
              <span className="sep">/</span>
              <Input
                onChange={onDateChange("day")}
                value={date.day}
                className={`nh-day ${error && "error"}`}
                name="2"
                id="day"
                maxLength={3}
                type="number"
                selectTextOnFocus={true}
                onFocus={handleFocus}
                placeholder=""
              />
              <span className="sep">/</span>
              <Input
                onChange={onDateChange("year")}
                value={date.year}
                className={`nh-year ${error && "error"}`}
                name="3"
                id="year"
                placeholder=""
                maxLength={3}
                type="number"
                selectTextOnFocus={true}
                onFocus={handleFocus}
              />
            </div>
          </div>
          <div className="rx-CalendarSelect mt-3">
            <DayPickerSingleDateController
              date={moment(calendarDate)}
              focused={true}
              onFocusChange={() => {}}
              initialVisibleMonth={() => (effectiveDate ? moment(effectiveDate) : moment())}
              numberOfMonths={1}
              hideKeyboardShortcutsPanel={true}
              daySize={31}
              horizontalMonthPadding={0}
              verticalHeight={200}
              transitionDuration={0}
              onDateChange={handleDateChange}
              isDayHighlighted={(day) =>
                moment(day).format("MM/DD/YYYY") === moment(calendarDate).format("MM/DD/YYYY")
              }
              enableOutsideDays={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateWithRangeButtons;
