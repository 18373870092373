import { PROVIDER_CLOCK_IN, PROVIDER_PRESENCE, PROVIDER_CLOCK_OUT } from "../constants/actionTypes";
import { Reducer } from "redux";

const defaultState = {
  clockedIn: false,
  status: "clockedOut",
};

const presenceReducer: Reducer<typeof defaultState, any> = (state = defaultState, action) => {
  switch (action.type) {
    case PROVIDER_PRESENCE:
    case PROVIDER_CLOCK_IN:
      if (action.payload && action.payload.error) {
        return {
          ...action.payload,
        };
      }
      return {
        ...action.payload,
        clockedIn: (action.payload || {}).status !== "offline",
      };
    case PROVIDER_CLOCK_OUT:
      return {
        ...action.payload,
        clockedOut: false,
      };
    default:
      return state;
  }
};

export default presenceReducer;
