import {
  ASYNC_START,
  ASYNC_END,
  RTC_INFO,
  LOGOUT,
  SWITCH_PRACTICE,
} from "../constants/actionTypes";
import { Reducer } from "redux";
import { IPusherProviderInfo } from "../constants/Types";

const defaultState = {};

const rtcReducer: Reducer<{ info?: IPusherProviderInfo }, any> = (
  state = defaultState,
  action: any,
) => {
  switch (action.type) {
    case RTC_INFO:
      // TODO: errors...
      return {
        ...state,
        info: action.payload,
      };
    case SWITCH_PRACTICE:
      return defaultState;
    case LOGOUT:
      return defaultState;
    case ASYNC_START:
      return state;
    case ASYNC_END:
      return state;
    default:
      return state;
  }
};

export default rtcReducer;
