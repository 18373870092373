import React from "react";
import moment from "moment";
import { Row, Col } from "reactstrap";

import styles from "./chart.scss";

const InsuranceProfile = ({ insuranceProfile, card }) => {
  return (
    <>
      <Row>
        <Col>
          <div className={styles.profileBlock}>
            <h2>Insurance Company</h2>
            <p>
              {insuranceProfile.companyName}
              <br />
              {insuranceProfile.street1}
              <br />
              {insuranceProfile.city}, {insuranceProfile.state} {insuranceProfile.postalCode}
              <br />
              {insuranceProfile.insurancePhone}
            </p>
          </div>
          <div className={styles.profileBlock}>
            <h2>ID Number</h2>
            <p>{insuranceProfile.insuranceID}</p>
          </div>
          <div className={styles.profileBlock}>
            <h2>Group Number</h2>
            <p>{insuranceProfile.groupNumber}</p>
          </div>
          <div className={styles.profileBlock}>
            <h2>Copay Amount</h2>
            <p>${insuranceProfile.pcpCopay}</p>
          </div>
        </Col>
        <Col>
          {insuranceProfile.responsibleParty !== "self" && (
            <>
              <div className={styles.profileBlock}>
                <h2>Responsible Party Information</h2>
                <p>
                  {insuranceProfile.responsiblePartyInfo.firstName}{" "}
                  {insuranceProfile.responsiblePartyInfo.lastName}
                  <br />
                  {insuranceProfile.responsiblePartyInfo.street}
                  <br />
                  {insuranceProfile.responsiblePartyInfo.city},{" "}
                  {insuranceProfile.responsiblePartyInfo.state}{" "}
                  {insuranceProfile.responsiblePartyInfo.postalCode}
                  <br />
                  {insuranceProfile.responsiblePartyInfo.homePhone && (
                    <>{insuranceProfile.responsiblePartyInfo.homePhone}</>
                  )}
                  <br />
                  {insuranceProfile.responsiblePartyInfo.workPhone && (
                    <>{insuranceProfile.responsiblePartyInfo.workPhone}</>
                  )}
                </p>
              </div>
              <div className={styles.profileBlock}>
                <h2>Date of Birth</h2>
                <p>{moment(insuranceProfile.responsiblePartyInfo.dob).format("MM/DD/YYYY")}</p>
              </div>
              <div className={styles.profileBlock}>
                <h2>Responsible Party Sex</h2>
                <p>{insuranceProfile.responsiblePartyInfo.sex}</p>
              </div>
            </>
          )}
          <div className={styles.profileBlock}>
            <h2>Patient relationship to responsible party</h2>
            <p>{insuranceProfile.responsibleParty}</p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          {card.frontImageURL ? (
            <img src={card.frontImageURL} height="150px" width="200px" className="mb-3" />
          ) : (
            <p>No card front image provided</p>
          )}
        </Col>
        <Col>
          {card.backImageURL ? (
            <img src={card.backImageURL} height="150px" width="200px" />
          ) : (
            <p>No card back image provided</p>
          )}
        </Col>
      </Row>
    </>
  );
};

export default InsuranceProfile;
