export const APP_LOAD = "APP_LOAD";
export const ASYNC_START = "ASYNC_START";
export const ASYNC_END = "ASYNC_END";
export const REDIRECT = "REDIRECT";
export const NAVIGATE_TO = "NAVIGATE_TO";
export const TOKEN_LOAD = "TOKEN_LOAD";
export const COMPONENT_LOAD = "COMPONENT_LOAD";
export const COMPONENT_LOAD_MORE = "COMPONENT_LOAD_MORE";
export const COMPONENT_UNLOAD = "COMPONENT_UNLOAD";
export const COMPONENT_CHANGED = "COMPONENT_CHANGED";
export const PERMISSION_ISSUE = "PERMISSION_ISSUE";
export const CAPTCHA_LOAD = "CAPTCHA_LOAD";
// Provider Login
export const AUTHENTICATE = "AUTHENTICATE";
export const ACCEPT_INVITATION = "ACCEPT_INVITATION";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const LOGOUT = "LOGOUT";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const REQUEST_PASSWORD_RESET = "REQUEST_PASSWORD_RESET";
export const INVITE_CREATED = "INVITE_CREATED";
export const INVITE_ACCEPTED = "INVITE_ACCEPTED";
export const SWITCH_PRACTICE = "SWITCH_PRACTICE";
export const REFRESH_USER = "REFRESH_USER";

// Provider availability
export const PROVIDER_PRESENCE = "PROVIDER_PRESENCE";
export const PROVIDER_CLOCK_IN = "PROVIDER_CLOCK_IN";
export const PROVIDER_CLOCK_OUT = "PROVIDER_CLOCK_OUT";

// Provider editing
export const PROVIDER_DETAILS_LOAD = "PROVIDER_DETAILS_LOAD";
export const PROVIDER_EDIT_SAVE = "PROVIDER_EDIT_SAVE";
export const PROVIDER_RESEND_INVITE = "PROVIDER_RESEND_INVITE";

// Video demo
export const VIDEO_CONNECT = "VIDEO_CONNECT";
export const VIDEO_ROOM_TOKEN = "VIDEO_ROOM_TOKEN";

// Encounters
export const ENCOUNTER_ENGAGE = "ENCOUNTER_ENGAGE";
export const ENCOUNTER_DETAILS = "ENCOUNTER_DETAILS";
export const ENCOUNTER_START = "ENCOUNTER_START";
export const ENCOUNTER_VIDEO_STOP = "ENCOUNTER_VIDEO_STOP";
export const ENCOUNTER_ENGAGEMENT_STATUS = "ENCOUNTER_ENGAGEMENT_STATUS";
export const ENCOUNTER_LOST = "ENCOUNTER_LOST";
export const ENCOUNTER_ACCEPTED = "ENCOUNTER_ACCEPTED";
export const ENCOUNTER_COMPLETE = "ENCOUNTER_COMPLETE";
export const ENCOUNTER_EJECTED = "ENCOUNTER_EJECTED";
export const ENCOUNTER_LOAD_NOTE = "ENCOUNTER_LOAD_NOTE";
export const ENCOUNTER_LOAD_NOTE_PDF = "ENCOUNTER_LOAD_NOTE_PDF";
export const ENCOUNTER_UNLOAD_NOTE_PDF = "ENCOUNTER_UNLOAD_NOTE_PDF";
export const ENCOUNTER_NOTE_FIELD_UPDATE = "ENCOUNTER_NOTE_FIELD_UPDATE";
export const ENCOUNTER_SAVE_NOTE = "ENCOUNTER_SAVE_NOTE";
export const ENCOUNTER_UPDATE_PROFILE = "ENCOUNTER_UPDATE_PROFILE";
export const ENCOUNTER_SAVE_PROFILE = "ENCOUNTER_SAVE_PROFILE";
export const ENCOUNTER_UPDATE_INTAKE = "ENCOUNTER_UPDATE_INTAKE";
export const ENCOUNTER_SAVE_INTAKE = "ENCOUNTER_SAVE_INTAKE";
export const ENCOUNTER_UPDATE_TEST_RESULT = "ENCOUNTER_UPDATE_TEST_RESULT";
export const ENCOUNTER_SAVE_PROVIDER_TEST_RESULT = "ENCOUNTER_SAVE_PROVIDER_TEST_RESULT";
export const ENCOUNTER_GET_PRESCRIPTION_LINK = "ENCOUNTER_GET_PRESCRIPTION_LINK";
export const ENCOUNTER_USER_VIDEO_STATE = "ENCOUNTER_USER_VIDEO_STATE";
export const ENCOUNTER_USER_VIDEO_CONNECT = "ENCOUNTER_USER_VIDEO_CONNECT";
export const ENCOUNTER_USER_VIDEO_DISCONNECT = "ENCOUNTER_USER_VIDEO_DISCONNECT";
export const ENCOUNTER_SAVE_VIDEO_EVENT = "ENCOUNTER_SAVE_VIDEO_EVENT";
export const ENCOUNTER_PRESCRIPTION_UPDATE = "ENCOUNTER_PRESCRIPTION_UPDATE";
export const ENCOUNTER_VISIT_HISTORY = "ENCOUNTER_VISIT_HISTORY";
export const ENCOUNTER_ABANDON = "ENCOUNTER_ABANDON";
export const ENCOUNTER_LOAD_TYTOCARE_LINK = "ENCOUNTER_LOAD_TYTOCARE_LINK";
export const INITIATE_NO_PATIENT_ENCOUNTER = "INITIATE_NO_PATIENT_ENCOUNTER";
export const ENCOUNTER_SAVED = "ENCOUNTER_SAVED";
export const ENCOUNTER_EXPLICIT_RESUME = "ENCOUNTER_EXPLICIT_RESUME";
export const ENCOUNTER_LOAD_BLANK_LTC_LINKS = "ENCOUNTER_LOAD_BLANK_LTC_LINKS";
export const ENCOUNTER_SAVE_MESSAGE = "ENCOUNTER_SAVE_MESSAGE";
export const ENCOUNTER_ADDITIONAL_STATUS = "ENCOUNTER_ADDITIONAL_STATUS";

export const ENCOUNTER_LOAD_UNSIGNED = "ENCOUNTER_LOAD_UNSIGNED";
export const ENCOUNTER_POLLER_DATA = "ENCOUNTER_POLLER_DATA";
export const ENCOUNTER_CHAT_DATA = "ENCOUNTER_CHAT_DATA";
export const ENCOUNTER_CHAT_TEMPLATE = "ENCOUNTER_CHAT_TEMPLATE";
export const ENCOUNTER_IMAGES = "ENCOUNTER_IMAGES";

// Review of Systems
export const REVIEW_OF_SYSTEMS = "REVIEW_OF_SYSTEMS";

export const RTC_INFO = "RTC_INFO";

// Waiting Room
export const WAITING_ROOM_AVAILABLE = "WAITING_ROOM_AVAILABLE";
export const WAITING_ROOM_PENDING = "WAITING_ROOM_PENDING";
export const WAITING_ROOM_ASSIGNED = "WAITING_ROOM_ASSIGNED";
export const WAITING_ROOM_CANCEL = "WAITING_ROOM_CANCEL";
export const WAITING_ROOM_LOAD_PENDING = "WAITING_ROOM_LOAD_PENDING";
export const WAITING_ROOM_LOAD_AVAILABLE = "WAITING_ROOM_LOAD_AVAILABLE";
export const WAITING_ROOM_MESSAGE = "WAITING_ROOM_MESSAGE";
export const WAITING_ROOM_MESSAGE_CLEAR = "WAITING_ROOM_MESSAGE_CLEAR";

// Time, and the inevitable passing thereof.
export const UPDATE_CURRENT_TIME = "UPDATE_CURRENT_TIME";

// Prescription notifications
export const PRESCRIPTION_NOTIFICATIONS = "PRESCRIPTION_NOTIFICATIONS";
export const PRESCRIPTION_NOTIFICATION_LINK = "PRESCRIPTION_NOTIFICATION_LINK";

// Visit History
export const PROVIDER_VISIT_HISTORY = "PROVIDER_VISIT_HISTORY";

export const ADD_NURSING_HOME_FLAG = "ADD_NURSING_HOME_FLAG";

// Medical Directors
export const MEDICAL_DIRECTORS_LIST = "MEDICAL_DIRECTORS_LIST";

// Providers list
export const PROVIDERS_LIST = "PROVIDERS_LIST";
export const PROVIDERS_FILTERED_LIST = "PROVIDERS_FILTERED_LIST";

// Faxes
export const FAX_ASSIGN = "FAX_ASSIGN";
export const FAX_COMPLETE = "FAX_COMPLETE";
export const ASSIGNED_FAX_LIST = "ASSIGNED_FAX_LIST";
export const UNASSIGNED_FAX_LIST = "UNASSIGNED_FAX_LIST";
export const DISMISS_FAX = "DISMISS_FAX";

// Idle monitoring
export const SET_IDLE_LOGOUT_TIME = "SET_IDLE_LOGOUT_TIME";

export const ACTIVITY_SUMMARY = "ACTIVITY_SUMMARY";
export const ENCOUNTER_MONITOR_LIST = "ENCOUNTER_MONITOR_LIST";
export const ENCOUNTER_MONITOR_DETAILS = "ENCOUNTER_MONITOR_DETAILS";

export const VIDEO_SETUP_COMPLETE = "VIDEO_SETUP_COMPLETE";
export const SIGNATURE_COLLECTED = "SIGNATURE_COLLECTED";

// Patients tab
export const SELECT_PRACTICE_FOR_ENCOUNTERS_SEARCH = "SELECT_PRACTICE_FOR_ENCOUNTERS_SEARCH";
export const SELECT_PRACTICE_FOR_PATIENTS_SEARCH = "SELECT_PRACTICE_FOR_PATIENTS_SEARCH";

//monitoring
export const SELECT_PRACTICE = "SELECT_PRACTICE";
export const LOAD_ENCOUNTERS_DETAILS = "LOAD_ENCOUNTERS_DETAILS";

//Recent Encounters
export const ENCOUNTERS_SEARCH_RESULTS = "ENCOUNTERS_SEARCH_RESULTS";

//Check hash on updated
export const DISPLAY_RELOAD_BUTTON = "DISPLAY_RELOAD_BUTTON"
export const EMR_NOTIFICATIONS = "EMR_NOTIFICATIONS";

