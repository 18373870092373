import React from "react";
import Details from "./Details"

const ActiveDetails = ({}) => {
    return(
        <div>
            <Details fromActiveEncounter={true}/>
        </div>
    )
}

export default ActiveDetails;