import React from "react";
import NavBar from "../Provider/NavBar";
import "../../main.scss";
import { Input, Label, Button } from "reactstrap";
import api from "../../api";
import { connect } from "react-redux";
import { IAppState } from "../../reducer";
import { PERMISSION_ADMIN } from "../../constants/Permissions";

const mapStateToProps = (state: IAppState) => {
  return {
    permissions: state.common.permissions,
  };
};
const mapDispatchToProps = () => ({});

const EncounterReport = ({ permissions }) => {
  const [encounterID, setEncounterID] = React.useState("");
  const [error, setError] = React.useState<string | null>(null);
  const [success, setSuccess] = React.useState<string | null>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const eID = event.target.value;
    setEncounterID(eID);
  };

  const handleSave = async () => {
    try {
      let res: { errorID: string } | {} = await api.Admin.reportEncounter({
        encounterID: encounterID,
      });
      setError(null);
      setEncounterID("");
      let successMessage = res?.errorID ? res.errorID : "No error returned.";
      setSuccess(successMessage);
    } catch (err: any) {
      setSuccess(null);
      setError(err?.response?.body?.message ? err.response.body.message : err?.status);
    }
  };

  const handleCancel = () => {
    setEncounterID("");
  };

  return (
    <>
      {permissions && permissions.includes(PERMISSION_ADMIN) && (
        <div>
          <NavBar />

          <div className="dashboard-container w-75">
            <h4>Encounter Snapshot</h4>
          </div>

          <div className="dashboard-component ml-5 mr-5">
            <div className="d-flex justify-content-center ml-0">
              {error && (
                <span
                  style={{
                    fontSize: "12px",
                    color: "red",
                    fontStyle: "italic",
                  }}
                >
                  {error}
                </span>
              )}
            </div>

            <div className="w-100 d-flex justify-content-center mt-5">
              {!success ? (
                <>
                  <Label className="mr-3 mt-auto">EncounterID:</Label>
                  <Input
                    type="text"
                    name="EncounterID"
                    id="encounterID"
                    placeholder="encounter-1234..."
                    onChange={(e) => handleChange(e)}
                    className="w-75"
                    autoComplete="off"
                    value={encounterID}
                  />
                </>
              ) : (
                <>
                  <span style={{ fontSize: "15px", color: "green", fontStyle: "Bold" }}>
                    {success}
                  </span>
                </>
              )}
            </div>
            <div className="w-100 mt-5 d-flex justify-content-center">
              <Button disabled={!encounterID} onClick={() => handleSave()} color="primary">
                Save
              </Button>
              <Button disabled={!encounterID} onClick={() => handleCancel()} color="link">
                Cancel
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(EncounterReport);
