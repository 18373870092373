import React from "react";
import { FormGroup, Input, Label } from "reactstrap";
import PropTypes from "prop-types";

const FeatureCheckBoxes = ({ setCheckBox, anyChecked, isNursingHomeSite }) => {
  return (
    <>
      <FormGroup check>
        <Label check>
          <Input
            type="checkbox"
            value="reportedByNurse"
            onChange={(e) => setCheckBox(e.target)}
            checked={anyChecked("reportedByNurse")}
          />
          {isNursingHomeSite ? "Reported by nurse or telemedicine extender" : "Reported by nurse"}
        </Label>
      </FormGroup>
      {!isNursingHomeSite && (
        <FormGroup check>
          <Label check>
            <Input
              type="checkbox"
              value="unableToAssess"
              onChange={(e) => setCheckBox(e.target)}
              checked={anyChecked("unableToAssess")}
            />
            Deferred, unable to assess
          </Label>
        </FormGroup>
      )}
    </>
  );
};

FeatureCheckBoxes.propTypes = {
  setCheckBox: PropTypes.func,
  anyChecked: PropTypes.func,
  isNursingHomeSite: PropTypes.bool,
};

export default FeatureCheckBoxes;
