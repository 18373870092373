import api from "./api";
import {
  ASYNC_START,
  ASYNC_END,
  AUTHENTICATE,
  ACCEPT_INVITATION,
  LOGOUT,
  REFRESH_TOKEN,
  SWITCH_PRACTICE,
  REFRESH_USER,
  NAVIGATE_TO,
  COMPONENT_LOAD,
  LOAD_ENCOUNTERS_DETAILS,
} from "./constants/actionTypes";

const promiseMiddleware = (store: any) => (next: any) => (action: any) => {
  if (isPromise(action.payload)) {
    const subtype = action.subtype || action.type;

    if (!action.withoutSpinner) {
      store.dispatch({ type: ASYNC_START, subtype: subtype });
    }
    const currentView = store.getState().viewChangeCounter;
    const skipTracking = action.skipTracking;

    action.payload.then(
      (res: any) => {
        const currentState = store.getState();
        if (!skipTracking && currentState.viewChangeCounter !== currentView) {
          return;
        }
        action.payload = res;
        store.dispatch({
          type: ASYNC_END,
          subtype: subtype,
          promise: action.payload,
        });
        store.dispatch(action);
      },
      (error: any) => {
        const currentState = store.getState();
        if (!skipTracking && currentState.viewChangeCounter !== currentView) {
          return;
        }
        
        // TODO: If we can't parse the json, it breaks here too, with no error.response.status
        console.log("ERROR", error);
        const response = error.response || {};
        // if there is no current currentUser skip permissions issue go to logout
        const currentUser = store.getState().common.currentUser;
        if(response.status === 401 && action.type === COMPONENT_LOAD && currentUser) {
          
          return store.dispatch({ type: NAVIGATE_TO, targetPath: "/permissions-issue"})
        }
        if (response.status === 401 && action.type !== AUTHENTICATE && action.type !== COMPONENT_LOAD ) {

          const currentLocation = currentState.router.location;
          const targetPath = `${currentLocation.pathname}${currentLocation.search}`;
          store.dispatch({
            type: ASYNC_END,
            subtype: subtype,
            promise: action.payload,
          });
          return store.dispatch({ type: LOGOUT, targetPath: targetPath });
        }
        action.error = true;
        action.payload = response.body;
        if (!action.skipTracking) {
          store.dispatch({
            type: ASYNC_END,
            subtype: subtype,
            promise: action.payload,
          });
        }
        store.dispatch(action);
      },
    );

    return;
  }

  next(action);
};

const localStorageMiddleware = (store: any) => (next: any) => (action: any) => {
  if (
    action.type === ACCEPT_INVITATION ||
    action.type === AUTHENTICATE ||
    action.type === REFRESH_TOKEN ||
    action.type == SWITCH_PRACTICE
  ) {
    if (!action.error) {
      const payload = action.payload || {};
      window.localStorage.setItem("jwt", payload.token);
      api.setToken(payload.token);
    }
  } else if (action.type === LOGOUT) {
    window.localStorage.setItem("jwt", "");
    api.setToken(null);
  }

  next(action);
};

const refreshUserMiddleware = (store: any) => (next: any) => (action: any) => {
  
    if (action.type == SWITCH_PRACTICE) {
          // when the practice is switched, the current user/provider should be refreshed
              store.dispatch({
            type: REFRESH_USER,
            payload: api.Authorization.current(),
            noRedirect: !!action.noRedirect,
            targetPath: action.targetPath ? action.targetPath : "/",
        });
      
    } else if (action.type === REFRESH_USER) {
      if (!action.noRedirect) {
        // now thay a refresh has been performed, return the user to the "overview" screen
        store.dispatch({
          type: NAVIGATE_TO,
          targetPath: action.targetPath ? action.targetPath : "/",
        });
      }
    
  }
  

  next(action);
};

function isPromise(v: any) {
  return v && typeof v.then === "function";
}

export { promiseMiddleware, localStorageMiddleware, refreshUserMiddleware };
