import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";

import ResultConfirmation from "./ResultConfirmation";
import StartTimer from "./StartTimer";

const TytocareVitals = ({ vitals }) => {
  const VitalsItems = ({ vitals }) => {
    const checkIfEmpty = (value) => {
      if (value) {
        return value;
      }
      return "not recorded";
    };

    const capitalize = (s) => {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    };
    return (
      <div className="d-flex-column items">
        <ul>
          <li>
            Weight: <span>{checkIfEmpty(vitals.weight)}</span>lbs
          </li>
          <li>Height: {checkIfEmpty(vitals.height)}</li>
          <li>
            Heart Rate: {checkIfEmpty(vitals.respiratoryRate)}beats per{" "}
            {checkIfEmpty(vitals.heartRatePer10s)}s
          </li>
        </ul>

        <ul>
          <li>{`Blood Pressure: ${
            !vitals.systolic || !vitals.diastolic
              ? checkIfEmpty(false)
              : `${vitals.systolic} / ${vitals.diastolic} mmHg`
          }`}</li>
          <li>
            Which arm was the blood pressure taken from?:{" "}
            {capitalize(checkIfEmpty(vitals.bloodPressureArm))} arm
          </li>
          <li>
            In which position was the blood pressure taken?:{" "}
            {capitalize(checkIfEmpty(vitals.bloodPressurePosition))}
          </li>
        </ul>

        <ul>
          <li>SaO2 (Pulse Oxygen Saturation): {checkIfEmpty(vitals.saO2)}%</li>
          <li>Which oxygen source is used?: {capitalize(checkIfEmpty(vitals.saO2Source))}</li>
          <li>Respiratory Rate: {checkIfEmpty(vitals.respiratoryRate)}</li>
        </ul>
      </div>
    );
  };
  return (
    <div className="tytocare-vitals">
      <div className="tytocare-vitals-header">
        <h5>Recorded vitals:</h5>
        <VitalsItems vitals={vitals} />
      </div>
    </div>
  );
};

const TytocareResultConfirmation = (props) => {
  return (
    <>
      {!props.hideHeader && <h2>Review Tytocare results before proceeding</h2>}
      <iframe src={props.tytocareURL}></iframe>
      {props.vitals && <TytocareVitals vitals={props.vitals} />}
      <div className="footer d-flex flex-row align-items-center">
        <Button className="cont-btn" onClick={props.navigateView}>
          Continue
        </Button>
      </div>
    </>
  );
};
export default ({
  showReviewPopup,
  tytocareURL,
  testResultProps,
  onlyShowPopUp,
  hideHeader,
  vitals,
}) => {
  const [isNext, showNextView] = useState(false);
  const [showTimer, showStartTimer] = useState(true);
  const testResult = testResultProps.testResult || null;
  const timeout = testResultProps.timeout || null;

  useEffect(() => {
    if (!testResult || !testResult.state || testResult.state === "prepared") {
      showStartTimer(false);
    }
    if (testResult && testResult.providerResult && testResult.providerResult.recorded) {
      showStartTimer(false);
    }
  }, []);

  const navigateView = () => {
    showNextView(true);
  };
  return (
    <div className="overflow-auto modal-overlay tytocare-popup d-flex flex-column ">
      <div className="ml-auto mr-auto d-flex tytocare-results-container flex-column justify-content-between align-items-center">
        <div className="align-self-end">
          {showTimer && <StartTimer timeout={timeout} onlyProgress={true} />}
        </div>
        {!isNext ? (
          <TytocareResultConfirmation
            showReviewPopup={showReviewPopup}
            tytocareURL={tytocareURL}
            hideHeader={hideHeader}
            navigateView={navigateView}
            onlyShowPopUp={onlyShowPopUp}
            vitals={vitals}
          />
        ) : (
          <ResultConfirmation
            tytocareView
            {...testResultProps}
            showTytocareReviewPopup={showReviewPopup}
          />
        )}
      </div>
    </div>
  );
};
