import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import PropTypes from "prop-types";

import PDFView from "./PDFView";
import SwoopyArrow from "../../images/SwoopyArrow.svg";
import ProviderSelect from "./ProviderSelect";
import { FAX_ASSIGN, DISMISS_FAX } from "../../constants/actionTypes";
import api from "../../api";

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
  };
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
const mapDispatchToProps = (dispatch) => ({
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'faxID' implicitly has an 'any' type.
  assignFax: (faxID, payload) => dispatch({ type: FAX_ASSIGN, payload, faxID }),
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'faxID' implicitly has an 'any' type.
  dismissFax: (faxID) => dispatch({ type: DISMISS_FAX, faxID }),
});

class AssignFax extends Component {
  constructor(props) {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
    super(props);
    this.state = { assignedProvider: null };
    this.selectProvider = this.selectProvider.bind(this);
    this.assignFax = this.assignFax.bind(this);
  }

  selectProvider(event) {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
    this.setState({ assignedProvider: event.target.value });
  }

  assignFax() {
    const payload = api.Faxes.assignFax(this.props.faxID, this.state.assignedProvider);
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'faxID' does not exist on type 'Readonly<... Remove this comment to see the full error message
    this.props.assignFax(this.props.faxID, payload);
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'faxID' does not exist on type 'Readonly<... Remove this comment to see the full error message
    setTimeout(() => this.props.dismissFax(this.props.faxID), 3000);
    this.props.onClose();
  }
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'dismissFax' does not exist on type 'Read... Remove this comment to see the full error message

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'faxID' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { url, onClose } = this.props;
    const buttonDisabled = !this.state.assignedProvider;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'url' does not exist on type 'Readonly<{}... Remove this comment to see the full error message
    return (
      <div>
        <PDFView url={url} onClose={onClose}>
          {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'assignedProvider' does not exist on type... Remove this comment to see the full error message */}
          <div className="w-100 d-flex justify-content-center">
            <ProviderSelect
              className="assign-fax-provider-select"
              menuPlacement="top"
              selected={this.state.assignedProvider}
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'assignedProvider' does not exist on type... Remove this comment to see the full error message
              onChange={this.selectProvider}
            />
            <Button color="primary" disabled={buttonDisabled} onClick={this.assignFax}>
              Forward <SwoopyArrow />
            </Button>
          </div>
        </PDFView>
      </div>
    );
  }
}

AssignFax.propTypes = {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
  assignFax: PropTypes.func,
  dismissFax: PropTypes.func,
  faxID: PropTypes.object,
  onClose: PropTypes.func,
  url: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AssignFax);
