import React, { FC } from "react";
import { Label } from "reactstrap";

import BlockSection from "./BlockSection";

type IOtherIntake = {
  question: string;
  answer: string;
};

const isValidAttachment = (urlString: string, question: string)=> {
  if (!question.includes("ATTACHMENT")) {
    return false
  }
  try { 
    return Boolean(new URL(urlString)); 
  }
  catch(e){ 
    return false; 
  }
}

const OtherIntakeGeneral: FC<{ otherIntake?: IOtherIntake[] }> = ({ otherIntake = [] }) => {
  return otherIntake.length > 0 ? (
    <div className="info-edit-wrapper content-block">
      <BlockSection title="Additional Information">
        {otherIntake.map(({ question, answer }, index) => {
          return (
            <div key={index}>
              <Label className={"mr-2 mt-2"}>{question}</Label>
              <span>{ !isValidAttachment(answer, question) ? answer : <a href={answer} target="_blank">click to view</a>}</span>
            </div>
          );
        })}
      </BlockSection>
    </div>
  ) : null;
};



export default OtherIntakeGeneral;
