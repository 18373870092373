import React, { useEffect, useState } from "react";
import BlockSection from "./BlockSection";

const SkinTest = ({ test, onChange, isEditing }) => {
  const handleChange = (field, value) => {
    let tempVal;
    if (field === "wheal" && +value > test.flare) {
      tempVal = +value + 1;
      onChange({ ...test, flare: tempVal, wheal: +value });
    } else {
      onChange({ ...test, [field]: +value });
    }
  };

  return (
    <div key={test.index}>
      {test.name && (
        <>
          <div>
            <b>{test.name}</b>
          </div>
          <div className="measurements d-flex text-muted justify-content-between w-100">
            <div className="text-details-input d-flex align-items-center">
              <div className="w-75 pr-2">{`Wheal: `}</div>
              {isEditing ? (
                <>
                  <input
                    className="text-center allergy-input"
                    type="number"
                    max={test.flare - 1}
                    onChange={(e) => handleChange("wheal", e.target.value)}
                    value={test.wheal}
                  />
                  <div>{"mm"}</div>
                </>
              ) : (
                <div className="text-center ml-1 mr-1 d-flex">
                  <div className="mr-1">{test.wheal}</div>
                  <div>{"mm"}</div>
                </div>
              )}
            </div>
            <div className="text-details-input d-flex align-items-center pl-4">
              <div className="w-75 pr-2">{`Flare: `}</div>
              <div>
                {isEditing ? (
                  <div className="d-flex h-100">
                    <input
                      className="text-center allergy-input"
                      type="number"
                      max="20"
                      onChange={(e) => handleChange("flare", e.target.value)}
                      value={test.flare}
                    />
                    <div className="align-self-center">{"mm"}</div>
                  </div>
                ) : (
                  <div className="text-center ml-1 mr-5 d-flex">
                    <div className="mr-1">{test.flare}</div>
                    <div>{"mm"}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ({ panel, title, allergens, images, isEditing, onChange, ...props }) => {
  const [rows, setRows] = useState([]);
  const [image, setImage] = useState({});

  useEffect(() => {
    const tempRows = [];
    const testResults = panel.measurements.map((measurement, index) => {
      const allergen = allergens.find((allergen) => allergen.key === measurement.key);
      if (allergen) {
        allergen.wheal = measurement.wheal;
        allergen.flare = measurement.flare;
        allergen.index = index;
      }

      return allergen;
    });
    const middle = Math.ceil(testResults.length / 2);
    for (let n = 0; n < middle; n++) {
      const left = testResults[n];
      const right = testResults[middle + n] || {};
      tempRows.push({ left, right });
    }
    setRows(tempRows);
  }, [panel]);

  useEffect(() => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ left: any; right: any; }[]' is... Remove this comment to see the full error message
    const photo = images.find((image) => {
      return image.key === panel.key;
    });
    setImage(photo);
  }, []);

  const handleChange = (test) => {
    const temp = panel.measurements.map((measurement) => {
      if (measurement.key === test.key) {
        return { key: test.key, wheal: test.wheal, flare: test.flare };
      } else {
        return measurement;
      }
    });
    onChange({ key: panel.key, measurements: temp });
  };
  return (
    <BlockSection title={title} childClassName="mb-3 w-100">
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; title: any; childClassN... Remove this comment to see the full error message */}
      <div className="d-flex mb-3 w-100">
        <div className="align-self-center ">
          <img src={image.imageURL} className="allergy-photo" height="202" width="262" />
        {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'imageURL' does not exist on type '{}'. */}
        </div>
        <div className="mt-5 w-auto">
          {rows.map((row, index) => {
            return (
              <div className="d-flex justify-content-between w-100" key={index}>
                <div className="w-50 pr-2">
                  <SkinTest test={row.left} onChange={handleChange} isEditing={isEditing} />
                {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'left' does not exist on type 'never'. */}
                </div>
                <div className="w-50 pl-2">
                  <SkinTest test={row.right} onChange={handleChange} isEditing={isEditing} />
                {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'right' does not exist on type 'never'. */}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </BlockSection>
  );
};
