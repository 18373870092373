import React, { useState, useEffect } from "react";
import { Label } from "reactstrap";
import PropTypes from "prop-types";

import api from "../../api";
import BlockSection from "./BlockSection";
import { symptomDetailsMap } from "./SymptomDetails";

const OtherIntake = ({ conditionSpecificIntake, visitType, isEditing, onChanged }) => {
  const [intakeCatalog, setIntakeCatalog] = useState({});
  const answers = conditionSpecificIntake.answers ? conditionSpecificIntake.answers : [];

  useEffect(() => {
    loadCatalog();
  }, []);

  const loadCatalog = async () => {
    try {
      let result = await api.Catalog.conditionSpecificIntake({
        condition: visitType,
      });
      setIntakeCatalog(result[visitType]);
    } catch (err) {
      bugsnagClient.notify(err);
    }
  };

  return (
    <BlockSection title="Condition-Specific Intake" bodyClassName="center">
      {intakeCatalog.screens &&
        // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'bugsnagClient'.
        intakeCatalog.screens.map((screen) => (
          <IntakeSection
            screen={screen}
            key={screen.key}
            isEditing={isEditing}
            answers={answers}
            onChanged={onChanged}
          />
        ))}
    </BlockSection>
  );
};

const IntakeSection = ({ screen, answers, isEditing, onChanged }) => {
  const checkDependencies = (dep) => {
    let questionKeys = Object.keys(dep);

    // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'screen' implicitly has an 'any' t... Remove this comment to see the full error message
    for (let i = 0; i < questionKeys.length; i++) {
      // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'onChanged' implicitly has an 'any... Remove this comment to see the full error message
      const test = answers.find((answer) => {
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'dep' implicitly has an 'any' type.
        if (answer.questionKey === questionKeys[i]) {
          if (answer.optionKeys.indexOf(dep[questionKeys[i]]) < 0) {
            // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'answer' implicitly has an 'any' type.
            return false;
          } else return true;
        }
      });
      if (!test) {
        return false;
      }
    }
    return true;
  };

  const getAnswerLabel = (questionKey, questionAnswers) => {
    const answerObj = answers.find((answer) => {
      if (answer.questionKey === questionKey) {
        return answer;
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'questionKey' implicitly has an 'any' ty... Remove this comment to see the full error message
      }
    });
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'questionAnswers' implicitly has an 'any... Remove this comment to see the full error message
    const value = answerObj ? answerObj.value : "";
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'answer' implicitly has an 'any' type.
    const answerLabels = [];
    answerObj &&
      answerObj.optionKeys &&
      answerObj.optionKeys.forEach((key) => {
        questionAnswers.find((option) => {
          // @ts-expect-error ts-migrate(7034) FIXME: Variable 'answerLabels' implicitly has type 'any[]... Remove this comment to see the full error message
          if (option.key === key) {
            answerLabels.push(option.label);
          }
        });
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'key' implicitly has an 'any' type.
      });
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'option' implicitly has an 'any' type.
    return { answerLabels, value };
  };

  const handleChange = (question) => (val) => {
    const newKey =
      question.options &&
      val !== null &&
      // @ts-expect-error ts-migrate(7005) FIXME: Variable 'answerLabels' implicitly has an 'any[]' ... Remove this comment to see the full error message
      question.options.find((option) => {
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'question' implicitly has an 'any' type.
        if (option.label === val.label) {
          return option.key;
        }
      });
    const temp = JSON.parse(JSON.stringify(answers));
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'option' implicitly has an 'any' type.
    temp.find((answer) => {
      if (answer.questionKey === question.key) {
        if (question.type === "checkbox") {
          if (answer.optionKeys === null) {
            // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'answer' implicitly has an 'any' type.
            answer.optionKeys = [newKey.key];
          } else {
            answer.optionKeys.push(newKey.key);
          }
        } else {
          if (question.type === "integer") {
            answer.value = val;
          } else if (question.type === "date") {
            answer.value = val.date;
            answer.optionKeys = [val.key];
          } else {
            answer.optionKeys = [newKey.key];
          }
        }
      }
    });

    screen.questions.forEach((catalogQuestion) => {
      if (catalogQuestion.dependencies) {
        const questionDependencies = Object.keys(catalogQuestion.dependencies);
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'catalogQuestion' implicitly has an 'any... Remove this comment to see the full error message
        if (questionDependencies.indexOf(question.key) > -1) {
          if (catalogQuestion.dependencies[question.key] !== newKey) {
            temp.find((answer) => {
              if (answer.questionKey === catalogQuestion.key) {
                answer.optionKeys = [];
                answer.value = "";
                // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'answer' implicitly has an 'any' type.
              }
            });
          }
        }
      }
    });
    onChanged({ conditionSpecificIntake: { answers: temp } });
  };

  const handleRemove = (question) => (val) => {
    const temp = JSON.parse(JSON.stringify(answers));
    temp.find((answer) => {
      if (answer.questionKey === question.key) {
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'question' implicitly has an 'any' type.
        const idx = answer.optionKeys.indexOf(val);
        answer.optionKeys.splice(idx, 1);
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'answer' implicitly has an 'any' type.
      }
    });
    onChanged({ conditionSpecificIntake: { answers: temp } });
  };

  return (
    <>
      <div className="edit-review-of-systems-edit-title">{screen.title.en}</div>
      {screen.questions.map((question) => {
        let DetailComponent = symptomDetailsMap[question.type];
        const labels = getAnswerLabel(question.key, question.options);
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'question' implicitly has an 'any' type.
        const options =
          question.options &&
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          question.options.filter((option) => labels.answerLabels.indexOf(option.label) < 0);
        if (question.dependencies) {
          // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'option' implicitly has an 'any' type.
          if (!checkDependencies(question.dependencies, question, labels)) {
            return <span />;
          }
        }

        return (
          <div key={question.key}>
            {/* @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 3. */}
            {question.type !== "checkbox" && (
              <Label className={isEditing ? "mr-2 mt-3" : "mr-2 mt-2"}>{question.question}</Label>
            )}
            {!isEditing && <AnswerDisplays labels={labels} type={question.type} />}
            {isEditing && DetailComponent && (
              <DetailComponent
                className="info-select"
                classNamePrefix="info-select"
                options={options}
                detail={{ options: question.options }}
                answer={{
                  answerAsLabel: labels.answerLabels,
                  answer: labels.value,
                }}
                onDetailChange={handleChange(question)}
                updateStyles={"no-indent"}
                onRemoveDetail={handleRemove(question)}
              />
            )}
          </div>
        );
      })}
    </>
  );
};

const AnswerDisplays = ({ labels, type }) => {
  if (!labels) return;
  if (type === "checkbox") {
    return (
      <ul>
        {labels.answerLabels.map((label) => (
          // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'labels' implicitly has an 'any' t... Remove this comment to see the full error message
          <li>{label}</li>
        ))}
      </ul>
    );
  } else if (type === "integer") {
    return <span>{labels.value}</span>;
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'label' implicitly has an 'any' type.
  } else if (type === "date") {
    if (labels.value) {
      const date = new Date(labels.value);
      return date.toLocaleDateString("en-US");
    }
    return "N/A";
  } else {
    return labels.answerLabels.map((label) => label);
  }
};

OtherIntake.propTypes = {
  conditionSpecificIntake: PropTypes.object,
  visitType: PropTypes.string,
  isEditing: PropTypes.bool,
  restoreData: PropTypes.object,
  onChanged: PropTypes.func,
};
export default OtherIntake;
