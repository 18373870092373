import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Button } from "reactstrap";

import NavBar from "../Provider/NavBar";
import Icon from "../../images/ProfileSearchIcon.svg";
import "./patient.scss";
import Search from "./Search";
import { push } from "connected-react-router";
import { store } from "../../store";
import { SWITCH_PRACTICE } from "../../constants/actionTypes";
import api from "../../api";
import EncounterSearch from "./EncounterSearch/EncounterSearch";
import NewPatientModal from "../Rounding/NewRoundingPatient";
import PatientModal from "../Patients/NewPatientModal";
import { isPCCUser } from "../../constants/Providers";
import { IlistPractice } from "../../constants/Types";

const Tab = ({ currentUser, onSwitchPractice }) => {
  const [showModal, setShowModal] = useState(false);
  const isNursingHomeSite = !!(currentUser && currentUser.isNursingHomeSite);
  const isPccIntegrated = isPCCUser(currentUser);
  const [practices, setPractices] = useState<IlistPractice["items"]>({});

  const switchPractice = (practiceID: string, userID: string) => {
    onSwitchPractice(practiceID, userID);
  };
  const fetchProviderPractices = async () => {
    try {
      let result = await api.Providers.listPractices();
      setPractices(result.items);
    } catch (error) {
      console.error("EncounterSearch::fetchProviderPractices::error::", error);
    }
  };
  useEffect(() => {
    fetchProviderPractices()
  },[])

  return (
    <div>
      {isNursingHomeSite
        ? showModal && <NewPatientModal setModal={() => setShowModal(false)} />
        : showModal && <PatientModal setModal={() => setShowModal(false)} />}
      <NavBar />
      <div className="dashboard-container">
        <div className="patientstab-header">
          {!isPccIntegrated && (
            <Button
              id="qa-addnewpatient-btn"
              className="btn-add mb-4"
              onClick={() => setShowModal(true)}
            >
              Add new patient +
            </Button>
          )}
        </div>
        <Row>
          <Col md={{ size: 12 }}>
            <Search practices={practices} switchPractice={switchPractice} />
            <EncounterSearch practices={practices} switchPractice={switchPractice} />
          </Col>
        </Row>
        <Row></Row>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: state.common.currentUser
  };
};

const matchdispatchToProps = (dispatch) => ({
  onSwitchPractice: (practiceID, userID) =>
    dispatch({
      type: SWITCH_PRACTICE,
      payload: api.Authorization.switchPractice(practiceID),
      targetPath: `/patients/${userID}`,
    }),
});

export default connect(mapStateToProps, matchdispatchToProps)(Tab);
