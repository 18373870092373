import React, { useState, FC } from "react";
import Ellipses from "../../images/DotsThree.svg";
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import styles from "./PatientSideBar.scss";

type ChartMenuTypes = {
  onEdit?: () => void;
  isNursingHomeSite: boolean;
  onResync?: () => void;
  isPCCPatient: boolean;
  isAdmin?: boolean;
  setConsentModal: (consentModal: boolean) => void;
};

const ChartMenu: FC<ChartMenuTypes> = ({
  onEdit,
  isNursingHomeSite,
  onResync,
  isPCCPatient,
  isAdmin,
  setConsentModal,
}) => {
  const [dropdownOpen, setOpen] = useState(false);

  const toggle = () => setOpen(!dropdownOpen);

  return (
    <div className={`${styles.chartDropDown} qa-chartMenu`}>
      <ButtonDropdown direction="left" isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle className={`${styles.chartDropDownBtn} qa-chartDropdownBtn`}>
          <Ellipses />
        </DropdownToggle>
        <DropdownMenu>
          {!!isAdmin && !!isPCCPatient && (
            <DropdownItem onClick={onResync} className="qa-chartMenuResyncBtn">
              Resync PCC Data
            </DropdownItem>
          )}
          {!!isNursingHomeSite && (
            <DropdownItem
              onClick={() => setConsentModal(true)}
              className="qa-chartMenuEditSerivicesBtn"
            >
              Edit Patient Services
            </DropdownItem>
          )}
          {!isPCCPatient && (
            <DropdownItem onClick={onEdit} className="qa-chartMenuEditPatientBtn">
              Edit Patient Info
            </DropdownItem>
          )}
        </DropdownMenu>
      </ButtonDropdown>
    </div>
  );
};

export default ChartMenu;
