import React from "react";
import classNames from "classnames";
import { Button } from "reactstrap";

import styles from "./PrescriptionButton.scss";
import api from "../../api";
import bugsnagClient from "../../services/bugsnag";
import { toast } from "react-toastify";
import { errorLogger } from "../../utils";

const openInNewTab = (url) => {
  var win = window.open(url, "_blank");
  if (win) {
    win.focus();
  }
};

export default ({ disabled, userID, children, style, setPrescriptionError }) => {
  const onClick = async () => {
    try {
      let result = await api.Prescriptions.dosespotLink(userID);
      if (result.link !== "") {
        openInNewTab(result.link);
        setPrescriptionError("");
      }
    } catch (err) {
      setPrescriptionError(err.response.body.status);
      errorLogger(err);
    }
  };

  return (
    <Button
      disabled={disabled}
      color="None"
      className={classNames(styles.prescriptionButton, style)}
      onClick={onClick}
      id="qa-writerx-btn"
    >
      {children}
    </Button>
  );
};
