import React, { FC, useEffect, useRef, useState } from "react";
import "../../stylesheets/encounterMonitor.scss";
import { Label, Tooltip } from "reactstrap";
import Info from "../../images/Info.svg";
import { seconds, minutes } from "../../utils";
import { timeLine } from "../../constants/Encounter";
import { Chart } from "react-google-charts";
import Select from "react-select";
import { chartsTimeFrameOptions} from "../../constants/Encounter";


const ToolTipComp = ({ event, idx, isToolTipOpen, toggle }) => {
  return (
    <Tooltip
      isOpen={isToolTipOpen("eventToolTip" + idx)}
      target={"eventToolTip" + idx}
      toggle={() => toggle("eventToolTip" + idx)}
      placement="right"
      style={{ backgroundColor: "#76adf1", color: "#000000" }}
    >
      Total count of encounters whos last timeline event is recorded as {event}
    </Tooltip>
  );
};


const MethodCharts = ({visitCount, timeFrame}) => {

  const options = {
    height:"inherit",
    width: "inherit",
    is3D: false,
    backgroundColor: "white",
    hAxis: {title:"none"},
    // legend : { position:"none"}, 
    bars: "vertical",
    seriesType: "bars",
  }
  if(visitCount.length === 0){
    return (
      <div className="bar-chart">
        No Provider Data.
        </div>
    )
  }
  return(
    <div className="bar-chart">
    <span className="w-25 bar-chart-header">{`Visit Count By Method From the ${timeFrame}`}</span>
        <div >
            <Chart
                chartType="Bar"
                data={visitCount}
                options={options}
                loader={<div className="d-flex justify-content-center mt-5" style={{height: 140 , width: 900}}>Loading chart...</div>}
                />
        </div>
    </div>
  )
  
}
const BarWithPie = ({visitCountByP, timeFrame, pieData, view}) => {

  const label = view === "ChartByProviderView" ? "Provider" : "Practice"

  const optionsBar = {
    height:"inherit",
    width: "inherit",
    is3D: false,
    backgroundColor: "white",
    hAxis: {title:"none"},
    legend: { position:"bottom"},
    bars: "vertical",
    seriesType: "bars",
  }

  const optionsPie = {
    title: '',
    height:160,
    width: 255,
    legend: { position:"right"},
    pieSliceText: "visits",
    pieStartAngle: 100,
  }

  if(visitCountByP.length === 0){
    return (
      <div className="bar-chart m-auto">
       {view === CHART_BY_PROVIDER_VIEW ?  "No Provider Data." : "No Practice Data"}
        </div>
    )
  }
  
  return(
    <div className="d-flex flex-row provider-chart">
    <div className="w-100">
    <span className="w-25 bar-chart-header">{`Visit Count By ${label} From the ${timeFrame}`}</span>
      <Chart
          chartType="Bar"
          data={visitCountByP}
          options={optionsBar}
          loader={<div className="d-flex justify-content-center mt-5" style={{height: 140 , width: 900}}>Loading chart...</div>}
        />
    </div>

    <div className="pie-chart-wrapper w-25">
      <span className="w-25 pie-chart-header">{`${label} Visit Count`}</span>
      <Chart
          chartType="PieChart"
          data={pieData}
          options={optionsPie}
          loader={<div className="d-flex justify-content-center mt-5" style={{height: 140 , width: 900}}>Loading chart...</div>}
          width="100%"
        />
    </div>
</div>
  )
}

const sortTimeLineEvents = (events) => {
  events?.sort((a, b) => {
    return a?.count > b?.count ? -1 : 1;
  });
  return events;
};

type Props = {
  setMultiSelectInputValue: (value: string[], variants: { label: string; value: string }[]) => void;
  handleSearch: (value: string[], field: string) => void;
  data: any;
  searchParams?: string[];
  visitCount: any;
  vCountProvider: any;
  providerPieTotal: any;
  vCountPractice: any;
  practicePieTotal: any;
  timeFrame: {label: string, value: {startedFrom: string, startedTo: string}};
  setTimeFrame: (value: {label: string, value: {startedFrom: string, startedTo: string}}) => void;
};

const VISIT_COUNT_VIEW = "VisitCountView";
const TIMELINE_EVENT_VIEW = "TimelineEventView";
const CHART_BY_METHOD_VIEW = "ChartByMethodView";
const CHART_BY_PROVIDER_VIEW = "ChartByProviderView";
const CHART_BY_PRACTICE_VIEW = "ChartByPracticeView"

const MonitorStatsTable: FC<Props> = ({
  setMultiSelectInputValue,
  handleSearch,
  data,
  searchParams,
  visitCount,
  vCountProvider,
  providerPieTotal,
  timeFrame,
  setTimeFrame,
  vCountPractice,
  practicePieTotal,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState({});
  const [timelineEvents, setTimelineEvents] = useState([]);
  const [currentView, setCurrentView] = useState(VISIT_COUNT_VIEW);
  const [tFrame, setTFrame] = useState(timeFrame);
  const [chartView, setChartView] = useState(CHART_BY_METHOD_VIEW);
  useEffect(() => {
    let sorted = sortTimeLineEvents(data?.timelineEvents) || [];
    setTimelineEvents(sorted);
  }, [data]);

  const toggle = (targetName) => {
    if (!tooltipOpen[targetName]) {
      setTooltipOpen({ ...tooltipOpen, [targetName]: true });
    } else {
      setTooltipOpen({ ...tooltipOpen, [targetName]: !tooltipOpen[targetName] });
    }
  };

  const isToolTipOpen = (targetName) => {
    return tooltipOpen[targetName] ? tooltipOpen[targetName] : false;
  };

  const handleClick = (value: string) => {
    let newStatusSearch = searchParams ? [...searchParams, value] : [value];

    setMultiSelectInputValue(newStatusSearch, timeLine);
    handleSearch(newStatusSearch, "latestVisitStatus");
  };

  const changeView = (view) => {
    setCurrentView(view);
  };
  const changeChartView = (view) => {
    setChartView(view);
  }

  const changeTimeFrame = (item: { label: string; value: {startedFrom: string, startedTo: string } }) => {
    // make api call on change of time frame
    setTimeFrame(item);
    setTFrame(item);
  }

  const totalEncounters = data?.stats?.totalEncounters;

  const waitingTimeAvg = data?.stats?.waiting?.avgTime;
  const waitingTimeMinT = data?.stats?.waiting?.minTime;
  const waitingTimeMaxT = data?.stats?.waiting?.maxTime;

  const videoTimeAvg = data?.stats?.video?.avgTime;
  const videoTimeMinT = data?.stats?.video?.minTime;
  const videoTimeMaxT = data?.stats?.video?.maxTime;

  const customStyles = {
    valueContainer: (provided) => ({
      ...provided,
      boxShadow: "0 2px 13px 0 rgba(47, 83, 151, 0.1);"

    }),
  }

  return (
    <div className="align-content-stretch encounter-search-menu encounter-monitor-table encounter-stats-menu">
      <div className="d-flex ">
        <div className={currentView === VISIT_COUNT_VIEW ? "events-table-header vCount d-flex": "events-table-header timeline d-flex"}>
          <div className="title-container d-flex flex-row mb-3">
          <span className="d-flex">
          <h1
                className={
                  currentView === VISIT_COUNT_VIEW
                    ? `selected qa-monitorEncounterMonitorHeaderToggle recent-encounter-tab stats-tabs`
                    : "qa-monitorEncounterMonitorHeaderToggle recent-encounter-tab stats-tabs"
                }
                onClick={() => changeView(VISIT_COUNT_VIEW)}
              >
                Visit Count
              </h1>
              <h1
                className={
                  currentView === TIMELINE_EVENT_VIEW
                    ? `selected qa-monitorPCRTestKitHeaderToggle recent-encounter-tab stats-tabs`
                    : "qa-monitorPCRTestKitHeaderToggle recent-encounter-tab stats-tabs"
                }
                onClick={() => changeView(TIMELINE_EVENT_VIEW)}
              >
                Filtered Timeline Events
            {/* <span className="title-subtext pl-3 pt-1">Based on your current search</span> */}

              </h1>
          </span>
          </div>
          {currentView === TIMELINE_EVENT_VIEW ?
          <div className="events-list p-2">
            {timelineEvents.map((e: { event: string; count: number }, idx) => {
              const event = e?.event;
              return (
                <div
                  key={`event-${idx}`}
                  className="m-1 timeline-item"
                  onClick={() => handleClick(event)}
                >
                  {event}
                  <span id={"eventToolTip" + idx}>
                    <Info />
                    <ToolTipComp
                      event={event}
                      idx={idx}
                      isToolTipOpen={isToolTipOpen}
                      toggle={toggle}
                    />
                  </span>{" "}
                  {`: ${e?.count}`}
                </div>
              );
            })}
          </div>
          :
          <div className="select-list d-flex flex-column mt-2">
                <div style={{width: "200px"}} className="mb-3 ">
                <Label className="chart-select-label">Select Date Range to Display</Label>
                <Select
                name="chart-timeFrame-select"
                placeHolder="Select Chart Time Frame"
                 options={chartsTimeFrameOptions}
                onChange={changeTimeFrame as any}
                isMulti={false}
                value={tFrame}
                styles={customStyles}
                />
                </div>
            </div>
          
          }
        </div>

        <div className="v-divider-line"></div>

        {currentView === TIMELINE_EVENT_VIEW && <div className="stats-table-header">
          <div className=" d-flex flex-row">
            <h1 className="stat-title">Filtered Encounter Stats</h1>
            <span className="title-subtext pl-3 pt-1">Based on your current search</span>
          </div>
          <div className="d-flex stats-item-list pt-2">
            <div className="stat-item total-encounters">
              <h2 className="w-100 stat-item-title">Total Encounters</h2>
              <div>
                <div className="stat">{totalEncounters ? totalEncounters : "--"}</div>
              </div>
            </div>
            <div className="stat-item avg-time-stat">
              <h2 className="w-100 stat-item-title">Avg. Waiting time</h2>
              <div className="d-flex flex-row">
                <div className="stat">
                  {waitingTimeAvg
                    ? `${minutes(waitingTimeAvg)}min ${seconds(waitingTimeAvg)}sec`
                    : "--"}
                </div>
                <div className="d-flex flex-column">
                  <span className="min-time">
                    Min:{" "}
                    {waitingTimeMinT
                      ? ` ${minutes(waitingTimeMinT)}min ${seconds(waitingTimeMinT)}sec`
                      : "--"}
                  </span>{" "}
                  <span className="max-time">
                    Max:{" "}
                    {waitingTimeMaxT
                      ? ` ${minutes(waitingTimeMaxT)}min ${seconds(waitingTimeMaxT)}sec`
                      : "--"}
                  </span>
                </div>
              </div>
            </div>

            <div className="stat-item avg-time-stat ">
              <h2 className="w-100 stat-item-title ">Avg. Video Time</h2>
              <div className="d-flex flex-row">
                <div className="stat">
                  {videoTimeAvg ? `${minutes(videoTimeAvg)}min ${seconds(videoTimeAvg)}sec` : "--"}
                </div>
                <div className="d-flex flex-column">
                  {" "}
                  <span className="min-time">
                    {" "}
                    Min:
                    {videoTimeMinT
                      ? ` ${minutes(videoTimeMinT)}min ${seconds(videoTimeMinT)}sec`
                      : "--"}
                  </span>{" "}
                  <span className="max-time">
                    Max:{" "}
                    {videoTimeMaxT
                      ? ` ${minutes(videoTimeMaxT)}min ${seconds(videoTimeMaxT)}sec`
                      : "--"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>}
        {currentView === VISIT_COUNT_VIEW && <div className="charts-table-header">
        <div className="title-container d-flex flex-row mb-3">
          <span className="d-flex">
          <h1
                className={
                  chartView === CHART_BY_METHOD_VIEW
                    ? `selected-chart qa-monitorEncounterMonitorHeaderToggle recent-encounter-tab-chart`
                    : "qa-monitorEncounterMonitorHeaderToggle recent-encounter-tab-chart"
                }
                onClick={() => changeChartView(CHART_BY_METHOD_VIEW)}
              >
                Chart By Method
              </h1>
              <h1
                className={
                  chartView === CHART_BY_PROVIDER_VIEW
                    ? `selected-chart qa-monitorPCRTestKitHeaderToggle recent-encounter-tab-chart`
                    : "qa-monitorPCRTestKitHeaderToggle recent-encounter-tab-chart"
                }
                onClick={() => changeChartView(CHART_BY_PROVIDER_VIEW)}
              >
                Provider
              </h1>
              <h1
                className={
                  chartView === CHART_BY_PRACTICE_VIEW
                    ? `selected-chart qa-monitorPCRTestKitHeaderToggle recent-encounter-tab-chart`
                    : "qa-monitorPCRTestKitHeaderToggle recent-encounter-tab-chart"
                }
                onClick={() => changeChartView(CHART_BY_PRACTICE_VIEW)}
              >
                Practice
              </h1>
          </span>
          </div>
          <div className="bar-chart-wrapper">
            {chartView === CHART_BY_METHOD_VIEW && <MethodCharts visitCount={visitCount} timeFrame={timeFrame.label}/>}
            {chartView === CHART_BY_PROVIDER_VIEW && <BarWithPie visitCountByP={vCountProvider} timeFrame={timeFrame.label} pieData={providerPieTotal} view={chartView}/>}
            {chartView === CHART_BY_PRACTICE_VIEW && <BarWithPie visitCountByP={vCountPractice} timeFrame={timeFrame.label} pieData={practicePieTotal} view={chartView}/> }
          </div>
        </div>}
      </div>
    </div>
  );
};

export default MonitorStatsTable;
