import React, { Component } from "react";
import { connect } from "react-redux";

import { strepLabel, fluLabel, utiLabel, covidLabel } from "../../constants/TestConstants";
import { IAppState } from "../../reducer";
import {
  IMedicalProfile,
  ITestDetails,
  IUserDetails,
  IUserMedicalIntake,
} from "../../constants/Types";

const mapStateToProps = (state: IAppState, ownProps: OwnProps) => {
  return {
    ...ownProps,
    noteLoaded: state.encounter.noteLoaded,
    detailsLoaded: state.encounter.detailsLoaded,
    note: state.encounter.note,
  };
};

const mapDispatchToProps = () => ({});

const interpretTestResult = (testResult?: ITestDetails) => {
  if (
    !testResult ||
    !testResult.testType ||
    !testResult.providerResult ||
    !testResult.providerResult.results
  ) {
    return "";
  }

  const results = testResult.providerResult.results;

  switch (testResult.testType) {
    case "uti":
      return "Rapid test result: " + utiLabel(results);
    case "flu":
      return "Rapid test result: " + fluLabel(results);
    case "strep":
      return "Rapid test result: " + strepLabel(results);
    case "covid":
      return "Rapid test result: " + covidLabel(results);
    default:
      "";
  }
};

type OwnProps = {
  testResult?: ITestDetails;
  patient: IUserDetails;
  profile: Partial<IMedicalProfile>;
  intake: IUserMedicalIntake;
  onChange: (event: any) => void;
};

type Props = ReturnType<typeof mapStateToProps>;

class AssessmentIntro extends Component<Props, { intro: string }> {
  constructor(props: Props) {
    super(props);
    this.state = { intro: "" };
    this.introString = this.introString.bind(this);
  }

  introString() {
    const props = this.props;
    const age = props.patient.age;
    const gender = props.patient.sex == "M" ? "male" : "female";
    const testResult = interpretTestResult(props.testResult);

    const chiefComplaint = props.intake.chiefComplaint
      ? props.intake.chiefComplaint.complaint
      : "unspecified";
    return `${age} yo ${gender} with chief complaint of ${chiefComplaint}. ${testResult}`;
  }

  componentDidMount() {
    this.setState({ intro: this.introString() });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.detailsLoaded &&
      this.props.noteLoaded &&
      this.props.note.assessmentIntro != this.state.intro
    ) {
      if (this.props.onChange) {
        this.props.onChange({
          target: { name: "assessmentIntro", value: this.state.intro },
        });
      }
    }
    if (this.introString() != this.state.intro) {
      this.setState({ intro: this.introString() });
    }
  }

  render() {
    if (!this.props.note) {
      return null;
    }
    return (
      <div>
        <p>{this.state.intro}</p>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentIntro);
