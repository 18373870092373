import React, { FC, useState } from "react";
import PDFView from "../Provider/PDFView";
import { IPreviousPDFNotes } from "../../constants/Types";
import { getTitle } from "./Encounter";

type Props = {
  previousPDFNotes?: IPreviousPDFNotes[];
  chiefComplaint: string;
  userID?: string;
};

const PDFViewOnLink = ({ item, chiefComplaint, initial }) => {
  const [pdfIsOpen, setPdfView] = useState<boolean>(false);
  const handleClose = () => {
    setPdfView(false);
  };
  return (
    <>
      <span className="text-link-like" onClick={() => setPdfView(true)}>
        {getTitle(initial, chiefComplaint, item.finishDate)}
      </span>
      {pdfIsOpen && <PDFView prevVisitNote url={item.url} onClose={handleClose} children={null} />}
    </>
  );
};

const PrevVisitNotes: FC<Props> = ({ previousPDFNotes, chiefComplaint, userID }) => {
  return (
    <>
      {previousPDFNotes && previousPDFNotes.length > 0 ? (
        <div className="d-flex flex-column">
          {previousPDFNotes.map((item, index) => (
            <PDFViewOnLink item={item} chiefComplaint={chiefComplaint} initial={index === 0} />
          ))}
        </div>
      ) : (
        <span>
          Previous visit notes may exist, please see{" "}
          <a href={`/active-patient-chart/${userID}`} target="_blank">
            patient chart
          </a>{" "}
          to read previous visit notes.
        </span>
      )}
    </>
  );
};
export default PrevVisitNotes;
