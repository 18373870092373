import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import "./TableModal.scss";
import Octicon, { X } from "@githubprimer/octicons-react";
import { Row, Col } from "reactstrap";
import CheckToggle from "../CheckToggle";
import {columnOptions} from "../../constants/Encounter"

const Checkbox = ({ type, name, checked, onChange }) => {
  return <input className="ml-2" type={type} name={name} checked={checked} onChange={onChange} />;
};

const TableColumnsModal = ({ showModal, setShowModal, selectedColumns, setColumns }) => {
  const [selected, setSelected] = useState<[] | string[]>([]);
  const [allSelected, setAllSelected] = useState<boolean>(false);

  useEffect(() => {
    setSelected(selectedColumns);
    
  }, [selectedColumns]);

  useEffect(() => {
    let allChecked = selected.length === selectedColumns.length;
    setAllSelected(allChecked);
  }, [])

  const handleCheck = (value: string) => {
    const list: string[] = [...selected];

    if (list.includes(value)) {
      let index = list.indexOf(value);
      list.splice(index, 1);
    } else {
      list.push(value);
    }
    setSelected(list);
  };

  const handleSave = () => {
    setColumns(selected);
  };

  const handleAllCheck = () => {
    if (allSelected) {
      setSelected([]);
    } else {
      setSelected(columnOptions);
    }
    setAllSelected(!allSelected);
  }
  if (!showModal) {
    // popup not visible
    return null;
  }
 
  return (
    <div className="modal-overlay">
      <div className="m-auto monitor-modal">
        <div className="d-flex flex-row-reverse">
          <Button className="exit-btn" onClick={() => setShowModal(!showModal)}>
            <Octicon icon={X} />
          </Button>
        </div>
        <Row>
          <Col className="title-container d-flex justify-content-between" md="12">
            <h1 className="title ">Visible Columns</h1>
            <div className="d-flex pl-5 pr-5">
                <CheckToggle
                  size={"small"}
                  onChange={handleAllCheck}
                  checked={allSelected}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Check All/Uncheck All</span>
              </div>
          </Col>
        </Row>

        <div className="inputs-container d-flex w-100">
          <Row className="ml-5">
            <Col md="6">
              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("timestamp")}
                  checked={selected?.includes("timestamp")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Visit Created</span>
              </div>
              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("patientName")}
                  checked={selected?.includes("patientName")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Patient</span>
              </div>

              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("location")}
                  checked={selected?.includes("location")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Location</span>
              </div>                

              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("patientSex")}
                  checked={selected?.includes("patientSex")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Patient Sex</span>
              </div>

              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("patientAge")}
                  checked={selected?.includes("patientAge")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Patient Age</span>
              </div>

              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("patientLanguage")}
                  checked={selected?.includes("patientLanguage")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Patient Language</span>
              </div>

              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("patientReliantID")}
                  checked={selected?.includes("patientReliantID")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Patient Reliant ID</span>
              </div>

              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("patientPCCID")}
                  checked={selected?.includes("patientPCCID")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Patient PCC ID</span>
              </div>

              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("providerName")}
                  checked={selected?.includes("providerName")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Provider</span>
              </div>

              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("extender")}
                  checked={selected?.includes("extender")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Extender</span>
              </div>

              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("scribe")}
                  checked={selected?.includes("scribe")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Scribe</span>
              </div>                                                   

              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("medicalPracticeName")}
                  checked={selected?.includes("medicalPracticeName")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Practice Name</span>
              </div>
              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("chiefComplaint")}
                  checked={selected?.includes("chiefComplaint")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Chief Complaint</span>
              </div>
              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("patientWaitedTime")}
                  checked={selected?.includes("patientWaitedTime")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Time in Waiting Room</span>
              </div>
              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("communicationMethod")}
                  checked={selected?.includes("communicationMethod")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Method</span>
              </div>
              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("latestVisitStatus")}
                  checked={selected?.includes("latestVisitStatus")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Visit Timeline</span>
              </div>
              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("status")}
                  checked={selected?.includes("status")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">EMR Status</span>
              </div>
              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("newMessages")}
                  checked={selected?.includes("newMessages")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">New Messages</span>
              </div>

              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("lastContact")}
                  checked={selected?.includes("lastContact")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Last Message</span>
              </div>
              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("prescribedDate")}
                  checked={selected?.includes("prescribedDate")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Prescription Written</span>
              </div>

              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("pharmacyName")}
                  checked={selected?.includes("pharmacyName")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Pharmacy Name</span>
              </div> 
              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("medicationPerscribed")}
                  checked={selected?.includes("medicationPerscribed")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Medication Perscribed</span>
              </div> 
              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("prescriptionRXCUI")}
                  checked={selected?.includes("prescriptionRXCUI")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Prescription RXCUI</span>
              </div> 
              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("viaFormulary")}
                  checked={selected?.includes("viaFormulary")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Via Formulary</span>
              </div> 
              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("scribeDate")}
                  checked={selected?.includes("scribeDate")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Scribe Handoff</span>
              </div>
            </Col>
            <Col md="6">              
              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("signedDate")}
                  checked={selected?.includes("signedDate")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Provider Signed</span>
              </div>
              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("patientNumOfAttempts")}
                  checked={selected?.includes("patientNumOfAttempts")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Patient Video Attempts</span>
              </div>
              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("neverEnteredWaitingRoom")}
                  checked={selected?.includes("neverEnteredWaitingRoom")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Patient Entered Waiting Room</span>
              </div>
              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("patientExitedWaitingRoomPrematured")}
                  checked={selected?.includes("patientExitedWaitingRoomPrematured")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Patient Left Waiting Room</span>
              </div>
              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("providerVideoChatTime")}
                  checked={selected?.includes("providerVideoChatTime")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Video Time</span>
              </div>
              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("totalActiveChartingTime")}
                  checked={selected?.includes("totalActiveChartingTime")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Total Active Charting Time</span>
              </div>               
              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("encounterId")}
                  checked={selected?.includes("encounterId")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">EMR Encounter ID</span>
              </div>
              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("organizationType")}
                  checked={selected?.includes("organizationType")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Organization Type</span>
              </div>
              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("organization")}
                  checked={selected?.includes("organization")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Organization</span>
              </div>
              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("clinicalServicesLocation")}
                  checked={selected?.includes("clinicalServicesLocation")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Clinical Services Location</span>
              </div>
              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("healthGorillaSessions")}
                  checked={selected?.includes("healthGorillaSessions")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Health Gorilla Sessions</span>
              </div>
              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("followUp")}
                  checked={selected?.includes("followUp")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Follow Up</span>
              </div>
              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("visitType")}
                  checked={selected?.includes("visitType")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Visit Type</span>
              </div>
              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("chargeAmountToPatient")}
                  checked={selected?.includes("chargeAmountToPatient")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Charge Amount to Patient</span>
              </div>
              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("promoCodeUsed")}
                  checked={selected?.includes("promoCodeUsed")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Promo Code Used</span>
              </div>
              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("patientCopay")}
                  checked={selected?.includes("patientCopay")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Patient Copay</span>
              </div>
              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("billedInsurance")}
                  checked={selected?.includes("billedInsurance")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Billed Insurance</span>
              </div>
              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("patientPrimaryPayer")}
                  checked={selected?.includes("patientPrimaryPayer")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Patient Primary Payer</span>
              </div>
              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("visitBillingLocation")}
                  checked={selected?.includes("visitBillingLocation")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Visit Billing Location</span>
              </div>
              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("CPTCode")}
                  checked={selected?.includes("CPTCode")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">CPT Code</span>
              </div>
              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("ICD10")}
                  checked={selected?.includes("ICD10")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">ICD10</span>
              </div>
              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("suggestedFollowUpDate")}
                  checked={selected?.includes("suggestedFollowUpDate")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Suggested Follow Up Date</span>
              </div>
              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("effectiveDate")}
                  checked={selected?.includes("effectiveDate")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Effective Date</span>
              </div>
              <div className="d-flex mb-3">
                <CheckToggle
                  size={"small"}
                  onChange={() => handleCheck("outcome")}
                  checked={selected?.includes("outcome")}
                  className="encounter-modal-toggle"
                  disabled={false}
                />
                <span className="check-label">Outcome</span>
              </div>             
            </Col>
          </Row>
        </div>
        <div className="d-flex justify-content-end mr-5">
          <Button className="link" color="link" onClick={() => setShowModal(!showModal)}>
            Cancel
          </Button>
          <Button className="primary" color="primary" onClick={() => handleSave()}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TableColumnsModal;
