import React, { Component } from "react";
import PropTypes from "prop-types";

const isDigits = (s) => {
  const re = /^[0-9\b]+$/;
  return s === "" || re.test(s);
};

class PhoneNumber extends Component {
  constructor(props) {
    super(props);
    if (props.value) {
      const parts = props.value.split(/\D+/);
      parts.push("", "", "", ""); // ensure indicies always exist...
      this.state = { area: parts[1], exchange: parts[2], number: parts[3] };
    } else {
      this.state = { area: "", exchange: "", number: "" };
    }
    this.onFieldChanged = this.onFieldChanged.bind(this);
  }

  onFieldChanged(field) {
    return (event) => {
      const newValue = event.target.value;
      if (!isDigits(newValue)) {
        return;
      }
      const nextField = {
        area: "exchange",
        exchange: "number",
      };
      const n = this.refs[nextField[field]];
      if (n && newValue.length === 3) {
        n.focus();
      }
      this.setState({ [field]: newValue });
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'focus' does not exist on type 'ReactInst... Remove this comment to see the full error message
      const { area, exchange, number } = { ...this.state, [field]: newValue };
      this.props.onChange({
        target: { value: `${area}-${exchange}-${number}` },
      });
    };
  }
  render() {
    const state = this.state;
    return (
      <div className="phone-entry">
        <input
          type="tel"
          ref="area"
          maxLength="3"
          value={state.area}
          onChange={this.onFieldChanged("area")}
          id="qa-phone-area-input"
        />{" "}
        -
        <input
          type="tel"
          ref="exchange"
          maxLength="3"
          value={state.exchange}
          onChange={this.onFieldChanged("exchange")}
          id="qa-phone-exchange-input"
        />{" "}
        -
        <input
          id="qa-phone-number-input"
          type="tel"
          ref="number"
          maxLength="4"
          value={state.number}
          onChange={this.onFieldChanged("number")}
        />
      </div>
    );
  }
}

PhoneNumber.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default PhoneNumber;
