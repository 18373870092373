import React, { FC } from "react";
import { Label } from "reactstrap";

import BlockSection from "./BlockSection";
import InfoSelect from "./InfoSelect";
import ListEditor from "./ListEditor";
import YesNoToggle from "./YesNoToggle";
import { allergyEnvironmentalEffects } from "../../constants/Encounter";
import ThreeWayToggle from "./ThreeWayToggle";
import { IAllergyEnvironmentalEffect } from "../../constants/Types";

type Props = {
  allergyEnv: IAllergyEnvironmentalEffect;
  oldAllergyEnv: IAllergyEnvironmentalEffect;
  onChange: (value: object) => void;
};

const AllergyEnvironmentalEffectEditor: FC<Props> = ({ allergyEnv, oldAllergyEnv, onChange }) => {
  const onEnvEffectsChange = (field) => (newValue) => {
    onChange({ ...allergyEnv, [field]: newValue });
  };

  const handleOtherFactorsChange = (factor) => (newValue) => {
    onChange({
      ...allergyEnv,
      otherFactors: { ...allergyEnv.otherFactors, [factor]: newValue },
    });
  };
  const searchableItemListSource = (text) => {
    let filter = allergyEnvironmentalEffects.pets.filter(
      (item) => item.toLowerCase().indexOf(text.toLowerCase()) > -1,
    );
    return { items: filter };
  };
  return (
    <div>
      <BlockSection title={"Allergy Environmental Effects"}>
        <div className="form-row">
          <div className="mb-0 col-md-4">
            <Label>When do you have symptoms?</Label>
            <div className="col pl-0 w-75">
              <InfoSelect
                value={allergyEnv.whenSymptoms}
                options={allergyEnvironmentalEffects.allergyEffectTimes}
                oldValue={oldAllergyEnv.whenSymptoms}
                onChange={onEnvEffectsChange("whenSymptoms")}
              />
            </div>
          </div>
          <div className="mb-0 col-md-6 ml-2">
            <Label>Do pets cause symptoms?</Label>
            <YesNoToggle
              value={allergyEnv.petsCauseSymptoms}
              oldValue={oldAllergyEnv.petsCauseSymptoms}
              onChange={onEnvEffectsChange("petsCauseSymptoms")}
            />
          </div>
        </div>
        {allergyEnv.petsCauseSymptoms && (
          <div className="form-row mt-3">
            <div className="mb-0">
              <Label>Pets causing symptoms?</Label>
              <ListEditor
                items={allergyEnv.pets}
                oldItems={oldAllergyEnv.pets || []}
                itemListSource={searchableItemListSource}
                onChange={onEnvEffectsChange("pets")}
              />
            </div>
          </div>
        )}
      </BlockSection>
      <BlockSection title={"Other Factors"}>
        {Object.keys(allergyEnv.otherFactors).map((factor, key) => {
          return (
            <div className="row mb-2" key={key}>
              <Label className="col-md-4">{`${factor}: `}</Label>
              <div className="col-md-3 mb-3">
                <ThreeWayToggle
                  value={allergyEnv.otherFactors[factor]}
                  oldValue={oldAllergyEnv.otherFactors[factor]}
                  onChange={handleOtherFactorsChange(factor)}
                />
              </div>
            </div>
          );
        })}
      </BlockSection>
    </div>
  );
};

export default AllergyEnvironmentalEffectEditor;
