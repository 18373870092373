export const selectStyles = {
  option: (provided, state) => ({
    ...provided,
  }),
  container: (base) => ({
    ...base,
    width: "200px",
  }),
  control: (base) => ({
    ...base,
    height: 34,
    minHeight: 32,
    fontSize: 12,
    width: "100%",
    textAlign: "left",
    cursor: "pointer",
    marginBottom: "10px",
    display: "flex",
    alignContent: "center",
    backgroundColor: "#fff",
    borderRadius: "0.25rem",
    paddingLeft: "5px",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    marginBottom: "3px",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 0,
    paddingLeft: 2,
    width: "30%",
  }),
};
