import React, { useState } from "react";

import { ResponsiveLine, PointTooltipProps } from "@nivo/line";
import moment from "moment-timezone";

import { VitalsLineGraphProps as VLGP, linepoints } from "./VitalsGraph";

export interface VitalsLineGraphProps extends VLGP {}

const VitalsLineGraph: React.FC<VitalsLineGraphProps> = ({
  isExpanded,
  onClicked,
  yAxisRange,
  outOfRange,
  dataPoints,
  acceptableRange,
  baseLines,
  tickValues,
  unit,
  customAxisBottom,
  timeZone,
}) => {
  // make a copy of the dataPoints, stripping any 'null' points from the data (used for tooltips)
  const activePoints: linepoints[] = dataPoints.reduce((points: linepoints[], data) => {
    points.push({ id: data.id, data: [] });
    return points;
  }, []);
  // include only non-null values into the active points
  for (let n = 0; n < dataPoints[0].data.length; n++) {
    if (
      dataPoints[0].data[n].y != null ||
      (!!dataPoints[1] && dataPoints[1].data[n] && dataPoints[1].data[n].y != null)
    ) {
      activePoints[0].data.push(dataPoints[0].data[n]);
      !!activePoints[1] && activePoints[1].data.push(dataPoints[1].data[n]);
    }
  }

  const toolTipElement = React.memo(
    (props: PointTooltipProps) => {
      const time = moment(props.point.data.x).tz(timeZone).format("ddd MM/DD/YYYY h:mm a");
      const lengthOfAxis = activePoints[0].data.length;
      const index = props.point.index % lengthOfAxis;
      return (
        <div className="graphLineTooltip">
          <p>{time}</p>
          <p>
            {activePoints[0].id} : {activePoints[0].data[index].y} {unit}
          </p>
          {activePoints[1] && (
            <p>
              {activePoints[1].id} : {activePoints[1].data[index].y} {unit}
            </p>
          )}
        </div>
      );
    },
    (prevProps: PointTooltipProps, currentProps: PointTooltipProps) => {
      return prevProps.point.id === currentProps.point.id;
    },
  );

  const refsLayer = (props) => {
    if (!acceptableRange || !acceptableRange.lowerLimit || !acceptableRange.upperLimit) {
      // don't draw a reference layer if acceptable range is unsopecified
      return null;
    }

    return (
      <g>
        <rect
          y={props.yScale(acceptableRange.upperLimit)}
          width={props.width}
          height={
            props.yScale(acceptableRange.lowerLimit) - props.yScale(acceptableRange.upperLimit)
          }
          fill={outOfRange ? "rgba(255,177,177,0.2)" : "rgba(197,222,255,.3)"}
        />
        {baseLines.secondValue && (
          <line
            x1="0"
            y1={props.yScale(baseLines.secondValue)}
            x2={props.width}
            y2={props.yScale(baseLines.secondValue)}
            stroke="#94A4B9"
            strokeDasharray="6 4"
          />
        )}
        <line
          x1="0"
          y1={props.yScale(baseLines.firstValue)}
          x2={props.width}
          y2={props.yScale(baseLines.firstValue)}
          stroke="#94A4B9"
          strokeDasharray="6 4"
        />
        <line
          x1="0"
          y1={props.yScale(yAxisRange.min)}
          x2={props.width}
          y2={props.yScale(yAxisRange.min)}
          stroke="#dddddd"
        />
        <line
          x1="0"
          y1={props.yScale(yAxisRange.max)}
          x2={props.width}
          y2={props.yScale(yAxisRange.max)}
          stroke="#dddddd"
        />
      </g>
    );
  };
  const styles = !!isExpanded
    ? {
        paddingTop: "10px",
        height: "270px",
        width: "100%",
        transition: "height, .7s",
        borderTop: "0.5px, solid, #7F91A8",
      }
    : { height: "80px", width: "100%", transition: "height, .7s" };

  const lineColors = outOfRange ? ["#DC4545", "#FD6767"] : ["#4A8FE7", "#00BA88"];
  const pointColor = outOfRange ? "#FD6767" : "#BDDAFF";

  const propsExpanded = !!isExpanded
    ? {
        axisLeft: {
          tickSize: 0,
          tickPadding: 10,
          legend: !!unit ? unit : false,
          legendPosition: "middle",
          legendOffset: -45,
          tickValues: tickValues,
        },
        axisBottom: {
          ...{
            stacked: true,
            format: "%b %d",
            tickValues: "every 1 days",
            legendOffset: -10,
            tickSize: 0,
            tickPadding: 7,
          },
          ...customAxisBottom,
        },
        pointSize: 6,
        pointBorderWidth: 1,
        margin: { top: 5, right: 20, bottom: 20, left: 50 },
      }
    : {
        axisLeft: null,
        axisBottom: null,
      };
  return (
    <div style={styles} onClick={() => !!onClicked && onClicked()}>
      <ResponsiveLine
        {...propsExpanded}
        data={dataPoints}
        xScale={{
          stacked: true,
          type: "time",
          format: "%Y-%m-%dT%H:%M",
          useUTC: false,
        }}
        yScale={{
          type: "linear",
          min: yAxisRange.min,
          max: yAxisRange.max,
        }}
        colors={lineColors}
        xFormat="time:%Y-%m-%dT%H:%M"
        enableGridY={false}
        enableCrosshair={false}
        // isInteractive={!!isExpanded}
        enablePoints={!!isExpanded}
        enablePointLabel={false}
        lineWidth={2}
        pointSize={6}
        pointBorderWidth={1}
        pointColor={pointColor}
        pointBorderColor={{ from: "serieColor" }}
        useMesh={true}
        enableSlices={false}
        tooltip={toolTipElement}
        layers={[
          "grid",
          refsLayer,
          "markers",
          "axes",
          "areas",
          "crosshair",
          "lines",
          "slices",
          "points",
          "mesh",
          "legends",
        ]}
      />
    </div>
  );
};

export default VitalsLineGraph;
