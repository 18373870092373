import React from "react";
import { Row, Col } from "reactstrap";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Card, CardHeader, CardBody } from "../../../containers/Card";
import Stethescope from "../../../images/stethescope.svg";
import UsersIcon from "../../../images/UsersIcon.svg";
import PaperIcon from "../../../images/PaperIcon.svg";
import MedicationsIcon from "../../../images/medications.svg";
import AllergiesIcon from "../.././../images/allergies.svg";

const Form = styled.div`
  margin-bottom: 30px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin-top: 1rem;

  h6 {
    font-weight: 600;
    font-size: 12px;
    color: #000000;
  }
`;

const ListView = styled.div`
  border-bottom: 1px solid rgba(0, 53, 119, 0.2);
  padding: 0.7rem 0;
  span {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
  }
`;

const ColumnWrapper = ({ children }) => {
  return (
    <Col md={{ size: 12 }} style={{ padding: "0 0 0px 10px" }}>
      {children}
    </Col>
  );
};

const WomenHealth = ({ womensHealth }) => (
  <>
    <FormGroup>
      <h6>Has been pregnant?</h6>
      <span className="qa-chartWomensHealthPregnant">
        {womensHealth.hasBeenPregnant === true ? `Yes` : "No"}
      </span>
    </FormGroup>
    <FormGroup>
      <h6>Number of pregnancies</h6>
      <span className="qa-chartWomensHealthNumberOfPregnancies">
        {womensHealth.numberOfPregnancies ? womensHealth.numberOfPregnancies : 0}
      </span>
    </FormGroup>
    <FormGroup>
      <h6>Number of deliveries</h6>
      <span>{womensHealth.numberOfDeliveries ? womensHealth.numberOfDeliveries : 0}</span>
    </FormGroup>
    <FormGroup>
      <h6>Birth Control Method</h6>
      <span className="qa-chartWomensHealthBirthControl">
        {womensHealth.birthControlMethod
          ? womensHealth.birthControlMethod.join(", ")
          : "None Listed"}
      </span>
    </FormGroup>
  </>
);

const TobaccoInfo = ({ socialHistory }) => {
  return (
    <>
      {socialHistory.tobaccoNow && (
        <>
          <FormGroup>
            <h6>Current Tobacco Smoker?</h6>
            <span className="qa-chartTobaccoSmoker">
              {socialHistory.tobaccoNow === true ? `Yes` : "No"}
            </span>
          </FormGroup>
          <FormGroup>
            <h6>What do you use?</h6>
            <span className="qa-chartTobaccoSmokerWhatKind">
              {socialHistory.currentTobaccoList
                ? socialHistory.currentTobaccoList.join("")
                : "None listed"}
            </span>
          </FormGroup>

          {!socialHistory.currentTobaccoList ||
          socialHistory.currentTobaccoList.indexOf("Cigarette") > -1 ? (
            <FormGroup>
              <h6>How many packs per day?</h6>
              <span className="qa-chartTobaccoSmokerCount">
                {socialHistory.currentTobaccoCount ? socialHistory.currentTobaccoCount : "Unknown"}
              </span>
            </FormGroup>
          ) : (
            <FormGroup>
              <h6>How often?</h6>
              <span className="qa-chartTobaccoSmokerFrequency">
                {socialHistory.currentTobaccoFrequency
                  ? socialHistory.currentTobaccoFrequency
                  : "Unknown"}
              </span>
            </FormGroup>
          )}
        </>
      )}
      {!socialHistory.tobaccoNow && socialHistory.tobaccoEver && (
        <>
          <FormGroup>
            <h6>Former Tobacco User</h6>
            <span className="qa-chartTobaccoSmoker">
              {socialHistory.tobaccoEver ? `Yes` : "No"}
            </span>
          </FormGroup>
          <FormGroup>
            <h6>What did you use?</h6>
            <span className="qa-chartTobaccoSmokerWhatKind">
              {socialHistory.pastTobaccoList
                ? socialHistory.pastTobaccoList.join("")
                : "None listed"}
            </span>
          </FormGroup>

          {!socialHistory.pastTobaccoList ||
          socialHistory.pastTobaccoList.indexOf("Cigarette") > -1 ? (
            <FormGroup>
              <h6>How many packs per day?</h6>
              <span className="qa-chartTobaccoSmokerCount">
                {socialHistory.pastTobaccoCount ? socialHistory.pastTobaccoCount : "Unknown"}
              </span>
            </FormGroup>
          ) : (
            <FormGroup>
              <h6>How often?</h6>
              <span className="qa-chartTobaccoFrequency">
                {socialHistory.pastTobaccoFrequency
                  ? socialHistory.pastTobaccoFrequency
                  : "Unknown"}
              </span>
            </FormGroup>
          )}

          <FormGroup>
            <h6>For how many years?</h6>
            <span className="qa-chartTobaccoSmokerDuration">
              {socialHistory.pastTobaccoYears ? socialHistory.pastTobaccoYears : "Unknown"}
            </span>
          </FormGroup>
        </>
      )}
    </>
  );
};

function MedicalProfile(props) {
  const medicalHistory = props.medicalHistory || [];
  const familyMedicalHistory = props.profile.FamilyMedicalHistory || [];
  const medications = props.medications || [];
  const allergies = props.allergies || [];
  const surgicalHistory = props.surgicalHistory || []; 
  const socialHistory = props.socialHistory || {};
  const correctiveLenses = props.profile.correctiveLenses || {};
  const womensHealth = props.profile.womensHealth;
  const patient = props.patient;

  // TODO: replace check from `company name` to `updated at` once supplied by backend
  let lenses: string[] = [];
  if (correctiveLenses.contacts) {
    lenses.push("contacts");
  }
  if (correctiveLenses.glasses) {
    lenses.push("glasses");
  }

  return (
    <Form>
      <Row>
        <Col md={{ size: 6 }}>
          <Row>
            <ColumnWrapper>
              <Card>
                <CardHeader>
                  <Stethescope />
                  <span className="qa-chartMedicalProfileProviderNotesCard">Provider Notes</span>
                </CardHeader>
                <CardBody>
                  <p className="qa-chartProviderNotes">{props.profile.providerNotes || "None"}</p>
                </CardBody>
              </Card>
            </ColumnWrapper>
            <ColumnWrapper>
              <Card>
                <CardHeader>
                  <UsersIcon />
                  <span className="qa-chartMedicalProfileSocaialHistoryCard">Social History</span>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={{ size: 6 }}>
                      <FormGroup>
                        <h6>Marital Status</h6>
                        <span className="qa-chartSocalialHistoryMaritalStatus">
                          {socialHistory.maritalStatus ? `${socialHistory.maritalStatus} ` : ""}
                        </span>
                      </FormGroup>

                      <FormGroup>
                        <h6>Works on healthcare</h6>
                        <span className="qa-chartSocalialHistoryHealthcareWork">
                          {socialHistory.workInHealthcare ? "Yes" : "No"}
                        </span>
                      </FormGroup>

                      <FormGroup>
                        <h6>Race & Ethnicity</h6>
                        <span className="qa-chartSocalialHistoryRaceEthnicity">
                          {(socialHistory.race || ["None Listed"]).join(", ")},&nbsp;
                          {(socialHistory.ethnicity || ["None Listed"]).join(", ")}
                        </span>
                      </FormGroup>

                      <FormGroup>
                        <h6>Corrective Lenses</h6>
                        <span className="qa-chartSocalialHistoryLenses">
                          {lenses.length > 0 ? lenses.join(", ") : "None"}
                        </span>
                      </FormGroup>

                      <TobaccoInfo socialHistory={socialHistory} />
                    </Col>
                    <Col md={{ size: 6 }}>
                      <FormGroup>
                        <h6>Drinks alcohol?</h6>
                        <span className="qa-chartSocalialHistoryAlcohol">
                          {socialHistory.alcoholConsumption === true ? "Yes" : "No"}
                        </span>
                      </FormGroup>

                      {socialHistory.alcoholConsumption && (
                        <>
                          <FormGroup>
                            <h6>Frequency of alcohol consumption</h6>
                            <span className="qa-chartSocalialHistoryAlcoholFrequency">
                              {socialHistory.alcoholConsumptionFrequency}
                            </span>
                          </FormGroup>
                        </>
                      )}
                      {patient.sex === "F" && <WomenHealth womensHealth={womensHealth} />}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </ColumnWrapper>
            <ColumnWrapper>
              <Card>
                <CardHeader>
                  <PaperIcon />
                  <span className="qa-chartMedicalProfileMedicalHistoryCard">
                    Past Medical History ({medicalHistory.length})
                  </span>
                </CardHeader>
                <CardBody>
                  {medicalHistory.map((item, idx) => (
                    <ListView key={idx}>
                      <span>{item}</span>
                    </ListView>
                  ))}
                </CardBody>
              </Card>
            </ColumnWrapper>
          </Row>
        </Col>

        <Col md={{ size: 6 }}>
          <Row>
            <ColumnWrapper>
              <Card>
                <CardHeader>
                  <MedicationsIcon />
                  <span className="qa-chartMedicalProfileMedicationsCard">
                    Medications ({medications.length})
                  </span>
                </CardHeader>
                <CardBody>
                  {medications.map((item, idx) => (
                    <ListView key={idx}>
                      <span>{item}</span>
                    </ListView>
                  ))}
                </CardBody>
              </Card>
            </ColumnWrapper>
            <ColumnWrapper>
              <Card>
                <CardHeader>
                  <AllergiesIcon />
                  <span className="qa-chartMedicalProfileAllergiesCard">
                    Allergies ({allergies.length})
                  </span>
                </CardHeader>
                <CardBody>
                  {allergies.map((item, idx) => (
                    <ListView key={idx}>
                      <span>{item}</span>
                    </ListView>
                  ))}
                </CardBody>
              </Card>
            </ColumnWrapper>
            <ColumnWrapper>
              <Card>
                <CardHeader>
                  <PaperIcon />
                  <span className="qa-chartMedicalProfileSurgicalHistoryCard">
                    Surgical History ({surgicalHistory.length})
                  </span>
                </CardHeader>
                <CardBody>
                  {surgicalHistory.map((item, idx) => (
                    <ListView key={idx}>
                      <span>{item}</span>
                    </ListView>
                  ))}
                </CardBody>
              </Card>
            </ColumnWrapper>
            <ColumnWrapper>
              <Card>
                <CardHeader>
                  <UsersIcon />
                  <span className="qa-chartFamilyMedicalHistoryCard">
                    Family Health History ({familyMedicalHistory.length})
                  </span>
                </CardHeader>
                <CardBody>
                  <div className="d-flex flex-wrap">
                    {familyMedicalHistory.map((item, index) => {
                      const historyString = [
                        item.relationshipToPatient,
                        item.maternalOrPaternal,
                        item.ageAtDX,
                      ]
                        .filter((e) => e !== "")
                        .join(", ");
                      return (
                        <FormGroup className="w-50" key={`familyHealthHistoryCard-${index}`}>
                          <h6>{item.familyHealthCondition}</h6>
                          <span className="qa-chartFamilyHealthHistoryMaritalStatus">
                            {historyString}
                          </span>
                        </FormGroup>
                      );
                    })}
                  </div>
                </CardBody>
              </Card>
            </ColumnWrapper>
          </Row>
        </Col>
      </Row>
    </Form>
  );
}

MedicalProfile.propTypes = {
  allergies: PropTypes.arrayOf(PropTypes.string),
  medicalHistory: PropTypes.arrayOf(PropTypes.string),
  medications: PropTypes.arrayOf(PropTypes.string),
  patient: PropTypes.object,
  profile: PropTypes.object,
  socialHistory: PropTypes.object,
  surgicalHistory: PropTypes.arrayOf(PropTypes.string),
};
export default MedicalProfile;
