import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import update from "immutability-helper";
import set from "lodash.set";
import moment from "moment";
import PropTypes from "prop-types";

import NavBar from "../Provider/NavBar";
import { INVITE_CREATED, NAVIGATE_TO } from "../../constants/actionTypes";
import api from "../../api";
import ProviderForm from "./ProviderForm";

const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: state.common.currentUser,
    errors: state.errors[INVITE_CREATED],
  };
};

const mapDispatchToProps = (dispatch) => ({
  onSubmit: (payload) => dispatch({ type: INVITE_CREATED, targetPath: "/providers", payload }),
  onCancel: () => dispatch({ type: NAVIGATE_TO, targetPath: "/providers" }),
});

class ProviderInvite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      firstName: "",
      lastName: "",
      prefix: "",
      suffix: "",
      dateOfBirth: "",
      street: "",
      city: "",
      state: "",
      postalCode: "",
      phone: "",
      fax: "",
      npiNumber: "",
      medicalDirectors: {},
      permissions: [],

      capabilities: {
        linguistic: [],
        demographic: [],
        geographic: [],
      },
      seePatientsErr: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(field, value) {
    if (field === "dateOfBirth") {
      let dob = moment(value).format("YYYY-MM-DD");
      const o = set({}, field, { $set: dob });
      this.setState({
        ...update(this.state, o),
      });
    } else {
      const o = set({}, field, { $set: value });
      this.setState({
        ...update(this.state, o),
      });
    }
  }

  onSubmit(event) {
    if (this.state.permissions.includes("seePatients") && this.state.suffix.length === 0) {
      this.setState({ seePatientsErr: true });
    } else {
      this.setState({ seePatientsErr: false });
      this.props.onSubmit(api.Providers.invite(this.state));
    }
  }

  render() {
    return (
      <div>
        <NavBar />

        <div className="dashboard-container" id="qa-dashboard-container-invite">
          <div>
            <h4> New Provider </h4>
          </div>
          <div
            className="dashboard-component align-content-stretch"
            id="qa-dashboard-component-invite"
          >
            <ProviderForm
              provider={this.state}
              onChange={this.onChange}
              errors={this.props.errors}
            />
            <div className="d-flex">
              <Button
                color="primary"
                onClick={this.onSubmit}
                id="qa-create-provider-account-button"
              >
                Create Provider Account
              </Button>
              <Button
                className="ml-2"
                color="link"
                onClick={this.props.onCancel}
                id="qa-cancel-invite-button"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ProviderInvite.propTypes = {
  currentUser: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProviderInvite);
