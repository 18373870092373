import React from "react";
import { Button } from "reactstrap";

export default function YesNoToggle(props) {
  const setValue = (v) => (ev) => {
    ev.preventDefault();
    if (props.onChange) {
      props.onChange(v);
    }
  };
  const edited = props.value != props.oldValue;
  const cl = edited ? "yes-no-toggle d-flex edited" : "yes-no-toggle d-flex";
  return (
    <div className={cl}>
      <Button className={props.value ? "mr-3 selected" : "mr-3"} outline onClick={setValue(true)}>
        Yes
      </Button>
      <Button className={props.value ? "" : "selected"} outline onClick={setValue(false)}>
        No
      </Button>
    </div>
  );
}
