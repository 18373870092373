import React from "react";
import Select from "react-select";
import { Form, Label, Input, FormGroup, Button } from "reactstrap";

import {
  saO2Options,
  BPPositionOptions,
  BPArmOptions,
  heightFTOptions,
  heightINOptions,
  litersPerMinOxygenOptions,
} from "../../constants/Encounter";

const dropdownStyles = (bool, length) => {
  const s = bool
    ? {
        control: (styles) => ({ ...styles }),
        container: (styles) => ({ ...styles, width: length }),
      }
    : {
        control: (styles) => ({ ...styles, border: "2px solid rgb(74, 143, 231)" }),
        container: (styles) => ({ ...styles, width: length }),
      };

  return s;
};

const isDigits = (s) => {
  return s === "" || (!isNaN(s) && parseInt(s) >= 0);
};

const SplitHeight = (height) => {
  if (!height) {
    return ["", ""];
  }
  let [feet, inches] = height.split("'", 2);
  feet = feet || "";
  inches = (inches || "").replace('"', "");

  return [feet, inches];
};

const FormButtons = ({ options, value, oldValue, name, onFieldChange }) => {
  const labels = Object.keys(options);
  const edited = value != oldValue;
  const cl = edited ? "yes-no-toggle edited" : "yes-no-toggle";
  return (
    <div className={cl}>
      {labels.map((label, key) => {
        const item = options[label];
        return (
          <Button
            key={key}
            outline
            className={item === value ? "mr-3 selected multi" : "mr-3 multi"}
            onClick={() => onFieldChange(name)(item)}
          >
            <div>{label}</div>
          </Button>
        );
      })}
    </div>
  );
};

const MissingFieldErrorMessage = () => {
  return <div className="save-error-msg">Missing Required Field(s)</div>;
};

export default (props) => {
  const vitals = props.vitals;
  const old = props.oldVitals;
  const {
    weight,
    heartRate,
    respiratoryRate,
    systolic,
    diastolic,
    bloodPressureArm,
    bloodPressurePosition,
    saO2,
    saO2Source,
    saO2LitersMin,
  } = vitals;
  const onFieldChange = props.onFieldChange;
  const isClinicalServices = props.isClinicalServices;
  const weightError = props.showErrorsFor.indexOf("weight") > -1;
  const heartRateError = props.showErrorsFor.indexOf("heartRate") > -1;
  let [feet, inches] = SplitHeight(vitals.height);
  let [oldFeet, oldInches] = SplitHeight(old.height);

  const onHeightChange = (feet, inches) => (value) => {
    const f = feet === null ? value : feet;
    const i = inches === null ? value : inches;
    const n = !i && !f ? "" : `${f}'${i}"`;
    onFieldChange("height")(n);
  };

  const onInputChange = (fieldName) => (event) => {
    const v = event.target.value;
    if (!isDigits(v) || (fieldName === "saO2" && parseInt(v) > 100)) {
      return;
    }
    onFieldChange(fieldName)(v);
  };

  let kgs = 0;
  if (weight && isDigits(weight)) {
    kgs = parseFloat(weight) * 0.453592;
  }

  const fieldClass = (b, edited) => {
    return edited ? b + " field-edited" : b;
  };

  return (
    <Form className="mb-0">
      {props.showErrorsFor.length > 0 && <MissingFieldErrorMessage />}
      <div className="form-row">
        <FormGroup className="mt-2 mr-4">
          <Label className="mr-2">Height: </Label>
          <div className="d-flex">
            <div className="d-flex">
              <Select
                styles={dropdownStyles(oldFeet === feet, "60px")}
                className="mr-2"
                hideSelectedOptions={true}
                options={heightFTOptions}
                isSearchable={false}
                value={heightFTOptions.find((ft) => ft.value === feet)}
                onChange={(item) => onHeightChange(null, inches)(item.value)}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => null,
                }}
                placeholder="Select"
              />
              <Label className="align-self-end mr-2">ft</Label>
            </div>
            <div className="d-flex">
              <Select
                styles={dropdownStyles(oldInches === inches, "60px")}
                className="mr-2"
                hideSelectedOptions={true}
                options={heightINOptions}
                isSearchable={false}
                value={heightINOptions.find((i) => i.value === inches)}
                onChange={(item) => onHeightChange(feet, null)(item.value)}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => null,
                }}
                placeholder="Select"
              />
              <Label className="align-self-end">in</Label>
            </div>
          </div>
        </FormGroup>
        <FormGroup className="mt-2 mr-4">
          <Label className="mr-2">Weight:</Label>
          <div className="d-flex">
            <Input
              className={fieldClass(
                `mr-2 ${weightError ? "required-field-warning" : ""} three-digit`,
                (old.weight || "") != weight,
              )}
              name="weight"
              value={weight}
              onChange={onInputChange("weight")}
            />
            <Label className="align-self-end">lbs = {kgs.toFixed(1)}kg</Label>
          </div>
        </FormGroup>
        <FormGroup className="mt-2 mr-4">
          <Label className="mr-3"> Heart Rate: </Label>
          <div className="d-flex">
            <Input
              className={fieldClass(
                `mr-2 ${heartRateError ? "required-field-warning" : ""} three-digit`,
                (old.heartRate || "") != heartRate,
              )}
              name="heartRate"
              value={heartRate}
              onChange={onInputChange("heartRate")}
            />
            <Label className="align-self-end">bpm</Label>
          </div>
        </FormGroup>
        {isClinicalServices && (
          <FormGroup className="mt-2 mr-4">
            <Label className="mr-3"> Respiratory Rate: </Label>
            <div className="d-flex">
              <Input
                className={fieldClass(
                  "mr-2 three-digit",
                  (old.respiratoryRate || "") != respiratoryRate,
                )}
                name="respiratoryRate"
                maxLength="3"
                value={respiratoryRate || ""}
                onChange={onInputChange("respiratoryRate")}
              />
              <Label className="align-self-end">breaths per minute</Label>
            </div>
          </FormGroup>
        )}
      </div>
      {isClinicalServices && (
        <>
          <div className="form-row">
            <FormGroup className="mt-2 mr-4">
              <Label className="mr-2">Blood Pressure: </Label>
              <div className="d-flex">
                <div className="d-flex">
                  <Input
                    className={fieldClass("mr-1 two-digit", old.systolic != systolic)}
                    name="systolic"
                    value={systolic || ""}
                    onChange={onInputChange("systolic")}
                  />
                  <Label className="align-self-end mr-1">/</Label>
                </div>
                <div className="d-flex">
                  <Input
                    className={fieldClass("mr-2 two-digit", old.diastolic != diastolic)}
                    name="diastolic"
                    value={diastolic || ""}
                    onChange={onInputChange("diastolic")}
                  />
                  <Label className="align-self-end">mmHg</Label>
                </div>
              </div>
            </FormGroup>
          </div>
          <div className="form-row">
            <FormGroup className="mt-2 mr-4">
              <Label className="mr-2">Which arm was the blood pressure taken from?</Label>
              <FormButtons
                options={BPArmOptions}
                value={bloodPressureArm}
                oldValue={old.bloodPressureArm}
                name={"bloodPressureArm"}
                onFieldChange={onFieldChange}
              />
            </FormGroup>
          </div>
          <div className="form-row">
            <FormGroup className="mt-2 mr-4">
              <Label className="mr-2">
                In which of these positions was the blood pressure taken?
              </Label>
              <FormButtons
                options={BPPositionOptions}
                value={bloodPressurePosition}
                oldValue={old.bloodPressurePosition}
                name={"bloodPressurePosition"}
                onFieldChange={onFieldChange}
              />
            </FormGroup>
          </div>
          <div className="form-row">
            <FormGroup className="mt-2 mr-4">
              <Label className="mr-2">SaO2 (Pulse Oxygen Saturation);</Label>
              <div className="d-flex">
                <Input
                  className={fieldClass("mr-2 two-digit", (old.saO2 || "") != saO2)}
                  name="saO2"
                  value={saO2 || ""}
                  onChange={onInputChange("saO2")}
                />
                <Label className="align-self-end">%</Label>
              </div>
            </FormGroup>
          </div>
          <div className="form-row">
            <FormGroup className="mt-2 mr-4">
              <Label className="mr-2">Which oxygen source is used?</Label>
              <FormButtons
                options={saO2Options}
                value={saO2Source}
                oldValue={old.saO2Source}
                name={"saO2Source"}
                onFieldChange={onFieldChange}
              />
            </FormGroup>
          </div>
          {saO2Source === "L/min of oxygen" && (
            <div className="form-row">
              <FormGroup className="mt-2 mr-4">
                <div className="d-flex">
                  <Select
                    styles={dropdownStyles(old.saO2LitersMin === saO2LitersMin, "100px")}
                    className="mr-2"
                    hideSelectedOptions={true}
                    options={litersPerMinOxygenOptions}
                    isSearchable={false}
                    value={litersPerMinOxygenOptions.find((i) => i.value === saO2LitersMin)}
                    onChange={(item) => onFieldChange("saO2LitersMin")(item.value)}
                    components={{
                      IndicatorSeparator: () => null,
                    }}
                  />
                  <Label className="align-self-end">L/min of oxygen</Label>
                </div>
              </FormGroup>
            </div>
          )}
        </>
      )}
    </Form>
  );
};
