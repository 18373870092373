import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Table, Button } from "reactstrap";

import "./ProvidersToAudit.scss";
import api from "../../api";
import { NAVIGATE_TO } from "../../constants/actionTypes";
import { IAuditProviderListItem } from "../../constants/Types";
import AuditInfo from "../ProviderManagement/AuditInfo";
import { calculatePercentage } from "../ProviderManagement/List";
import { STATES } from "../../constants/Providers";

const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: state.common.currentUser,
  };
};
const mapDispatchToProps = (dispatch) => ({
  auditProvider: (id) => dispatch({ type: NAVIGATE_TO, targetPath: `/providers/audit/${id}` }),
});

const TableRow = ({ provider, audit, currentUser, ...props }) => {
  const location = provider?.capabilities?.geographic?.join(", ") || "-";
  const auditorID = currentUser?.providerID;

  return (
    <tr>
      <td>
        <div className="d-flex flex-column">
          <span className="name">{provider.fullName}</span>
        </div>
      </td>
      <td>
        <div className="d-flex flex-column">
          <span className="states">{location}</span>
        </div>
      </td>

      <td>
        <div className="d-flex flex-column flex-row">
          {provider.capabilities.geographic.map((state, index) => {
            const stateAudited = provider.auditStatistics.stateAudited || {};
            const stateFinished = provider.auditStatistics.stateFinished || {};
            const visitsAudited = stateAudited[state] ? stateAudited[state] : 0;
            const visitsFinished = stateFinished[state] ? stateFinished[state] : 0;
            const suprPhys = provider.supervisingPhysicians;

            if (suprPhys[state] === auditorID) {
              return (
                <AuditInfo
                  className="mt-1 audit-percent"
                  threshold={20}
                  percentage={calculatePercentage(visitsAudited, visitsFinished)}
                  key={`audit-${index}`}
                >
                  <div>
                    <div className="audit-state">{STATES[state]}</div>
                    <div>{calculatePercentage(visitsAudited, visitsFinished)}%</div>
                  </div>
                </AuditInfo>
              );
            }
          })}
        </div>
      </td>

      <td>
        <div className="d-flex justify-content-around">
          <Button
            color="primary"
            className="audit-button qa-providerAuditButton"
            onClick={audit(provider.id)}
          >
            Audit
          </Button>
        </div>
      </td>
    </tr>
  );
};

const ProvidersToAudit = ({ auditProvider, currentUser }) => {
  const [providers, setProviders] = useState<IAuditProviderListItem[]>([]);

  const fetchProvidersToAudit = async () => {
    const response = await api.Auditing.providers({ incompleteOnly: true });

    setProviders(!!response.Items ? response.Items : []);
  };

  useEffect(() => {
    fetchProvidersToAudit();
  }, []);

  const audit = (id: IAuditProviderListItem["id"]) => () => {
    auditProvider(id);
  };

  return (
    <div className="dashboard-component providers-to-audit-container">
      <h5>Providers to Audit</h5>
      {providers.length > 0 ? (
        <Table className="table">
          <tbody>
            {providers.map((provider, idx) => (
              <TableRow
                key={`row-${idx}`}
                provider={provider}
                audit={audit}
                currentUser={currentUser}
              />
            ))}
          </tbody>
        </Table>
      ) : (
        <p className="info">You are up to date with your audit requirements for this month</p>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProvidersToAudit);
