import React, { useState, useEffect } from "react";
import ContentBLock from "../Encounter/ContentBlock";
import { findFeature, featureSetAttributes } from "./Features";
import EntryBlock from "./EntryBlock";
import { GroupNoteEntry } from "./Notes";
import FeatureEntry from "./FeatureEntry";
import FeatureCheckBoxes from "./FeatureCheckBoxes";
import PropTypes from "prop-types";

const TonsillarArch = ({
  features,
  onChange,
  note,
  onNoteUpdate,
  onCheckboxUpdate,
  isNursingHomeSite,
  onRemoveSection,
}) => {
  const [hideContent, setHideContent] = useState(true);
  const erythematous = findFeature(
    features,
    "tonsillarArchErythematous",
    "Erythematous",
    "Tonsillar Arch",
  );
  const tonsillarSize = findFeature(
    features,
    "tonsillarArchTonsilarSize",
    "Tonsillar Size",
    "Tonsillar Arch",
  );
  const exudate = findFeature(features, "tonsillarArchExudate", "Exudate", "Tonsillar Arch");
  const setAttribute = featureSetAttributes({ onChange });

  const setCheckBox = (target) => {
    onCheckboxUpdate(features, "Tonsillar Arch", target.checked, target.value);
  };

  const anyChecked = (val) => {
    const foundIdx = (features || []).findIndex((e) => e.group === "Tonsillar Arch");
    if (foundIdx < 0) {
      return false;
    }
    return features[foundIdx][val];
  };

  const hasEntires = () => {
    const foundIdx = (features || []).findIndex((e) => e.group === "Tonsillar Arch");
    if (foundIdx < 0) {
      return false;
    }
    return !!features[foundIdx].attributes;
  };

  const handleRemoveSection = () => {
    onRemoveSection("Tonsillar Arch");
    setHideContent(true);
  };

  useEffect(() => {
    setHideContent(!hasEntires());
  }, []);

  if (hideContent) {
    return (
      <ContentBLock
        title={isNursingHomeSite ? "Tonsils" : "Tonsillar Arch"}
        titleOnly={true}
        className="hide-content"
        onClick={() => setHideContent(false)}
      />
    );
  }

  return (
    <EntryBlock title={isNursingHomeSite ? "Tonsils" : "Tonsillar Arch"}  className="large">
      <div>
        <FeatureEntry
          {...{
            feature: erythematous,
            values: ["No", "Yes"],
            onChange: setAttribute,
            id: "qa-tonsillarArch-entry-erythematous",
          }}
        />
        <FeatureEntry
          {...{
            feature: tonsillarSize,
            values: isNursingHomeSite
              ? ["Normal", "Enlarged", "Absent"]
              : ["1+", "2+", "3+", "4+", "5+"],
            onChange: setAttribute,
            id: "qa-tonsillarArch-entry-tonsillarSize",
          }}
        />
        <FeatureEntry
          {...{
            feature: exudate,
            values: ["No", "Yes"],
            onChange: setAttribute,
            id: "qa-tonsillarArch-entry-exudate",
          }}
        />
        <FeatureCheckBoxes {...{ setCheckBox, anyChecked, isNursingHomeSite }} />
        <div className="remove" onClick={handleRemoveSection}>
          Remove section -
        </div>
      </div>
      <div>
        <GroupNoteEntry
          {...{
            groupName: "Tonsillar Arch",
            onChange: onNoteUpdate,
            note,
            id: "qa-tonsillarArch-group-entry",
          }}
        />
      </div>
    </EntryBlock>
  );
};

TonsillarArch.propTypes = {
  features: PropTypes.array,
  onChange: PropTypes.func,
  note: PropTypes.string,
  onNoteUpdate: PropTypes.func,
  onCheckboxUpdate: PropTypes.func,
  isNursingHomeSite: PropTypes.bool,
};

export default TonsillarArch;
