import React, { FC } from "react";
import { Label } from "reactstrap";

import InfoSelect from "./InfoSelect";
import { HerpesIllContactsView, HerpesIllContactsEditor } from "./HerpesIllContacts";
import RequestedTestType from "./RequestedTestType";
import BlockSection from "./BlockSection";
import YesNoToggle from "./YesNoToggle";
import { relevantDiseaseLookup, userVisibleDiseaseNames } from "../../constants/Encounter";
import { IUserMedicalIntake } from "../../constants/Types";

type EditorProps = {
  disease: string[] | string;
  oldDisease: string[];
  handleToggle: () => void;
  relevantDisease: string | null;
  onChange: (value: any) => void;
};

export const Editor: FC<EditorProps> = ({ disease, oldDisease, ...props }) => {
  const textValue = disease && disease[0] !== "false" ? disease[0] : "";
  const yes = disease.length && disease[0] !== "false";
  const oldYes = oldDisease.length && disease[0] !== "false";

  const onChange = (value) => {
    props.onChange([value]);
  };
  const onSetToggle = (ev) => {
    if (!ev) {
      props.handleToggle();
    } else {
      let newValue = textValue ? [textValue] : ["true"];
      props.onChange(newValue);
    }
  };

  const options = ["Family", "Co-worker", "Friend", "Other"];
  return (
    <div className="d-flex">
      <div className="mr-3">
        <YesNoToggle value={yes} oldValue={oldYes} onChange={onSetToggle} />
      </div>
      {disease && disease.length > 0 && props.relevantDisease !== "STD" && (
        <div className="w-25">
          <InfoSelect
            value={textValue === "true" || textValue === "Yes" ? "Select.." : textValue}
            options={options}
            onChange={onChange}
          />
        </div>
      )}
    </div>
  );
};

type CoronaVirusContactProps = {
  onChange: (value: any) => void;
  illContacts: Partial<Props["illContacts"]>;
  oldValue: any;
  recentExposure: string;
};

const CoronaVirusContact: FC<CoronaVirusContactProps> = ({
  onChange,
  illContacts,
  oldValue,
  recentExposure,
}) => {
  const onToggle = (newValue) => {
    onChange({ illContacts: { ...illContacts, recentExposure: newValue } });
  };

  const oldData = oldValue.intakeHistory.illContacts.recentExposure;
  return (
    <div className="mt-3">
      <Label>{recentExposure}</Label>
      <div className="mr-3">
        <YesNoToggle value={illContacts.recentExposure} oldValue={oldData} onChange={onToggle} />
      </div>
    </div>
  );
};

const ExtractDisease = (
  testType: string,
  diseases?: {
    [key: string]: string[];
  },
) => {
  const ds = diseases || {};
  const relevantDisease: string = relevantDiseaseLookup[testType] || "other";
  return ds[relevantDisease] || [];
};

type Props = {
  illContacts: IUserMedicalIntake["illContacts"];
  recentExposure: IUserMedicalIntake["recentExposure"];
  onChanged: (value: any) => void;
  visitType: string;
  readOnly?: boolean;
  isEditing?: boolean;
  restoreData?: any;
  showRequiredFieldErrors?: string[];
  requiredFields?: string[];
  setRequiredFields?: (value: string) => void;
};

const IllContacts: FC<Props> = (props) => {
  const illContacts = props.illContacts || {};
  const diseases = illContacts.diseases || {};
  const relevantDisease: string = relevantDiseaseLookup[props.visitType] || "other";
  const disease = ExtractDisease(props.visitType, illContacts.diseases);
  const recentExposure = "Was this exposure less than 8 days ago?";
  const oldDisease = ExtractDisease(props.visitType, (props.restoreData || {}).diseases);

  const onChange = (newValue) => {
    props.onChanged({
      illContacts: {
        ...illContacts,
        diseases: { ...diseases, [relevantDisease]: newValue },
      },
    });
  };
  const handleToggle = () => {
    props.onChanged({
      illContacts: { notApplicable: true, testActiveOrPast: illContacts.testActiveOrPast },
    });
  };
  const formatQuestion = () => {
    const question =
      relevantDisease === "STD"
        ? "Exposure to a sexually transmitted disease (STD)?"
        : `Contact with a person who may have ${
            userVisibleDiseaseNames[props.visitType] || "been ill"
          }?`;
    return question;
  };

  let content;
  let diseaseList =
    relevantDisease === "STD" || disease[0] === "" || disease[0] === "true" || disease[0] === "Yes"
      ? "Yes"
      : `Yes - ${disease.join(", ")}`;
  if (props.isEditing && !props.readOnly) {
    content = (
      <div>
        <Label>{formatQuestion()}</Label>
        <Editor
          disease={disease}
          oldDisease={oldDisease}
          onChange={onChange}
          relevantDisease={relevantDisease}
          handleToggle={handleToggle}
        />
        {relevantDisease === "coronavirus" &&
          props.illContacts.diseases &&
          props.illContacts.diseases.coronavirus && (
            <CoronaVirusContact
              onChange={props.onChanged}
              illContacts={illContacts}
              oldValue={props.restoreData}
              recentExposure={recentExposure}
            />
          )}
      </div>
    );
  } else {
    content = (
      <div>
        <Label>{formatQuestion()}</Label>
        {disease.length > 0 && disease[0] !== "false" ? ` ${diseaseList}` : " No"}
        {relevantDisease === "coronavirus" &&
          props.illContacts.diseases &&
          props.illContacts.diseases.coronavirus && (
            <div>
              <Label className="mt-3 mr-2">{recentExposure}</Label>
              {illContacts.recentExposure ? "Yes" : "No"}
            </div>
          )}
      </div>
    );
  }
  return (
    <div>
      {relevantDisease !== "Herpes" ? (
        <>
          <BlockSection title="Other Ill Contacts">{content}</BlockSection>
          {props.visitType === "coronavirus_consult_and_testing" && (
            <RequestedTestType
              illContacts={illContacts}
              restoreData={props.restoreData}
              isEditing={props.isEditing}
              onChanged={props.onChanged}
              readOnly={props.readOnly}
            />
          )}
        </>
      ) : (
        <div>
          {props.isEditing && !props.readOnly ? (
            <HerpesIllContactsEditor
              diseases={diseases}
              oldDiseases={props.restoreData.intakeHistory.illContacts.diseases}
              onChanged={props.onChanged}
            />
          ) : (
            <HerpesIllContactsView diseases={diseases} />
          )}
        </div>
      )}
    </div>
  );
};

export default IllContacts;
