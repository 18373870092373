import React from "react";
import { Card } from "reactstrap";
import styles from "./chart.scss";
import moment  from "moment-timezone";

type VitalsChartCardProps = {
  title: string;
  type: string;
  data: {
    systolicValue?: number;
    diastolicValue?: number;
    value: number;
    unit?: string;
    recordedDate: string;
    method: string;
  };
  onSelect: (type: string) => void;
  outOfRange: boolean;
  isExpanded: boolean;
  limits: {
    upper: string;
    lower: string;
  };
  timeZone: string
  
};

const VitalsChartCard = ({
  title,
  type,
  data,
  onSelect,
  outOfRange,
  isExpanded,
  limits,
  timeZone,
}: VitalsChartCardProps) => {
  if (!data) {
    return null;
  }

  const value =
    type === "bloodPressure" && !!data.systolicValue && !!data.diastolicValue
      ? `${data.systolicValue}/${data.diastolicValue}`
      : String(data?.value || "None");

  const riskLevel =
    !!limits.upper && value >= limits.upper
      ? "High"
      : !!limits.lower && value < limits.lower
      ? "Low"
      : "Medium";
  const currentDate = type === "riskAssessment" && moment().format("MM/DD/YYYY");
  const vitalsDate:moment.Moment|null = data?.recordedDate ? moment(data.recordedDate).tz(timeZone) : null;

  if (isExpanded) {
    return (
      <div className={`${styles.cardContainer} mb-3 w-100`}>
        <Card
          className={styles.expandedCard}
          style={!!isExpanded && type === "bloodPressure" ? { height: 355 } : {}}
          onClick={() => onSelect(type)}
        >
          <div>
            <h1 className={styles.title}>{title}</h1>
            {type != "bloodPressure" && type != "riskAssessment" && (
              <div className={styles.latest}>
                <div
                  className={`${styles.limit} ${
                    outOfRange ? styles.outsideLimit : styles.insideLimit
                  }`}
                >
                  {value}
                </div>
                <div className={styles.units}>{data.unit}</div>
              </div>
            )}
            {type === "bloodPressure" && (
              <>
                <div className={styles.latest}>
                  <div className={styles.container}>
                    <div className={`${styles.limit} ${styles.systolic}`}>{data.systolicValue}</div>
                    <div className={styles.units}>{data.unit}</div>
                    <span className={styles.bpType}>Systolic</span>
                  </div>
                </div>

                <div className={styles.latest}>
                  <div className={styles.container}>
                    <div className={`${styles.limit} ${styles.diastolic}`}>
                      {data.diastolicValue}
                    </div>
                    <div className={styles.units}>{data.unit}</div>
                    <span className={styles.bpType}>Diastolic</span>
                  </div>
                </div>
              </>
            )}
            {type === "riskAssessment" && (
              <div className={styles.latest}>
                <div
                  className={
                    riskLevel === "High"
                      ? `${styles.limit} ${styles.outsideLimit}`
                      : riskLevel === "Low"
                      ? `${styles.limit} ${styles.insideLimit}`
                      : `${styles.limit} ${styles.mediumRisk}`
                  }
                >
                  {value}
                </div>
              </div>
            )}
            <h2>Last Recorded</h2>
            <p>
              {type === "riskAssessment"
                ? currentDate : `${vitalsDate?.format("L LT")}`
              }
            </p>
            {type != "riskAssessment" ? (
              <>
                <h2>Method:</h2>
                <p>{!!data.method ? data.method : "Not Recorded"}</p>
                {!!limits?.upper && <h2>Upper Limit</h2>}
                <p>{!!limits?.upper ? limits.upper : ""}</p>
                {!!limits?.lower && <h2>Lower Limit</h2>}
                <p>{!!limits?.lower ? limits.lower : ""}</p>
              </>
            ) : (
              <>
                <h2>Risk Level</h2>
                <p>{riskLevel}</p>
              </>
            )}
          </div>
        </Card>
      </div>
    );
  } else {
    return (
      <>
        <div className={`d-flex flex-direction-column justify-content-between mb-3 w-100`}>
          <div className={`card-group ${styles.cardGroups}`} onClick={() => onSelect(type)}>
            <Card className={styles.observationsType}>
              <div>
                <h2 className={styles.title}>{title}</h2>
                {type != "riskAssessment" && (
                  <>
                    <div className={styles.limits}>
                      {!!limits && !!limits.upper ? `Upper Limit: ${limits.upper}` : ""}
                    </div>
                    <div className={styles.limits}>
                      {!!limits && !!limits.lower ? `Lower Limit: ${limits.lower}` : ""}
                    </div>
                  </>
                )}
              </div>
            </Card>
            <Card className={styles.observationsDateCard}>
              <div className={styles.date}>
                <div>
                {type === "riskAssessment"
                  ? currentDate : `${vitalsDate?.format("L")}`
                }
                </div>
                <div>
                {type != "riskAssessment" && `${vitalsDate?.format("LT")}`
                }
                </div>
              </div>
            </Card>
            <Card
              className={`text-center ${styles.observationLimit} ${
                outOfRange
                  ? styles.observationOutsideLimit
                  : !outOfRange && type === "riskAssessment"
                  ? styles.mediumRisk
                  : styles.observationInLimit
              }`}
            >
              <div className={styles.latest}>
                {value}
                {type != "riskAssessment" && <div>{value ? data.unit : ""}</div>}
              </div>
            </Card>
          </div>
        </div>
      </>
    );
  }
};

export default VitalsChartCard;
