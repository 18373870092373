import React, { FC } from "react";
import moment from "moment";
import { IObservation } from "../../constants/Types";
type Props = {
  PCCObservations: IObservation[];
};

const vitalsLayout = [
  {
    type: "temperature",
    label: "Temperature",
  },
  {
    type: "heartrate",
    label: "Heartrate",
  },
  {
    type: "bloodPressure",
    label: "Blood Pressure",
  },
  {
    type: "bloodSugar",
    label: "Blood Sugar",
  },
  {
    type: "oxygenSaturation",
    label: "Oxygen Saturation",
  },
  {
    type: "respirations",
    label: "Respirations",
  },
  {
    type: "height",
    label: "Height",
  },
  {
    type: "weight",
    label: "Weight",
  },
  {
    type: "painLevel",
    label: "Pain Level",
  },
];

const PCCVitals: FC<Props> = ({ PCCObservations }) => {
  const list = vitalsLayout.reduce((result, entry, idx) => {
    if (idx >= 0) {
      result.push([]);
    }
    result[result.length - 1].push(entry);
    return result;
  }, []);

  return (
    <>
      {list.map((item) => (
        <ObservationsList
          type={item[0].type}
          label={item[0].label}
          observations={PCCObservations}
        />
      ))}
    </>
  );
};

const ObservationsList = ({ type, label, observations }) => {
  const observation = (observations || []).find((item) => item.type === type) || {};
  const value =
    type === "bloodPressure" && !!observation.systolicValue && !!observation.diastolicValue
      ? `${observation.systolicValue}/${observation.diastolicValue}`
      : observation.value != null
      ? "" + observation.value
      : "";
  const methodCode = observation.methodCode || {};
  const coding = methodCode.codings || [];
  const method = coding.reduce((result, code) => {
    if (result.length > 0) {
      result += ", ";
    }
    result += code.display;
    return result;
  }, "");
  const recordedDate = !!observation.recordedDate
    ? moment(observation.recordedDate).format("MM/DD/YYYY, hh:mm A")
    : "";

  const vitalsLabel = !value
    ? `${label} - Not Recorded`
    : label != "Pain Level"
    ? `${label} - ${value} ${observation.unit}`
    : `${label}- ${value}`;

  const vitalsMethod = !!method ? `Method: ${method}` : "Method: Not Recorded";
  const vitalsDate = recordedDate ? recordedDate : "Not Recorded";

  return (
    <div>
      <p
        className={`qa-pccVitalsEncounter-${label}`}
      >{`${vitalsLabel} - ${vitalsMethod} - ${vitalsDate}`}</p>
    </div>
  );
};

export default PCCVitals;
