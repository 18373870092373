import React from "react";
import { Form, Label, FormGroup } from "reactstrap";
import PropTypes from "prop-types";

import { boolToString, possiblyNullIntToString } from "./LungAllergyHistory";
import InfoSelect from "./InfoSelect";
import YesNoToggle from "./YesNoToggle";
import CheckToggle from "../CheckToggle";

const ethnicityOptions = [
  {
    label: "Mexican",
    key: "Mexican",
  },
  {
    label: "Mexican American",
    key: "Mexican American",
  },
  {
    label: "Chicano/a",
    key: "Chicano/a",
  },
  {
    label: "Puerto Rican",
    key: "Puerto Rican",
  },
  {
    label: "Cuban",
    key: "Cuban",
  },
  {
    label: "Unknown",
    key: "Unknown",
  },
  {
    label: "Other",
    key: "Other",
  },
  {
    label: "Not of Hispanic, Latino/a, or Spanish origin",
    key: "Not of Hispanic, Latino/a, or Spanish origin",
  },
];

const raceOptions = [
  {
    label: "White",
    key: "White",
  },
  {
    label: "Black or African American",
    key: "Black or African American",
  },
  {
    label: "American Indian or Alaska Native",
    key: "American Indian or Alaska Native",
  },
  {
    label: "Other",
    key: "Other",
  },
  {
    label: "Chinese",
    key: "Chinese",
  },
  {
    label: "Filipino",
    key: "Filipino",
  },
  {
    label: "Asian Indian",
    key: "Asian Indian",
  },
  {
    label: "Vietnamese",
    key: "Vietnamese",
  },
  {
    label: "Korean",
    key: "Korean",
  },
  {
    label: "Japanese",
    key: "Japanese",
  },
  {
    label: "Other Asian",
    key: "Other Asian",
  },
  {
    label: "Native Hawaiian",
    key: "Native Hawaiian",
  },
  {
    label: "Samoan",
    key: "Samoan",
  },
  {
    label: "Chamorro",
    key: "Chamorro",
  },
  {
    label: "Other Pacific Islander",
    key: "Other Pacific Islander",
  },
];

const View = (props) => {
  const sh = props.socialHistory;

  return (
    <div>
      <ul>
        <li>Marital Status: {sh.maritalStatus}</li>
        <li>Ethnicity: {sh.ethnicity ? sh.ethnicity.join(", ") : "-"}</li>
        <li>Race: {sh.race ? sh.race.join(", ") : "-"}</li>
        <li>
          Works in healthcare:{" "}
          {sh.workInHealthcare === true
            ? "Works in healthcare"
            : sh.workInHealthcare === null
            ? "Unknown"
            : "Doesn't work in healthcare"}
        </li>
        <li>Current Tobacco User: {boolToString(sh.tobaccoNow)}</li>
        {sh.tobaccoNow && (
          <ul>
            {sh.currentTobaccoList && <li>{sh.currentTobaccoList.join(", ")}</li>}
            {!sh.currentTobaccoList || sh.currentTobaccoList.indexOf("Cigarette") > -1 ? (
              <li>Packs per day: {possiblyNullIntToString(sh.currentTobaccoCount)}</li>
            ) : (
              sh.currentTobaccoFrequency && <li>How Often: {sh.currentTobaccoFrequency}</li>
            )}
          </ul>
        )}
        {!sh.tobaccoNow && sh.tobaccoEver && (
          <div>
            <li>Former Tobacco User: {boolToString(sh.tobaccoEver)}</li>
            <ul>
              {sh.pastTobaccoList && <li>{sh.pastTobaccoList.join(", ")}</li>}
              {!sh.pastTobaccoList || sh.pastTobaccoList.indexOf("Cigarette") > -1 ? (
                <li>Packs per day: {possiblyNullIntToString(sh.pastTobaccoCount)}</li>
              ) : (
                sh.pastTobaccoFrequency && <li>How Often: {sh.pastTobaccoFrequency}</li>
              )}
              <li>For {possiblyNullIntToString(sh.pastTobaccoYears)} Years</li>
            </ul>
          </div>
        )}
        {sh.alcoholConsumption === true ? (
          <li>User consumes alcohol: Yes</li>
        ) : sh.alcoholConsumption === null ? (
          <li>User consumes alcohol: Unknown</li>
        ) : (
          <li>User consumes alcohol: No </li>
        )}
        {sh.alcoholConsumption && (
          <li>Frequency of alcohol consumption: {sh.alcoholConsumptionFrequency}</li>
        )}
      </ul>
    </div>
  );
};

const marriageOptions = ["Single", "Married", "Divorced"];

const tobaccoOptions = [
  "Cigarette",
  "Pipe",
  "Vape",
  "Juul",
  "Dab",
  "Drip",
  "Snuff",
  "Chewing Tobacco",
  "Other",
];

const tobaccoFrequency = [
  "Rarely",
  "Occasionally",
  "Once a week",
  "Once every couple days",
  "Once a day",
  "Multiple times a day",
];

const packsPerDayOptions = [...Array(40).keys()];
const yearsUsedOptions = [...Array(120).keys()];

const alcoholUseOptions = [
  "Every day",
  "5 to 6 times a week",
  "3 to 4 times a week",
  "Twice a week",
  "Once a week",
  "2 to 3 times a month",
  "Once a month",
  "3 to 11 times in the past year",
  "1 or 2 times in the past year",
];

const Editor = (props) => {
  const sh = props.socialHistory;
  const oh = props.oldSocialHistory;
  let raceSelected = props.socialHistory.race || [];
  let ethnicitySelected = props.socialHistory.ethnicity || [];

  const getValueOrPlaceHolder = (property) => {
    return property ? property : "Select Option";
  };

  const getToggleEthnicity = (key) => {
    return (checked) => {
      if (checked) {
        ethnicitySelected.push(key);
      } else {
        let index = ethnicitySelected.indexOf(key);
        if (index > -1) {
          ethnicitySelected.splice(index, 1);
        }
      }

      props.onEthnicityRaceChange({
        field: "ethnicity",
        value: ethnicitySelected,
      });
    };
  };

  const getToggleRace = (key) => {
    const raceselectedcopy = raceSelected.slice();
    return (checked) => {
      if (checked) {
        raceselectedcopy.push(key);
      } else {
        let index = raceselectedcopy.indexOf(key);
        if (index > -1) {
          raceselectedcopy.splice(index, 1);
        }
      }
      props.onEthnicityRaceChange({
        field: "race",
        value: raceselectedcopy,
      });
    };
  };

  return (
    <Form className="mb-0">
      <div className="form-row mb-3 ethnicty-race-edit flex-nowrap">
        <FormGroup className="mb-0 col-md-4">
          <Label>Ethnicity</Label>
          {ethnicityOptions.map((eo, idx) => (
            <div className="mb-3 item">
              <CheckToggle
                key={idx}
                checked={ethnicitySelected.includes(eo.key)}
                onChange={getToggleEthnicity(eo.key)}
              >
                {eo.label}
              </CheckToggle>
            </div>
          ))}
        </FormGroup>
        <FormGroup className="mb-0 col-md-8">
          <Label>Race</Label>
          <div className="w-100 d-flex flex-column flex-wrap race-view">
            {raceOptions.map((ro, idx) => (
              <div className="mb-3 item">
                <CheckToggle
                  key={idx}
                  checked={raceSelected.includes(ro.key)}
                  onChange={getToggleRace(ro.key)}
                >
                  {ro.label}
                </CheckToggle>
              </div>
            ))}
          </div>
        </FormGroup>
      </div>
      <div className="form-row">
        <FormGroup className="mb-2 col-md-3">
          <Label>Marital Status</Label>
          <InfoSelect
            value={sh.maritalStatus}
            options={marriageOptions}
            oldValue={oh.maritalStatus}
            onChange={props.onFieldChange("maritalStatus")}
          />
        </FormGroup>
        <FormGroup className="mb-0 col-md-3">
          <Label>Work in healthcare?</Label>
          <YesNoToggle
            value={sh.workInHealthcare}
            oldValue={oh.workInHealthcare}
            onChange={props.onFieldChange("workInHealthcare")}
          />
        </FormGroup>
        <FormGroup className="mb-0 col-md-3">
          <Label>Current Tobacco User</Label>
          <YesNoToggle
            value={sh.tobaccoNow}
            oldValue={oh.tobaccoNow}
            onChange={props.onFieldChange("tobaccoNow")}
          />
        </FormGroup>
        {!sh.tobaccoNow ? (
          <FormGroup className="mb-0 col-md-3">
            <Label>Former Tobacco User</Label>
            <YesNoToggle
              value={sh.tobaccoEver}
              oldValue={oh.tobaccoEver}
              onChange={props.onFieldChange("tobaccoEver")}
            />
          </FormGroup>
        ) : (
          <span className="mb-0 col-md-3" />
        )}
      </div>
      {sh.tobaccoNow && (
        <div className="form-row mt-2">
          <FormGroup className="mb-0 col-md-3">
            <Label>What do you use?</Label>
            <InfoSelect
              value={getValueOrPlaceHolder(sh.currentTobaccoList)}
              options={tobaccoOptions}
              oldValue={oh.currentTobaccoList}
              onChange={props.onTobaccoTypeChange("currentTobaccoList", oh.currentTobaccoList)}
            />
          </FormGroup>
          {!sh.currentTobaccoList || sh.currentTobaccoList.indexOf("Cigarette") > -1 ? (
            <FormGroup className="mb-0 col-md-4 mr-3">
              <Label>{"How many packs per day?"}</Label>
              <InfoSelect
                value={getValueOrPlaceHolder(sh.currentTobaccoCount)}
                options={packsPerDayOptions}
                oldValue={oh.currentTobaccoCount}
                onChange={props.onFieldChange("currentTobaccoCount")}
              />
            </FormGroup>
          ) : (
            <FormGroup className="mb-0 col-md-3 mr-3">
              <Label>{"How often?"}</Label>
              <InfoSelect
                value={getValueOrPlaceHolder(sh.currentTobaccoFrequency)}
                options={tobaccoFrequency}
                oldValue={oh.currentTobaccoFrequency}
                onChange={props.onFieldChange("currentTobaccoFrequency")}
              />
            </FormGroup>
          )}
        </div>
      )}
      {!sh.tobaccoNow && sh.tobaccoEver && (
        <div className="form-row mt-2">
          <FormGroup className="mb-0 col-md-3 mr-3">
            <Label>What did you use?</Label>
            <InfoSelect
              value={getValueOrPlaceHolder(sh.pastTobaccoList)}
              options={tobaccoOptions}
              oldValue={oh.pastTobaccoList}
              onChange={props.onTobaccoTypeChange("pastTobaccoList", oh.pastTobaccoList)}
            />
          </FormGroup>
          {!sh.pastTobaccoList || sh.pastTobaccoList.indexOf("Cigarette") > -1 ? (
            <FormGroup className="mb-0 col-md-4 mr-3">
              <Label>{"How many packs per day?"}</Label>
              <InfoSelect
                value={getValueOrPlaceHolder(sh.pastTobaccoCount)}
                options={packsPerDayOptions}
                oldValue={oh.pastTobaccoCount}
                onChange={props.onFieldChange("pastTobaccoCount")}
              />
            </FormGroup>
          ) : (
            <FormGroup className="mb-0 col-md-3 mr-3">
              <Label>{"How often?"}</Label>
              <InfoSelect
                value={getValueOrPlaceHolder(sh.pastTobaccoFrequency)}
                options={tobaccoFrequency}
                oldValue={oh.pastTobaccoFrequency}
                onChange={props.onFieldChange("pastTobaccoFrequency")}
              />
            </FormGroup>
          )}
          <FormGroup className="mb-0 col-md-3 mr-3">
            <Label>For how many years?</Label>
            <InfoSelect
              value={getValueOrPlaceHolder(sh.pastTobaccoYears)}
              options={yearsUsedOptions}
              oldValue={oh.pastTobaccoYears}
              onChange={props.onFieldChange("pastTobaccoYears")}
            />
          </FormGroup>
        </div>
      )}
      <FormGroup className="mb-2 ml-0 col-md-3">
        <Label>Consume alcohol?</Label>
        <YesNoToggle
          value={sh.alcoholConsumption}
          oldValue={oh.alcoholConsumption}
          onChange={props.onFieldChange("alcoholConsumption")}
        />
      </FormGroup>
      {sh.alcoholConsumption && (
        <FormGroup className="mb-0 col-md-3 mr-3">
          <Label>Frequency of alcohol consumption?</Label>
          <InfoSelect
            value={sh.alcoholConsumptionFrequency}
            options={alcoholUseOptions}
            oldValue={sh.alcoholConsumptionFrequency}
            onChange={props.onFieldChange("alcoholConsumptionFrequency")}
          />
        </FormGroup>
      )}
    </Form>
  );
};

export default function SocialHistory(props) {
  const sh = props.socialHistory;
  const oh = props.oldSocialHistory;

  const onFieldChange = (field) => (newValue) => {
    if (field === "currentTobaccoList" || field === "pastTobaccoList") {
      newValue = [newValue];
    }
    if (field === "tobaccoNow") {
      let toFalse = "tobaccoEver";
      props.onChange({ ...sh, [field]: newValue, [toFalse]: false });
    } else {
      props.onChange({ ...sh, [field]: newValue });
    }
  };

  const onEthnicityRaceChange = ({ field, value }) => {
    props.onChange({ ...sh, [field]: value });
  };

  const onTobaccoTypeChange = (field, oldValue) => (newValue) => {
    const sh = props.socialHistory;
    const oh = props.oldSocialHistory;
    newValue = [newValue];
    if (oldValue && oldValue.indexOf("Cigarette") > -1 && newValue[0] !== "Cigarette") {
      let currentToNull = "currentTobaccoCount";
      let pastToNull = "pastTobaccoCount";

      props.onChange({
        ...sh,
        [field]: newValue,
        [pastToNull]: null,
        [currentToNull]: null,
      });
    } else if (oldValue && oldValue.indexOf("Cigarette") < 0 && newValue[0] === "Cigarette") {
      let currentToNull = "currentTobaccoFrequency";
      let pastToNull = "pastTobaccoFrequency";

      props.onChange({
        ...sh,
        [field]: newValue,
        [pastToNull]: null,
        [currentToNull]: null,
      });
    } else {
      props.onChange({ ...sh, [field]: newValue });
    }
  };

  if (props.isEditing && !props.readOnly) {
    return (
      <Editor
        socialHistory={sh}
        oldSocialHistory={oh}
        onEthnicityRaceChange={onEthnicityRaceChange}
        onFieldChange={onFieldChange}
        onTobaccoTypeChange={onTobaccoTypeChange}
      />
    );
  }

  return <View socialHistory={sh} />;
}

SocialHistory.propTypes = {
  isEditing: PropTypes.bool,
  oldSocialHistory: PropTypes.object,
  onChange: PropTypes.func,
  socialHistory: PropTypes.object,
};
