import React, { FC } from "react";
import { Form, Label, FormGroup } from "reactstrap";

import { ICorrectiveLenses } from "../../constants/Types";
import YesNoToggle from "./YesNoToggle";

type ViewProps = {
  correctiveLenses: Partial<ICorrectiveLenses>;
  correctiveLensesSet?: boolean;
};

const View: FC<ViewProps> = (props) => {
  const sh = props.correctiveLenses;
  let cl = "-";

  if (props.correctiveLensesSet) {
    let details: string[] = [];

    if (sh.glasses) {
      details.push("glasses");
    }

    if (sh.contacts) {
      details.push("contacts");
    }

    if (details.length > 0) {
      cl = details.join(", ");
    }
  }

  return (
    <div>
      <span>{cl}</span>
    </div>
  );
};

type onChange = (field: keyof ICorrectiveLenses) => (newValue: boolean) => void;
type EditorProps = {
  correctiveLenses: Partial<ICorrectiveLenses>;
  oldCorrectiveLenses: Partial<ICorrectiveLenses>;
  onFieldChange: onChange;
  patientTab?: boolean;
};

const Editor: FC<EditorProps> = (props) => {
  const sh = props.correctiveLenses;
  const oh = props.oldCorrectiveLenses;

  return (
    <Form className="mb-0">
      <div className={props.patientTab ? "form-row patient-select" : "form-row"}>
        <FormGroup className="mb-0 col-md-4">
          <Label>Corrective Lenses?</Label>
          <YesNoToggle
            value={sh.correctiveLenses}
            oldValue={oh.correctiveLenses}
            onChange={props.onFieldChange("correctiveLenses")}
          />
        </FormGroup>
        <FormGroup className="mb-0 col-md-4">
          <Label>Glasses?</Label>
          <YesNoToggle
            value={sh.glasses}
            oldValue={oh.glasses}
            onChange={props.onFieldChange("glasses")}
          />
        </FormGroup>
        <FormGroup className="mb-0 col-md-4">
          <Label>Contacts?</Label>
          <YesNoToggle
            value={sh.contacts}
            oldValue={oh.contacts}
            onChange={props.onFieldChange("contacts")}
          />
        </FormGroup>
      </div>
    </Form>
  );
};

type CorrectiveLensesProps = {
  correctiveLenses: Partial<ICorrectiveLenses>;
  correctiveLensesSet?: boolean;
  oldCorrectiveLenses: Partial<ICorrectiveLenses>;
  onChange: (val: Partial<ICorrectiveLenses>) => void;
  isEditing?: boolean;
  patientTab?: boolean;
};

const CorrectiveLenses: FC<CorrectiveLensesProps> = (props) => {
  const sh = props.correctiveLenses;
  const oh = props.oldCorrectiveLenses;

  const onFieldChange: onChange = (field) => (newValue) => {
    props.onChange({ ...sh, [field]: newValue });
  };

  if (props.isEditing && !props.readOnly) {
    return (
      <Editor
        correctiveLenses={sh}
        oldCorrectiveLenses={oh}
        onFieldChange={onFieldChange}
        patientTab={props.patientTab}
      />
    );
  }
  return <View correctiveLensesSet={props.correctiveLensesSet} correctiveLenses={sh} />;
};

export default CorrectiveLenses;
