import React, { Component, Dispatch } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Badge,
  Navbar,
  Collapse,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
const { version } = require("../../../package.json");
const EMRLogo = require("../../images/emr-logo.png");
import MDBoxLogo from "../../images/Reliant-Logo.svg";
import Caret from "../../images/Caret.svg";
import VideoSetup from "../VideoSetup";
import {
  LOGOUT,
  WAITING_ROOM_LOAD_PENDING,
  WAITING_ROOM_LOAD_AVAILABLE,
  REFRESH_USER,
  ENCOUNTER_LOAD_UNSIGNED,
  REFRESH_TOKEN,
} from "../../constants/actionTypes";
import ClockIn from "../ClockIn";
import ProfileBubble from "../ProfileBubble";
import {
  PERMISSION_MANAGE,
  PERMISSION_PAGE,
  hasPermission,
  hasAnyPermission,
  PERMISSION_SEE_PATIENTS,
  PERMISSION_MONITOR,
  PERMISSION_MEDICAL_DIRECTOR,
  PERMISSION_TELEMEDICINE_EXTENDER,
  PERMISSION_BILLING,
  PERMISSION_SUPERVISING_PHYSICIAN,
  PERMISSION_SCRIBE,
  PERMISSION_RECENT_ENCOUNTER,
  PERMISSION_RECENT_ENCOUNTER_LIMITED,
} from "../../constants/Permissions";
import Acceptor from "../WaitingRoom/Acceptor";
import api from "../../api";
import SignatureCollector from "../SignatureCollector";
import PracticeSelector from "../PracticeSelector";
import { IAppState } from "../../reducer";
import AppReloadBtn from "../AppReloadBtn";
import { toast } from "react-toastify";
import {
  checkIsJSON,
  isBetaSite,
  showInWRNotification,
  showInIntakeNotification, errorLogger,
} from "../../utils";
import bugsnagClient from "../../services/bugsnag";

const mapStateToProps = (state: IAppState) => {
  return {
    currentPath: state.router.location.pathname,
    currentUser: state.common.currentUser,
    permissions: state.common.permissions,

    availablePatients: state.waitingRoom.available,
    availableLoaded: !!state.waitingRoom.availableLoaded,
    pendingPatients: state.waitingRoom.pending,
    pendingLoaded: !!state.waitingRoom.pendingLoaded,

    redNotificationBubbleNumber: state.unsignedEncounters.redNotificationBubbleNumber,
    currentAPI_EMRHash: state.common.currentAPI_EMRHash,
    reloadButton: state.common.reloadButton,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  logout: (payload) => dispatch({ type: LOGOUT, payload }),
  loadPending: () =>
    dispatch({ type: WAITING_ROOM_LOAD_PENDING, payload: api.Encounters.pending() }),
  loadAvailable: () =>
    dispatch({ type: WAITING_ROOM_LOAD_AVAILABLE, payload: api.Encounters.available() }),
  loadUnsignedEncounters: () =>
    dispatch({ type: ENCOUNTER_LOAD_UNSIGNED, payload: api.Encounters.unsignedEncounters() }),
  refreshToken: () => dispatch({ type: REFRESH_TOKEN, payload: api.Authorization.refreshToken() }),
});

const LinkItem = (props) => {
  return (
    <NavItem active={props.to === props.current}>
      <Link className="nav-link mt-3" to={props.to}>
        {props.children}
      </Link>
    </NavItem>
  );
};

interface NavBarState {
  isOpen: boolean;
  showVideoSetup: boolean;
  pendingLoaded: boolean;
  availableLoaded: boolean;
  appUpdate: boolean;
}

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class NavBar extends Component<Props, NavBarState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: true,
      showVideoSetup: false,
      pendingLoaded: false,
      availableLoaded: false,
      appUpdate: false,
    };
  }

  toggle = () => this.setState({ isOpen: !this.state.isOpen });

  logout = () => {
    this.props.logout(api.Availability.clockOut());
  };

  showVideoSetup = () => {
    this.setState({ showVideoSetup: true });
  };
  hideVideoSetup = () => {
    this.setState({ showVideoSetup: false });
  };

  componentDidMount() {
    // refresh pending and available patients in the waiting room
    if (
      !!this.props.permissions &&
      !!hasPermission(this.props.permissions, PERMISSION_SEE_PATIENTS)
    ) {
      try {
        this.props.loadPending();
      } catch (e: any) {
       errorLogger(e)
      }

      try {
        this.props.loadAvailable();
      } catch (e: any) {
        errorLogger(e)
      }

      try {
        this.props.loadUnsignedEncounters();
      } catch (e: any) {
        errorLogger(e);
      }
    }
  }

  componentDidUpdate(prevProps: Props) {
    const prevPendingPatients: any[] = prevProps.pendingPatients || [];
    const pendingPatients: any[] = this.props.pendingPatients || [];
    // filter only new patients that appear in the pending list
    const newPendingPatients = pendingPatients.filter((patient) => {
      return (
        prevPendingPatients.findIndex(
          (prevPatient) => prevPatient.referenceID === patient.referenceID,
        ) < 0
      );
    });
    const wrPendingLength = localStorage.getItem("wrPendingLength");

    if(Number(wrPendingLength) && (Number(wrPendingLength) < pendingPatients.length)) {
      if (newPendingPatients.length > 0) {
        // show notification for new pending patients, after the initial load
        showInIntakeNotification(this.props.currentUser)
      }
    }
    localStorage.setItem("wrPendingLength", String(pendingPatients.length))

    if (prevProps.pendingLoaded !== this.props.pendingLoaded) {
      this.setState({ pendingLoaded: this.props.pendingLoaded });
    }

    const prevAvailablePatients: any[] = prevProps.availablePatients || [];
    const availablePatients: any[] = this.props.availablePatients || [];
    // filter only new patients that appear in the available list
    const newAvailablePatients = availablePatients.filter((patient) => {
      return (
        prevAvailablePatients.findIndex(
          (prevPatient) => prevPatient.referenceID === patient.referenceID,
        ) < 0
      );
    });
    const wrAvailableLength = localStorage.getItem("wrAvailableLength");

    if(Number(wrAvailableLength) && (Number(wrAvailableLength) < availablePatients.length)) {

      if (newAvailablePatients.length > 0) {
        // show notification for new available patients, after the initial load
        showInWRNotification(this.props.currentUser);
      }
    }
    localStorage.setItem("wrAvailableLength", String(availablePatients.length))

    if (prevProps.availableLoaded !== this.props.availableLoaded) {
      this.setState({ availableLoaded: this.props.availableLoaded });
    }
  }

  render() {
    const isNursingHomeSite =
      !!this.props.currentUser && !!this.props.currentUser.isNursingHomeSite;

    const canRecentEncounter = hasAnyPermission(
      this.props.permissions,
      PERMISSION_RECENT_ENCOUNTER,
      PERMISSION_RECENT_ENCOUNTER_LIMITED,
    );
    const canManage = hasPermission(this.props.permissions, PERMISSION_MANAGE);
    const canProviderPage = hasPermission(this.props.permissions, PERMISSION_PAGE);
    const canSeePatients = hasPermission(this.props.permissions, PERMISSION_SEE_PATIENTS);
    const canMonitor = hasPermission(this.props.permissions, PERMISSION_MONITOR);
    const isDirector = hasPermission(this.props.permissions, PERMISSION_MEDICAL_DIRECTOR);
    const isScribe = hasPermission(this.props.permissions, PERMISSION_SCRIBE);
    const isSupervisingPhysician = hasPermission(
      this.props.permissions,
      PERMISSION_SUPERVISING_PHYSICIAN,
    );
    const isTelemedExtender = hasPermission(
      this.props.permissions,
      PERMISSION_TELEMEDICINE_EXTENDER,
    );
    const isBilling = hasPermission(this.props.permissions, PERMISSION_BILLING);

    const user = this.props.currentUser || {
      signatureUploaded: false,
      videoSetup: false,
      firstName: "",
      lastName: "",
    };
    const getSignature = this.props.currentUser && !user.signatureUploaded && !isScribe;
    const skipIntro = user && user.videoSetup;
    const availablecount = this.props.availablePatients ? this.props.availablePatients.length : 0;

    const scripts = document.querySelectorAll("script[src]");
    const currentScript = scripts[scripts?.length - 1];
    const currentEMRHash = currentScript.getAttribute("src")?.split(".")[1];

    const licensedStates = this.props?.currentUser?.capabilities?.geographic?.join(", ") || ["None"].toString();
    if(this.props.currentAPI_EMRHash){
      bugsnagClient.addMetadata('Additional info', { EMRHash: currentEMRHash, currentAPI_EMRHash: this.props.currentAPI_EMRHash });
    }
    return (
      <div>
        {getSignature && <SignatureCollector />}
        <Navbar color="light" light expand="md">
          <NavbarBrand to="/" tag={Link}>
            <div style={{ position: "relative" }}>
              <MDBoxLogo height={25} />
              {isBetaSite &&
              <div className="beta-logo-badge">
                <Badge color="success" pill>
                  Beta
                </Badge>
              </div>
              }
            </div>
          </NavbarBrand>
          <NavbarToggler onClick={this.toggle} className="mr-2" />
          <Collapse isOpen={this.state.isOpen} className="justify-content-center" navbar>
            {!!this.props.permissions.length && !isTelemedExtender && (
              <Nav navbar>
                {(canManage || canSeePatients || isScribe || canMonitor || isDirector || isSupervisingPhysician) && (
                <LinkItem current={this.props.currentPath} to="/">
                  Overview{" "}
                  {this.props.redNotificationBubbleNumber > 0 && (
                    <div className="unreadMessages-badge">
                      <Badge pill style={{ backgroundColor: "#FD6767" }}>
                        {this.props.redNotificationBubbleNumber}
                      </Badge>
                    </div>
                  )}
                </LinkItem>
                )}
                {canSeePatients && (
                  <LinkItem current={this.props.currentPath} to="/waiting-room">
                    <div style={{ position: "relative" }}>
                      Waiting Room{" "}
                      {availablecount > 0 && (
                        <div className="notification-badge">
                          <Badge pill style={{ backgroundColor: "#FD6767" }}>
                            {availablecount}
                          </Badge>
                        </div>
                      )}
                    </div>
                  </LinkItem>
                )}
                {(canManage || canSeePatients || isScribe) && (
                  <LinkItem current={this.props.currentPath} to="/patients">
                    Patients
                  </LinkItem>
                )}
                {isNursingHomeSite && (canManage || canSeePatients) && (
                  <LinkItem current={this.props.currentPath} to="/rounding">
                    Rounding
                  </LinkItem>
                )}
                {isBilling && (
                  <LinkItem current={this.props.currentPath} to="/billing">
                    Billing
                  </LinkItem>
                )}
                {(canProviderPage) && (
                  <LinkItem current={this.props.currentPath} to="/providers">
                    Providers
                  </LinkItem>
                )}

                {(canManage || isDirector) && false && (
                  <LinkItem current={this.props.currentPath} to="/verify-insurance">
                    Verify Insurance
                  </LinkItem>
                )}
                {canMonitor && (
                  <LinkItem current={this.props.currentPath} to="/activity-monitor">
                    Activity Monitor
                  </LinkItem>
                )}
                {canRecentEncounter && (
                  <LinkItem current={this.props.currentPath} to={`/encounter-monitor/`}>
                    Recent Encounters
                  </LinkItem>
                )}
              </Nav>
            )}
          </Collapse>
          <div className="justify-content-end" style={{ maxWidth: 300, width: "20%" }}>
            <PracticeSelector />
          </div>
          {this.props.currentAPI_EMRHash && this.props.currentAPI_EMRHash != currentEMRHash && (
            <div className="">
              <AppReloadBtn apiHash={this.props.currentAPI_EMRHash} emrHash={currentEMRHash} />
            </div>
          )}
          <div className="profile-dropdown">
            <UncontrolledDropdown inNavbar>
              <DropdownToggle nav>
                <ProfileBubble />
              </DropdownToggle>
              <DropdownMenu right>
                <Caret id="caret" />
                <DropdownItem disabled className="provider-name">
                  {user.firstName} {user.lastName}
                </DropdownItem>
                <DropdownItem disabled className="licensed-states">{`Licensed States: ${licensedStates}`}</DropdownItem>
                {canSeePatients && (
                  <DropdownItem className="d-flex justify-content-center">
                    <ClockIn />
                  </DropdownItem>
                )}
                <DropdownItem divider />
                <DropdownItem disabled>Profile</DropdownItem>
                <DropdownItem onClick={this.showVideoSetup}>Test video and audio</DropdownItem>
                <DropdownItem disabled>Help</DropdownItem>
                <DropdownItem onClick={this.logout}>Sign out</DropdownItem>
                <p
                  style={{ fontSize: 12, opacity: "50%", textAlign: "center" }}
                >{`Version: ${currentEMRHash}`}</p>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </Navbar>
        {this.state.showVideoSetup && !isScribe && (
          <VideoSetup skipInto={skipIntro} videoWorking={this.hideVideoSetup} />
        )}
        <Acceptor />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
