import React, { FC } from "react";

import { IUserDetails, IUserMedicalIntake } from "../../constants/Types";

type Props = {
  intake: Partial<IUserMedicalIntake>;
  symptoms: string[];
  patient: Partial<IUserDetails>;
};

const Contraindications: FC<Props> = ({ intake = {}, symptoms, patient }) => {
  const coronavirus: Partial<typeof intake.coronavirus> = intake.coronavirus || {};
  const contraindications = intake.contraindications || [];
  return (
    <div className="coronavirus-warning">
      <span className="coronavirus-redflag">Notice</span>
      <br />
      {coronavirus.coronavirusContact !== null && (
        <CoronavirusWarning intake={intake} symptoms={symptoms} />
      )}
      {contraindications.length > 0 && (
        <div className="contraindications">{contraindications.join(", ")}</div>
      )}
      {patient !== null && patient.covidMonitoring && (
        <div className="contraindications">
          <p>
            Patient enrolled in COVID-19 Symptom Monitoring Program. <br />
            To view patient inputs{" "}
            <a target="_blank" href={`/active-patient-chart/${patient.userID}`}>
              click here
            </a>
          </p>{" "}
        </div>
      )}
    </div>
  );
};

type PropsCoronavirusWarning = {
  intake: Partial<IUserMedicalIntake>;
  symptoms: string[];
};

const CoronavirusWarning: FC<PropsCoronavirusWarning> = ({ intake = {}, symptoms }) => {
  const coronavirus: Partial<typeof intake.coronavirus> = intake.coronavirus || {};

  const contactLookup = {
    yes: "Yes, that person has",
    no: "No, that person does not have",
  };
  const contact = contactLookup[coronavirus.coronavirusContactConfirmed!]
    ? contactLookup[coronavirus.coronavirusContactConfirmed!]
    : "Unknown whether that person has";
  const symptomsList = symptoms.length > 0 ? symptoms.join(", ") : "None";

  return (
    <>
      {!coronavirus.coronavirusContact ? (
        <span className="coronavirus-title">
          Patient reports no contact with person who has undergone testing for Coronavirus
          (COVID-19)
        </span>
      ) : (
        <>
          <span className="coronavirus-title">
            Patient reporting risk factors for novel coronavirus (COVID-19)
          </span>
          <ul>
            <li>{`Symptoms: ${symptomsList}`}</li>
            <li>
              Reports having been exposed to someone who was ill and underwent testing for novel
              coronavirus (COVID-19)
            </li>
            <li>{`${contact} laboratory-confirmed case of novel coronavirus (COVID-19)`}</li>
          </ul>
        </>
      )}
    </>
  );
};

export default Contraindications;
