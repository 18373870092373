import {
  ASYNC_START,
  ENCOUNTER_ENGAGE,
  ENCOUNTER_DETAILS,
  ENCOUNTER_START,
  ENCOUNTER_VIDEO_STOP,
  ENCOUNTER_COMPLETE,
  ASYNC_END,
  ENCOUNTER_ENGAGEMENT_STATUS,
  ENCOUNTER_ACCEPTED,
  ENCOUNTER_LOST,
  ENCOUNTER_LOAD_NOTE,
  ENCOUNTER_LOAD_NOTE_PDF,
  ENCOUNTER_NOTE_FIELD_UPDATE,
  ENCOUNTER_UPDATE_PROFILE,
  ENCOUNTER_SAVE_PROFILE,
  ENCOUNTER_UPDATE_INTAKE,
  ENCOUNTER_SAVE_INTAKE,
  WAITING_ROOM_ASSIGNED,
  WAITING_ROOM_CANCEL,
  ENCOUNTER_UPDATE_TEST_RESULT,
  ENCOUNTER_SAVE_PROVIDER_TEST_RESULT,
  ENCOUNTER_GET_PRESCRIPTION_LINK,
  ENCOUNTER_EJECTED,
  ENCOUNTER_USER_VIDEO_STATE,
  ENCOUNTER_USER_VIDEO_CONNECT,
  ENCOUNTER_USER_VIDEO_DISCONNECT,
  ENCOUNTER_PRESCRIPTION_UPDATE,
  ENCOUNTER_UNLOAD_NOTE_PDF,
  ENCOUNTER_ABANDON,
  ENCOUNTER_LOAD_TYTOCARE_LINK,
  WAITING_ROOM_AVAILABLE,
  INITIATE_NO_PATIENT_ENCOUNTER,
  ENCOUNTER_SAVED,
  ENCOUNTER_EXPLICIT_RESUME,
  ENCOUNTER_LOAD_BLANK_LTC_LINKS,
  ENCOUNTER_SAVE_MESSAGE,
  ENCOUNTER_ADDITIONAL_STATUS,
  LOGOUT,
} from "../constants/actionTypes";

import { USER_VIDEO_FINISHED } from "../constants/Encounter";

import { NOT_ENGAGED, IN_ENCOUNTER } from "../constants/Encounter";

import update from "immutability-helper";
import { Reducer } from "redux";
import {
  IEncounterDetailsResponse,
  IltcLinkMappings,
  IMedicalProfile,
  IProviderNote,
  IUserMedicalIntake,
} from "../constants/Types";

// TODO: New state properties have been added over time and we should set the "default" values for these new properties,
//       but we should do it at a future time when we refactor the encounter code, so we can extensively test these changes.
const defaultState = {
  encounterID: null,
  inProgress: false,
  videoStarted: false,
  engagementStatus: null,
  referenceID: null,
  encounterSaved: false,
  encounterExplicitResume: false,
  details: {},
  note: {},
  userVideoConnected: false,
  videoToken: "",
  roomName: "",
};

type EncounterState = {
  encounterID: null | string;
  inProgress: boolean;
  videoStarted: boolean;
  engagementStatus: null | string;
  referenceID: null | string;
  encounterSaved: boolean;
  encounterExplicitResume: boolean;
  details: Partial<IEncounterDetailsResponse>;
  note: Partial<IProviderNote>;
  userVideoConnected: boolean;
  storedProfile?: Partial<IMedicalProfile>;
  storedIntake?: Partial<IUserMedicalIntake>;
  noteLoaded?: boolean;
  prescriptionLink?: {
    link: string;
    errors?: string[];
  };
  resuming?: boolean;
  userVideoState?: string;
  providerVideoComplete?: boolean;
  videoWasStarted?: boolean;
  providerWasEjected?: boolean;
  ltcLinkMappings?: IltcLinkMappings;
  detailsLoaded?: boolean;
  message?: string;
  noteURL?: string;
  requisitionURL?: string;
  roomName: string;
  videoToken: string;
};

function isEncounterSubtype(action) {
  return (
    action.subtype == ENCOUNTER_ENGAGE ||
    action.subtype == ENCOUNTER_START ||
    action.subtype == ENCOUNTER_DETAILS ||
    action.subtype == ENCOUNTER_ENGAGEMENT_STATUS
  );
}

const encounterReducer: Reducer<EncounterState, any> = (state = defaultState, action) => {
  const payload = action.payload || {};
  switch (action.type) {
    case ENCOUNTER_ENGAGE:
      if (action.error) {
        return {
          ...state,
          encounterID: null,
          referenceID: action.referenceID,
          engagementStatus: NOT_ENGAGED,
          encounterSaved: false,
        };
      }
      return {
        ...state,
        encounterID: payload.encounterID,
        engagementStatus: payload.status,
        resuming: false,
        referenceID: action.referenceID,
      };
    case ENCOUNTER_DETAILS:
      // TODO: failures to load...
      return {
        ...state,
        details: action.payload,
        detailsLoaded: true,
        storedProfile: payload.medicalProfile,
        storedIntake: payload.medicalIntake,
        providerVideoComplete: payload.providerVideoComplete,
      };
    case ENCOUNTER_LOAD_NOTE:
      // TODO: failures to load...
      return {
        ...state,
        noteLoaded: true,
        note: action.payload,
      };
    case ENCOUNTER_LOAD_NOTE_PDF:
      return {
        ...state,
        noteURL: action.payload ? action.payload.url : null,
        requisitionURL: action.payload?.requisitionURL ? action.payload?.requisitionURL : null
      };
    case ENCOUNTER_SAVED:
      return {
        ...state,
        encounterSaved: action.flag,
        engagementStatus: NOT_ENGAGED,
        // clear the existing encounter details on save
        encounterID: null,
        referenceID: null,
        details: {},
        detailsLoaded: false,
        storedProfile: {},
        storedIntake: {},
        resuming: false,
        encounterExplicitResume: false,
      };
    case ENCOUNTER_EXPLICIT_RESUME:
      return {
        ...state,
        flag: false,
        encounterExplicitResume: true, // explicitly resuming the encounter
        encounterSaved: false, // reset the saved encounter flag
      };
    case ENCOUNTER_LOAD_TYTOCARE_LINK:
      return {
        ...state,
        tytocareURL: action.payload ? action.payload.reviewURL : null,
      };
    case ENCOUNTER_UNLOAD_NOTE_PDF:
      return {
        ...state,
        noteURL: null,
        requisitionURL: null
      };
    case ENCOUNTER_NOTE_FIELD_UPDATE:
      return {
        ...state,
        note: { ...state.note, [action.field]: action.value },
      };
    case ENCOUNTER_PRESCRIPTION_UPDATE:
      return {
        ...state,
        prescriptionData: action.payload,
      };
    case ENCOUNTER_UPDATE_PROFILE:
      return update(state, {
        details: { medicalProfile: { $merge: action.values } },
      });
    case ENCOUNTER_UPDATE_INTAKE:
      return update(state, {
        details: { medicalIntake: { $merge: action.values } },
      });
    case ENCOUNTER_UPDATE_TEST_RESULT:
      return update(state, {
        details: { testResult: { $set: action.values } },
      });
    case ENCOUNTER_SAVE_PROVIDER_TEST_RESULT:
      // TODO: If not error...
      return update(state, {
        details: {
          testResult: { providerResult: { recorded: { $set: true } } },
        },
      });
    case ENCOUNTER_ADDITIONAL_STATUS:
      // TODO: If not error...
      return update(state, {
        details: {
          additionalStatus: { $set: action.payload },
        },
      });
    case ENCOUNTER_SAVE_PROFILE:
      // TODO: Handle failure
      return {
        ...state,
        storedProfile: action.newProfile,
      };
    case ENCOUNTER_SAVE_INTAKE:
      // TODO: Handle failure
      return {
        ...state,
        storedIntake: action.newIntake,
      };
    case ENCOUNTER_START:
      if (action.error) {
        return state;
      }
      return {
        ...state,
        videoToken: payload.token,
        roomName: payload.roomName,
        acceptedAt: null,
        videoStarted: true,
        videoWasStarted: true,
      };

    case ENCOUNTER_SAVE_MESSAGE:
      return {
        ...state,
        message: action.message,
      };
    case ENCOUNTER_VIDEO_STOP:
      return {
        ...state,
        videoToken: "",
        videoStarted: false,
        providerVideoComplete: true,
      };
    case ENCOUNTER_ENGAGEMENT_STATUS:
      return {
        ...state,
        engagementStatus: payload.status,
        encounterID: payload.encounterID,
        referenceID: payload.referenceID,
        resuming: action.resuming,
        userVideoState: payload.userVideoState,
        providerVideoComplete: payload.providerVideoComplete,
      };
    case INITIATE_NO_PATIENT_ENCOUNTER:
      return {
        ...state,
        engagementStatus: payload.status,
        encounterID: payload.encounterID,
        referenceID: payload.referenceID,
        userVideoState: payload.userVideoState,
        providerVideoComplete: payload.providerVideoComplete,
        encounterSaved: false,
      };
    case ENCOUNTER_ACCEPTED:
      return {
        ...state,
        engagementStatus: IN_ENCOUNTER,
        encounterID: action.payload ? action.payload.encounterID : state.encounterID,
        encounterWasAccepted: true,
        acceptedAt: new Date().getTime(),
        resuming: action.resuming,
        providerWasEjected: false,
      };
    case ENCOUNTER_ABANDON:
    case ENCOUNTER_COMPLETE:
      // TODO: check for errors, like it failed to complete...
      return {
        ...defaultState,
        engagementStatus: NOT_ENGAGED,
        encounterSaved: false,
        resuming: false,
        encounterExplicitResume: false,
      };
    case ENCOUNTER_GET_PRESCRIPTION_LINK:
      return { ...state, prescriptionLink: action.payload };
    case WAITING_ROOM_ASSIGNED:
    case WAITING_ROOM_CANCEL:
      let encounter = action.encounter;
      if (action.toMe) {
        return state;
      }
      if (state.referenceID && state.referenceID === encounter.referenceID) {
        return {
          ...defaultState,
          encounterID: null,
          referenceID: null,
          engagementStatus: NOT_ENGAGED,
          // Message needs to be set.... Probably in waiting room state...
        };
      }
      return state;
    case ENCOUNTER_LOST:
      return {
        ...defaultState,
        encounterID: null,
        referenceID: null,
        engagementStatus: NOT_ENGAGED,
        resuming: false,
        encounterExplicitResume: false,
      };
    case WAITING_ROOM_AVAILABLE:
      if (action.encounter.referenceID === state.referenceID) {
        // This provider was given the boot.
        return {
          ...defaultState,
          message: state.message,
          encounterID: null,
          referenceID: null,
          resuming: false,
          engagementStatus: NOT_ENGAGED,
          providerWasEjected: true,
        };
      }
      return state;
    case ENCOUNTER_EJECTED:
      if (payload.referenceID !== state.referenceID) {
        return state;
      }
      return {
        ...defaultState,
        encounterID: null,
        referenceID: null,
        resuming: false,
        engagementStatus: NOT_ENGAGED,
      };
    case ENCOUNTER_USER_VIDEO_STATE:
      return {
        ...state,
        userVideoState: payload.userVideoState,
        userVideoConnected:
          payload.userVideoState == USER_VIDEO_FINISHED ? false : state.userVideoConnected,
      };
    case ENCOUNTER_USER_VIDEO_CONNECT:
      return {
        ...state,
        userVideoConnected: true,
      };
    case ENCOUNTER_USER_VIDEO_DISCONNECT:
      return {
        ...state,
        userVideoConnected: false,
      };
    case ENCOUNTER_LOAD_BLANK_LTC_LINKS:
      return {
        ...state,
        ltcLinkMappings: action.payload,
      };
    case ASYNC_START:
      if (isEncounterSubtype(action)) {
        return { ...state, inProgress: true };
      }
      return state;
    case ASYNC_END:
      if (isEncounterSubtype(action)) {
        return { ...state, inProgress: false };
      }
      return state;
    case LOGOUT:
      if (state?.details?.asyncChat) {
        return { ...defaultState, message: state.message };
      }
      // on logout, reset the encounter state back to the default state
      return defaultState;
    default:
      return state;
  }
};

export default encounterReducer;
