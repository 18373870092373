import moment from "moment";

export const NOT_ENGAGED = "NotEngaged";
export const WAITING_ON_USER = "WaitingOnUser";
export const NOT_CLOCKED_IN = "NotClockedIn";
export const IN_ENCOUNTER = "InEncounter";

export const USER_VIDEO_NOT_STARTED = "not-started";
export const USER_VIDEO_STARTED = "started";
export const USER_VIDEO_FINISHED = "finished";

const range = (start: any, stop: any, step: any) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

export const visitNameLookup = {
  strep: "Strep",
  uti: "UTI",
  flu: "Flu",
  conjunctivitis: "Conjunctivitis",
  gastroenteritis: "Gastroenteritis",
  sinusitis: "Sinusitis",
  contraceptives: "Contraceptives",
  rash_and_allergy: "Rash_and_allergy",
  herpes: "Herpes",
  coronavirus: "Coronavirus",
  covid_drive_through: "Coronavirus",
  covid_at_home_pcr: "Coronavirus",
};

export const waitingRoomVisitTypeLookup = {
  strep: "Sore throat",
  uti: "Urinary symptoms",
  flu: "Cold/Flu symptoms",
  conjunctivitis: "Pink eye symptoms",
  gastroenteritis: "Vomiting & diarrhea",
  sinusitis: "Sinus pain & pressure",
  contraceptives: "Birth control",
  rash_and_allergy: "Rash & allergy",
  herpes: "Herpes symptoms",
  coronavirus: "Coronavirus (COVID-19) consult",
  covid_drive_through: "COVID-19 Drive Thru Testing",
  covid_at_home_pcr: "SARS-CoV2 at home PCR consult",
};

// Info Edit Section Titles

export const sectionTitles = {
  currentSymptomsSection: "Current Symptoms",
  eyeAllergySymptomsSection: "Eye Allergy Symptoms",
  noseAllergySymptomsSection: "Nose Allergy Symptoms",
  skinAllergySymptomsSection: "Skin Allergy Symptoms",
  LungAllergySymptomsSection: "Lung Allergy Symptoms",
  illContactsSection: "Ill Contacts",
  travelHistorySection: "Travel History",
  testingReasonSection: "Reason for Testing",
  conditionHistorySection: "Condition History",
  medicationsTriedSection: "Medications Tried",
  eyeAllergyMedicationsTriedSection: "Eye Allergy Medications Tried",
  noseAllergyMedicationsTriedSection: "Nose Allergy Medications Tried",
  skinAllergyMedicationsTriedSection: "Skin Allergy Medications Tried",
  lungAllergyMedicationsTriedSection: "Lung Allergy Medications Tried",
  reviewOfSystemsSection: "ROS",
  allergyEnvironmentalEffectsSection: "Allergy Environmental Effects",
  allergySocialHistorySection: "Allergy Social History",
  medicalHistorySection: "Medical History",
  conditionSpecificIntakeSection: "Condition-Specific Intake",
  pertinentMedicationsSection: "Pertinent Medications",
  providerNotesSection: "providerNotesSection",
  FamilyMedicalHistory: "FamilyMedicalHistory",
};

// Intake Fields
export const medicalHistoryFields = [
  "allergies",
  "medicalHistoryKeys",
  "surgicalHistoryKeys",
  "medications",
  "socialHistoryV2",
  "correctiveLenses",
  "womensHealth",
  "providerNotes",
  "FamilyMedicalHistory",
];

export const medicalIntakeFields = [
  "reviewOfSystemsKeys",
  "symptomDetailsKeys",
  "currentSymptomsBySystem",
  "currentSymptoms",
  "pertinentMedications",
  "reviewOfSystemsWritten",
  "reviewOfSystemsGotSSC",
];

export const eyeAllergyIntakeFields = ["allergyEyesSymptoms", "allergyEyesSymptomsBySystem"];
export const noseAllergyIntakeFields = ["allergyNoseSymptoms", "allergyNoseSymptomsBySystem"];
export const skinAllergyIntakeFields = ["allergySkinSymptoms", "allergySkinSymptomsBySystem"];
export const LungAllergyIntakeFields = ["allergyLungsSymptoms", "allergyLungsSymptomsBySystem"];

export const allergyVisitType = "rash_and_allergy";

let days = Array.from({ length: 6 }, (x, i) => ({
  value: i + " Days",
  label: i + " Days",
}));
let weeks = Array.from({ length: 51 }, (x, i) => ({
  value: i + " Weeks",
  label: i + " Weeks",
}));
let years = Array.from({ length: 10 }, (x, i) => ({
  value: i + " Years",
  label: i + " Years",
}));

export const symptomDurationMap = {
  0: years,
  1: weeks,
  2: days,
};

// Allergy Environmental Effects Constants
export const allergyEnvironmentalEffects = {
  allergyEffectTimes: [
    "Intermittent",
    "Seasonal-Fall",
    "Seasonal-Winter",
    "Seasonal-Spring",
    "Seasonal-Summer",
    "Year-round",
  ],
  pets: ["Dogs", "Cats", "Birds", "Rabbits", "Horses", "Rodents"],
};

// Alergy History Constants
export const allergyHistory = {
  pets: ["Dog", "Cat", "Bird", "Horse", "Mouse", "Rat", "Gerbil"],
  childrenInDaycare: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  missedDays: Array.from({ length: 36 }, (v, k) => k),
  residenceBuildingTypes: ["House", "Townhouse", "Condominium", "Apartment"],
  residenceLocations: ["City", "Suburb", "Countryside"],
  residenceConstructionYears: Array.from({ length: 150 }, (v, k) => k + 1869 + ""),
  numberOfPlant: Array.from({ length: 100 }, (v, k) => k),
  residenceHeatingTypes: [
    "Forced hot air",
    "Hot water",
    "Steam space heater",
    "Baseboard",
    "Electric ceiling",
    "None",
  ],
  residenceACTypes: ["Central air conditioning", "Individual unit air conditioning", "None"],
  flooringTypes: ["Wall-to-wall carpet", "Wood", "Ceramic", "Synthetic", "Tile"],
  bedTypes: ["Innerspring", "Foam rubber", "Cotton", "Waterbed", "Airbed", "Other"],
  beddingAge: Array.from({ length: 50 }, (v, k) => k),
  pillowTypes: ["Fiber", "Foam", "Feather", "Dacron", "Other"],
  hobbyMaterials: [
    ["Chemicals", "Clay", "Mold"],
    ["Paints", "Metals", "Wood"],
    ["Ceramic", "None"],
  ],
};

// Women's Health History
export const womensHealthHistory = {
  timesPregnantOptions: range(1, 20, 1),
  timesDelivered: range(0, 20, 1),
  timeOnOralBC: ["One month", "Several months", "Months", "One year", "More than one year"],
  contraceptionOptions: [
    "Condoms",
    "Diaphragm",
    "Spermicide",
    "Abstinence",
    "Natural family planning",
    "IUD",
    "Birth control patch",
    "Birth control pills",
    "Birth control vaginal ring",
    "Depoprovera injections",
    "Sterilization of partner",
    "N/A",
  ],
};

// vitals
export const saO2Options = {
  "Room Air": "room air",
  "____ L/min of oxygen": "L/min of oxygen",
};

export const BPPositionOptions = {
  Sitting: "Sitting",
  "Laying Down": "Laying Down",
  Standing: "Standing",
};

export const BPArmOptions = { "Right Arm": "right", "Left Arm": "left" };

export const BPArmReadOptions = { right: "Right Arm", left: "Left Arm" };

export const heightFTOptions = [
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
];

export const heightINOptions = [
  { label: "0", value: "0" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
  { label: "9", value: "9" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
];

export const litersPerMinOxygenOptions = [
  { label: "0", value: "0" },
  { label: "0.5", value: "0.5" },
  { label: "1", value: "1" },
  { label: "1.5", value: "1.5" },
  { label: "2", value: "2" },
  { label: "2.5", value: "2.5" },
  { label: "3", value: "3" },
  { label: "3.5", value: "3.5" },
  { label: "4", value: "4" },
  { label: "4.5", value: "4.5" },
  { label: "5", value: "5" },
  { label: "5.5", value: "5.5" },
  { label: "6", value: "6" },
  { label: "6.5", value: "6.5" },
  { label: "7", value: "7" },
  { label: "7.5", value: "7.5" },
  { label: "8", value: "8" },
  { label: "8.5", value: "8.5" },
  { label: "9", value: "9" },
  { label: "9.5", value: "9.5" },
  { label: "10", value: "10" },
  { label: "10.5", value: "10.5" },
  { label: "11", value: "11" },
  { label: "11.5", value: "11.5" },
  { label: "12", value: "12" },
  { label: "12.5", value: "12.5" },
  { label: "13", value: "13" },
  { label: "13.5", value: "13.5" },
  { label: "14", value: "14" },
  { label: "14.5", value: "14.5" },
  { label: "15", value: "15" },
];

// Ill contacts

export const relevantDiseaseLookup = {
  uti: "STD",
  flu: "flu",
  strep: "strep",
  conjunctivitis: "conjunctivitis",
  sinusitis: "sinusitis",
  gastroenteritis: "gastroenteritis",
  herpes: "Herpes",
  coronavirus: "flu",
  covid_drive_through: "flu",
  coronavirus_consult_and_testing: "coronavirus",
  covid_at_home_pcr: "coronavirus",
};

export const userVisibleDiseaseNames = {
  uti: "an STD",
  flu: "the flu",
  strep: "Strep",
  conjunctivitis: "pink eye symptoms",
  sinusitis: "a cold, respiratory infection, or the flu",
  gastroenteritis: "vomiting and diarrhea",
  herpes: "Herpes",
  coronavirus: "the flu",
  covid_drive_through: "the flu",
  coronavirus_consult_and_testing: "COVID-19",
  covid_at_home_pcr: "COVID-19",
};

// additionalStatus constants
export const STOP_PRESCRIPTION = "Stop Prescription";
export const STOP_PRESCRIPTION_NO = "Stop Prescription + No";
export const STOP_PRESCRIPTION_NEW = "Stop Prescription + New";
export const DECLINED = "declined";
export const NO_PRESCRIPTION = "No Prescription";
export const PRESCRIPTION_WRITTEN = "Prescription Written";
export const NEW_PRESCRIPTION = "New Prescription";

export const initialPresStatus = [
  { label: "No Rx - Patient Denied", value: "No Prescription - Patient Denied" },
  { label: "No Rx - Provider Denied", value: "No Prescription - Provider Denied" },
];
export const followUpPresStatus = [{ label: "No Rx this visit", value: "No Prescription" }];

export function GetStatusString(status: string): { statusString: string; error: boolean } {
  let statusString: string;
  let error = false;
  switch (status) {
    case "Entered":
      statusString = "Entered in Dosespot";
      break;
    case "Printed":
      statusString = "Printed in Dosespot";
      break;
    case "Sending":
      statusString = "Sending in Dosespot";
      break;
    case "eRxSent":
      statusString = "eRx Sent in Dosespot";
      break;
    case "EpcsError":
    case "EpcsSigned":
    case "Error":
      error = true;
      statusString = "Dosespot encountered an error in submitting. Please try again.";
      break;
    case "Deleted":
      statusString = "Deleted in Dosespot";
      break;
    case "Requested":
      statusString = "Deleted in Dosespot";
      break;
    case "Edited":
      statusString = "Edited in Dosespot";
      break;
    case "ReadyToSign":
      statusString = "Ready To Sign in Dosespot";
      break;
    case "PharmacyVerified":
      statusString = "Pharmacy Verified in Dosespot";
      break;
    default:
      error = true;
      statusString = "Unknown Status";
  }

  return { statusString, error };
}

export const statuses = [
  { label: "In-Progress", value: "in-progress" },
  { label: "Canceled", value: "canceled" },
  { label: "Pending", value: "pending" },
  { label: "Available", value: "available" }, 
  { label: "Aborted", value: "aborted" },
  { label: "Finished", value: "finished" },
  { label: "Postponed", value: "postponed" },
];

export const timeLine = [
  {label: "visit-finished", value: "visit-finished"},
  { label: "scribe-encounters-started", value: "scribe-encounters-started" },
  { label: "scribe-encounters-saved", value: "scribe-encounters-saved" },
  { label: "scribe-encounters-transferred-to-provider", value: "scribe-encounters-transferred-to-provider" },
  { label: "provider-no-user-encounters-started", value: "provider-no-user-encounters-started" },
  { label: "provider-encounters-postponed" , value: "provider-encounters-postponed" },
  { label: "provider-encounters-saved", value: "provider-encounters-saved" },
  { label: "encounter-assignment", value: "encounter-assignment" },
  { label: "provider-encounters-started", value: "provider-encounters-started" },
  { label: "provider-encounters-prepared", value: "provider-encounters-prepared" },
  { label: "provider-encounters-engaged", value:"provider-encounters-engaged" },
  { label: "provider-encounters-video-completed", value: "provider-encounters-video-completed" },
  {label: "provider-encounters-video-room-logger", value: "provider-encounters-video-room-logger"},
  { label: "provider-encounters-abandoned", value: "provider-encounters-abandoned" },
  { label: "provider-encounters-finished", value: "provider-encounters-finished" },
  { label: "provider-encounters-aborted", value: "provider-encounters-aborted" },
  { label: "provider-signed-note", value: "provider-signed-note" },
  { label: "provider-prescribed", value: "provider-prescribed" },
  {label: "provider-ended-video", value: "provider-ended-video"},
  {label: "provider-assigned", value: "provider-assigned"},
  {label: "patient-ended-video", value: "patient-ended-video"},
  {label: "patient-canceled-visit", value: "patient-canceled-visit"},
  {label: "patient-finished", value: "patient-finished"},
  { label: "patient-in-waiting-room", value: "patient-in-waiting-room" },
  { label: "patient-exited-waiting-room", value: "patient-exited-waiting-room" },
  { label: "users-archive", value: "users-archive" },
  {label: "user-offline", value: "user-offline"},
  {label: "user-online", value: "user-online"},
  { label: "user-encounters-initialized-video", value: "user-encounters-initialized-video" },
  { label: "user-encounters-initialized-chat", value: "user-encounters-initialized-chat" },
  { label: "user-encounters-prepared", value: "user-encounters-prepared" },
  { label: "user-encounters-ready", value: "user-encounters-ready" },
  { label: "user-encounters-started", value: "user-encounters-started" },
  { label: "user-encounters-resumed", value: "user-encounters-resumed" },
  { label: "user-encounters-rescheduled", value: "user-encounters-rescheduled" },
  { label: "user-encounters-postponed", value: "user-encounters-postponed" },
  { label: "user-encounters-started-video", value: "user-encounters-started-video"},
  { label: "user-encounters-finished-video", value: "user-encounters-finished-video" },
  { label: "user-encounters-cancel", value: "user-encounters-cancel" },
  { label: "user-encounters-finished", value: "user-encounters-finished" },
  { label: "user-encounters-iframe-logger", value: "user-encounters-iframe-logger" },
  { label: "user-encounter-schedule-cancel-appointment", value: "user-encounter-schedule-cancel-appointment" },
  {label: "user-encounters-iframe-logger", value: "user-encounters-iframe-logger"},
  { label: "task-eject-provider", value: "task-eject-provider" },
  { label: "task-encounter-monitor-postponed", value: "task-encounter-monitor-postponed" },
  { label: "user-encounters-other-intake", value: "user-encounters-other-intake" },
];


export const defaultParams = {
  pageSize: 50,
  pageStart: 0,
  patientName: "",
  practiceName: "",
  chiefComplaint: "",
  location:[],
  method: [],
  visitStatus: [],
  providerName: "",
  newMessages: "",
  startedFrom: "",
  startedTo: "",
  prescribedFrom: "",
  lastContactFrom: "",
  lastContactTo: "",
  prescribedTo: "",
  scribedFrom: "",
  scribedTo: "",
  signedNoteFrom: "",
  signedNoteTo: "",
  patientNumOfAttempts: "",
  patientNumOfAttemptsOperator: "",
  patientExitedWaitingRoomPrematured: "",
  neverEnteredWaitingRoom: "",
  encounterSuccessfullyFinished: "",
  patientWaitedTime: null,
  patientWaitedTimeOperator: "",
  latestVisitStatus: [],
  universal: "",
  encounterId: "",
  providerVideoChatTimeOperator: "",
  providerVideoChatTime: "",
  page: 0,
  sortOrder: "descending",
  sortBy: "timestamp",
};

export const defaultChartParams = {
  patientName: "",
  practiceName: "",
  chiefComplaint: "",
  location: [],
  method: [],
  visitStatus: [],
  providerName: "",
  newMessages: "",
  startedFrom: "",
  startedTo: "",
  prescribedFrom: "",
  lastContactFrom: "",
  lastContactTo: "",
  prescribedTo: "",
  scribedFrom: "",
  scribedTo: "",
  signedNoteFrom: "",
  signedNoteTo: "",
  patientNumOfAttempts: "",
  patientNumOfAttemptsOperator: "",
  patientExitedWaitingRoomPrematured: "",
  neverEnteredWaitingRoom: "",
  encounterSuccessfullyFinished: "",
  patientWaitedTime: null,
  patientWaitedTimeOperator: "",
  latestVisitStatus: [],
  universal: "",
  encounterId: "",
  providerVideoChatTimeOperator: "",
  providerVideoChatTime: "",
  page: 0,
}

export const defaultColumns = [
  "timestamp",
  "patientName",
  "providerName",
  "medicalPracticeName",
  "location",
  "chiefComplaint",
  "patientWaitedTime",
  "communicationMethod",
  "latestVisitStatus",
  "status",
  "providerVideoChatTime",
];


export const columnOptions = [
  'patientName', 
  'location', 
  'patientSex', 
  'patientLanguage', 
  'patientAge', 
  'patientReliantID', 
  'patientPCCID', 
  'providerName',
  'extender', 
  'scribe', 
  'medicalPracticeName', 
  'chiefComplaint', 
  'patientWaitedTime', 
  'communicationMethod', 
  'latestVisitStatus', 
  'status', 
  'newMessages', 
  'lastContact', 
  'prescribedDate', 
  'pharmacyName', 
  'medicationPerscribed', 
  'prescriptionRXCUI', 
  'viaFormulary', 
  'scribeDate', 
  'outcome', 
  'effectiveDate', 
  'suggestedFollowUpDate', 
  'ICD10', 
  'CPTCode', 
  'visitBillingLocation', 
  'patientPrimaryPayer', 
  'billedInsurance', 
  'patientCopay', 
  'promoCodeUsed',
  'chargeAmountToPatient', 
  'visitType', 
  'followUp', 
  'healthGorillaSessions', 
  'clinicalServicesLocation', 
  'organization', 
  'organizationType', 
  'totalActiveChartingTime', 
  'providerVideoChatTime', 
  'patientExitedWaitingRoomPrematured', 
  'neverEnteredWaitingRoom', 
  'patientNumOfAttempts', 
  'signedDate', 
  'timestamp', 
  'encounterId'
]


export const methods = [
  { label: "Chat", value: "Chat" },
  { label: "Video", value: "Video" },
  { label: "- -", value: "N/A" },
];
export const newMessages = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];
export const exitedWaitingRoomPrem = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];
export const neverEnteredWaitingRoom = [
  { label: "Yes", value: false },
  { label: "No", value: true },
];

export const timeOperator = [
  { label: "More", value: "more" },
  { label: "Less", value: "less" },
 
];


export const providerResources = [
  {label: "Awesome Woman", value: [
  {label: "Training Content", value: "https://docs.google.com/presentation/d/1K2g9kOTnIhNx8rkumKtzTfbcPXSdIAQQ/edit#slide=id.p1"},
  {label: "Awesome Woman Patient Flow", value: "https://drive.google.com/drive/folders/0AM7y3lGCUbyAUk9PVA"},
 ]
},
  {label: "COVID-19 Anti-viral Initial Visit Flow", value: "https://drive.google.com/drive/folders/0AM7y3lGCUbyAUk9PVA"},
  {label: "First Hero", value: "https://docs.google.com/document/d/10ZmbGb0nnlFc-oRQ9WjNTJP0lU1lJnvJ/edit"},
  {label: "RedBoxRx Medications & Protocols", value: "https://docs.google.com/spreadsheets/d/1VB2HWCfe6aDiam8H9zP70Tkz_d_CvGLFOZN7nlGiZtU/edit#gid=1815772299"},
  {label: "Reliant Resources", value: [
    {label: "Patient Questions & Education Templates", value: "https://docs.google.com/document/d/1eO4YDwLNBuXPiPqmQ4REZiU-xxww9KK3rg5Z6oqj2Uc/edit"},
    {label: "Provider Handbook", value: "https://docs.google.com/document/d/1S41vlhXuZP9zDp7ABadaaB42TCmSQdbX/edit"},
    {label: "Ispring Learning | Grand Rounds | NewsLetters", value: "https://reliant.ispringlearn.com/"},
 
  ]
},
  {label: "Screening Questions", value: [
  {label: "GAD-7", value: "https://www.mdcalc.com/calc/1727/gad7-general-anxiety-disorder7"},
  {label: "PHQ-9", value: "https://www.mdcalc.com/calc/1725/phq9-patient-health-questionnaire9"},
  {label: "ADHD-Part A", value: "https://addadult.com/test/online-adhd-screening-test-part-a/"},
  {label: "ADHD-Part B", value: "https://addadult.com/test/online-adult-adhd-screening-test-part-b/"},
  {label: "Insomnia ISI", value: "https://mindside.sg/Online-Insomnia-Severity-Index"},]},
  {label: "Suicide Prevention", value: "https://docs.google.com/document/d/14OVi4zJyERiXBec3PSkAfVb1zaa6yLlRyKCU3NmF_-8/edit"}
]

export const chartsTimeFrameOptions = [
  {label: "Last 7 Days", value: {startedFrom: moment().subtract(7, 'days').format("YYYY-MM-DD"), startedTo: moment().format("YYYY-MM-DD")}},
  {label: "Last 14 Days", value: {startedFrom: moment().subtract(14, 'days').format("YYYY-MM-DD"), startedTo: moment().format("YYYY-MM-DD")}},
  {label: "Last 30 Days", value: {startedFrom: moment().subtract(30, 'days').format("YYYY-MM-DD"), startedTo: moment().format("YYYY-MM-DD")}},
  {label: "Last 60 Days", value: {startedFrom: moment().subtract(60, 'days').format("YYYY-MM-DD"), startedTo: moment().format("YYYY-MM-DD")}},
  {label: "Last 90 Days", value: {startedFrom: moment().subtract(90, 'days').format("YYYY-MM-DD"), startedTo: moment().format("YYYY-MM-DD")}},
  {label: "Last 6 Months", value: {startedFrom: moment().subtract(6, 'months').format("YYYY-MM-DD"), startedTo: moment().format("YYYY-MM-DD")}},
  {label: "Last 12 Months", value: {startedFrom: moment().subtract(12, 'months').format("YYYY-MM-DD"), startedTo: moment().format("YYYY-MM-DD")}},
]

