import React, { Component } from "react";
import { connect } from "react-redux";
import * as tv from "twilio-video";
import { Button, Col, Row } from "reactstrap";
import ErrorList from "./ErrorList";
import AudioAnalyser from "./AudioAnalyser";

import "../stylesheets/videoSetup.scss";
import VideoSetupIntro from "../images/VideoSetup.svg";
import AllowArrow from "../images/AllowArrow.svg";
import VideoIcon from "../images/VideoIcon.svg";
import SpeakerIcon from "../images/SpeakerIcon.svg";
import { VIDEO_SETUP_COMPLETE } from "../constants/actionTypes";
import api from "../api";

const micIcon = require("../images/micIcon.png");

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: state.common.currentUser,
    ...ownProps,
  };
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
const mapDispatchToProps = (dispatch) => ({
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'payload' implicitly has an 'any' type.
  videoSetupComplete: (payload) => dispatch({ type: VIDEO_SETUP_COMPLETE, payload }),
});

const STEP_INTRO = "STEP_INTRO";
const STEP_VIDEO_CHECK = "STEP_VIDEO_CHECK";
const STEP_CONTACT_SUPPORT = "STEP_CONTACT_SUPPORT";

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'onNext' implicitly has an 'any' t... Remove this comment to see the full error message
const Introduction = ({ onNext }) => {
  return (
    <div className="video-setup-intro d-flex flex-column align-items-center">
      <h1>
        Welcome to MDBox's <strong>Provider Dashboard!</strong>
      </h1>
      <VideoSetupIntro />
      <div>It looks like you haven't set up your video yet.</div>
      <div>You must set it up before taking any patients. </div>
      <Button color="primary" onClick={onNext}>
        {" "}
        Set up video &gt;&gt;
      </Button>
    </div>
  );
};

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const InstructionStep = ({ children, number, active, className }) => {
  let cl = `instruction-step d-flex ${className}`;
  if (active === number) {
    cl = `${cl} active`;
  } else {
    cl = `${cl} pending`;
  }
  return (
    <div className={cl}>
      <div className="instruction-number">{number}.</div>
      <div className="instruction-content">{children}</div>
    </div>
  );
};

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'segment' implicitly has an 'any' ... Remove this comment to see the full error message
const ProgressSegment = ({ segment, step }) => {
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'step' implicitly has an 'any' typ... Remove this comment to see the full error message
  const cl = step >= segment ? "progress-segment filled" : "progress-segment";
  return <div className={cl} />;
};

class VideoCheck extends Component {
  constructor(props) {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
    super(props);
    this.state = { previewStarted: false, step: 1 };
    this.startVideoPreview = this.startVideoPreview.bind(this);
  }

  componentWillUnmount() {
    if (this.state.localTracks) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'localTracks' does not exist on type 'Rea... Remove this comment to see the full error message
      this.state.localTracks.forEach(function(track) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'localTracks' does not exist on type 'Rea... Remove this comment to see the full error message
        track.stop();
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'track' implicitly has an 'any' type.
        track.detach().forEach(function(detachedElement) {
          detachedElement.remove();
        });
      });
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'detachedElement' implicitly has an 'any... Remove this comment to see the full error message
      this.setState({ localTracks: [] });
    }
  }

  startVideoPreview() {
    this.getMicrophone();
    this.setState({ step: 2, error: null });
    this.createLocalTracks();
    setTimeout(() => this.nextStep(), 3000);
  }

  async getMicrophone() {
    const audio = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    });
    this.setState({ audio });
  }

  nextStep() {
    this.setState({ step: this.state.step + 1 });
  }

  createLocalTracks() {
    const setError = (error) => {
      this.setState({ error: error });
    };
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'step' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    const localMediaContainer = document.getElementById("local-tracks");
    if (!localMediaContainer) {
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'error' implicitly has an 'any' type.
      return;
    }

    tv.createLocalTracks({
      audio: true,
      video: true,
    }).then(
      (localTracks) => {
        localTracks.forEach(function(track) {
          localMediaContainer.appendChild(track.attach());
        });
        this.setState({ localTracks: localTracks, previewStarted: true });
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'localTracks' implicitly has an 'any' ty... Remove this comment to see the full error message
      },
      (error) => setError(error.message),
    );
  }

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'track' implicitly has an 'any' type.
  playNote = () => {
    const context = new AudioContext();
    const osc1 = context.createOscillator();
    const volume = context.createGain();
    const duration = 0.5;
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'error' implicitly has an 'any' type.
    const startTime = context.currentTime;

    osc1.type = "triangle";

    volume.gain.value = 0.1;

    osc1.connect(volume);

    volume.connect(context.destination);

    osc1.start(startTime);
    osc1.stop(startTime + duration);
  };

  render() {
    return (
      <div className="video-check">
        <Row className="mb-3">
          <Col md={5}>
            <h1> Video Setup </h1>
          </Col>
          <Col md={7}>
            <div className="setup-progress-bar d-flex">
              <ProgressSegment segment={1} step={this.state.step} />
              <ProgressSegment segment={2} step={this.state.step} />
              <ProgressSegment segment={3} step={this.state.step} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={5}>
            {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'step' does not exist on type 'Readonly<{... Remove this comment to see the full error message */}
            {!this.state.previewStarted && (
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'step' does not exist on type 'Readonly<{... Remove this comment to see the full error message
              <div className="video-preview d-flex flex-column justify-content-center align-items-center">
                {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'step' does not exist on type 'Readonly<{... Remove this comment to see the full error message */}
                <Button onClick={this.startVideoPreview}>Start Video</Button>
              </div>
            )}
            {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'previewStarted' does not exist on type '... Remove this comment to see the full error message */}
            <div id="local-tracks" />
            <ErrorList className="mt-5" errors={[this.state.error]} />
          </Col>
          <Col md={7}>
            <InstructionStep number={1} active={this.state.step}>
              <p>
                {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
                Click "Start Video" to the left. A drop down in the top left of your browser should
                appear.
              </p>
              {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'className' is missing in type '{ childre... Remove this comment to see the full error message */}
            </InstructionStep>
            {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'step' does not exist on type 'Readonly<{... Remove this comment to see the full error message */}
            <InstructionStep number={2} active={this.state.step}>
              <p>Press "Allow" if asked for permission</p>
              {this.state.step === 2 && (
                <div className="allow-arrow">
                  {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'className' is missing in type '{ childre... Remove this comment to see the full error message */}
                  <AllowArrow />
                </div>
              )}
              {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'step' does not exist on type 'Readonly<{... Remove this comment to see the full error message */}
            </InstructionStep>
            <InstructionStep number={3} active={this.state.step}>
              {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'step' does not exist on type 'Readonly<{... Remove this comment to see the full error message */}
              <div className="d-flex align-items-stretch">
                <div>
                  <VideoIcon className="icon" />
                  {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'className' is missing in type '{ childre... Remove this comment to see the full error message */}
                </div>
                <div>
                  {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'step' does not exist on type 'Readonly<{... Remove this comment to see the full error message */}
                  <p>Is your video working? Can you see yourself?</p>
                </div>
              </div>
              <div className="d-flex align-items-stretch">
                <div className="d-flexalign-content-center">
                  <img className="icon" src={micIcon} />
                </div>
                <div>
                  <p>Is your mic working?</p>
                </div>
                <div className="audio-bar align-self-center">
                  <AudioAnalyser audio={this.state.localTracks ? this.state.audio : null} />
                </div>
              </div>
              <div className="d-flex align-items-stretch">
                <div>
                  {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ audio: any; }' is not assignable to type '... Remove this comment to see the full error message */}
                  <SpeakerIcon className="icon" />
                  {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'audio' does not exist on type 'Readonly<... Remove this comment to see the full error message */}
                </div>
                <div>
                  <p>Is your sound working?</p>
                </div>
                <Button
                  onClick={this.playNote}
                  color="primary"
                  className="test-speaker-btn mr-2 align-self-center"
                >
                  Test Speaker
                </Button>
              </div>
            </InstructionStep>
            {this.state.step === 3 && (
              <div className="d-flex justify-content-center btn-row">
                {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'step' does not exist on type 'Readonly<{... Remove this comment to see the full error message */}
                <Button color="primary" className="mr-2" onClick={this.props.working}>
                  Video and audio are working!
                </Button>
                {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'working' does not exist on type 'Readonl... Remove this comment to see the full error message */}
                <Button color="link" onClick={this.props.notWorking}>
                  Not working - help!
                </Button>
              </div>
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'notWorking' does not exist on type 'Read... Remove this comment to see the full error message
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const ContactSupport = ({}) => {
  return (
    <div className="contact-support d-flex flex-column justify-content-between align-items-stretch">
      <h1>Sorry to hear your video setup is not cooperating!</h1>

      <div>
        <h2>Please contact support at:</h2>
        <a href="mailto:support@reliantid.com" target="_blank">
          support@reliantid.com
        </a>
      </div>
    </div>
  );
};

class VideoSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: STEP_INTRO,
    };
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
    this.videoCheck = this.videoCheck.bind(this);
    this.displaySupport = this.displaySupport.bind(this);
    this.videoWorking = this.videoWorking.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick, false);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick, false);
  }

  videoCheck() {
    this.setState({ step: STEP_VIDEO_CHECK });
  }

  displaySupport() {
    this.setState({ step: STEP_CONTACT_SUPPORT });
  }

  videoWorking() {
    this.props.videoSetupComplete(api.Providers.setVideoSetup(true));
  }
  handleClick = (e) => {
    if (this.props.skipInto) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'videoSetupComplete' does not exist on ty... Remove this comment to see the full error message
      if (!this.node.contains(e.target)) {
        this.props.videoWorking();
      }
    }
  };

  render() {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
    return (
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'skipInto' does not exist on type 'Readon... Remove this comment to see the full error message
      <div className="presence-veil">
        {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'node' does not exist on type 'VideoSetup... Remove this comment to see the full error message */}
        {this.props.skipInto && this.state.step !== STEP_CONTACT_SUPPORT ? (
          <div ref={(node) => (this.node = node)}>
            <VideoCheck notWorking={this.displaySupport} working={this.props.videoWorking} />
            {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'skipInto' does not exist on type 'Readon... Remove this comment to see the full error message */}
          </div>
        ) : (
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'step' does not exist on type 'Readonly<{... Remove this comment to see the full error message
          <div>
            {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'node' does not exist on type 'VideoSetup... Remove this comment to see the full error message */}
            {this.state.step === STEP_INTRO && <Introduction onNext={this.videoCheck} />}
            {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'videoWorking' does not exist on type 'Re... Remove this comment to see the full error message */}
            {this.state.step === STEP_VIDEO_CHECK && (
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'step' does not exist on type 'Readonly<{... Remove this comment to see the full error message
              <VideoCheck notWorking={this.displaySupport} working={this.videoWorking} />
            )}
          </div>
        )}
        {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'step' does not exist on type 'Readonly<{... Remove this comment to see the full error message */}
        {this.state.step === STEP_CONTACT_SUPPORT && (
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ notWorking: () => void; working: () => voi... Remove this comment to see the full error message
          <div ref={(node) => (this.node = node)}>
            <ContactSupport />
          </div>
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'step' does not exist on type 'Readonly<{... Remove this comment to see the full error message
        )}
      </div>
    );
  }
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'node' does not exist on type 'VideoSetup... Remove this comment to see the full error message
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(VideoSetup);
