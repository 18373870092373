import React from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";

const PatientEndModal = ({ onFinish, onAbandon, onReconnect, ...props }) => {
  return (
    <div className="modal-overlay d-flex flex-column">
      <div className="ml-auto mr-auto provider-message-container d-flex flex-column justify-content-between align-items-center">
        <h4>
          Video session has an issue. Do you have enough information to write the note?
        </h4>
        <Button className="mb-4 first-button" color="primary" outline onClick={onFinish}>
          {" "}
          Finish writing note
        </Button>
        <Button className="mb-4" color="primary" outline onClick={onAbandon}>
          {" "}
          Mark this visit as incomplete{" "}
        </Button>
        <Button color="primary" outline onClick={onReconnect}>
          {" "}
          Attempt Reconnect{" "}
        </Button>
      </div>
    </div>
  );
};

PatientEndModal.propTypes = {
  onFinish: PropTypes.func,
  onAbandon: PropTypes.func,
  onReconnect: PropTypes.func,
};

export default PatientEndModal;
