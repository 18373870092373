import React, { useState, useEffect } from "react";
import "../../stylesheets/encounterMonitor.scss";
import { Button } from "reactstrap";
import ReactPaginate from "react-paginate";

const PaginationAE = ({ totalCount, pageSize, pageStart, onChange, page, setPage }) => {
  useEffect(() => {}, [pageStart]);

  const total = totalCount ? Number(totalCount) : 0;
  const size = pageSize ? Number(pageSize) : 0;
  const start = pageStart ? Number(pageStart) : 0;
  const current = page ? Number(page) - 1 : 0;

  const handlePageChange = (e) => {
    const selected = e?.selected;
    const newPage = selected;
    setPage(newPage);

    let nextPageStart = Number(selected) * size;
    let nextPageSize = nextPageStart < total ? size : total - Number(nextPageStart);

    onChange(nextPageStart, nextPageSize, newPage);
  };

  const endCount = start + size > total ? total : start + size;
  const startCount = start === 0 ? 0 : start + 1;
  const pageCount = Math.ceil(total / size);

  if (total < 1) {
    return null;
  }

  return (
    <>
      <span className="paginationKey">{`Displaying items: ${startCount}-${endCount} of ${
        total ? total?.toLocaleString("en-US") : 0
      }`}</span>
      <ReactPaginate
        breakLabel="..."
        nextLabel={total ? ">" : ""}
        onPageChange={(e) => handlePageChange(e)}
        pageRangeDisplayed={2}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel={total ? "<" : ""}
        className="monitorPagination"
        forcePage={current}
      />
    </>
  );
};

export default PaginationAE;
