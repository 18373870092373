import React, { Component } from "react";
import { connect } from "react-redux";

import { UPDATE_CURRENT_TIME } from "../constants/actionTypes";

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
const mapStateToProps = (state, ownProps) => {
  return {
    frequency: ownProps.frequency || 15,
  };
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
const mapDispatchToProps = (dispatch) => ({
  tick: () => dispatch({ type: UPDATE_CURRENT_TIME }),
});

class MonotonicClock extends Component {
  constructor(props) {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
    super(props);
    this.state = { timer: null };
  }

  componentDidMount() {
    this.props.tick();
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'tick' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    this.startTimer();
  }

  startTimer() {
    if (!this.state.timer) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'timer' does not exist on type 'Readonly<... Remove this comment to see the full error message
      let timer = setInterval(this.props.tick.bind(this), this.props.frequency * 1000);
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'tick' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      this.setState({ timer: timer });
    }
  }

  componentWillUnmount() {
    if (this.state.timer) {
      clearInterval(this.state.timer);
    }
  }

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'timer' does not exist on type 'Readonly<... Remove this comment to see the full error message
    return null;
  }
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'timer' does not exist on type 'Readonly<... Remove this comment to see the full error message
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MonotonicClock);
