import React, { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import debounce from "lodash.debounce";
import { push } from "connected-react-router";
import { store } from "../../store";
import { FormGroup, Label, Input, Table, Button } from "reactstrap";
import PropTypes from "prop-types";
import {
  SELECT_PRACTICE_FOR_ENCOUNTERS_SEARCH, SELECT_PRACTICE_FOR_PATIENTS_SEARCH,
  SET_IDLE_LOGOUT_TIME,
  SWITCH_PRACTICE,
} from "../../constants/actionTypes";
import api from "../../api";
import styles from "./search.scss";
import Spinner from "../../images/Spinner.svg";
import Magnifying from "../../images/SearchMagnifying.svg";
import { isPCCUser, STATES } from "../../constants/Providers";
import PracticeFilter from "./EncounterSearch/PraticeFilter/PracticeFilter";
import { I_IndexedUser } from "../../constants/Types";

const ResultTable = ({ items, isPccIntegrated, currentPracticeID, switchPractice }) => {
  items = items || [];
  const viewUser = (practiceID: string, userID: string) => () => {
    if (currentPracticeID === practiceID) {
      store.dispatch(push(`/patients/${userID}`));
    } else {
      switchPractice(practiceID, userID);
    }
  };
  const viewEncounter = (link: string) => () => {
      store.dispatch(push(`/encounter-monitor/?encounterId=${link}`));
  };
  return (
    <>
      <Table responsive className={styles.patientTable}>
        <thead>
          <tr>
            <th>Patient</th>
            <th>DOB</th>
            <th>Patient ID</th>
            <th>Phone</th>
            <th>Number of Encounters</th>
            <th>State</th>
            <th>Practice</th>
          </tr>
        </thead>
        <tbody>
          {items.map((i) => (
            <tr key={i.userID}>
              <td onClick={viewUser(i.practiceID, i.userID)}>
                {i.firstName} {i.lastName}
              </td>
              <td onClick={viewUser(i.practiceID, i.userID)}>{i.dob}</td>
              <td onClick={viewUser(i.practiceID, i.userID)}>{i.PCCMRN || "-"} </td>
              <td onClick={viewUser(i.practiceID, i.userID)}>{i.phone || "-"} </td>
              <td onClick={viewEncounter(i.encounterLink)}>{i.encounterCount}</td>
              <td onClick={viewUser(i.practiceID, i.userID)}>{i.state || "CA"}</td>
              <td onClick={viewUser(i.practiceID, i.userID)}>{i.practiceName || "Hy-Vee Redbox"}</td>
              <td onClick={viewUser(i.practiceID, i.userID)}>
                {i.patientStatus === "Discharged" && (
                  <div className={styles.dischargedTag}>{i.patientStatus}</div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <p className={styles.warningLabel}>
        Searching all practices. Only showing {items.length} results. If you don’t see what you’re
        looking for, type more of your search.
      </p>
    </>
  );
};
function Search({
  currentUser,
  setIdleTime,
  currentPracticeID,
  switchPractice,
  practices,
  patientSearchPractice,
  setSelectedPractice,
}) {
  const [searchValue, setSearchValue] = useState("");
  const [debouncedSearchValue, setDebouncedSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState<I_IndexedUser[] | null>([]);
  const [inProgress, setInProgress] = useState(false);
  const isPccIntegrated = isPCCUser(currentUser);

  useEffect(() => {
    const fetchData = async () => {
      if (debouncedSearchValue.length < 3) {
        setSearchResults(null);
        return;
      }
      setInProgress(true);
      try {
        let result = await api.UserRecords.search({
          query: debouncedSearchValue,
          practice: patientSearchPractice.id,
        });
        let listData = result.items || [];
        listData.sort((a, b) => {
          if (`${a.lastName}`.toLowerCase() > `${b.lastName}`.toLowerCase()) {
            return 1;
          }
          return -1;
        });
        if (isPccIntegrated && !!listData) {
          listData.map((item, index) => {
            listData[index].patientStatus === "Discharged"
              ? listData.push(listData.splice(index, 1)[0])
              : 0;
          });
        }
        setSearchResults(listData);
      } finally {
        setInProgress(false);
      }
    };

    fetchData();
  }, [currentUser, debouncedSearchValue]);

  const debouncedSetSearchValue = useCallback(debounce(setDebouncedSearchValue, 375), []);

  const onPracticeFilterChange = (practice: { name: string; id: string; nhFlag: boolean }) => {
    setSelectedPractice(practice);
    setSearchResults(null);
    setSearchValue("")
  };

  function handleSearchChange(event) {
    const { value } = event.target;
    setSearchValue(value);
    debouncedSetSearchValue(value + " "); // adding a space at the end of the request fixes a bug in finding patients by DOB
    setIdleTime();
  }

  return (
    <div className="dashboard-component">
      <FormGroup className="d-flex">
        <Label className="mr-3 mt-2 text-nowrap">Patient search:</Label>
        <Input
          className={styles.searchInput}
          placeholder="Patient name, DOB, phone number…"
          onChange={handleSearchChange}
          value={searchValue}
          id={"qa-patientsearch-input"}
        />
        <Magnifying className={styles.magnifying} />
        <PracticeFilter
          selectedPractice={patientSearchPractice}
          providerPractices={Object.keys(practices).map((el) => ({
            state: el,
            practices: practices[el],
          }))}
          onPracticeSelect={onPracticeFilterChange}
        />
      </FormGroup>
      {inProgress && (
        <div className={styles.spinnerContainer}>
          <Spinner />
        </div>
      )}
      {!inProgress && searchValue.length > 3 && !searchResults && (
        <p className={styles.noResults}>No matching patients found.</p>
      )}
      {!inProgress && searchResults && (
        <ResultTable
          isPccIntegrated={isPccIntegrated}
          items={searchResults}
          currentPracticeID={currentPracticeID}
          switchPractice={switchPractice}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  currentUser: state.common.currentUser,
  currentPracticeID: state.common.currentUser ? state.common.currentUser.currentPracticeID : "",
  patientSearchPractice: state.patientsTab.patientSearchPractice || {},
});

const matchdispatchToProps = (dispatch) => ({
  setIdleTime: () => dispatch({ type: SET_IDLE_LOGOUT_TIME }),
  setSelectedPractice: (practice: { name: string; id: string; nhFlag: boolean }) =>
    dispatch({ type: SELECT_PRACTICE_FOR_PATIENTS_SEARCH, practice }),
});

Search.propTypes = {
  currentUser: PropTypes.object,
  setIdleTime: PropTypes.func,
  switchPractice: PropTypes.func,
};
export default connect(mapStateToProps, matchdispatchToProps)(Search);
