import React, {FC} from "react";
import { Table } from "reactstrap";
import "./chart.scss"
import { Tooltip } from 'reactstrap';
import { store } from "../../store";
import { push } from "connected-react-router";
import {IPatientDuplicateItem} from "../../constants/Types"

type Props = {
    duplicatePatients: IPatientDuplicateItem[];
    fromActiveEncounter: boolean;
    canRecentEncounter: boolean;
}

const DuplicatePatientsTab: FC<Props> = ({duplicatePatients, fromActiveEncounter, canRecentEncounter}) => {

    const openEncounterM = (id: string) => () => {
        if(fromActiveEncounter){
            window.open(`/active-encounter-monitor/?encounterId=${id}`, "_blank")
        }else{
            store.dispatch(push(`/encounter-monitor/?encounterId=${id}`));
        }
        
    };

    if(!duplicatePatients.length){
        return(
            <div>There are no patient matches.</div>
        )
    }
    return(
        <div>
            <div>
            <h2 className="flex-fill" style={{ fontSize: "20px" }}>Patient Matches</h2>

            <div className="duplicatPatientsTable">
                <Table>
                    <thead>
                        <tr>
                        <th>Patient</th>
                        <th>Practice</th>
                        <th>DOB</th>
                        <th>Sex</th>
                        <th>ID</th>
                        <th>State</th>
                        <th>Encounters</th>
                        </tr>
                    </thead>
                    <tbody>
                        
                            {duplicatePatients?.map((patient, idx) => {
                                return(
                                    <tr key={idx} className={canRecentEncounter ? "pMatchRow activeDup" : "pMatchRow"} onClick={canRecentEncounter && openEncounterM(patient.encounterLink)}>
                                    <td key={`${idx}-name`} >{`${patient.firstName} ${patient.lastName}`}</td>
                                    <td key={`${idx}-practice`}>{patient.practiceName}</td>
                                    <td key={`${idx}-dob`}>{patient.dob}</td>
                                    <td key={`${idx}-sex`}>{patient.sex}</td>
                                    <td key={`${idx}-ID`}>{patient.userID}</td>
                                    <td key={`${idx}-location`}>{patient.state}</td>
                                    <td key={`${idx}-count`} >{patient.encounterCount}</td>
                                    </tr>
                                )
                            })}
                        
                    </tbody>
                </Table>
            </div>
            </div>
        </div>
    )
}

export default DuplicatePatientsTab