import React, { Component, Dispatch, FC, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Form, FormGroup, Input } from "reactstrap";

import api from "../../api";
import CenteredForm from "../CenteredForm";
import ErrorList from "../ErrorList";
import { REQUEST_PASSWORD_RESET, NAVIGATE_TO, CAPTCHA_LOAD } from "../../constants/actionTypes";
import { IAppState } from "../../reducer";
import ReCAPTCHA from "react-google-recaptcha";

const mapStateToProps = (state: IAppState) => {
  return {
    inProgress: state.progress[REQUEST_PASSWORD_RESET],
    currentUser: state.common.currentUser,
    errors: state.errors[REQUEST_PASSWORD_RESET],
    captchaConfig: state.common.captchaConfig,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  onSubmit: (email: string, captchaToken: string) =>
    dispatch({
      type: REQUEST_PASSWORD_RESET,
      payload: api.Authorization.requestPasswordReset(email, captchaToken),
      targetPath: "/login",
      flashMessage:
        "Password reset request sent, please check your email for instructions on how to reset your password",
    }),
  onLoggedIn: (destination: string) =>
    dispatch({
      type: NAVIGATE_TO,
      targetPath: destination,
      flashMessage: "Cannot reset password, you are already logged in.",
    }),
  onCaptchaConfigLoad: (data) =>
    dispatch({
      type: CAPTCHA_LOAD,
      payload: data,
    }),
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const ForgotPassword: FC<Props> = (props) => {
  const [email, setEmail] = useState("");
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  useEffect(() => {
    if (props.currentUser) {
      props.onLoggedIn("/"); 
    }
  }, [props.currentUser]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let token
    try {
      token = await recaptchaRef.current?.executeAsync();
    } catch(err) {
     console.log(err)
    }
    
   
    props.onSubmit(email, token || "emr");
  };

  return (
    <CenteredForm>
      <h4>Request Password Reset</h4>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Input
            type="email"
            name="email"
            placeholder="your.email@address.com"
            value={email}
            disabled={props.inProgress}
            onChange={handleChange}
            id="email"
          />
        </FormGroup>
        <ErrorList errors={props.errors} />
        {props.captchaConfig?.isEnable && (
            <ReCAPTCHA
              size="invisible"
              ref={recaptchaRef}
              sitekey={props.captchaConfig?.siteKey}
            />
        )}
        <Button disabled={props.inProgress} color="primary">
          Request Reset
        </Button>
      </Form>
      <div className="reset-container">
        <Link className="reset-link" to="/login">
          Cancel
        </Link>
      </div>
    </CenteredForm>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
