import React from "react";

import { findFeature, featureSetAttributes } from "../PhysicalExam/Features";
import EntryBlock from "../PhysicalExam/EntryBlock";
import { GroupNoteEntry } from "../PhysicalExam/Notes";
import FeatureEntry from "../PhysicalExam/FeatureEntry";
import PropTypes from "prop-types";

const RxNotAsInstructed = ({ features, onChange, note, onNoteUpdate }) => {
  const rxNotAsInstructed = findFeature(
    features,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'features' does not exist on type 'Props'... Remove this comment to see the full error message
    "rxNotAsInstructed",
    "RxNotAsInstructed",
    "RxNotAsInstructed",
  );

  const setAttribute = featureSetAttributes({ onChange });

  return (
    <EntryBlock title="Do you take any medications in a different way from what was told to you?">
      <div>
        <FeatureEntry
          {...{
            feature: rxNotAsInstructed,
            values: ["Yes", "No"],
            onChange: setAttribute,
            omitTitle: true,
            id:"qa-rxNotAsInstructed-entry"
          }}
        />
      </div>
      <div>
        <GroupNoteEntry {...{ groupName: "RxNotAsInstructed", onChange: onNoteUpdate, note, id:"qa-rxNotAsInstructed-group-entry" }} />
      </div>
    {/* @ts-expect-error ts-migrate(2739) FIXME: Type '{ groupName: string; onChange: ((...args: an... Remove this comment to see the full error message */}
    </EntryBlock>
  );
};

RxNotAsInstructed.propTypes = {
  feature: PropTypes.array,
  onChange: PropTypes.func,
  note: PropTypes.string,
  onNoteUpdate: PropTypes.func,
};
export default RxNotAsInstructed;
