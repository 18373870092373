import React, { Component } from "react";

import bugsnagClient from "../../services/bugsnag";
import { mapKeysToString } from "./MedicationsTried";
import ListEditor from "./ListEditor";
import { CommaSeparatedList } from "./MedicalHistory";
import { IHistoryKeys, IKeyedLabelEntry } from "../../constants/Types";

type Props = {
  catalogEndpoint: () => Promise<IKeyedLabelEntry[]>;
  values?: IHistoryKeys;
  oldItems?: IHistoryKeys;
  isEditing?: boolean;
  onChange: (catalog: any) => (keyValues: any, actionType: any) => void;
  onRemove: (catalog: any) => (keyValues: any, actionType: any) => void;
  patientTab?: boolean;
};

class CatalogBackedListOrEditor extends Component<
  Props,
  { itemList: { items: string[] }; catalog?: IKeyedLabelEntry[] }
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      itemList: { items: [] },
    };
  }

  componentDidMount() {
    this.loadCatalog();
  }

  loadCatalog = async () => {
    try {
      let result = await this.props.catalogEndpoint();
      this.setState({ catalog: result });
      this.mapCatalogToList(result);
    } catch (err: any) {
      bugsnagClient.notify(err);
    }
  };

  mapCatalogToList = (catalog: IKeyedLabelEntry[]) => {
    let itemListMap = catalog.map((item) => (item.labels ? item.labels.en : ""));
    this.setState({ itemList: { items: itemListMap } });
  };

  searchableItemListSource = (text: string) => {
    let filter = this.state.itemList.items
      ? this.state.itemList.items.filter(
          (item) => item.toLowerCase().indexOf(text.toLowerCase()) > -1,
        )
      : [];
    return { items: filter };
  };

  render() {
    let labels: string[] | null = mapKeysToString(
      this.props.values?.keys || [],
      this.state.catalog || [],
    );
    labels = labels.concat(this.props.values?.other || []);
    labels = labels.length == 0 ? null : labels;
    let oldItemLabels = mapKeysToString(this.props.oldItems?.keys || [], this.state.catalog || []);
    oldItemLabels = oldItemLabels.concat(this.props.oldItems?.other || []);
    if (this.props.isEditing && !this.props.readOnly) {
      return (
        <ListEditor
          items={labels}
          oldItems={oldItemLabels || []}
          itemListSource={this.searchableItemListSource}
          onChange={this.props.onChange(this.state.catalog)}
          onRemove={this.props.onRemove(this.state.catalog)}
          patientTab={this.props.patientTab}
        />
      );
    }
    return <CommaSeparatedList items={labels} />;
  }
}

export default CatalogBackedListOrEditor;
