import React, { FC } from "react";
import { LoadingComponentProps } from "react-loadable";

import Spinner from "../images/Spinner.svg";
import bugsnagClient from "../services/bugsnag";



const ComponentLoading: FC<LoadingComponentProps> = (props) => {
  const handleClick = () => {
    try {
      props.retry();
    } catch (err) {
      bugsnagClient.notify(err);
    }
  };

  if (props.error) {
    if (bugsnagClient) bugsnagClient.notify(props.error);
    return (
      <div>
        Error! <button onClick={handleClick}>Retry</button>
      </div>
    );
  } else if (props.pastDelay) {
    return (
      <div className="component-load-spinner">
        <Spinner />
      </div>
    );
  } else {
    return null;
  }
}

export default ComponentLoading