import React from "react";
import BlockSection from "./BlockSection";
import Attachments from "./Attachments";
import PropTypes from "prop-types";

const Prescriptions = ({ prescriptionDetails, visitType, Images }) => {
  return (
    <>
      {!!Images && Images.length > 0 ? (
        <Attachments attachments={Images} visitType={visitType} />
      ) : (
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ attachments: any[]; visitType: string; }' ... Remove this comment to see the full error message
        ""
      )}
      {!!prescriptionDetails && (
        <>
          <BlockSection title="Prescription Details">
            <div>
              {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; title: string; }' is no... Remove this comment to see the full error message */}
              <ul>
                <li>{`Name of medication: ${prescriptionDetails.medicationName}`}</li>
                <li>{`Shape of pill: ${prescriptionDetails.medicationShape}`}</li>
                <li>{`Color of pill: ${prescriptionDetails.medicationColor}`}</li>
                <li>{`Medication dosage: ${prescriptionDetails.medicationDosage} ${prescriptionDetails.medicationDosageUnit}`}</li>
                <li>{`Scheduled or taken as needed: ${prescriptionDetails.medicationSchedule}`}</li>
              </ul>
            </div>
          </BlockSection>
          <BlockSection title="Reasons for Renewal">
            <div>
              {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; title: string; }' is no... Remove this comment to see the full error message */}
              <p>{prescriptionDetails.renewalReason}</p>
            </div>
          </BlockSection>
          <BlockSection title="Pharmacy Information">
            <div>
              {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; title: string; }' is no... Remove this comment to see the full error message */}
              <ul>
                <li>{`Pharmacy: ${
                  !prescriptionDetails.pharmacyName
                    ? "Patient does not know the pharmacy name"
                    : prescriptionDetails.pharmacyName
                }`}</li>
                {!prescriptionDetails.pharmacyStreet ||
                !prescriptionDetails.pharmacyCity ||
                !prescriptionDetails.pharmacyState ||
                !prescriptionDetails.pharmacyPostalCode ? (
                  <li>Pharmacy Address: Patient does not know the pharmacy address</li>
                ) : (
                  <li>{`Pharmacy Address: ${prescriptionDetails.pharmacyStreet} ${prescriptionDetails.pharmacyCity}, ${prescriptionDetails.pharmacyState} ${prescriptionDetails.pharmacyPostalCode}`}</li>
                )}
                <li>{`Pharmacy Phone Number: ${
                  !prescriptionDetails.pharmacyPhone
                    ? "Patient does not know the pharmacy phone number"
                    : [
                      prescriptionDetails.pharmacyPhone.slice(0, 3),
                      "-",
                      prescriptionDetails.pharmacyPhone.slice(3, 6),
                      "-",
                      prescriptionDetails.pharmacyPhone.slice(6),
                    ].join("")
                }`}</li>
              </ul>
            </div>
          </BlockSection>
          <BlockSection title="Prescriber's Information">
            <div>
              <ul>
                {!prescriptionDetails.prescriberFullName ? (
                  <li>Prescriber Name: Patient does not know prescribers name</li>
                ) : (
                  <li>{`Prescriber Name: ${prescriptionDetails.prescriberFullName}`}</li>
                )}
                {!prescriptionDetails.prescriberEmail ? (
                  <li>Prescriber Email: Patient does not know prescribers email</li>
                ) : (
                  <li>{`Prescriber Email: ${prescriptionDetails.prescriberEmail}`}</li>
                )}
                {!prescriptionDetails.prescriberPhone ? (
                  <li>Prescriber Phone Number : Patient does not know prescribers phone</li>
                ) : (
                  <li>{`Prescriber Phone Number: ${[
                    prescriptionDetails.prescriberPhone.slice(0, 3),
                    "-",
                    prescriptionDetails.prescriberPhone.slice(3, 6),
                    "-",
                    prescriptionDetails.prescriberPhone.slice(6),
                  ].join("")}`}</li>
                )}
              </ul>
            </div>
          </BlockSection>
        </>
      )}
    </>
  );
};

Prescriptions.propTypes = {
  prescriptionDetails: PropTypes.object.isRequired,
  visitType: PropTypes.string.isRequired,
  Images: PropTypes.arrayOf(PropTypes.object),
};
export default Prescriptions;
