import React from "react";

import { findFeature, featureSetAttributes } from "../PhysicalExam/Features";
import { GroupNoteEntry } from "../PhysicalExam/Notes";
import FeatureEntry from "../PhysicalExam/FeatureEntry";
import ContentBlock from "../Encounter/ContentBlock";
import PropTypes from "prop-types";

const RxNotAsPrescribed = ({ features, onChange, note, onNoteUpdate }) => {
  const rxNotAsPrescribed = findFeature(
    features,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'features' does not exist on type 'Props'... Remove this comment to see the full error message
    "rxNotAsPrescribed",
    "RxNotAsPrescribed",
    "RxNotAsPrescribed",
  );

  const setAttribute = featureSetAttributes({ onChange });

  return (
    <ContentBlock title="What happens if you dont take this medication as prescribed?">
      <div>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element[]; title: string; }' is ... Remove this comment to see the full error message */}
        <FeatureEntry
          {...{
            feature: rxNotAsPrescribed,
            values: [],
            onChange: setAttribute,
            omitTitle: true,
          }}
        />
      </div>
      <div>
        <GroupNoteEntry {...{ groupName: "RxNotAsPrescribed", onChange: onNoteUpdate, note, id:"qa-rxNotAsPrescribed-group-entry" }} />
      </div>
    {/* @ts-expect-error ts-migrate(2739) FIXME: Type '{ groupName: string; onChange: ((...args: an... Remove this comment to see the full error message */}
    </ContentBlock>
  );
};

RxNotAsPrescribed.propTypes = {
  feature: PropTypes.array,
  onChange: PropTypes.func,
  note: PropTypes.string,
  onNoteUpdate: PropTypes.func,
};
export default RxNotAsPrescribed;
