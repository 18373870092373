import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import Octicon, { ChevronUp, ChevronDown } from "@githubprimer/octicons-react";
import PropTypes from "prop-types";

import { SET_IDLE_LOGOUT_TIME, NAVIGATE_TO } from "../../constants/actionTypes";
import Select from "react-select";
import "./search.scss";
import { STATES } from "../StateSelector";
import {IProviderSearchParameters} from "../../constants/Types";
import {activeChoices, demographicChoices, languageChoices, permissionChoices, defaultSearchParameters} from "../../constants/Providers"
import Pagination from "./Pagination";
const EmptyComponent = () => null;

const regexpPhone = /[\s)(-]/g;

const Search = ({ setShowModal, showModal, searchParams, handleSearch, cancelSearch, page, pageSize, pageStart, totalCount, handlePageChange,  ...props }) => {
  const [searchValues, setSearchValues] = useState<IProviderSearchParameters>(searchParams);
  const [debouncedSearchValue, setDebouncedSearchValue] = useState("");
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [disableSearch, setDisableSearch] = useState(true);

  const bodyRef = useRef(document.querySelector("body"));


  const setSelectInputValue = (value: string[], variants: {label: string, value: string}[]) => {
    return variants.filter((el) => value.includes(el.value)) || null
  }

  const setSearchParamList = (value, key: keyof typeof searchParams) => {
    const updatedParams = { ...searchValues };

    updatedParams[key] = value;

    if(value === "" && Object.values(updatedParams).every((val) => val === "" || val === [])) {
      setShowCancel(false);
      setDisableSearch(true)
    }else{
      setShowCancel(true);
      setDisableSearch(false)
    }

    setSearchValues(updatedParams);
  }

  // handle select change handler
  const selectHandler = (
    item: { label: string; value: string }[], 
    meta: { name: keyof typeof searchParams},
  ) => {
    let value = item.map((el) => el.value);
    setSearchParamList(value, meta.name);
  }

  // text input change handler
  const textInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchParamList(event.target.value, event.target.name as keyof typeof searchParams);
  }

  const searchHandler = () => {
    handleSearch(searchValues);
  }

  const cancelHandler = () => {
    setSearchValues(defaultSearchParameters)
    setShowCancel(false);
    setDisableSearch(true)
    cancelSearch()
  }

  const handleEnterPress = (e) => {
    const checkParams = Object.values(searchValues).filter((item) => item !== "" && item !== []);
    if (e.key === "Enter" && checkParams.length > 0) {
      e.preventDefault();
      handleSearch(searchValues);
    }
  };


  const customStyles = {
    valueContainer: (provided) => ({
      ...provided,
      maxHeight: "40px",
      overflow: "hidden",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: "8px 2px",
    }),
    clearIndicator: (provided) => ({
      ...provided,
      padding: "8px 2px",
      innerHeight: "9px",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      padding: "1px 0px",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      padding: "1px 0px",
    }),


  };

  return (
    <>
    <div className="dashboard-component provider-search-menu align-content-stretch">
      <form onKeyPress={handleEnterPress}>
        <div className="d-flex">
          <div className="provider-search-menu-block mt-2 mb-3 w-100">

            <div className="provider-search-menu-item">
              <div className="provider-search-header">
              <h1>Provider Search</h1>
              <button color="link" type="button" className="advanced-search"  onClick={() => setShowAdvanced(!showAdvanced)}>
                Advanced Search
                <span>
                  {showAdvanced ? (
                    <Octicon size="small" icon={ChevronDown} />
                  ) : (
                    <Octicon size="small" icon={ChevronUp} />
                  )}
                </span>
              </button>
              </div>
            </div>

            <div className="provider-search-menu-item">
                    <label>Search</label>
                    <input
                    placeholder="Provider name, phone, ..."
                    onChange={textInputHandler}
                    value={searchValues.universal}
                    className="input-text-item"
                    name="universal"
                    type="text"
                    id="universal"
                    />
            </div>
            <div className="provider-search-menu-item">
                    <label>Provider Name or Initials</label>
                    <input
                    placeholder="Provider Name"
                    onChange={textInputHandler}
                    value={searchValues.provider}
                    className="input-text-item"
                    name="provider"
                    type="text"
                    id="provider-name"
                    />
            </div>
            <div className="provider-search-menu-item">
              <label>Location</label>
              <Select
              name="location"
              isSearchable={true}
              placeholder="State Abbreviation"
              menuPortalTarget={bodyRef.current}
              options={STATES}
              isMulti={true}
              styles={customStyles}
              className="select-input-item"
              onChange={selectHandler as any}
              value={setSelectInputValue(
                searchValues.location ? searchValues.location : [],
                STATES
              )}
              />
            </div>
            <div className="provider-search-menu-item">
              <label>Practice Name</label>
              <input
              placeholder="Practice Name"
              onChange={textInputHandler}
              value={searchValues?.practice}
              className="input-text-item"
              name="practice"
              type="text"
              id="practice-name"
              />
            </div>
            
          </div>
          </div>
          {showAdvanced && <div className="provider-search-menu-block mt-2 mb-3 last-block">
            <div className="provider-search-menu-item">
              <label>Provider Status</label>
              <Select
              name="active"
              isSearchable={true}
              placeholder="Provider Status"
              menuPortalTarget={bodyRef.current}
              options={activeChoices}
              isMulti={true}
              styles={customStyles}
              className="select-input-item"
              onChange={selectHandler as any}
              value={setSelectInputValue(
                searchValues.active ? searchValues.active : [],
                activeChoices
              )}
              />
          </div>
          <div className="provider-search-menu-item pl-3">
            <label>Demographic</label>
            <Select
            name="demographic"
            isSearchable={true}
            placeholder="Demographic"
            menuPortalTarget={bodyRef.current}
            options={demographicChoices}
            isMulti={true}
            styles={customStyles}
            className="select-input-item"
            onChange={selectHandler as any}
            value={setSelectInputValue(
              searchValues.demographic ? searchValues.demographic : [],
              demographicChoices
            )}
            />
            </div>
            <div className="provider-search-menu-item">
            <label>Language</label>
            <Select
            name="language"
            isSearchable={true}
            placeholder="Language"
            menuPortalTarget={bodyRef.current}
            options={languageChoices}
            isMulti={true}
            styles={customStyles}
            className="select-input-item"
            onChange={selectHandler as any}
            value={setSelectInputValue(
              searchValues.language ? searchValues.language : [],
              languageChoices
            )}
            />
            </div>
            <div className="provider-search-menu-item pr-2">
            <label>Permissions</label>
            <Select
            name="permissions"
            isSearchable={true}
            placeholder="Permissions"
            menuPortalTarget={bodyRef.current}
            options={permissionChoices}
            isMulti={true}
            styles={customStyles}
            className="select-input-item"
            onChange={selectHandler as any}
            value={setSelectInputValue(
              searchValues.permissions ? searchValues.permissions : [],
              permissionChoices
            )}
            />
            </div>
            <div className="provider-search-menu-item" style={{minWidth: "185px"}}>
              {}
            </div>
        </div>}
      </form>
    </div>
    <div className="btn-group">
    <div className="pagination-providers">
          <Pagination
            totalCount={totalCount}
            pageSize={pageSize}
            pageStart={pageStart}
            onChange={handlePageChange}
            page={page}
           
          />
     </div>
    <div className="provider-search-menu-buttons">
    <button
            color="link"
            className="customize-table-btn"
            type="button"
            onClick={() => setShowModal(!showModal)}
            disabled={!!showModal}
          >
            Customize Table Columns
          </button>
    {showCancel && (
      <button className="provider-search-cancel-button" type="button" onClick={cancelHandler}>
        Cancel
      </button>
    )}
    <button
      className={`provider-search-button ${disableSearch ? "disabled" : ""} `}
      type="button"
      disabled={disableSearch}
      onClick={searchHandler}
    >
      Search
    </button>
    </div>
  </div>
  </>
  );

};

const mapStateToProps = (state) => ({
  currentUser: state.common.currentUser,
  permissions: state.common.permissions,
});

const mapDispatchToProps = (dispatch) => ({
  setIdleTime: () => dispatch({ type: SET_IDLE_LOGOUT_TIME }),
});

Search.propTypes = {
  currentUser: PropTypes.object,
  permissions: PropTypes.array,
  setIdleTime: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Search);
