import React, { useState } from "react";
import { Button, Input, Label } from "reactstrap";
import { useDropzone } from "react-dropzone";
import Select from "react-select";
import Proptypes from "prop-types";

import UploadDetails from "./UploadDetails";
import CheckToggle from "../CheckToggle";
import PDF from "../../images/pdf.svg";
import AddFile from "../../images/addFile.svg";
import Spinner from "../../images/Spinner.svg";
import api from "../../api";

const fileTypes = {
  Other: {
    needsLabDetails: false,
    requisitionType: false,
  },
  Waiver: {
    needsLabDetails: false,
    requisitionType: false,
  },
  "PAD Advanced Biomedical": {
    needsLabDetails: false,
    requisitionType: true,
  },
  "Diabetes Advanced Biomedical": {
    needsLabDetails: false,
    requisitionType: true,
  },
  "CGX Advanced Biomedical": {
    needsLabDetails: false,
    requisitionType: true,
  },
  "Cardio Advanced Biomedical": {
    needsLabDetails: false,
    requisitionType: true,
  },
  "PGX Brookside": {
    needsLabDetails: false,
    requisitionType: true,
  },
  "CGX Brookside": {
    needsLabDetails: false,
    requisitionType: true,
  },
  "Cardio Brookside": {
    needsLabDetails: false,
    requisitionType: true,
  },
  "Cardio Bio Choice": {
    needsLabDetails: false,
    requisitionType: true,
  },
  "Cardio Principal Diagnostic": {
    needsLabDetails: false,
    requisitionType: true,
  },
  "Cardio Geneus": {
    needsLabDetails: false,
    requisitionType: true,
  },
  "PGX Geneus": {
    needsLabDetails: false,
    requisitionType: true,
  },
  "CGX Requisition": {
    needsLabDetails: false,
    requisitionType: true,
  },
  "Cardio Requisition": {
    needsLabDetails: false,
    requisitionType: true,
  },
  "Neuro Bio Scientific": {
    needsLabDetails: false,
    requisitionType: true,
  },
  "Cardio Bio Scientific": {
    needsLabDetails: false,
    requisitionType: true,
  },
  "Diabetes Bio Scientific": {
    needsLabDetails: false,
    requisitionType: true,
  },
  "Cancer Bio Scientific": {
    needsLabDetails: false,
    requisitionType: true,
  },
  "Pharma Bio Scientific": {
    needsLabDetails: false,
    requisitionType: true,
  },
  "Sonoran Cancer": {
    needsLabDetails: false,
    requisitionType: true,
  },
  "Sonoran Diabetes": {
    needsLabDetails: false,
    requisitionType: true,
  },
  "Sonoran Cardio": {
    needsLabDetails: false,
    requisitionType: true,
  },
  "First Choice Cancer": {
    needsLabDetails: false,
    requisitionType: true,
  },
  "First Choice Diabetes": {
    needsLabDetails: false,
    requisitionType: true,
  },
  "First Choice Cardio": {
    needsLabDetails: false,
    requisitionType: true,
  },
  "PGX Requisition": {
    needsLabDetails: false,
    requisitionType: true,
  },
  "PGX Diagx": {
    needsLabDetails: false,
    requisitionType: true,
  },
	"CGX Diagx": {
    needsLabDetails: false,
    requisitionType: true,
  },
	"Cardio Diagx": {
    needsLabDetails: false,
    requisitionType: true,
  },
	"PGX Limitless": {
    needsLabDetails: false,
    requisitionType: true,
  },
	"CGX Limitless": {
    needsLabDetails: false,
    requisitionType: true,
  },
	"Cardio Limitless": {
    needsLabDetails: false,
    requisitionType: true,
  },
  "diabetes_bio_choice": {
    needsLabDetails: false,
    requisitionType: true
  },
  "cancer_bio_choice": {
    needsLabDetails: false,
    requisitionType: true
  },
  "diabetes_principal_diagnostic": {
    needsLabDetails: false,
    requisitionType: true
  },
  "cancer_principal_diagnostic": {
    needsLabDetails: false,
    requisitionType: true
  },
  "allergy_diax": {
    needsLabDetails: false,
    requisitionType: true
  },
  "covid_diax": {
    needsLabDetails: false,
    requisitionType: true
  },
  "molecular_diax": {
    needsLabDetails: false,
    requisitionType: true
  },
  "udt_diax": {
    needsLabDetails: false,
    requisitionType: true
  },
  "General Lab Requisition": {
    needsLabDetails: false,
    requisitionType: true,
  },
  "SARS-CoV2 Nasal Swab Test Result": {
    needsLabDetails: true,
    requisitionType: false,
  },
  "SARS-CoV2 Saliva Test Result": {
    needsLabDetails: true,
    requisitionType: false,
  },
  "SARS-CoV-2 Antibody Assessment": {
    needsLabDetails: true,
    requisitionType: false,
  }
};

const uploadFileOptions = Object.keys(fileTypes).map((t) => {
  return {
    value: t,
    label: t,
  };
});

const UploadFileModal = ({ visible, userID, userName, testID, testType, onClose, loadEntries }) => {
  if (!visible) {
    // popup not visible
    return null;
  }

  const [description, setDescription] = useState(null);
  const [token, setToken] = useState(null);
  const [file, setFile] = useState(null);
  const [notify, setNotify] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [details, setDetails] = useState({});
  const [fileOption, setFileOption] = useState("");

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (files) => onUpload(files),
    accept: "application/pdf",
  });

  const prepareImage = (extension) => {
    try {
      const result = api.ImageUpload.prepare(extension);
      return result;
    } catch (e) {
      setError("uploadError");
    }
  };

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'extension' implicitly has an 'any' type... Remove this comment to see the full error message
  const uploadImage = async (result, body) => {
    const { destinationUrl, uploadHeaders } = result;
    try {
      const data = await api.ImageUpload.upload(destinationUrl, body, uploadHeaders);
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'result' implicitly has an 'any' type.
      return data;
    } catch (e) {
      setError("uploadError");
    }
  };

  const onUpload = async (e) => {
    setLoading(true);
    setError("");
    const files = e;
    const parts = files.length ? files[0].name.split(".") : [];
    if (parts[parts.length - 1] !== "pdf") {
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
      setError("Error: Invalid File Type");
    } else {
      const result = await prepareImage(`.${parts[parts.length - 1]}`);

      await uploadImage(result, files[0]);
      setToken(result.token);
      setFile(files[0]);
    }
    setLoading(false);
  };

  const handleSubmit = async () => {
    const payload = {
      description: description,
      attachmentToken: token,
      notifyUser: notify,
      testID: testID,
      ...details,
    };

    if (fileOption !== "Other") {
      payload.description = fileOption;
    }

    try {
      await api.UserRecords.addChartFile(userID, payload);
      !!onClose && onClose();
    } catch (err) {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'null'.
      setError("Error: Submission Failed");
    }
    loadEntries([]);
  };

  const handleSelect = (event) => {
    const newValue = event.target.value;
    setDescription(newValue);
  };

  const handleClearPreview = () => {
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    setFile(null);
    setToken(null);
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
    setNotify(false);
    setError("");
    setFileOption("");
    setDescription(null);
  };

  const updateDetails = (fieldName) => (value) => {
    setDetails({ ...details, [fieldName]: value });
  };

  const getSize = (fileSize) => {
    const num = fileSize / 1000000;
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'fieldName' implicitly has an 'any' type... Remove this comment to see the full error message
    return Math.round((num + Number.EPSILON) * 100) / 100;
  };

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'fileSize' implicitly has an 'any' type.
  const status = error === "uploadError" ? "Upload Failed" : "Upload Completed";
  const options = testType.map((type) => {
    return { label: type, value: type };
  });
  return (
    <div className="modal-overlay">
      {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
      <div className="ml-auto mr-auto center-modal upload">
        <h1 className="title">{`Upload File to ${userName}’s chart:`}</h1>
        <div className="addFile-img">
          <AddFile />
        </div>
        <section className="container">
          <div {...getRootProps({ className: "dropzone" })}>
            <input
              {...getInputProps()}
              onChange={(e) => onUpload(e.target.files)}
              accept="application/pdf"
              id="file"
              type="file"
              name="file"
            />
            <p>Drag and drop or browse your files</p>
          </div>
        </section>
        {loading && (
          <div className="loading-spinner">
            <Spinner />{" "}
          </div>
        )}
        {file && (
          <div className="file-review">
            <div className="file-preview">
              <div className="pdf-icon">
                <PDF />
              </div>
              <div className="file-details">
                <div className={`name ${error}`}>{file.name}</div>
                <div className={`status ${error}`}>{status}</div>
                <div className="size">
                  {/* @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'. */}
                  {!error && `${getSize(file.size)} of ${getSize(file.size)} MB`}
                </div>
              </div>
              <div className="close-btn" onClick={handleClearPreview}>
                X{/* @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'. */}
              </div>
              {/* @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'. */}
            </div>
            <div className="description-select w-40">
              <Select
                options={uploadFileOptions}
                onChange={(option) => setFileOption(option.value)}
                backspaceRemovesValue={false}
                placeholder="Choose upload option"
              />
            </div>
            {/* @ts-expect-error ts-migrate(7006) FIXME: Parameter 'option' implicitly has an 'any' type. */}
            {fileOption === "Other" && (
              <>
                <div className="description-select">
                  <Label style={{ color: "#4a8fe7" }}>File Name</Label>
                  <Input type="text" onChange={handleSelect} placeholder="Enter File Name" />
                </div>
              </>
            )}
            {fileTypes[fileOption] && fileTypes[fileOption].needsLabDetails && (
              <>
                <UploadDetails details={details} onChange={updateDetails} isEditing={true} />
                <div className="notify-select">
                  <CheckToggle checked={notify} onChange={() => setNotify(!notify)}>
                    <span>Notify patient that labs are uploaded</span>
                  </CheckToggle>
                </div>
              </>
            )}
          </div>
        )}
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'string'. */}
        {error && error !== "uploadError" && <div className="error">{error}</div>}
        <div className="buttons">
          <Button onClick={() => !!onClose && onClose()}>Cancel</Button>
          <Button
            onClick={handleSubmit}
            disabled={
              fileOption !== "" && fileOption !== "Other" ? false : !!!description || !!error
            }
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

UploadFileModal.propTypes = {
  visible: Proptypes.bool.isRequired,
  userID: Proptypes.string.isRequired,
  userName: Proptypes.string.isRequired,
  testID: Proptypes.string.isRequired,
  testType: Proptypes.array,
  onClose: Proptypes.func,
  loadEntries: Proptypes.func,
};

export default UploadFileModal;
