import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Button } from "reactstrap";

import Octicon from "@githubprimer/octicons-react";

import styles from "./AuditButton.scss";

const AuditButton = ({ type, icon, children, onClick, className }) => (
  <div className={styles.auditActionButton}>
    <Button color={type} className="round-button" onClick={onClick}>
      <Octicon icon={icon} />
    </Button>
    <span className={classNames(styles.label, className)}>{children}</span>
  </div>
);

AuditButton.propTypes = {
  type: PropTypes.string,
  icon: PropTypes.element,
  children: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default AuditButton;
