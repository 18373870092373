import React, { Component } from "react";
import { connect } from "react-redux";

import {
  ENCOUNTER_LOST,
  ENCOUNTER_ENGAGEMENT_STATUS,
  ENCOUNTER_ACCEPTED,
} from "../../constants/actionTypes";
import {
  NOT_ENGAGED,
  WAITING_ON_USER,
  NOT_CLOCKED_IN,
  IN_ENCOUNTER,
} from "../../constants/Encounter";
import api from "../../api";
import Spinner from "../../images/Spinner.svg";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import { checkIsJSON, errorLogger } from "../../utils";

const defaultTimeInMs = 30000;

const mapStateToProps = (state, ownProps) => {
  return {
    currentTime: state.time.currentTime,
    currentUser: state.common.currentUser,
    engagementStatus: state.encounter.engagementStatus
      ? state.encounter.engagementStatus.status
      : null,
    encounterID: state.encounter.encounterID,
    referenceID: state.encounter.referenceID,
    presence: state.presence,
    encounterWasAccepted: state.encounter.encounterWasAccepted,
    encounterSaved: state.encounter.encounterSaved,
    ...state.encounter,
  };
};

const mapDispatchToProps = (dispatch) => ({
  encounterLost: () => dispatch({ type: ENCOUNTER_LOST }),
  encounterStatus: (payload, resuming) =>
    dispatch({ type: ENCOUNTER_ENGAGEMENT_STATUS, payload, resuming }),
  encounterAccepted: (resuming) =>
    dispatch({ type: ENCOUNTER_ACCEPTED, targetPath: "/encounter", resuming }),
});

interface AcceptorProps {
  currentTime: number;
  currentUser: object;
  encounterAccepted: Function;
  encounterID: string;
  encounterLost: Function;
  encounterSaved: boolean;
  encounterStatus: Function;
  encounterWasAccepted: boolean;
  inProgress: boolean;
  presence: object;
  referenceID: string;
  videoStarted: boolean;
  engagementStatus: string | null;
  resuming: boolean;
}

interface AcceptorState {
  statusPolling: boolean;
  nextTime: number;
  displayCancelBtn: boolean;
}
class Acceptor extends Component<AcceptorProps, AcceptorState> {
  state = { statusPolling: false, nextTime: 0, displayCancelBtn: false };

  poll() {
    const nextTime = new Date().getTime() + 10000; // 10 seconds plus or minus timer resolution
    this.setState({ statusPolling: true, nextTime: nextTime });
    this.props.encounterStatus(api.Encounters.engagementStatus(), false);
  }

  stopPolling() {
    this.setState({ statusPolling: false, nextTime: 0 });
  }
  onAbortEncounter = async () => {
    try {
      await api.Encounters.abortEncounter(this.props.referenceID);
      this.props.encounterStatus(api.Encounters.engagementStatus(), true);
      this.stopPolling();
      localStorage.removeItem(`cancelBtnID-${this.props.referenceID}`);
    } catch (error: any) {
      errorLogger(error);
    }
    this.setState({ displayCancelBtn: false });
  };
  checkCancelButtonIsAvailable = () => {
    const timeWaiting = localStorage.getItem(`cancelBtnID-${this.props.referenceID}`);

    let newTimerTime = 30000;

    if (timeWaiting) {
      newTimerTime -= Date.now() - +timeWaiting;
    } else {
      localStorage.setItem(`cancelBtnID-${this.props.referenceID}`, Date.now().toString());
    }

    setTimeout(() => {
      this.setState({ displayCancelBtn: true });
    }, newTimerTime);
  };
  componentDidMount() {
    this.props.encounterStatus(api.Encounters.engagementStatus(), true);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.engagementStatus !== this.props.engagementStatus &&
      this.props.engagementStatus === WAITING_ON_USER &&
      this.props.referenceID
    ) {
      this.checkCancelButtonIsAvailable();
    }
    if (!this.props.currentUser) {
      return;
    }
    if (
      this.props.resuming &&
      this.props.engagementStatus == IN_ENCOUNTER &&
      !this.props.encounterSaved
    ) {
      this.props.encounterAccepted(this.props.resuming);
      return;
    }
    if (this.props.referenceID && prevProps.referenceID !== this.props.referenceID) {
      // TODO: and not error...
      this.poll();
      return;
    }
    if (this.props.presence !== prevProps.presence) {
      this.props.encounterStatus(api.Encounters.engagementStatus(), true);
    }
    if (this.props.currentUser != prevProps.currentUser) {
      this.props.encounterStatus(api.Encounters.engagementStatus(), true);
    }
    if (!!this.state.nextTime && this.props.currentTime > this.state.nextTime) {
      this.poll();
    }
    if (prevProps.engagementStatus != this.props.engagementStatus) {
      switch (this.props.engagementStatus) {
        case NOT_ENGAGED:
        // TODO: Right thing for not clocked in? Probably not, encouter lost will remove it from the list
        case NOT_CLOCKED_IN:
          if (this.props.referenceID) {
            this.props.encounterLost();
          }
          this.stopPolling();
          break;
        case WAITING_ON_USER:
          if (!this.state.statusPolling) {
            this.poll();
          }
          break;
        case IN_ENCOUNTER:
          localStorage.removeItem(`cancelBtnID-${this.props.referenceID}`);
          if (!this.props.encounterWasAccepted) {
            this.props.encounterAccepted(this.props.resuming);
          }
          this.stopPolling();
          break;
      }
    }
  }

  componentWillUnmount() {
    this.stopPolling();
  }

  render() {
    if (this.state.statusPolling || this.props.engagementStatus == WAITING_ON_USER) {
      return (
        <div className="modal-overlay d-flex flex-column justify-content-center">
          <div className="ml-auto mr-auto engagement-polling-container d-flex flex-column justify-content-center">
            <Spinner className="align-self-center" />
            <h4 className="align-self-center">Waiting for user to start encounter...</h4>
            {this.props.referenceID && this.state.displayCancelBtn && (
              <Button
                outline
                className="align-self-center mt-3 mb-3"
                onClick={this.onAbortEncounter}
              >
                Cancel Start of Encounter
              </Button>
            )}
          </div>
        </div>
      );
    }
    return null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Acceptor);
