import React, { useState, useEffect } from "react";


const SelectorOption = ({ type, selected, onSelected, ...props }) => {
  const onClick = () => {
    !!onSelected && onSelected(type);
  };
  return (
    <div className={`option ${!!selected && "active"}`} onClick={onClick}>
      {props.children}
    </div>
  );
};

const SelectorBar = ({ options, selected, leftBarRenderer, onOptionSelected }) => {
  const [selectedType, setSelectedType] = useState(selected || "");

  const onSelected = (type) => {
    if (!!onOptionSelected) {
      if (onOptionSelected(type) !== false) {
        setSelectedType(type);
      }
    } else {
      setSelectedType(type);
    }
  };

  return (
    <div className="selector-bar-component">
      <div className="d-flex flex-row flex-wrap justify-content-between selector-bar">
        {(options || []).map((opt, idx) => (
          <SelectorOption
            key={`opt-${idx}`}
            type={opt.type}
            selected={selectedType === opt.type}
            onSelected={onSelected}
          >
            {opt.text}
          </SelectorOption>
        ))}
        <div className="d-flex align-items-center pl-5">
          {!!leftBarRenderer && leftBarRenderer({ options, selectedType })}
        </div>
      </div>
    </div>
  );
};

export default SelectorBar;
