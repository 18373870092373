import React from "react";
import { connect } from "react-redux";

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
const mapStateToProps = (state, ownProps) => {
  return {
    message: ownProps.message,
    delay: ownProps.delay || 5000,
  };
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
const mapDispatchToProps = (dispatch) => ({});

class ModalMessage extends React.Component {
  constructor(props) {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
    super(props);
    this.state = { timer: null };
    this.clear = this.clear.bind(this);
  }
  componentDidMount() {
    if (this.props.message) {
      this.clear();
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'message' does not exist on type 'Readonl... Remove this comment to see the full error message
    }
  }

  clear() {
    setTimeout(() => {
      if (this.props.onClear) {
        this.props.onClear();
      }
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onClear' does not exist on type 'Readonl... Remove this comment to see the full error message
      this.setState({ visible: false });
    }, this.props.delay);
    this.setState({ visible: true });
  }

  componentDidUpdate(prevProps) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'delay' does not exist on type 'Readonly<... Remove this comment to see the full error message
    if (!prevProps.message && this.props.message && !this.state.visible) {
      this.clear();
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'prevProps' implicitly has an 'any' type... Remove this comment to see the full error message
    }
  }

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'message' does not exist on type 'Readonl... Remove this comment to see the full error message
    const message = this.props.message;
    if (this.state.visible && message) {
      return (
        <div className="modal-overlay d-flex flex-column">
          {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'message' does not exist on type 'Readonl... Remove this comment to see the full error message */}
          <div className="ml-auto mr-auto provider-message-container d-flex flex-column justify-content-center">
            <h4>{message}</h4>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalMessage);
