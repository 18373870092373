/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import "babel-polyfill";

import ReactDOM from "react-dom/client";
import React from "react";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router"; // react-router v5.1
import { ConnectedRouter } from "connected-react-router";
import Loadable from "react-loadable";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import { store, history } from "./store";

import TokenLoader from "./components/TokenLoader";
import Redirect from "./components/redirect";
import RtcMonitor from "./components/RtcMonitor";
import MonotonicClock from "./components/MonotonicClock";

import "bootstrap/dist/css/bootstrap.css";
import "react-datepicker/dist/react-datepicker.css";
import "./main.scss";

import Loading from "./components/ComponentLoading";
import PatientPoller from "./components/WaitingRoom/PatientPoller";
import Login from "./components/Authentication/Login";
import AcceptInvite from "./components/Authentication/AcceptInvite";
import ResetPassword from "./components/Authentication/ResetPassword";
import ForgotPassword from "./components/Authentication/ForgotPassword";
import IdleMonitor from "./components/IdleMonitor";
import TokenRefresher from "./components/TokenRefresher";
import EnvBanner from "./components/EnvBanner";
import BrowserBanner from "./components/BrowserBanner";
import DetectAdblock from "./components/DetectAdblock";
import Bugsnag from "./services/bugsnag";
import ErrorHandler from "./components/ErrorHandler";
import PermissionsIssues from "./components/PermissionsIssue/PermissionsIssue";

const ProviderDashboard = Loadable({
  loader: () => import("./components/Provider/Dashboard"),
  loading: Loading,
  delay: 300,
});

const Invite = Loadable({
  loader: () => import("./components/ProviderManagement/Invite"),
  loading: Loading,
  delay: 300,
});

const ProviderAvailablePatients = Loadable({
  loader: () => import("./components/Provider/AvailablePatients"),
  loading: Loading,
  delay: 300,
});

const ManagerProviders = Loadable({
  loader: () => import("./components/Manager/Providers"),
  loading: Loading,
  delay: 300,
});

const ActivitySummary = Loadable({
  loader: () => import("./components/ActivityMonitor/ActivitySummary"),
  loading: Loading,
  delay: 300,
});

const EncounterMonitor = Loadable({
  loader: () => import("./components/EncounterMonitor/Monitor"),
  loading: Loading,
  delay: 300,
});

const ActiveEncounterMonitor = Loadable({
  loader: () => import("./components/EncounterMonitor/ActiveMonitor"),
  loading: Loading,
  delay: 300,
})

const EncounterMonitorDetails = Loadable({
  loader: () => import("./components/EncounterMonitor/Details"),
  loading: Loading,
  delay: 300,
});

const ActiveEncounterMonitorDetails = Loadable({
  loader: () => import("./components/EncounterMonitor/ActiveDetails"),
  loading: Loading,
  delay: 300,
})

const BillingTab = Loadable({
  loader: () => import("./components/Billing/Tab"),
  loading: Loading,
  delay: 300,
});

const RoundingTab = Loadable({
  loader: () => import("./components/Rounding/Tab"),
  loading: Loading,
  delay: 300,
});

const PatientTab = Loadable({
  loader: () => import("./components/Patients/Tab"),
  loading: Loading,
  delay: 300,
});

const PatientChart = Loadable({
  loader: () => import("./components/Patients/Chart"),
  loading: Loading,
  delay: 300,
});

const ActivePatientChart = Loadable({
  loader: () => import("./components/Patients/ActivePatientChart"),
  loading: Loading,
  delay: 300,
});

const InsuranceHistory = Loadable({
  loader: () => import("./components/Patients/InsuranceHistory"),
  loading: Loading,
  delay: 300,
});

const ProviderEdit = Loadable({
  loader: () => import("./components/ProviderManagement/Edit"),
  loading: Loading,
  delay: 300,
});

const ProviderAudit = Loadable({
  loader: () => import("./components/Audit"),
  loading: Loading,
  delay: 300,
});

const Encounter = Loadable({
  loader: () => import("./components/Encounter/Encounter"),
  loading: Loading,
  delay: 300,
});

const VerifyInsuranceList = Loadable({
  loader: () => import("./components/VerifyInsurance/InsuranceList"),
  loading: Loading,
  delay: 300,
});

const VideoChat = Loadable({
  loader: () => import("./components/VideoChat"),
  loading: Loading,
  delay: 300,
});

const EncounterReport = Loadable({
  loader: () => import("./components/AdminPages/EncounterReport"),
  loading: Loading,
  delay: 300,
});

const ErrorBoundary = Bugsnag.getPlugin("react")!.createErrorBoundary(React);
const root = ReactDOM.createRoot(document.getElementById("app"));

document.addEventListener("DOMContentLoaded", () => {
  root.render(
    <ErrorBoundary>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ErrorHandler>
            <TokenLoader>
              <ToastContainer
                position="top-right"
                autoClose={7000}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <DetectAdblock /> {/**This should NEVER be commented out**/}
              <BrowserBanner />
              <EnvBanner />
              <IdleMonitor />
              <TokenRefresher />
              <MonotonicClock frequency={15} />
              <RtcMonitor />
              <PatientPoller pollInterval={30000} />
              <Redirect />
              <Switch>
                <Route exact={true} path="/" component={ProviderDashboard} />
                <Route exact={true} path="/accept-invitation/:inviteID" component={AcceptInvite} />
                <Route exact={true} path="/login" component={Login} />
                <Route exact={true} path="/reset-password/:resetID" component={ResetPassword} />
                <Route exact={true} path="/request-password-reset" component={ForgotPassword} />
                <Route exact={true} path="/waiting-room" component={ProviderAvailablePatients} />
                <Route exact={true} path="/encounter" component={Encounter} />
                <Route exact={true} path="/video" component={VideoChat} />
                <Route exact={true} path="/providers" component={ManagerProviders} />
                <Route exact={true} path="/providers/new" component={Invite} />
                <Route exact={true} path="/providers/edit/:providerID" component={ProviderEdit} />
                <Route exact={true} path="/providers/audit/:providerID" component={ProviderAudit} />
                <Route exact={true} path="/activity-monitor" component={ActivitySummary} />
               <Route exact={true} path="/encounter-monitor/" component={EncounterMonitor} />
                <Route exact={true} path="/encounter-monitor/:encounterID" component={EncounterMonitorDetails}/>
                <Route exact={true} path="/billing" component={BillingTab} />
                <Route exact={true} path="/patients" component={PatientTab} />
                <Route exact={true} path="/rounding" component={RoundingTab} />
                <Route exact={true} path="/patients/:patientID" component={PatientChart} />
                <Route
                  exact={true}
                  path="/active-patient-chart/:patientID"
                  component={ActivePatientChart}
                />
                <Route
                  exact={true}
                  path="/insurnace-history/:patientID"
                  component={InsuranceHistory}
                />
                <Route exact={true} path="/verify-insurance" component={VerifyInsuranceList} />
                <Route exact={true} path="/encounter-report" component={EncounterReport} />
                <Route exact={true} path="/active-encounter-monitor/" component={ActiveEncounterMonitor}/>
                <Route exact={true} path="/active-encounter-monitor/:encounterID" component={ActiveEncounterMonitorDetails}/>
                <Route exact={true} path="/permissions-issue" component={PermissionsIssues} />
                <Route render={() => <div>Not Found</div>} />
              </Switch>
            </TokenLoader>
          </ErrorHandler>
        </ConnectedRouter>
      </Provider>
    </ErrorBoundary>,

  );
});
