import React, { Component, FC } from "react";
import { connect } from "react-redux";
import { Button, Alert, Row, Col, Table, Tooltip } from "reactstrap";
import moment from "moment-timezone";
import NavBar from "../Provider/NavBar";
import Spinner from "../../images/Spinner.svg";
import Arrow from "../../images/arrow.svg";
import api from "../../api";
import Chat from "../../images/MonitorChat.svg";
import Video from "../../images/MonitorVideo.svg";
import Octicon, { Plus, Dash } from "@githubprimer/octicons-react";
import {
  COMPONENT_LOAD,
  COMPONENT_UNLOAD,
  ENCOUNTER_MONITOR_DETAILS,
  NAVIGATE_TO,
} from "../../constants/actionTypes";

import "../../stylesheets/encounter.scss";
import "../../stylesheets/encounterMonitor.scss";
import PDFView from "../Provider/PDFView";
import PDFButton from "../PDFButton";
import ProviderSelect from "../Provider/ProviderSelect";
import PatientInfo from "../Encounter/PatientInfo";
import ChatHistory from "./ChatHistory";
import {
  IChatHistory,
  IEncounterTimelines,
  IMonitorEncounter,
  Event,
  IEvent,
  IEncounterSMSTimelines,
} from "../../constants/Types";
import { formatTime } from "./Monitor";
import bugsnagClient from "../../services/bugsnag";
import { browserNameParser, errorLogger, OSParser, isMobileOS } from "../../utils";
import ButtonSpinner from "../ButtonSpinner";

const mapStateToProps = (state, ownProps) => {
  return {
    permissions: state.common.permissions,
    encounterID: ownProps.match?.params?.encounterID,
    ...state.componentData[ENCOUNTER_MONITOR_DETAILS],
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onLoad: (payload) =>
    dispatch({
      type: COMPONENT_LOAD,
      subtype: ENCOUNTER_MONITOR_DETAILS,
      payload,
    }),
  onUnload: () => dispatch({ type: COMPONENT_UNLOAD, subtype: ENCOUNTER_MONITOR_DETAILS }),
  encounterList: (queryData) =>
    dispatch({ type: NAVIGATE_TO, targetPath: `/encounter-monitor/${queryData}` }),
});

const ProviderDetails = ({ provider }) => {
  return (
    <SectionView title="Provider" className="blue">
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Phone</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{provider?.name || "--"}</td>
            <td>{provider?.phone || "--"}</td>
          </tr>
        </tbody>
      </Table>
    </SectionView>
  );
};
const ScribeDetails = ({ scribe }) => {
  return (
    <SectionView title="Scribe" className="blue">
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Phone</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{scribe?.name || "--"}</td>
            <td>{scribe?.phone || "--"}</td>
          </tr>
        </tbody>
      </Table>
    </SectionView>
  );
};
const CommunicationHistory = ({ encounter, chatHistory }) => {
  if (!chatHistory.chatHistoryItems) {
    return (
      <div>
        <h5>Communication History</h5>
        <div> None </div>
      </div>
    );
  }
  return (
    <div className="mt-4 mb-4">
      <h5>Communication History</h5>
      <ChatHistory
        encounterID={encounter.referenceID}
        chatHistoryItems={chatHistory.chatHistoryItems}
        location={encounter.location}
      />
    </div>
  );
};
const EncounterSummary = ({ encounter }) => {
  const e = encounter;
  const t = moment(encounter.effectiveDate);
  const lastContact =
    encounter.lastContact && moment.utc(encounter.lastContact).isValid()
      ? moment.utc(encounter.lastContact).local().format("MM/DD/YY hh:mma")
      : "--";

  const duration = formatTime(encounter.lastContact);
  const isChat = e?.method === "Chat";
  return (
    <div>
      <div className="w-100 d-flex">
        <Table>
          <thead>
            <tr>
              <th>Visit Created</th>
              <th>Practice</th>
              <th>Chief Complaint</th>
              <th>Location</th>
              <th>Method</th>
              <th>New Messages</th>
              <th>Last Messages</th>
              <th>Provider</th>
              <th>Visit Timeline</th>
              <th>Patient Name</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t.format("MM/DD/YY h:mma")}</td>
              <td>{e?.practice || "--"}</td>
              <td>{e?.chiefComplaint || "--"}</td>
              <td>{e?.location || "--"}</td>
              <td>{isChat ? <Chat /> : e?.method === "Video" ? <Video /> : <span>--</span>}</td>
              <td>{e?.newMessages || "--"}</td>
              <td>
                {encounter.lastContact ? (
                  <>
                    <div>{lastContact}</div>
                    <div className="time-elapsed">{duration}</div>
                  </>
                ) : (
                  "--"
                )}
              </td>
              <td>{e?.provider?.name || "--"}</td>
              <td>{e?.status || "--"}</td>
              <td>{e?.patient?.name || "--"}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};

const EncounterAssignment = ({ encounter, provider, providerChange, onAssign }) => {
  return (
    <div className="encounter-assignment">
      <div className="d-flex">
        <h5>Assign Encounter</h5>
        <p className="assign-warn">
          Assigning an encounter will reopen and overwrite the existing note when finished.
        </p>
      </div>

      <div className="w-100 d-flex mb-3">
        <ProviderSelect
          className="provider-select qa-assignEncounterSelect"
          selected={provider}
          onChange={providerChange}
          encounterID={encounter.referenceID}
        />
        <div>
          <Button
            className="ml-3 qa-reopenAndAssignButton"
            disabled={!provider}
            color="primary"
            onClick={() => onAssign(provider.target.value, encounter.referenceID)}
          >
            Reopen &amp; Assign
          </Button>
        </div>
      </div>
    </div>
  );
};

/* const SurveyResults = ({ surveyResults }) => {
  if (!surveyResults) {
    return null;
  }

  return (
    <div>
      <h5> Survey Results </h5>
      <div className="w-100 d-flex">
        {Object.keys(surveyResults).map((k, idx) => (
          <DLI keyWord={idx} t={k} v={surveyResults[k]} />
        ))}
      </div>
    </div>
  );
}; */

/* const TestDetails = ({ testResult }) => {
  const keyWord = "providerTimeline";
  if (!testResult) {
    return null;
  }
  return (
    <div>
      <h5>Test Results</h5>
      <div className="w-100 d-flex">
        <DLI t="State" v={testResult.state} keyWord={keyWord} />
        <DLI t="Type" v={testResult.testType} keyWord={keyWord} />
        {testResult.imageURL && testResult.state === "complete" && (
          <dl>
            <dt>Image</dt>
            <dd>
              <img src={testResult.thumbnailURL} />
            </dd>
          </dl>
        )}
      </div>
      <RawView title="Image Analysis Test Result" data={testResult.result} />

      <RawView title="Provider Test Result" data={testResult.providerResult} />
    </div>
  );
}; */

const SectionView = ({ className, title, children }) => {
  return (
    <div className={`section${className} mt-3`}>
      <div className="section-title pt-2">
        <span>{title}</span>
      </div>
      <div className="section-content blue">{children}</div>
    </div>
  );
};

const PatientDetails = ({ patient, practice }) => {
  return (
    <SectionView title="Patient" className="blue">
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Sex</th>
            <th>DOB</th>
            <th>Patient City</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{patient?.name || "--"}</td>
            <td>{patient?.sex || "--"}</td>
            <td>{patient?.dob || "--"}</td>
            <td>{patient?.city || "--"}</td>
          </tr>
        </tbody>
      </Table>
      <div className="mt-5">
        <Table>
          <thead>
            <tr>
              <th>Patient State</th>
              <th>Phone</th>
              <th>Email Verified</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{patient?.state || "--"}</td>
              <td>{patient?.phone || "--"}</td>
              <td>{`${patient?.emailVerified}` || "false"}</td>
              <td>{patient?.email || "--"}</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="mt-5">
        <Table>
          <thead>
            <tr>
              <th>PCC ID Number</th>
              <th>Practice</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{patient?.pccmrn || "--"}</td>
              <td>{practice || "--"}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    </SectionView>
  );
};

const SystemDetails = ({ encounter, showEncounterStates, handleTimeline }) => {
  const e = encounter || {};

  const totalTime = e?.activeEncounterTime?.reduce((sum, a) => sum + a.TimeSpent, 0);
  const times = ["h", "m", "s"];

  const formatTime = totalTime
    ? moment
        .utc(totalTime * 1000)
        .format("HH:mm:ss")
        .split(":")
        .map((el, i) => el + times[i] + " ")
    : "--";

  const dateTime = (dt) => moment(dt).format("MM/DD/YYYY hh:mma");
  const getOffset = moment.tz.guess();
  const zone = moment.tz(getOffset).zoneAbbr();

  return (
    <>
      <div>
        <Table>
          <thead>
            <tr>
              <th>Encounter State</th>
              <th>Postponed?</th>
              <th>Provider State</th>
              <th>Patient State</th>
              <th>Total Active Charting Time</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{e?.status || "--"}</td>
              <td>{`${e?.postponed}` || "false"}</td>
              <td>{e?.providerState || "--"}</td>
              <td>{e?.userState || "--"}</td>
              <td>{formatTime}</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="mt-5">
        <Table>
          <thead>
            <tr>
              <th>Video Complete</th>
              <th>Red Flag</th>
              <th>Reference ID</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{`${e?.providerVideoComplete}` || "false"}</td>
              <td>{`${e?.redFlagged}` || "false"}</td>
              <td>{e?.referenceID || "--"}</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="mt-4">
        <Table>
          <thead>
            <tr>
              <th>Patient Last Waiting Room Ping</th>
              <th>Patient Last iFrame Ping</th>
              <th>Provider Last Ping</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {e?.userLastPingInWaitingRoomAt
                  ? `${dateTime(e?.userLastPingInWaitingRoomAt)} ${zone}`
                  : "--"}
              </td>
              <td>
                {e?.userLastPingInIframeAt
                  ? `${dateTime(e?.userLastPingInIframeAt)} ${zone}`
                  : "--"}
              </td>
              <td>{e?.providerLastPingAt ? `${dateTime(e?.providerLastPingAt)} ${zone}` : "--"}</td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="mt-4 mb-3">
        <div onClick={handleTimeline}>
          <span className="ml-2 mt-1 show-min-icons">
            {!showEncounterStates ? (
              <Octicon size={12} icon={Plus} />
            ) : (
              <Octicon size={12} icon={Dash} />
            )}
          </span>{" "}
          <span className="legacy-system-btn">Legacy System Details </span>
        </div>
      </div>
      {!!showEncounterStates && (
        <div>
          <span className="legacy-disclaimer">
            Do not use this information to determine visit status or timeline events as the complete
            information is available in the <q>visit timeline</q> section. Items here are in the
            process of being removed.
          </span>
          <div className="mt-3">
            <Table>
              <thead>
                <tr>
                  <th>Patient Initialized</th>
                  <th>Patient Prepared</th>
                  <th>Patient Ready</th>
                  <th>Patient Started</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {e.userInitializedAt
                      ? moment(e.userInitializedAt).format("MM/DD/YY h:mma")
                      : "--"}
                  </td>
                  <td>
                    {e.userPreparedAt ? moment(e.userPreparedAt).format("MM/DD/YY h:mma") : "--"}
                  </td>
                  <td>{e.userReadyAt ? moment(e.userReadyAt).format("MM/DD/YY h:mma") : "--"}</td>
                  <td>
                    {e.userStartedAt ? moment(e.userStartedAt).format("MM/DD/YY h:mma") : "--"}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <div className="mt-5">
            <Table>
              <thead>
                <tr>
                  <th>Provider Engaged</th>
                  <th>Provider Started</th>
                  <th>Provider Finished</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {e.providerEngagedAt
                      ? moment(e.providerEngagedAt).format("MM/DD/YY h:mma")
                      : "--"}
                  </td>
                  <td>
                    {e.providerStartedAt
                      ? moment(e.providerStartedAt).format("MM/DD/YY h:mma")
                      : "--"}
                  </td>
                  <td>
                    {e.providerFinishedAt
                      ? moment(e.providerFinishedAt).format("MM/DD/YY h:mma")
                      : "--"}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      )}
    </>
  );
};
type Props = {
  items: IEvent["events"];
  idx: any;
  show: string[];
  handleOpen: (idxs: number[]) => void;
  handleClose: (idx: number) => void;
  type: string;
  indexes: number[];
};

const IFrameEventsBlock: FC<Props> = ({ items, idx, type, show, handleOpen, handleClose, indexes }) => {
  if (!items) {
    return null;
  }

  const title =
    (type === "user-encounters-iframe-logger" ? "Patient's" : "Provider's") + " Video Room Events ";

    return (
    <>
      {!show.includes(idx) && (
        <td onClick={() => handleOpen(indexes)} style={{ cursor: "pointer" }}>
          {title}
          <Octicon size={12} icon={Plus} verticalAlign="middle" />
        </td>
      )}
      {show.includes(idx) && (
        <td id={idx}>
          <span style={{ cursor: "pointer" }} onClick={() => handleClose(idx)}>
            {title}
            <Octicon size={12} icon={Dash} verticalAlign="middle" />
          </span>
          {items.map((event, id) => (
            <tr style={{ border: "none" }} className="mt-5 pt-3" key={id + event.description}>
              <td style={{ border: "none" }} className="pl-0 ">
                {moment(event.createdAt).format("MM/DD/YY h:mm:ss a")}
              </td>
              <td id={`uncontrolledTooltipExample-${id}`} title={event.details && event.details} style={{ border: "none", cursor: event.details && "pointer" }}>
                {event.description} <br/>
                {event.details && `Browser: ${browserNameParser(event.details.toLowerCase())}, OS: ${OSParser(event.details.toLowerCase())}, ${isMobileOS(event.details.toLowerCase()) ? ("Mobile OS: " + isMobileOS(event.details.toLowerCase())) : ""}`}</td>
            </tr>
          ))}
        </td>
      )}
    </>
  );
};

interface DetailsProps {
  inProgress?: boolean;
  data: any;
  onUnload: () => void;
  encounterID: string;
  encounterList: Function;
  onLoad: Function;
  fromActiveEncounter: boolean
}

interface DetailsState {
  info: string | null;
  error: string | null;
  provider: any;
  pdfOpen: boolean;
  chatHistory: IChatHistory | null;
  timelines: IEncounterTimelines | null;
  smsTimelines: IEncounterSMSTimelines | null;
  showEncounterStates: boolean;
  showIFrameEvents: string[];
  loadingSMSTLine: boolean;
  size: number;
  snapButton: boolean;
  snapButtonText: string;
}

class Details extends Component<DetailsProps, DetailsState> {
  constructor(props) {
    super(props);
    this.state = {
      info: "",
      error: "",
      provider: null,
      pdfOpen: false,
      chatHistory: null,
      timelines: null,
      showEncounterStates: false,
      showIFrameEvents: [],
      smsTimelines: null,
      loadingSMSTLine: false,
      size: 5,
      snapButton: false,
      snapButtonText: "Capture Debugging Info"
    };
  }

  pdfOpen = () => {
    this.setState({ pdfOpen: true });
  };

  pdfClose = () => {
    this.setState({ pdfOpen: false });
  };

  providerChange = (provider) => {
    this.setState({ provider });
  };

  onAssign = async (providerID: string, referenceID: string) => {
    try {
      let result = await api.Encounters.assign(referenceID, providerID);
      this.setState({ info: "Encounter assigned" });
      setTimeout(() => this.setState({ info: null }), 3000);
    } catch (err: any) {
      // this.setState({ error: "Error assigning encounter" });
      bugsnagClient.notify(err);
      const status = err.response.status;
      if (status === 422) {
       errorLogger(err)
      }
    }
  };

  encounterSnapshot = async (referenceID: string) => {
    api.Monitoring.encounterSnapshot(referenceID)
    .then(() => {
      this.setState({ snapButton: true });
      this.setState({ snapButtonText: "Info Sent" });
    })
    .catch((err: any) => {
      bugsnagClient.notify(err);
      const status = err.response.status;
      if (status >= 400) {
       errorLogger(err)
      }
    });
  };

  handleTimeline = () => {
    this.setState({ showEncounterStates: !this.state.showEncounterStates });
  };

  handleShowIFrameEvents = (idxs) => {
    this.setState({ showIFrameEvents: idxs });
  };
  handleCloseIFrameEvent = (idx) => {
    
    if (this.state.showIFrameEvents.includes(idx)) {
      const removed = this.state.showIFrameEvents.filter( val => val != idx);
     
      this.setState({
        // return all elements that are not equal to the passed idx
        showIFrameEvents: removed,
      });
    }
  }

  handleSizeChange = () => {
    if(this.state.size === 5) {
      this.setState({ size: 0 });
    }else{
      this.setState({ size: 5 });
    }
  }

  async componentDidMount() {
    await this.props.onLoad(
      api.Monitoring.encounterDetails(this.props.encounterID).then((data: IMonitorEncounter) => {
        if (data.asyncChat) {
          api.Encounters.encounterHistoryChat(this.props.encounterID).then(
            (chatHistory: IChatHistory) => {
              this.setState({ chatHistory: chatHistory });
            },
          );
        }
        try {
          if (data.referenceID) {
            api.Monitoring.encounterTimelines(data.referenceID).then((timelines) => {
              this.setState({ timelines });
            });
            api.Monitoring.encounterSMSTimelines(data.referenceID, this.state.size).then((smsTimelines) => {
              this.setState({ smsTimelines });
              
            });
          }
        } catch (e) {}
        this.setState({ loadingSMSTLine: false });
        return data;
      }),
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.size !== this.state.size) {
      this.setState({loadingSMSTLine: true});
      try{
        api.Monitoring.encounterSMSTimelines(this.props.encounterID, this.state.size).then((smsTimelines) => {
          this.setState({ smsTimelines });
          this.setState({loadingSMSTLine: false});
        });
      }catch(err){
        console.log(err)
      }
      
    }
  }

  componentWillUnmount() {
    this.props.onUnload();
  }

  render() {
    const visitType: string = this.props.data && this.props.data.visitType;
    

    const queryHandler = () => {
      history.back(); 

    };
    const indexes = ((this.state.timelines && this.state.timelines?.events) || []).flatMap(({type}, idx) => type === "user-encounters-iframe-logger" || type ===  "provider-encounters-video-room-logger" ? idx : []);

    return (
      <div>
        {this.state.pdfOpen && <PDFView onClose={this.pdfClose} url={this.props.data.noteURL} />}
        {!this.props.fromActiveEncounter && <NavBar />}
        <div className="dashboard-container">
          <div className="encounter-monitor-details align-content-stretch">
            {this.props.inProgress && (
              <div className="d-flex flex-column w-100 align-items-center">
                <Spinner />
              </div>
            )}

            {this.props.data && (
              <>
                <div className="encounter-monitor-details-header justify-content-between">
                  <Row>
                    <Col className="col-5 d-flex">
                      <div>
                        <span className="navigate" onClick={queryHandler}>
                          <Arrow />
                          Back to list
                        </span>
                        <h4>Encounter Detail</h4>
                      </div>
                    </Col>
                    {!this.props.permissions.includes("noPHI") && (
                      <Col className="d-flex justify-content-end align-content-center">
                        {this.props.data && (
                          <EncounterAssignment
                            encounter={this.props.data}
                            onAssign={this.onAssign}
                            provider={this.state.provider}
                            providerChange={this.providerChange}
                          />
                        )}
                        <div>
                          <PDFButton
                            url={this.props.data.noteURL}
                            encounterKey={this.props.data.referenceID}
                            showEditButton={true}
                            disabled={!this.props.data.noteURL}
                            className="ml-3 pdf-btn"
                          >
                            View PDF Note
                          </PDFButton>
                        </div>
                        <div>
                          <Button
                            onClick={() => this.encounterSnapshot(this.props?.data?.referenceID)}
                            className="ml-3 pdf-btn"
                            color="primary"
                            disabled={this.state.snapButton}
                          >
                          {this.state.snapButtonText}
                        </Button>
                        </div>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Col>
                      <div>
                        {this.state.error && <Alert color="danger">{this.state.error}</Alert>}
                        {this.state.info && <Alert color="primary">{this.state.info}</Alert>}
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="section">
                  <div className="section-title">
                    <span>Encounter Information</span>
                  </div>
                  <div className="section-content">
                    {this.props.data && <EncounterSummary encounter={this.props.data} />}
                    <div>
                      <Row>
                        <Col>
                          {this.props.data && (
                            <PatientDetails
                              patient={this.props.data.patient}
                              practice={this.props.data?.practice}
                            />
                          )}
                        </Col>
                        <Col>
                          {this.props.data && (
                            <ProviderDetails provider={this.props.data.provider} />
                          )}
                        </Col>

                        <Col>
                          {this.props.data && <ScribeDetails scribe={this.props.data.scribe} />}
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <SectionView title="Visit Timeline" className="blue">
                            <Table>
                              <thead>
                                <tr>
                                  <th>Time</th>
                                  <th>Event</th>
                                </tr>
                              </thead>
                              <tbody>
                                {((this.state.timelines && this.state.timelines?.events) || []).map(
                                  (item, idx) => {
                                    
                                    return (
                                      <>
                                        <tr>
                                          <td>
                                            {moment(item.createdAt).format("MM/DD/YY h:mm:ss a")}
                                          </td>

                                          {item.type !== "user-encounters-iframe-logger" &&
                                          item.type !== "provider-encounters-video-room-logger" ? (
                                            <td>
                                              {item.type}
                                              {item?.payload && item?.type === "encounter-assignment" && item?.payload?.details ? 
                                              <>
                                              <div>({`Assigned By: ${item?.payload?.details}`})</div>
                                              <div>({`Assigned To: ${item?.payload?.providerName}`})</div>
                                              </>
                                              : item?.payload ?
                                              (
                                                <div> ({item.payload.providerName || item.payload.details})</div>
                                              ) : (
                                                ""
                                              )}
                                            </td>
                                          ) : (
                                            <IFrameEventsBlock
                                              type={item.type}
                                              items={item.events}
                                              idx={idx}
                                              show={this.state.showIFrameEvents}
                                              handleOpen={this.handleShowIFrameEvents}
                                              handleClose={this.handleCloseIFrameEvent}
                                              indexes={indexes}
                                            />
                                          )}
                                        </tr>
                                      </>
                                    );
                                  },
                                )}
                              </tbody>
                            </Table>
                          </SectionView>
                        </Col>
                        <Col>
                          <SectionView title="EMR System Details" className="blue">
                            {this.props.data && (
                              <SystemDetails
                                encounter={this.props.data}
                                showEncounterStates={this.state.showEncounterStates}
                                handleTimeline={this.handleTimeline}
                              />
                            )}
                          </SectionView>
                        </Col>
                      </Row>
                      {this.props.permissions.includes("recentEncounter") && (
                        <Row>
                          <Col>
                            <SectionView title="SMS Timeline" className="blue">
                              <Table>
                                <thead>
                                  <tr>
                                    <th>Time</th>
                                    <th>Recipient Phone</th>
                                    <th>Recipient Name</th>
                                    <th>Message</th>
                                    <th>Error</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {(
                                    (this.state.smsTimelines &&
                                      this.state.smsTimelines?.smsEvents) ||
                                    []
                                  ).map((item, idx) => {
                                    return (
                                      <>
                                        <tr>
                                          <td>{moment(item.createdAt).format("MM/DD/YY h:mma")}</td>
                                          <td>{item.recipientPhone}</td>
                                          <td>{item.recipientName}</td>
                                          <td style={{ width: "30%" }}>{item.message}</td>
                                          <td>{item.twilioError || "--"}</td>
                                        </tr>
                                      </>
                                    );
                                  })}
                                </tbody>
                              </Table>
                              <div className="d-flex justify-content-center">
                                {this.state.loadingSMSTLine ? <Button className="m-auto" color="primary" style={{width: "121px"}} disabled><ButtonSpinner inProgress={this.state.loadingSMSTLine }/></Button> : <Button className="m-auto" onClick={this.handleSizeChange} style={{width: "121px"}} color="primary">{this.state.size === 5 ? "Load More" : "Show Less"}</Button>}
                              </div>
                            </SectionView>
                          </Col>
                        </Row>
                      )}
                    </div>
                  </div>
                </div>
                {!this.props.permissions.includes("noPHI") && (
                  <div className="section">
                    <div className="section-title">
                      <span>Provider Encounter Preview</span>
                    </div>
                    <div className="section-content">
                      {this.state.chatHistory && (
                        <CommunicationHistory
                          chatHistory={this.state.chatHistory}
                          encounter={this.props.data}
                        />
                      )}
                      {this.props.data && (
                        <div className="encounter-tab">
                          <h5 className="ml-2">Patient Info</h5>
                          <PatientInfo
                            medicalProfile={this.props.data.medicalProfile}
                            medicalIntake={this.props.data.medicalIntake}
                            patient={this.props.data.patient}
                            visitType={this.props.data.visitType}
                            readOnly={true}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Details);
