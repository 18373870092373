import React from "react";
import PropTypes from "prop-types";
import Select, { components } from "react-select";

import CheckMark from "../images/CheckMark.svg";

const stringToSelectItem = (s) => {
  if (typeof s === "string") {
    return { value: s, label: s };
  }
  return s;
};
const selectItemToString = (so) => {
  return so.label;
};
const EmptyComponent = () => {
  return null;
};

const CheckOption = (props) => {
  const cl = props.isFocused ? "d-flex flex-row option focused" : "d-flex flex-row option";
  const cb = props.isSelected ? "checkbox selected" : "checkbox";
  return (
    <div className={cl} {...props.innerProps}>
      <div className={cb}>{props.isSelected && <CheckMark />}</div>
      <div className="label">{props.label}</div>
    </div>
  );
};

const ValueContainer = ({ children, ...props }) => {
  const label = props.selectProps.label;
  return (
    <components.ValueContainer {...props}>
      <span>{label}</span>
      {children}
    </components.ValueContainer>
  );
};

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: 300,
  }),
  control: () => ({
    width: 112,
    display: "flex",
    "border-radius": 150,
    border: "2px solid #DEDEDE",
    "font-weight": "bold",
  }),
  valueContainer: (provided) => ({
    ...provided,
    "padding-right": 0,
  }),
  dropdownIndicator: (provided, state) => {
    return {
      ...provided,
      color: state.getValue().length > 0 ? "#4A4A4A" : "#DEDEDE",
    };
  },
};

const CheckSelect = ({ options, selected, onChange, label }) => {
  const sSelected = (selected || []).map(stringToSelectItem);
  const sOptions = (options || []).map(stringToSelectItem);
  const cl =
    selected.length > 0 ? "info-select drop-down-select selected" : "info-select drop-down-select";

  const onSelect = (newValues) => {
    onChange(newValues.map(selectItemToString));
  };

  return (
    <Select
      value={sSelected}
      label={label}
      components={{
        IndicatorSeparator: EmptyComponent,
        ValueContainer,
        MultiValueContainer: EmptyComponent,
        Option: CheckOption,
      }}
      className={cl}
      menuPlacement="top"
      onChange={onSelect}
      isClearable={false}
      hideSelectedOptions={false}
      isSearchable={false}
      closeMenuOnSelect={false}
      placeholder=""
      options={sOptions}
      isMulti={true}
      styles={customStyles}
    />
  );
};

CheckSelect.propTypes = {
  options: PropTypes.array,
  selected: PropTypes.array,
  onChange: PropTypes.func,
  label: PropTypes.string,
};

export default CheckSelect;
