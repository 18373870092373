import React, { FC } from "react";
import { Label } from "reactstrap";

import BlockSection from "./BlockSection";
import ListEditor from "./ListEditor";
import YesNoToggle from "./YesNoToggle";
import { IAllergySocialHistory } from "../../constants/Types";

type Props = {
  socialHistory: IAllergySocialHistory;
  oldSocialHistory: IAllergySocialHistory;
  onAllergyHistoryChange: (field: string) => (newValue: any) => void;
};

const AllergyOccupationalHistoryEditor: FC<Props> = ({
  socialHistory,
  oldSocialHistory,
  onAllergyHistoryChange,
}) => {
  return (
    <BlockSection title={"Occupation"}>
      <div className="form-row mb-3">
        <div className="col-6 col-sm-5 pr-4">
          <Label>Primary Occupation</Label>
          <input
            className="occupation-input w-100"
            value={socialHistory.allergyOccupationalHistory.primaryOccupation}
            onChange={(e) => onAllergyHistoryChange("primaryOccupation")(e.target.value)}
          />
        </div>
        <div className="col-6 col-sm-4">
          <Label>Work outside?</Label>
          <YesNoToggle
            value={socialHistory.allergyOccupationalHistory.workOutside}
            oldValue={oldSocialHistory.allergyOccupationalHistory.workOutside}
            onChange={onAllergyHistoryChange("workOutside")}
          />
        </div>
      </div>
      <div className="form-row mb-3 mt-3">
        <div className="col-6 col-sm-4 mr-4">
          <Label>Exposed to dust at work?</Label>
          <YesNoToggle
            value={socialHistory.allergyOccupationalHistory.dustExposure}
            oldValue={oldSocialHistory.allergyOccupationalHistory.dustExposure}
            onChange={onAllergyHistoryChange("dustExposure")}
          />
        </div>
        <div className="col-6 col-sm-4 mr-4 pr-0 ">
          <Label>Exposed to mold at work?</Label>
          <YesNoToggle
            value={socialHistory.allergyOccupationalHistory.moldExposure}
            oldValue={oldSocialHistory.allergyOccupationalHistory.moldExposure}
            onChange={onAllergyHistoryChange("moldExposure")}
          />
        </div>
        <div className="col-6 col-sm-3 pr-0 pl-0">
          <Label className="single-line-label">Work with chemicals?</Label>
          <YesNoToggle
            value={socialHistory.allergyOccupationalHistory.chemicalExposure}
            oldValue={oldSocialHistory.allergyOccupationalHistory.chemicalExposure}
            onChange={onAllergyHistoryChange("chemicalExposure")}
          />
        </div>
      </div>
      {socialHistory.allergyOccupationalHistory.chemicalExposure && (
        <div className="form-row mt-3">
          <div>
            <Label>Which chemicals?</Label>
            <ListEditor
              items={socialHistory.allergyOccupationalHistory.chemicalList}
              oldItems={oldSocialHistory.allergyOccupationalHistory.chemicalList || []}
              itemListSource={() => ({ items: [] })}
              onChange={onAllergyHistoryChange("chemicalList")}
            />
          </div>
        </div>
      )}
    </BlockSection>
  );
};

export default AllergyOccupationalHistoryEditor;
