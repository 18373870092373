import React, { useState, useCallback, useEffect, FC } from "react";
import { connect } from "react-redux";
import { FormGroup, Input, Table, Button, Row, Col } from "reactstrap";
import Select from "react-select";
import moment from "moment";
import classNames from "classnames";
import debounce from "lodash.debounce";
import { push } from "connected-react-router";

import api from "../../api";
import { store } from "../../store";
import { waitingRoomVisitTypeLookup } from "../../constants/Encounter";
import PDFButton from "../PDFButton";
import CheckToggle from "../CheckToggle";

import styles from "./billingSearch.scss";
import { selectStyles } from "./billingStyles";
import PatientNoteIcon from "../../images/PatientNoteIcon.svg";
import InsuranceIcon from "../../images/InsuranceIcon.svg";
import FaceSheetIcon from "../../images/FacesheetIcon.svg";
import DownloadIcon from "../../images/DownloadIcon.svg";
import Spinner from "../../images/Spinner.svg";
import X from "../../images/X.svg";
import { IAppState } from "../../reducer";
import { IEncounterSearchItem, IEncounterSearchOptions } from "../../constants/Types";

const PAGE_SIZE = 10;

type EncounterRowProps = {
  encounter: IEncounterSearchItem;
  toggleSubmitted: (encounterReference: string, claimStatus: boolean) => void;
};

const EncounterRow: FC<EncounterRowProps> = ({ encounter, toggleSubmitted }) => {
  const vd = moment(encounter.dateOfVisit);
  const [isChecked, setIsChecked] = useState(encounter.claimSubmitted);
  const viewUser = (userID: string) => {
    store.dispatch(push(`/patients/${userID}`));
  };

  const handleClick = () => {
    setIsChecked(!isChecked);
    toggleSubmitted(encounter.key!, !encounter.claimSubmitted);
  };

  return (
    <Row className={styles.billingRow}>
      <Col className={styles.billingCol} md="1">
        <span>Submitted</span>
        <CheckToggle
          onChange={handleClick}
          checked={isChecked}
          className="qa-billingSubmittedToggle"
        />
      </Col>
      <Col className={styles.billingCol} md="2">
        <Button
          color="link"
          className={classNames(styles.patientLink, "qa-billingTablePatientName")}
          onClick={() => viewUser(encounter.userID)}
        >
          {encounter.patientName}
        </Button>
      </Col>
      <Col className={classNames(styles.billingCol, styles.cellWithTitle)} md="2">
        <span>Visit Type</span>
        {waitingRoomVisitTypeLookup[encounter.visitType] ||
          encounter.chiefComplaint ||
          encounter.visitType}
      </Col>
      <Col className={classNames(styles.billingCol, styles.cellWithTitle)} md="1">
        <span>Visit Date</span>
        {vd.format("MM/DD/YYYY")}
      </Col>
      <Col className={classNames(styles.billingCol, styles.cellWithTitle)} md="1">
        <span>ICD 10 Code</span>
        <div className="d-flex flex-column">
          {encounter.icd10Codes &&
            encounter.icd10Codes.map((code, idx) => {
              return (
                <div className={`${styles.codeList} qa-billingICDCode`} key={idx}>
                  {code}
                </div>
              );
            })}
        </div>
      </Col>
      <Col
        className={classNames(styles.billingCol, styles.cellWithTitle, "qa-billingProviderName")}
        md="2"
      >
        <span>Provider</span>
        {encounter.providerName}
      </Col>
      <Col className={classNames(styles.billingCol, styles.cellWithTitle)} md="1">
        <Row>
          <Col>
            <span>CPT Codes</span>
            <div className="d-flex flex-column codeList qa-billingCPTCode">
              <div className={styles.codeList}>{encounter.cptCodes.emCode || "none"}</div>
            </div>
          </Col>
        </Row>
        {encounter.cptCodes.modifier && (
          <Row>
            <Col>
              <span>Modifier </span>
              <div className="d-flex flex-column codeList qa-billingModifier">
                <div className={styles.codeList}>{encounter.cptCodes.modifier}</div>
              </div>
            </Col>
          </Row>
        )}
      </Col>
      <Col className={styles.billingCol} md="2">
        <div className="d-flex flex-column justify-content-between h-100 w-100">
          <div className="d-flex justify-content-around">
            {encounter.facesheetURL && (
              <PDFButton
                url={encounter.facesheetURL}
                encounterKey={encounter.key!}
                style="dashboard-button"
                id="facesheetPDFBtn"
                className="qa-billingFaceSheetButton"
              >
                <FaceSheetIcon />
              </PDFButton>
            )}

            <PDFButton
              url={encounter.insuranceURL!}
              encounterKey={encounter.key!}
              style="dashboard-button"
              id="insurancePDFBtn"
              className="qa-billingInsuranceButton"
            >
              <InsuranceIcon />
            </PDFButton>
            <PDFButton
              url={encounter.noteURL!}
              encounterKey={encounter.key!}
              style="dashboard-button"
              id="notePDFBtn"
              showEditButton={false}
              className="qa-billingPDFButton"
            >
              <PatientNoteIcon />
            </PDFButton>
          </div>
          {!!encounter.isEnhanced ? (
            <div className={styles.enhancedVisitTag}>Enhanced Visit</div>
          ) : !!encounter.clinicalServicesUsed ? (
            <div className={styles.clinicalServicesTag}>Clinical Services Used</div>
          ) : null}
        </div>
      </Col>
    </Row>
  );
};

type PaginationBlockProps = {
  pageStart: number;
  totalCount: number;
  onChange: (i: number) => void;
  id: string;
};

const PaginationBlock: FC<PaginationBlockProps> = ({ pageStart, totalCount, onChange, id }) => {
  const totalPages = totalCount / PAGE_SIZE;
  const currentPage = pageStart / PAGE_SIZE;

  let previousList: number[] = [];
  let nextList: number[] = [];
  let ci = pageStart;
  if (currentPage > 0) {
    while (previousList.length < 2 && ci > 0) {
      ci -= PAGE_SIZE;
      previousList.unshift(ci);
    }
  }
  ci = pageStart;
  if (currentPage < totalPages) {
    while (nextList.length + previousList.length < 4 && ci + PAGE_SIZE < totalCount) {
      ci += PAGE_SIZE;
      nextList.push(ci);
    }
  }

  return (
    <div className={styles.paginationBlock} id={id}>
      {previousList.map((i) => (
        <div key={i}>
          <Button
            color="link"
            id="billingPageBtn"
            onClick={() => onChange(i)}
            className="qa-billingPageBtn"
          >
            {i / PAGE_SIZE + 1}
          </Button>
        </div>
      ))}
      <div className={styles.currentPage}>{currentPage + 1}</div>
      {nextList.map((i) => (
        <div key={i}>
          <Button
            color="link"
            id="billingPageBtn"
            onClick={() => onChange(i)}
            className="qa-billingPageBtn"
          >
            {i / PAGE_SIZE + 1}
          </Button>
        </div>
      ))}
    </div>
  );
};

const views = [
  { label: "Unsubmitted", value: false },
  { label: "Submitted", value: true },
  { label: "All", value: null },
];

const BillingSearch: FC<Props> = ({ currentUser }) => {
  if (!currentUser) {
    return (
      <div className="dashboard-component d-flex justify-content-center">
        <Spinner />
      </div>
    );
  }

  const [patientName, setPatientName] = useState("");
  const [debouncedPatientName, setDebouncedPatientName] = useState("");
  const [currentView, setCurrentView] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageStart, setPageStart] = useState(0);
  const [items, setItems] = useState<IEncounterSearchItem[]>([]);

  useEffect(() => {
    setInProgress(true);
    let providerID = "";

    fetchData({
      pageSize: PAGE_SIZE,
      patientName: debouncedPatientName,
      pageStart,
      providerID,
      insuranceUsed: true,
      claimSubmitted: currentView,
    });
  }, [debouncedPatientName, pageStart, currentUser, currentView]);

  const debouncedSetName = useCallback(debounce(setDebouncedPatientName, 375), []);

  const fetchData = async (query: IEncounterSearchOptions) => {
    try {
      let result = await api.Records.search(query);
      setItems(result.items || []);
      setTotalCount(result.totalCount);
    } finally {
      setInProgress(false);
    }
  };

  const handlePatientNameChange = useCallback((newName) => {
    setPatientName(newName);
    setPageStart(0);
    setTotalCount(0);
    debouncedSetName(newName);
    setInProgress(true);
  }, []);

  const handleViewChange = (view: boolean) => {
    setCurrentView(view);
    setPageStart(0);
  };

  const handlePageChange = useCallback((ps) => {
    setPageStart(ps);
    setInProgress(true);
  }, []);

  const toggleSubmitted = (encounterReference: string, claimStatus: boolean) => {
    api.Records.markClaimSubmitted(encounterReference, claimStatus).then(() => {
      setTimeout(
        () =>
          fetchData({
            pageSize: PAGE_SIZE,
            patientName: debouncedPatientName,
            pageStart,
            providerID: "",
            insuranceUsed: true,
            claimSubmitted: currentView,
          }),
        1000,
      );
    });
  };

  const handleDownload = () => {
    api.Reporting.PCRReport().then((response) => {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.csv");
      document.body.appendChild(link);
      link.click();
    });
  };

  const showNameClear = !!patientName;
  return (
    <div
      className={classNames(
        "dashboard-component",
        "table-container",
        styles.billingSearchContainer,
      )}
    >
      <div className="billing-header">
        <h1>All Recent Visits</h1>
        <div
          className="download-btn qa-billingCovidDownloadBtn"
          id="covidReportDownload"
          onClick={handleDownload}
        >
          <span>Download COVID-19 Testing Report</span>
          <div>
            <DownloadIcon />
          </div>
        </div>
      </div>
      <div className={styles.filterBar}>
        <FormGroup className="d-flex">
          <Input
            placeholder="Patient name..."
            value={patientName}
            id="billingSearch"
            onChange={(event) => handlePatientNameChange(event.target.value)}
            className="qa-billingPatientNameSearch"
          />
        </FormGroup>
        <div className="select-container">
          <Select
            styles={selectStyles}
            id="submittedFilter"
            hideSelectedOptions={true}
            options={views}
            isSearchable={false}
            value={views.find((views) => views.value === currentView)}
            onChange={(item: any) => handleViewChange(item.value as boolean)}
            className="qa-billingSubmittedFilter"
          />
        </div>
        {showNameClear && (
          <>
            <div className={styles.filterIndicator}>&bull;</div>
            <Button
              color="link"
              className={classNames(styles.clearFilterButton, "qa-billingClearFilterBtn")}
              id="clearFilterBtn"
              onClick={() => handlePatientNameChange("")}
            >
              <X />
            </Button>
          </>
        )}
        <div className="ml-auto">
          <PaginationBlock
            pageStart={pageStart}
            totalCount={totalCount}
            id="paginationBlock"
            onChange={handlePageChange}
          />
        </div>
      </div>
      {inProgress ? (
        <div className="w-100 d-flex justify-content-center">
          <Spinner />
        </div>
      ) : (
        <Table className={styles.billingTable}>
          <tbody>
            {items.map((item) => (
              <EncounterRow key={item.key} encounter={item} toggleSubmitted={toggleSubmitted} />
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

type Props = ReturnType<typeof mapStateToProps>;

const mapStateToProps = (state: IAppState) => ({
  permissions: state.common.permissions,
  currentUser: state.common.currentUser,
});

export default connect(mapStateToProps)(BillingSearch);
