import React, { Component } from "react";
import Octicon, { Plus } from "@githubprimer/octicons-react";
import classNames from "classnames";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import isEqual from "react-fast-compare";

import NavBar from "../Provider/NavBar";
import DashboardIcon from "../../images/DashboardIcon.svg";
import ProviderList from "./List";
import api from "../../api";
import List from "../ProviderManagement/List";
import Search from "../ProviderManagement/Search";
import { COMPONENT_LOAD, COMPONENT_UNLOAD, NAVIGATE_TO, PROVIDERS_FILTERED_LIST } from "../../constants/actionTypes";
import {
  hasPermission,
  PERMISSION_PAGE,
  PERMISSION_MANAGE,
  PERMISSION_SUPERVISING_PHYSICIAN,
} from "../../constants/Permissions";
import { ICurrentUser } from "../../constants/Types";
import "../ProviderManagement/search.scss"
import TableColumnsModal from "../ProviderManagement/TableModal"
import { defaultSearchParameters } from "../../constants/Providers";



type Props = {
  currentUser: ICurrentUser;
  onNewProvider: () => any;
  onLoadProviders: (queryData) => any;
  onUnloadProviders: () => any;
  data: any;
};

const mapStateToProps = (state, ownProps) => ({
  currentUser: state.common.currentUser,
  ...state.componentData[PROVIDERS_FILTERED_LIST],
  
});

const mapDispatchToProps = (dispatch) => ({
  onNewProvider: () => dispatch({ type: NAVIGATE_TO, targetPath: "/providers/new" }),
  onLoadProviders: (payload) => 
  dispatch({
    type: COMPONENT_LOAD,
    subType: PROVIDERS_FILTERED_LIST,
    payload,
  }),
  onUnloadProviders: () => dispatch({
    type: COMPONENT_UNLOAD,
    subType: PROVIDERS_FILTERED_LIST,
  })
});

class Providers extends Component<Props> {
  state = {
    scope: "all",
    listItems: [],
    searchValue: "",
    filters: {
      name: "",
      location: "",
      status: "Active",
    },
    isLoading: false,
    searchQuery: defaultSearchParameters,
    page: 0,
    pageSize: 50,
    pageStart: 0,
    totalCount: 0,
  };

  refreshList = async () => { 
    this.setState({ isLoading: true });

    let searchQuery = { ...this.state.searchQuery };
    // convert all the arrays in the query to strings
    for (const [key, value] of Object.entries(searchQuery)){
      if(Array.isArray(searchQuery[key]) && searchQuery[key].length > 0){
        searchQuery[key] = searchQuery[key].toString()
      }
    } 
    try{
      await this.props.onLoadProviders(
        api.Providers.providerFilter(searchQuery).then((data) => {
        this.setState({ listItems: data?.items || [] , totalCount: data?.totalCount || 0, pageStart: data?.pageStart, pageSize: data?.pageSize, isLoading: false });
      }));
    }catch(error){
      console.log(error)
      this.setState({ isLoading: false });
    }
    
  };

  

  componentDidMount() {
    this.refreshList();
  }

  componentDidUpdate(prevProps: Props, prevState) {
    if (!!prevProps.currentUser && this.props.currentUser && !isEqual(prevProps.currentUser, this.props.currentUser) || prevState.searchQuery !== this.state.searchQuery) {
      // current user changed since the last update, refresh the list
      this.refreshList();
    }
  }

  componentWillUnmount() {
    this.props.onUnloadProviders();
  }

  dropSearch = (field, search) => {
    this.setState({ filters: { ...this.state.filters, [field]: search } });
  };


  handleScopeChange = (scope) => {
    this.setState({scope: scope});
    this.setState({ searchQuery: {...this.state.searchQuery, scope: scope} }, () => {
      this.refreshList();
    });
  };

  handleSearchParams = (searchParams) => {
    this.setState({searchQuery: searchParams})
  }

  handleCancel = () => {
    this.setState({searchQuery: defaultSearchParameters, page: 0, pageStart: 0, pageSize: 50} , () => {
      this.refreshList();
    });
  }

  handlePageChange = (page, pageStart, pageSize) => {
    this.setState({ page, pageStart, pageSize });
    this.setState({ searchQuery: {...this.state.searchQuery, page: page, pageStart: pageStart, pageSize: pageSize} }, () => {
      this.refreshList();
    }
    );
  }

  render() {
    const permissions = this.props.currentUser?.permissions || [];
    const isManager = hasPermission(permissions, PERMISSION_MANAGE);
    const isSupervisingPhysician = hasPermission(permissions, PERMISSION_SUPERVISING_PHYSICIAN);

    return (
      <div>
        <NavBar />

        <div className=" manage-provider-container dashboard-component mb-3 ml-0 mr-0">
          <div className="d-flex w-100 justify-content-between " style={{height: "60px"}}>

          
              <div className="provider-toggle d-flex">
                <span className="d-flex">
                <h1
                  className={classNames(
                    "mr-3 ml-2",
                    "option",
                    {
                      selected: (this.state.scope === "all"),
                    },
                    "qa-allProvidersButton",
                  )}
                  onClick={() => isSupervisingPhysician && this.handleScopeChange("all")}
                >
                  All
                </h1>
                {isSupervisingPhysician && (
                <h1
                  className={classNames(
                    "option",
                    {
                      selected: (this.state.scope === "supervised"),
                    },
                    "qa-myProvidersButton", 
                  )}
                  onClick={() => this.handleScopeChange("supervised")}
                >
                  My Providers
                </h1>
                 )}
                </span>
              </div>
           
            {isManager && (
              <div className="">
                <Button
                  color="success"
                  className="add-new-provider-button qa-addNewProviderButton mb-3"
                  onClick={this.props.onNewProvider}
                >
                  Add New Provider
                </Button>
              </div>
            )}
          </div>
          <div className="align-content-stretch ">  
              <List
               isLoading={this.state.isLoading}
                items={this.state.listItems}
                dropSearch={this.dropSearch}
                allProviders={this.state.listItems}
                filters={this.state.filters}
                handleSearchParams={this.handleSearchParams}
                handleCancel={this.handleCancel}
                onPageChange={this.handlePageChange}
                page={this.state.page}
                pageStart={this.state.pageStart}
                pageSize={this.state.pageSize}
                totalCount={this.state.totalCount}
              />
           
          </div>
        </div>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Providers);
