import React from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";

const ThreeWayToggle = (props) => {
  const setValue = (v) => (ev) => {
    ev.preventDefault();
    if (props.onChange) {
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'v' implicitly has an 'any' type.
      props.onChange(v);
    }
  };
  const edited = props.value != props.oldValue;
  const cl = edited ? "yes-no-toggle d-flex edited" : "yes-no-toggle d-flex";
  return (
    <div className={cl}>
      <Button
        className={props.value === "improve" ? "mr-3 selected" : "mr-3"}
        outline
        onClick={setValue("improve")}
      >
        Improve
      </Button>
      <Button
        className={props.value === "worsen" ? "mr-3 selected" : "mr-3"}
        outline
        onClick={setValue("worsen")}
      >
        Worsen
      </Button>
      <Button
        className={props.value === "no effect" ? "selected" : ""}
        outline
        onClick={setValue("no effect")}
      >
        No Effect
      </Button>
    </div>
  );
};

ThreeWayToggle.propTypes = {
  value: PropTypes.string.isRequired,
  oldValue: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default ThreeWayToggle;
