import React, { useEffect, useState } from "react";
import { featureSetAttributes, findFeature } from "../PhysicalExam/Features";
import EntryBlock from "./EntryBlock";
import FeatureEntry from "./FeatureEntry";
import { NoteEntry } from "./Notes";
import ButtonGroupEntry from "./ButtonGroupEntry";
import FeatureCheckBoxes from "./FeatureCheckBoxes";
import PropTypes from "prop-types";
import ContentBLock from "../Encounter/ContentBlock";
import PhysicalExam from "../Encounter/PhysicalExam";

const GeneralSection = ({
  physicalExam,
  onFeatureUpdate,
  visitType,
  onChange,
  onCheckboxUpdate,
  isNursingHomeSite,
  onRemoveSection,
}) => {
  const [hideContent, setHideContent] = useState(true);
  const content = physicalExam.notes || "";
  const appearance = findFeature(physicalExam.features, "appearance", "Appearance", "General");
  const build = findFeature(physicalExam.features, "build", "Build", "General");
  const nutrition = findFeature(physicalExam.features, "nutrition", "Nutrition", "General");

  const setAttribute = featureSetAttributes({ onChange: onFeatureUpdate });

  const setCheckBox = (target) => {
    onCheckboxUpdate(physicalExam.features, "General", target.checked, target.value);
  };

  const anyChecked = (val) => {
    const foundIdx = (physicalExam.features || []).findIndex((e) => e.group === "General");
    if (foundIdx < 0) {
      return false;
    }

    return physicalExam.features[foundIdx][val];
  };

  const hasEntires = () => {
    const foundIdx = (physicalExam.features || []).findIndex((e) => e.group === "General");
    if (foundIdx < 0) {
      return false;
    }
    return !!physicalExam.features[foundIdx].attributes;
  };


  useEffect(() => {
    setHideContent(!hasEntires());
  }, []);

  const handleRemoveSection = () => {
    onRemoveSection("General");
    setHideContent(true);
  };

  if (hideContent) {
    return (
      <ContentBLock
        title="General"
        titleOnly={true}
        className="hide-content"
        onClick={() => setHideContent(false)}
      />
    );
  }
  return (
    <EntryBlock title="General" className="large">
      <div>
        <FeatureEntry
          feature={appearance}
          values={["NAD", "Acute distress"]}
          onChange={setAttribute}
          id="qa-general-entry-appearance"
        />
        {isNursingHomeSite ? (
          <FeatureEntry
            {...{
              feature: nutrition,
              values: ["WD / WN", "Obese", "Morbidly Obese"],
              onChange: setAttribute,
              id: "qa-general-entry-nutrition",
            }}
          />
        ) : (
          <FeatureEntry
            {...{
              feature: build,
              values: ["WD / WN", "Obese"],
              onChange: setAttribute,
              id: "qa-general-entry-build",
            }}
          />
        )}

        <FeatureCheckBoxes {...{ setCheckBox, anyChecked, isNursingHomeSite }} />
        <div className="remove" onClick={handleRemoveSection}>
          Remove section -
        </div>
      </div>
      <div>
        <NoteEntry type="textarea" placeholder="Notes" note={content} onChange={onChange} />
      </div>
    </EntryBlock>
  );
};

GeneralSection.propTypes = {
  physicalExam: PropTypes.object,
  onFeatureUpdate: PropTypes.func,
  visitType: PropTypes.string,
  onChange: PropTypes.func,
  onCheckboxUpdate: PropTypes.func,
  isNursingHomeSite: PropTypes.bool,
};

export default GeneralSection;
