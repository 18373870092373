import React from "react";
import api from "../../api";
import Octicon, { X } from "@githubprimer/octicons-react";
import { Table, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";
import Info from "../../images/Info.svg";

const StatusModal = ({ visible, setVisible, data, requestAgain, cancelCSVs }) => {
    const handleClick = (action, csv) => {
        if (action === "Request Again") {
            requestAgain();
        } else if (action === "Cancel") {
            cancelCSVs();
        } else {
            window.location = csv;
        }
    };

    const getDate = (d) => {
        let weekday = moment(d).format("ddd.");
        let date = moment(d).format("MM/DD/YYYY hh:mm a");
        const getZone = moment.tz.guess();
        let zone = moment.tz(getZone).zoneAbbr();
        let formatedDate = `${weekday} ${date} ${zone}`;
        return formatedDate;
    };

    const getCriteria = (c) => {
        const ignoreFields = ["SortBy", "AscendingSortOrder"]
        
        const string = Object.entries(c)
            .map(([k, v]) => !ignoreFields.includes(k) && v !== null ? `${k}: ${v}` : "").filter((item) => item !== "").join(", ")

        let ttString = string ? string : "No filters applied"
    

        return ttString;
    };

    const timeDiff = (t) => {
        const now = moment();
        const then = moment(t);

        const diff = now.diff(then);
        const dur = moment.duration(diff);
        return `${parseInt(dur.asHours())}:${dur.minutes()}:${dur.seconds()}`;
    };

    if (!visible) {
        return null;
    }

    const count = data.length 

    return (
        <div className="modal-overlay">
            <div className="mr-auto ml-auto csv-status-modal">
                <div className="header-container-csv d-flex mb-3 justify-content-between">
                    <div className="d-flex pt-2">
                        <h4 className="csv-header mr-3 ml-4">My reports - CSV Downloads</h4>
                        {/* Count of number of reports returned /if there are more than 1 report */}
                        {count && <span className="csv-subtext mt-2">
                            {`The last ${count} reports you've requested are listed here and can be downloaded when
                            they're ready.`}
                        </span>}
                    </div>
                    <button type="button" className="csv-exit mr-3" onClick={() => setVisible(!visible)}>
                        <Octicon icon={X} size={20} />
                    </button>
                </div>
                <div className="content-container">
                    <Table>
                        <thead>
                            <tr>
                                <th>Export Requested</th>
                                <th>Criteria</th>
                                <th id="time-ellapsed">Time Elapsed <span><UncontrolledTooltip target="time-ellapsed" placement="right" >Hours:Minutes:Seconds</UncontrolledTooltip><Info/></span></th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody className="table-body-csv">
                            {data?.map((item, idx) => {
                                const actionClass = item?.Status === "Success" ? "Complete" : item?.Status;
                                const action =
                                    actionClass === "Complete"
                                        ? "Download"
                                        : actionClass === "Error"
                                            ? "Request Again"
                                            : "Cancel";
                                return (
                                    <tr key={idx}>
                                        <td key={`${idx}-Requested`} className="csv-requested">
                                            {getDate(item?.Request)}
                                        </td>
                                        <td key={`${idx}-Criteria`} className="csv-criteria" id={`criteria-${idx}`}>
                                          
                                            {getCriteria(item?.Criteria) !== "No filters applied" && <UncontrolledTooltip 
                                            fade="false" 
                                            placement="right" 
                                            target={`criteria-${idx}`} 
                                            style={{ width: "fit-content !important", height: "fit-content !important" }}
                                            >
                                             <span style={{width: "450px !important", height: "fit-content"}}> {getCriteria(item?.Criteria)} </span>
                                            </UncontrolledTooltip>}
                                           

                                            {getCriteria(item?.Criteria)}
                                        </td>
                                        <td key={`${idx}-Time`} className="csv-time">
                                            {timeDiff(item?.Request)}
                                        </td>
                                        <td key={`${idx}-Status`} className={actionClass}>
                                            {actionClass}
                                        </td>
                                        <td key={`${idx}-Action`}>
                                            {action !== "Cancel" ?<button
                                                type="button"
                                                className="action-btn"
                                                onClick={() => handleClick(action, item?.DownloadLink)}
                                                disabled={action === "Download" && !item?.DownloadLink}
                                            >
                                                {action}
                                            </button> :
                                            <span>...Loading</span>
                                            }
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    );
};

export default StatusModal;
