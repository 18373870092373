import React, { ChangeEvent, Fragment, PureComponent, ReactText, useEffect, useState } from "react";
import "react-dates/lib/css/_datepicker.css";
import "react-dates/initialize";
import { Button, Col, Input, Row } from "reactstrap";
import ContentBlock from "./ContentBlock";
import { checkEmcodes, DiagnosisCode, locations, selectItemToString } from "./Diagnosis";
import Prescription from "./Prescription/Prescription";
import MedicalHistory from "./MedicalHistory";
import pick from "lodash.pick";
import update from "immutability-helper";
import {
  sectionTitles,
  medicalHistoryFields,
  medicalIntakeFields,
} from "../../constants/Encounter";
import {
  ICurrentUser,
  IProviderNote,
  IUserMedicalIntake,
  IUserDetails,
  IPreviousPDFNotes,
  IEncounterDetailsResponse,
  IEncounterImages,
  IMedicalProfile,
  ICPTCode,
} from "../../constants/Types";
import "./DatePicker.scss";

import DateWithRangeButtons from "./DateWithRangeButtons";
import PrevVisitNotes from "./PrevVisitNotes";
import moment from "moment";
import ImageGalleryModal, { IImageGalleryObject } from "../ImageGalleryModal";
import { getTitle } from "./Encounter";
import { DiagnosisCPTCode } from "./DiagnosisCPTcode";
import api from "../../api";
import ReviewOfSystems from "./ReviewOfSystems";

export const InputError = ({ message, flag }) => (
  <h1 className="input-error">{flag ? message : ""}</h1>
);

export const CURRENT = "current";

type Conditions = {
  value: string;
  label: string;
};

interface Props {
  previousPDFNotes?: IPreviousPDFNotes[];
  currentUser?: ICurrentUser | null;
  note: Partial<IProviderNote>;
  onUpdate: (field: string, value: any) => void;
  onPrescriptionLink: Function;
  saveAddtionalStatus: Function;
  details?: Partial<IEncounterDetailsResponse>;
  patient: IUserDetails;
  visitType: string;
  intake: IUserMedicalIntake;
  chiefComplaint: string;
  userID?: string;
  missingRXRequestFields: any;
  missingDiagnosisFields: any;
  resuming?: boolean;
  referenceID: string;
  onPrescriptionValidated: Function;
  encounterImages?: IEncounterImages;
  onIntakeChanged: Function;
  onIntakeSave: Function;
  onRemoveUnsavedSection: Function;
  onProfileChanged: Function;
  onProfileSave: Function;
  onProviderNoteUpdate: (value: string) => void;
  medicalProfile: Partial<IMedicalProfile>;
  providerNotes?: string;
  unsavedSections?: any;
  imageModalOpenHandler: (title: string, imageBlock: ReactText) => void;
  setImageIndex: (idx: number) => void;
}

interface State {
  conditions: Conditions[];
  cptCodeErrors: string[];
  emCodes: ICPTCode[];
}

export default class RxRequestVisit extends PureComponent<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      conditions: [],
      cptCodeErrors: [],
      emCodes: [],
    };
    ``;
  }
  componentDidMount() {
    api.Catalog.cptCodes().then((result) =>
      this.setState({
        ...this.state,
        emCodes: result.items,
      }),
    );
    if (!this.props.note?.visitLocation) {
      this.props.onUpdate("visitLocation", "telehealthVisit");
    }
  }

  render() {
    const {
      previousPDFNotes,
      currentUser,
      chiefComplaint,
      note,
      onUpdate,
      visitType,
      intake,
      userID,
      onPrescriptionLink,
      saveAddtionalStatus,
      patient,
      details,
      missingRXRequestFields,
      missingDiagnosisFields,
      referenceID,
      onPrescriptionValidated,
      encounterImages,
      medicalProfile,
      // setImageIndex,
    } = this.props;

    const { medicalHistorySection, reviewOfSystemsSection } = sectionTitles;

    const showFemaleSpecific =
      this.props.patient &&
      this.props.patient.sex == "F" &&
      this.props.visitType != "contraceptives";

    const { diagnosis } = note;
    const providerNotes = note.assessment || "";

    let profile = medicalProfile || {};
    const medicalHistory = pick(profile, medicalHistoryFields);

    const providerNotesChange = (e) => {
      onUpdate(e.target.name, e.target.value);
    };

    const diagnosisChange = (items) => {
      if (items.length < 1) {
        onUpdate("diagnosis", null);
      } else {
        onUpdate("diagnosis", items?.map(selectItemToString));
      }
    };
    const onSaveMedicalHistory = (section: string, details: any) => {
      this.props.onRemoveUnsavedSection(section);
      this.props.onProfileSave(medicalHistoryFields, details);
    };
    const onLocationSelection = (loc: string) => {
      this.setState({
        ...this.state,
        cptCodeErrors: checkEmcodes(note.emCodes || [], loc, this.state.emCodes),
      });
      onUpdate("visitLocation", loc);
    };

    const onIntakeChanged =
      (fields: string[], section: string) => (newValues: IUserMedicalIntake) => {
        this.props.onRemoveUnsavedSection(section);
        this.props.onIntakeChanged(section)(newValues);
        this.props.onIntakeSave(
          fields,
          update(this.props.details, {
            medicalIntake: { $merge: newValues },
          }),
        );
      };
    const onProfileChanged = (section: string) => (newValues: any) => {
      this.props.onProfileChanged(section)(newValues);
      onSaveMedicalHistory(
        section,
        update(this.props.details, {
          medicalProfile: { $merge: newValues },
        }),
      );
    };
    const onEmcodesUpdate = (codes: string[]) => {
      this.setState({
        ...this.state,
        cptCodeErrors: checkEmcodes(codes, note?.visitLocation, this.state.emCodes),
      });
      onUpdate("emCodes", codes);
    };
    const onTimeSpentChange = (event: ChangeEvent<HTMLInputElement>) => {
      const value = parseInt((event.target.value || "").trim());

      if (isNaN(value)) {
        onUpdate(event.target.name, undefined);
      } else {
        onUpdate(event.target.name, value);
      }
    };
    const timeSpent = note?.timeSpent || 0;

    const otherIntake =
      !!intake.otherIntake && !!intake.otherIntake.otherIntake
        ? intake.otherIntake.otherIntake
        : [];

    const setIndex = (idx) => {
      this.props.setImageIndex(idx);
    };

    return (
      <>
        <ContentBlock title="Chief Complaint">
          <div>
            {details?.followUp
              ? `Follow-up visit for ${chiefComplaint} Treatment`
              : `Treatment for ${chiefComplaint}`}
          </div>
        </ContentBlock>
        {details?.followUp && (
          <ContentBlock title="Previous Visit Notes">
            <PrevVisitNotes
              previousPDFNotes={previousPDFNotes}
              chiefComplaint={chiefComplaint}
              userID={userID}
            />
          </ContentBlock>
        )}
        <ContentBlock title="Patient Intake Responses">
          {!!otherIntake &&
            otherIntake?.map((item, idx) => (
              <Fragment key={item.question}>
                <div className="rxIntakeResponses">
                  <span key={idx} className="rxQuestion">
                    {`${item.question}:`}
                  </span>
                  <span className="rxAnswer">{item.answer}</span>
                </div>
              </Fragment>
            ))}
        </ContentBlock>
        <ContentBlock title="Patient Images">
          {encounterImages &&
          (encounterImages.images?.length ||
            encounterImages.previousEncountersImages.reduce((ac, a) => ac.concat(a), []).length) ? (
            <>
              {encounterImages.previousEncountersImages?.map((previousImages, index) => {
                const title = getTitle(
                  index === 0,
                  chiefComplaint,
                  previousPDFNotes && previousPDFNotes[index].finishDate,
                );
                return (
                  <Fragment key={index}>
                    <div className="rxEncounterImagesTitle">{title}</div>
                    <div
                      className="rxEncounterImages"
                      onClick={() => this.props.imageModalOpenHandler(title, index)}
                    >
                      {previousImages?.map((item, idx) => (
                        <img
                          onClick={() => setIndex(idx)}
                          key={item.thumbnailPath}
                          src={item.thumbnailPath}
                          alt={`${chiefComplaint} image`}
                          height="80px"
                          width="80px"
                        />
                      ))}
                    </div>
                  </Fragment>
                );
              })}

              {encounterImages.images?.length && (
                <>
                  <div className="rxEncounterImagesTitle">Current Visit </div>
                  <div
                    className="rxEncounterImages"
                    onClick={() =>
                      this.props.imageModalOpenHandler("Current Visit Uploads", CURRENT)
                    }
                  >
                    {encounterImages?.images?.map((item, idx) => (
                      <img
                        onClick={() => setIndex(idx)}
                        key={item.thumbnailPath}
                        src={item.thumbnailPath}
                        alt={`${chiefComplaint} image`}
                        height="80px"
                        width="80px"
                      />
                    ))}
                  </div>
                </>
              )}
            </>
          ) : (
            <div></div>
          )}
        </ContentBlock>
        <ContentBlock title="">
          <MedicalHistory
            restoreData={{ medicalIntake: intake, medicalHistory }}
            title={medicalHistorySection}
            profile={profile}
            intake={intake}
            showFemaleSpecific={showFemaleSpecific}
            visitType={visitType}
            onProfileChanged={onProfileChanged(medicalHistorySection)}
            onIntakeChanged={onIntakeChanged(
              ["womensHealth", "fluImmunization", "pertinentMedications"],
              medicalHistorySection,
            )}
            patient={this.props.patient}
            onProviderNoteUpdate={this.props.onProviderNoteUpdate}
            providerNotes={this.props.providerNotes}
          />
        </ContentBlock>
        <ContentBlock title="Provider Notes">
          <div>
            <Input
              className="rxProviderNotes line-breaks"
              type="textarea"
              value={providerNotes}
              onChange={providerNotesChange}
              name="assessment"
              id="assessment"
              placeholder="Type any additional notes, assessment, diagnosis, or treatment information..."
            />
          </div>
        </ContentBlock>
        <ContentBlock
          title="Prescription"
          render={() => (
            <InputError
              message={
                "*You must either write a prescription or select prescription denied to complete this visit"
              }
              flag={missingRXRequestFields["prescriptionStatus"]}
            />
          )}
        >
          <Prescription
            note={note}
            onPrescriptionLink={onPrescriptionLink}
            onPrescriptionValidated={onPrescriptionValidated}
            currentUser={currentUser}
            patient={patient}
            onNoteUpdated={onUpdate}
            saveAddtionalStatus={saveAddtionalStatus}
            details={details}
            chiefComplaint={chiefComplaint}
            encounterID={referenceID}
          />
        </ContentBlock>
        <ContentBlock title="Diagnosis">
          <DiagnosisCode
            diagnosis={diagnosis}
            visitType={visitType}
            perSystemTitles={true}
            catalog={this.state.catalog}
            additionalRos={intake.reviewOfSystemsWritten}
            ROSGotSSC={intake.reviewOfSystemsGotSSC}
            symptomsDetailField={"symptomDetailsKeys"}
            symptomByStystemField={"reviewOfSystemsKeys"}
            readOnly={false}
          />
        </ContentBlock>
        <ContentBlock title={"Follow Up Plan"}>
          <DateWithRangeButtons
            onChange={(value) => onUpdate("followUpAt", value)}
            effectiveDate={note.followUpAt}
          />
        </ContentBlock>
        {/* <ImageGalleryModal
          isOpen={this.state.imageModalOpened}
          onClose={() => this.setState({ imageModalOpened: false })}
          onDelete={(currentImageIdx) => {
            this.setState((state) => ({
              imagesBlockContent: state.imagesBlockContent.filter((_, i) => i !== currentImageIdx),
            }));
            this.props.onImageArchive(this.state.imagesBlockContent[currentImageIdx].rawPath);
          }}
          images={this.state.imagesBlockContent}
          title={this.state.imageBlockTitle}
        /> */}
      </>
    );
  }
}
