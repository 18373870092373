import React from "react";
import Octicon, { Sync } from "@githubprimer/octicons-react";
import { Button } from "reactstrap";
const AppReloadBtn = ({ apiHash, emrHash }) => {
  const handleReload = () => {
    window.location.reload();
  };

  return (
    <div className="d-flex flex-column ml-3 mb-2" style={{ justifyContent: "center" }}>
      <span style={{ fontSize: "11px", fontWeight: 800, color: "#e53c51" }}>Update Available!</span>
      <Button
        onClick={() => handleReload()}
        style={{
          backgroundColor: "#e53c5161",
          border: "1.5px solid #e53c51",
          borderRadius: "5px",
          width: "86px",
          margin: "auto",
          verticalAlign: "middle",
          color: "#e53c51",
          boxShadow: "0 2px 25px 0 #e53c5142",
          fontWeight: 800,
          height: "35px",
          padding: "0px",
        }}
        data-emr-api-hash={apiHash}
        data-emr-current-hash={emrHash}
      >
        Reload
        <span className="pl-1 pb-2">
          <Octicon size="small" icon={Sync} />
        </span>
      </Button>
    </div>
  );
};

export default AppReloadBtn;
