import React, { ChangeEvent, Component, ReactEventHandler } from "react";
import moment from "moment";

const isDigits = (s: string) => {
  const re = /^[0-9\b]+$/;
  return s === "" || re.test(s);
};

type Props = {
  onChange: (value: any) => void;
  value: string;
  readOnly?: boolean;
};

type State = {
  year: string;
  month: string;
  day: string;
};

class DOB extends Component<Props, State> {
  constructor(props) {
    super(props);
    if (props.value) {
      const dv = moment(props.value);
      this.state = {
        year: String(dv.year()),
        month: String(dv.month() + 1),
        day: String(dv.date()),
      };
    } else {
      this.state = { year: "", month: "", day: "" };
    }

    this.onFieldChanged = this.onFieldChanged.bind(this);
  }

  onFieldChanged(field: keyof State) {
    return (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      if (!isDigits(newValue)) {
        return;
      }
      const nextField = {
        month: "day",
        day: "year",
      };
      const n = this.refs[nextField[field]];
      if (n && newValue.length === 2) {
        (n as HTMLInputElement).focus();
      }

      const updatedState = { ...this.state, [field]: newValue };

      this.setState(updatedState);

      const { year, month, day } = updatedState;

      const dateString = `${year}-${month}-${day}`;
      if (year.length < 4 || +month < 1 || +day < 1) {
        return;
      }
      const date = new Date(Date.parse(dateString));
      const today = new Date();
      if (date instanceof Date && !isNaN(date.valueOf())) {
        if (
          date.getFullYear() > 1800 &&
          date.getFullYear() <= today.getFullYear() &&
          date.getDate() > 0 &&
          date.getDate() < 32 &&
          date.getMonth() >= 0 &&
          date.getMonth() < 12
        ) {
          this.props.onChange({ target: { value: dateString } });
        }
      }
    };
  }
  render() {
    const state = this.state;
    if (this.props.readOnly) {
      return (
        <div className="dob-entry ml-3">
          {state.month} / {state.day} / {state.year}
        </div>
      );
    }

    return (
      <div className="dob-entry">
        <input
          type="tel"
          ref="month"
          placeholder="mm"
          maxLength={2}
          value={state.month}
          onChange={this.onFieldChanged("month")}
          className="qa-DOBInputMonth"
        />{" "}
        /
        <input
          type="tel"
          ref="day"
          placeholder="dd"
          maxLength={2}
          value={state.day}
          onChange={this.onFieldChanged("day")}
          className="qa-DOBInputDay"
        />{" "}
        /
        <input
          className="number qa-DOBInputYear"
          type="tel"
          placeholder="yyyy"
          ref="year"
          maxLength={4}
          value={state.year}
          onChange={this.onFieldChanged("year")}
        />
      </div>
    );
  }
}

export default DOB;
