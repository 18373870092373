import React from "react";
import styled from "styled-components";

const Button = styled.div`
  background: ${(props) => (props.value === true ? "#4A8FE7" : "#e7e7e7")};
  border-radius: 6px;
  height: 22px;
  width: 41px;
  font-size: 12px;
  border: none;
  text-align: center;
  padding: 2px 0;
  color: ${(props) => (props.value === true ? "#ffffff" : "#2B3034")};
  margin-left: 10px;
  cursor: pointer;
`;

const ButtonGroup = styled.div`
  display: flex;
  margin-left: 5px;
  height: 100%;
`;

export default function YesNoToggle(props) {
  const setValue = (v) => (ev) => {
    ev.preventDefault();
    if (props.onChange) {
      props.onChange(v);
    }
  };
  const edited = props.value != props.oldValue;

  return (
    <ButtonGroup>
      <Button value={props.value} onClick={setValue(true)}>
        Yes
      </Button>
      <Button value={!props.value} outline onClick={setValue(false)}>
        No
      </Button>
    </ButtonGroup>
  );
}
