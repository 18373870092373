import React, { FC, useEffect, useState } from "react";
import api from "../../api";
import { Button, Table } from "reactstrap";
import "./UpdateLogTable.scss";
import momentz from "moment-timezone";

const getDate = (dt) => momentz(dt).format("MM/DD/YYYY h:mm a");
const getZone = momentz.tz.guess();
const zone = momentz.tz(getZone).zoneAbbr();

const TableRows = ({ item, idx }) => {

  return (
    <tr key={`presenceTimeline-${idx}`} className="updateLogTable-body">
      <td className="table-item">
        {getDate(item.updatedAt)} {zone}
      </td>
      <td className="table-item">
        {item.status}
      </td>
      <td className="table-item">
        {item.trigger || "--" }
      </td>
    </tr>

  );
};

type Props = {
  providerID: string;
}

const ProviderPresenceTimeline: FC<Props> = ({ providerID }) => {
  const [data, setData] = useState<any>([]);
  const [nextDate,setNextDate] = useState<any>("")

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await api.Providers.getPresenceTimeline(providerID,nextDate);
      setData([...data, ...response?.items?.reverse()]);
      setNextDate(response.nextDate);
    } catch (error) {
      console.log("ERROR:: ", error);
    }
  };
  if (!data || !data.length) {
    return null;
  }

  return (
    <div className="dashboard-component d-flex flex-column align-content-stretch">
      <h4>Provider Presence Timeline</h4>
      <Table className="update-log-table">
        <thead>
        <tr className="tableHead">
          <th>Date</th>
          <th>Status</th>
          <th>Trigger</th>
        </tr>
        </thead>
        <tbody>
        {!!data &&
        data?.map((item, idx) => {
          return <TableRows item={item} idx={idx} />;
        })}
        </tbody>
      </Table>
      <Button className="align-self-center" onClick={fetchData} color="primary">Load more</Button>
    </div>
  );
};

export default ProviderPresenceTimeline;
