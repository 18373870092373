import React, { FC, useState } from "react";
import Octicon, { Plus } from "@githubprimer/octicons-react";
import { Button } from "reactstrap";
import { RequisitionVisitTypes } from "../constants/RequisitionTypes";
import AddendumEditor from "./Provider/AddendumEditor";
import PDFDownloadButton from "./Encounter/PDFDownloadButton";

import { connect } from "react-redux";
import { hasPermission, PERMISSION_SCRIBE } from "../constants/Permissions";
type Props = {
  encounterKey?: string;
  onClose: () => void;
  visitType?: string;
  permissions: string[];
  pdfRequisitionURL?: string | null;
};

const mapStateToProps = (state) => {
  return {
    permissions: state.common.permissions,
  };
};

const NoteFooter: FC<Props> = ({
  encounterKey,
  onClose,
  visitType,
  permissions,
  pdfRequisitionURL,
}) => {
  const [addendumOpen, setAddendumOpen] = useState(false);
  const isScribe = hasPermission(permissions, PERMISSION_SCRIBE);
  const onWriteAddendum = () => {
    setAddendumOpen(true);
  };

  const onCancelAddendum = () => {
    setAddendumOpen(false);
  };

  const onSaveAddendum = () => {
    setAddendumOpen(false);
    onClose();
  };

  return (
    <div className="w-100 d-flex justify-content-center">
      {addendumOpen ? (
        <AddendumEditor
          onSave={onSaveAddendum}
          onCancel={onCancelAddendum}
          encounterKey={encounterKey}
        />
      ) : (
        <>
          <Button
            disabled={isScribe}
            color="primary"
            id="qa-add-to-note-btn"
            onClick={onWriteAddendum}
          >
            Add to note &nbsp;
            <Octicon icon={Plus} />
          </Button>
          {visitType && (RequisitionVisitTypes.includes(visitType) || pdfRequisitionURL) && (
            <div className="ml-3">
              <PDFDownloadButton visitType={visitType} encounterID={encounterKey} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(NoteFooter);
