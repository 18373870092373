import React from "react";
import Monitor from "./Monitor";


const ActiveMonitor = ({}) => {
    return(
        <div style={{marginTop: "25px"}}>
            <Monitor fromActiveEncounter={true}/>
        </div>
    )
}

export default ActiveMonitor;