import React, { FC } from "react";
import { Label, Row, Col } from "reactstrap";

import BlockSection from "./BlockSection";
import YesNoToggle from "./YesNoToggle";
import { allergyHistory } from "../../constants/Encounter";
import CheckToggle from "../CheckToggle";
import { IAllergySocialHistory } from "../../constants/Types";

type Props = {
  socialHistory: IAllergySocialHistory;
  oldSocialHistory: IAllergySocialHistory;
  handleCheckboxClicked: (newValue: any) => void;
  onAllergyHistoryChange: (field: string) => (newValue: any) => void;
};

const AllergyHobbiesHistoryEditor: FC<Props> = ({
  socialHistory,
  oldSocialHistory,
  handleCheckboxClicked,
  onAllergyHistoryChange,
}) => {
  return (
    <BlockSection title={"Hobbies"}>
      <div className="form-row">
        <Label>Do your hobbies involve working with any of these materials?</Label>
      </div>
      <div className="mt-3">
        {allergyHistory.hobbyMaterials.map((row, i) => {
          return (
            <Row key={i} className="form-row mt-2">
              {row.map((material, j) => {
                return (
                  <Col
                    md="3"
                    className="d-flex supportive-care-checkbox col-6 col-sm-3 mr-5"
                    key={j}
                  >
                    <CheckToggle
                      checked={
                        socialHistory.allergyHobbiesHistory.hobbiesMaterials.indexOf(material) > -1
                      }
                      onChange={() => handleCheckboxClicked(material)}
                    >
                      {material}
                    </CheckToggle>
                  </Col>
                );
              })}
            </Row>
          );
        })}
      </div>
      <div className="form-row mt-3">
        <div className="col-6 col-sm-4 mr-4 pl-0">
          <Label>Hobbies outside?</Label>
          <div className="pl-0 mr-0 pr-0">
            <YesNoToggle
              value={socialHistory.allergyHobbiesHistory.hobbiesOutside}
              oldValue={oldSocialHistory.allergyHobbiesHistory.hobbiesOutside}
              onChange={onAllergyHistoryChange("hobbiesOutside")}
            />
          </div>
        </div>
        <div className="col-6 col-sm-4 pl-0">
          <Label>Hobbies with insects?</Label>
          <div className="pl-0 mr-0 pr-0">
            <YesNoToggle
              value={socialHistory.allergyHobbiesHistory.hobbiesInsects}
              oldValue={oldSocialHistory.allergyHobbiesHistory.hobbiesInsects}
              onChange={onAllergyHistoryChange("hobbiesInsects")}
            />
          </div>
        </div>
      </div>
    </BlockSection>
  );
};

export default AllergyHobbiesHistoryEditor;
