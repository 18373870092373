import React, { Component } from "react";
import { Progress } from "reactstrap";

class AudioAnalyser extends Component {
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  constructor(props) {
    super(props);
    this.state = { audioData: new Uint8Array(0) };
    this.tick = this.tick.bind(this);
  }

  componentDidUpdate(prevProps) {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'prevProps' implicitly has an 'any' type... Remove this comment to see the full error message
    if (!prevProps.audio && this.props.audio) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'audio' does not exist on type 'Readonly<... Remove this comment to see the full error message
      this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
      this.analyser = this.audioContext.createAnalyser();
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'analyser' does not exist on type 'AudioA... Remove this comment to see the full error message
      this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'dataArray' does not exist on type 'Audio... Remove this comment to see the full error message
      this.source = this.audioContext.createMediaStreamSource(this.props.audio);
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'source' does not exist on type 'AudioAna... Remove this comment to see the full error message
      this.source.connect(this.analyser);

      // @ts-expect-error ts-migrate(2339) FIXME: Property 'audio' does not exist on type 'Readonly<... Remove this comment to see the full error message
      this.rafId = requestAnimationFrame(this.tick);
    }
  }
  componentWillUnmount() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'analyser' does not exist on type 'AudioA... Remove this comment to see the full error message
    cancelAnimationFrame(this.rafId);
    if (this.analyser) {
      this.analyser.disconnect();
    }
    if (this.source) {
      this.source.disconnect();
    }
  }

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'rafId' does not exist on type 'AudioAnal... Remove this comment to see the full error message
  tick() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'analyser' does not exist on type 'AudioA... Remove this comment to see the full error message
    this.analyser.getByteTimeDomainData(this.dataArray);
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'source' does not exist on type 'AudioAna... Remove this comment to see the full error message
    this.setState({ audioData: this.dataArray });
    this.rafId = requestAnimationFrame(this.tick);
  }

  draw() {
    let sum = 0;
    let x;

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'analyser' does not exist on type 'AudioA... Remove this comment to see the full error message
    for (let i = 0; i < this.state.audioData.length; i++) {
      x = this.state.audioData[0];
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'dataArray' does not exist on type 'Audio... Remove this comment to see the full error message
      sum += x * x;
    }
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'rafId' does not exist on type 'AudioAnal... Remove this comment to see the full error message
    const rms = Math.sqrt(sum / this.state.audioData.length);
    return (rms - 130) * 3;
  }

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'audioData' does not exist on type 'Reado... Remove this comment to see the full error message
    return <Progress color="audio-bar" value={this.draw()} />;
  }
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'audioData' does not exist on type 'Reado... Remove this comment to see the full error message
export default AudioAnalyser;
// @ts-expect-error ts-migrate(2339) FIXME: Property 'audioData' does not exist on type 'Reado... Remove this comment to see the full error message
