import { LOCATION_CHANGE } from "connected-react-router";
import { Reducer } from "redux";

const defaultState = {
  shown: false,
};

type FlashState = {
  shown: boolean,
  message?: null | string
}

const flashReducer: Reducer<FlashState, any> =  (state = defaultState, action: any) => {
  if (action.type == LOCATION_CHANGE) {
    if (state.shown) {
      return { message: null, shown: false };
    }
    return {
      ...state,
      shown: true,
    };
  }
  if (action.flashMessage) {
    return {
      ...state,
      message: action.flashMessage,
      shown: false,
    };
  }
  return state;
};

export default flashReducer