import React from "react";
import styled, { css } from "styled-components";

interface Props {
  active: boolean;
  index: number;
  onClick: (event: any) => void;
  src: string;
}

const StyledThumbnail = styled.div<Props>`
  background-position: center;
  background-size: cover;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  height: 80px;
  width: 80px;
  margin: 5px;
  overflow: hidden;
  background-image: ${(props) => `url("${props.src}")`};

  &:hover {
    border: 3px solid #4a8fe7;
  }

  ${(props) =>
    props.active &&
    css`
      border: 3px solid #4a8fe7;
      filter: drop-shadow(0px 0px 8px #4a8fe7);
    `};
`;

export default function Thumbnail({ index, src, active, onClick }) {
  return (
    <StyledThumbnail
      onClick={(e: any) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(index);
      }}
      active={active}
      index={index}
      src={src}
    />
  );
}
