import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Loadable from "react-loadable";

import api from "../../api";
import NavBar from "./NavBar";
import PatientList from "../WaitingRoom/PatientList";
import ModalMessage from "../ModalMessage";
import ClockIcon from "../../images/ClockIcon.svg";
import {
  ENCOUNTER_ENGAGE,
  WAITING_ROOM_MESSAGE_CLEAR,
  WAITING_ROOM_LOAD_PENDING,
  WAITING_ROOM_LOAD_AVAILABLE,
} from "../../constants/actionTypes";

import Loading from "../ComponentLoading";
import ClockIn from "../ClockIn";
import { hasPermission, PERMISSION_SCRIBE } from "../../constants/Permissions";
import { toast } from "react-toastify";
import { checkIsJSON, errorLogger } from "../../utils";
import bugsnag from "../../services/bugsnag";


const VideoSetup = Loadable({
  loader: () => import("../VideoSetup"),
  loading: Loading,
  delay: 300,
});

const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: state.common.currentUser,
    availablePatients: state.waitingRoom.available,
    pendingPatients: state.waitingRoom.pending,
    presence: state.presence,
    message: state.waitingRoom.message,
  };
};

const mapDispatchToProps = (dispatch) => ({
  engageEncounter: (referenceID, payload) =>
    dispatch({
      type: ENCOUNTER_ENGAGE,
      payload: payload,
      referenceID: referenceID,
    }),
  loadPending: (payload) => dispatch({ type: WAITING_ROOM_LOAD_PENDING, payload }),
  loadAvailable: (payload) => dispatch({ type: WAITING_ROOM_LOAD_AVAILABLE, payload }),

  clearMessage: () => dispatch({ type: WAITING_ROOM_MESSAGE_CLEAR }),
});

const PresenceVeil = ({ presence }) => {
  if (presence && presence.clockedIn) {
    return null;
  }
  return (
    <div className="presence-veil">
      <div className="clock-in-message d-flex flex-column align-items-center">
        <div>You must clock in to begin accepting patients.</div>
        <div className="mt-5">
          <ClockIn />
        </div>
      </div>
    </div>
  );
};

function AvailablePatients({
  engageEncounter,
  currentUser,
  presence,
  pendingPatients,
  availablePatients,
  message,
  clearMessage,
  loadPending,
  loadAvailable,
}) {
  const [practiceID, setPracticeID] = useState(undefined);
  const [prevPendingPatients, setPrevPendingPatients] = useState([]);
  let pendingFetchInterval, availableFetchInterval;

  useEffect(() => {
    fetchPendingPatients();
    fetchAvailablePatients();
    return () => {
      if (pendingFetchInterval) {
        clearInterval(pendingFetchInterval);
      }
      if (availableFetchInterval) {
        clearInterval(availableFetchInterval);
      }
    };
  }, []);

  useEffect(() => {
    if (currentUser && currentUser.currentPracticeID !== practiceID) {
      setPracticeID(currentUser.currentPracticeID);
    }
  }, [currentUser]);

  useEffect(() => {
    setPrevPendingPatients(pendingPatients);
  }, [pendingPatients]);

  const onAcceptPatient = async (patient) => {
    try {
     await engageEncounter(patient.referenceID, api.Encounters.engage(patient.referenceID));
    }
    catch (e) {
     errorLogger(e)
    }

  };

  const fetchPendingPatients = () => {
    if (pendingFetchInterval) {
      clearInterval(pendingFetchInterval);
    }
    pendingFetchInterval = setInterval(() => {
      loadPending(api.Encounters.pending());
    }, 3000);
  };

  const fetchAvailablePatients = () => {
    if (availableFetchInterval) {
      clearInterval(availableFetchInterval);
    }
    availableFetchInterval = setInterval(() => {
      try {
        loadAvailable(api.Encounters.available());
      } catch (e: any) {
        errorLogger(e)
      }
    }, 3000);
  };
  const isScribe = hasPermission(currentUser?.permissions, PERMISSION_SCRIBE);
  const setupVideo = currentUser && !isScribe && !currentUser.videoSetup;

  return (
    <div>
      <NavBar />
      {setupVideo && <VideoSetup />}
      {!setupVideo && <PresenceVeil presence={presence} />}
      <div className="dashboard-container">
        <h4>
          {" "}
          <div className="header-icon float-left">
            <ClockIcon />
          </div>
          Patients in waiting room...{" "}
        </h4>
        <ModalMessage message={message} onClear={clearMessage} />
        <div className="dashboard-component align-content-stretch">
          <PatientList onAcceptPatient={onAcceptPatient} items={availablePatients} />
          <PatientList items={pendingPatients} />
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AvailablePatients);
