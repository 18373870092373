import React, { FC, useEffect, useState } from "react";
import ReactImageMagnify from "react-image-magnify";
import { Button } from "reactstrap";

import StrepConfirmation from "./StrepConfirmation";
import UTIConfirmation from "./UTIConfirmation";
import FluConfirmation from "./FluConfirmation";
import CoronavirusConfirmation from "./CoronavirusConfirmation";
import StartTimer from "./StartTimer";
import UTIColorChart from "../../images/UTIColorChart.png";
import { IProviderTestResult, ITestDetails } from "../../constants/Types";
import moment from "moment";

export type ComponentsProps = {
  result?: object[];
  providerResult: IProviderTestResult;
  onChange: (newValue: any) => void;
  showAlgorithmResult: boolean;
};

type Props = {
  testResult?: ITestDetails;
  onSave: (data: any) => void;
  onChange: (newValues: any) => void;
  telemedExtender?: {
    name: string;
    phone: string;
  };
  tytocareView?: boolean;
  showTytocareReviewPopup?: (value: boolean) => void;
};

function scalePortraitDimensions(maxWidth: number, point: { X: number; Y: number }) {
  let ratio = maxWidth / point.X;
  if (point.X < maxWidth) {
    ratio = 1.0;
  }
  // Yes, this swaps x and y.
  return { X: point.Y * ratio, Y: point.X * ratio };
}

const components = {
  strep: StrepConfirmation,
  uti: (props: ComponentsProps) => {
    return <UTIConfirmation {...props} />;
  },
  flu: (props: ComponentsProps) => {
    return <FluConfirmation {...props} />;
  },
  covid: (props: ComponentsProps) => {
    return <CoronavirusConfirmation {...props} />;
  },
};

const ResultConfirmation: FC<Props> = (props) => {
  const [testResults, setTestResult] = useState({
    testType: "",
    manufacturer: "",
    expirationDate: "",
  });
  const [assessment, setAssement] = useState("");
  const result = props.testResult;

  useEffect(() => {
    if (result?.testJsonRemark) {
      try {
        let { testInfo } = JSON.parse(result.testJsonRemark);
        let expirationDate = "";
        if (testInfo.expirationDate) {
          expirationDate = moment(testInfo.expirationDate).format("MM-DD-YYYY");
        }
        setTestResult({ ...testResults, ...testInfo, expirationDate });
      } catch (e) {
        console.log(e);
      }
    }
  }, [props.testResult]);

  const isTelemedExtender = !!props.telemedExtender;
  let classname = "";
  if (!props.tytocareView) {
    classname = "dialog-view";
  }
  const onSave = () => {
    let testResult = {};

    if (result?.testJsonRemark) {
      testResult = {
        testType: testResults.testType,
        manufacturer: testResults.manufacturer,
        expirationDate: testResults.expirationDate,
        assessment: assessment,
      };
    }
    let testJSONRemark = JSON.stringify(testResult);
    props.onSave({ testJSONRemark });
  };

  const onChange = (field) => (e) => {
    setTestResult({ ...testResults, [field]: e.target.value });
  };
  if (!result || !result.state || result.state === "prepared") {
    //when the view is rendered in the tyocare modal
    if (props.tytocareView && props.showTytocareReviewPopup) {
      props.showTytocareReviewPopup(false);
    }

    return null;
  }
  if (result && result.providerResult && result.providerResult.recorded) {
    //when the view is rendered in the tyocare modal
    if (props.tytocareView && props.showTytocareReviewPopup) {
      props.showTytocareReviewPopup(false);
    }
    return null;
  }

  const dimensions = result.dimensions;
  // Dimensions should be
  const smallDimensions = scalePortraitDimensions(400, dimensions);
  const largeDimensions = scalePortraitDimensions(1600, dimensions);
  const testComponent = components[result.testType || ""];

  return (
    <div className={`dialog-container ${classname}`}>
      <div className="form-container">
        <div className="float-right confirmation-timer">
          {!props.tytocareView && <StartTimer onlyProgress={true} />}
        </div>
        <h1> Determine test result before proceeding! </h1>
        <div className="d-flex mt-4 w-100">
          <div className="image-side">
            {result.testType === "uti" && (
              <div className="uti-reference">
                <img src={UTIColorChart} />
              </div>
            )}
            <ReactImageMagnify
              {...{
                enlargedImagePosition: "over",
                smallImage: {
                  height: smallDimensions.Y,
                  width: smallDimensions.X,
                  src: result.thumbnailURL,
                  isFluidWidth: false,
                },
                largeImage: {
                  src: result.imageURL,
                  width: largeDimensions.X,
                  height: largeDimensions.Y,
                },
              }}
            />
          </div>
          <div className="form-side">
            {!result.testJsonRemark ? (
              <>
                {testComponent({
                  result: result.result,
                  providerResult: result.providerResult,
                  onChange: props.onChange,
                  showAlgorithmResult: isTelemedExtender, // only show algorithm results if clinical service visit
                })}
              </>
            ) : (
              <div className="test-inputs">
                <div className="extender-input">
                  <span>Input from Extender:</span>
                  <div style={{ marginTop: "2px" }} className="input-field">
                    <label>Test Type: </label>
                    <input onChange={onChange("testType")} value={testResults.testType} />
                  </div>
                  <div className="input-field">
                    <label> Test Manufacturer: </label>

                    <input onChange={onChange("manufacturer")} value={testResults.manufacturer} />
                  </div>
                  <div className="input-field">
                    <label> Expiration Date: </label>

                    <input
                      onChange={onChange("expirationDate")}
                      value={testResults.expirationDate}
                    />
                  </div>
                </div>

                <div className="assessment">
                  <label>Provider assessment of result:</label>
                  <textarea
                    className="assessment-input"
                    placeholder="Enter test determination"
                    onChange={(e) => setAssement(e.target.value)}
                    value={assessment}
                  ></textarea>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="mt-5 d-flex justify-content-center">
          <Button
            onClick={onSave}
            color="primary"
            disabled={result?.providerResult?.set && !result.testJsonRemark}
            className="ml-3"
          >
            Submit &amp; Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ResultConfirmation;
