import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const isEqual = require("react-fast-compare");

import api from "../../api";
import { ENCOUNTER_PRESCRIPTION_UPDATE } from "../../constants/actionTypes";

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    currentTime: state.time.currentTime,
    prescriptionData: state.encounter.prescriptionData,
  };
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'dispatch' implicitly has an 'any' type.
const mapDispatchToProps = (dispatch) => ({
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'payload' implicitly has an 'any' type.
  providerPrescription: (payload) => dispatch({ type: ENCOUNTER_PRESCRIPTION_UPDATE, payload }),
});

class PrescriptionPoller extends Component {
  constructor(props) {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
    super(props);
    this.state = { polling: false, nextTime: null };
  }

  poll() {
    // timer resolution, but timer resolution is currently 12 s
    const nextTime = new Date().getTime();
    this.setState({ polling: true, nextTime: nextTime });
    this.props.providerPrescription(api.Encounters.checkPrescriptions(this.props.encounterID));
  }

  componentDidMount() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'encounterID' does not exist on type 'Rea... Remove this comment to see the full error message
    if (this.props.note.prescriptionStarted && !this.state.polling) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'note' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      this.poll();
    }
  }

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'polling' does not exist on type 'Readonl... Remove this comment to see the full error message
  componentDidUpdate(prevProps) {
    if (this.props.note.prescriptionStarted && !this.state.polling) {
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'prevProps' implicitly has an 'any' type... Remove this comment to see the full error message
      this.poll();
      return;
    }
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'note' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    if (this.state.nextTime && this.props.currentTime > this.state.nextTime) {
      this.poll();
    }
    if (
      this.props.prescriptionData &&
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'nextTime' does not exist on type 'Readon... Remove this comment to see the full error message
      !isEqual(prevProps.prescriptionData, this.props.prescriptionData)
    ) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'prescriptionData' does not exist on type... Remove this comment to see the full error message
      this.props.onUpdate("pharmacyDetails", this.props.prescriptionData.pharmacyDetails);
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'prescriptionData' does not exist on type... Remove this comment to see the full error message
      this.props.onUpdate("prescriptionDetails", this.props.prescriptionData.prescriptionDetails);
    }
  }

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'prescriptionData' does not exist on type... Remove this comment to see the full error message
    return null;
  }
}
PrescriptionPoller.propTypes = {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'onUpdate' does not exist on type 'Readon... Remove this comment to see the full error message
  note: PropTypes.object,
  onUpdate: PropTypes.func,
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'prescriptionData' does not exist on type... Remove this comment to see the full error message
  encounterID: PropTypes.string.isRequired,
};
export default connect(mapStateToProps, mapDispatchToProps)(PrescriptionPoller);
// @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
