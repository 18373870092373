import React, { useEffect, useState } from "react";
import styled from "styled-components";
import XIcon from "../../../images/x-black.svg";
import ListEditor from "./ListEditor";
import AwesomeDebouncePromise from "awesome-debounce-promise";

export const Card = styled.div`
  width: 100%;
  background-color: #f7f7f7;
  border-radius: 10px;
  position: relative;
  margin: 15px 0;
  padding: 20px;
`;

const EditorWrapper = styled(Card)`
  h2 {
    color: #4a8fe7;
    font-weight: bold;
    font-size: 18px;
  }
`;

const EditorHeader = styled.div`
  h2 {
    color: #4a8fe7;
    font-weight: bold;
    font-size: 18px;
  }
`;

const StyledXIcon = styled(XIcon)`
  cursor: pointer;
`;

const ListView = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 53, 119, 0.2);
  padding: 0.7rem 10px 0.7rem 10px;
  span {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
  }
  &:hover {
    background: rgba(141, 178, 224, 0.15);
  }

  ${(props) =>
    props.new === true &&
    `
      span {
        color: #4a8fe7;
      }
    `}
`;

const ListEditorWrapper = styled.div`
  margin-top: 20px;
`;
const EditorBody = styled.div``;

type Props = {
  title?: string;
  itemListSource?: (...args: any[]) => any;
  items?: any[];
  oldItems?: any[];
  onChange?: (...args: any[]) => any;
  userID?: string;
  data: string[];
  className: string;
};

function Editor(props: Props) {
  const isNewLabel = (label, oldItemLabels) => {
    return !oldItemLabels.includes(label);
  };

  const handleOnRemove = (item, items) => {
    if (props.onChange) {
      props.onChange(items.filter((i) => i !== item));
    }
  };

  const itemListSource = props.itemListSource;
  const debouncedSource = AwesomeDebouncePromise(itemListSource, 500);
  return (
    <EditorWrapper>
      <EditorHeader>
        <h2>{props.title}</h2>
      </EditorHeader>
      <EditorBody>
        {props.data.map((item, idx) => (
          <ListView key={idx} new={isNewLabel(item, props.oldItems)}>
            <span>{item}</span>
            <StyledXIcon onClick={() => handleOnRemove(item, props.data)} />
          </ListView>
        ))}
        <ListEditorWrapper>
          <ListEditor
            items={props.items}
            oldItems={props.oldItems || []}
            itemListSource={debouncedSource}
            onChange={props.onChange}
            userID={props.userID}
            md={12}
            sm={12}
          />
        </ListEditorWrapper>
      </EditorBody>
    </EditorWrapper>
  );
}

export default Editor;
