import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Table } from "reactstrap";
import { push } from "connected-react-router";

import Spinner from "../../images/Spinner.svg";
import api from "../../api";
import { store } from "../../store";
import { TableRow, TableHeader } from "./RoundingTable";

function RoundingList(props) {
  const [inProgress, setInProgress] = useState(true);
  const [roundList, setList] = useState(undefined);

  const viewPatient = (userID) => {
    store.dispatch(push(`/patients/${userID}`));
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'userID' implicitly has an 'any' type.
  };
  const setPatientZone = async (userID, zone) => {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'userID' implicitly has an 'any' type.
    await api.Providers.changeCovidZone(userID, { covidZone: zone });
    !!props.handleListChange && props.handleListChange();
  };
  const removeFromList = (userID) => {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'userID' implicitly has an 'any' type.
    api.Providers.changeRoundingFlag(userID, { activeRounding: false });
    let newList = roundList.filter((item) => userID !== item.ID);
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    setList(newList);
  };

  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
  const stringSort = (a, b) => {
    return ("" + a).localeCompare("" + b);
  };
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'a' implicitly has an 'any' type.
  const zoneSort = (a, b) => {
    const rank = (zone) => {
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'a' implicitly has an 'any' type.
      switch (zone.toLowerCase()) {
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'zone' implicitly has an 'any' type.
        case "green":
          return 1;
        case "blue":
          return 2;
        case "yellow":
          return 3;
        case "red":
          return 4;         
        default:
          return 0;
      }
    };

    const aVal = rank(a);
    const bVal = rank(b);
    return aVal < bVal ? -1 : aVal > bVal ? 1 : 0;
  };

  const [sortType, setSortType] = useState("FirstName");
  const [sortDirection, setSortDirection] = useState("ascending");
  const sortList = (items) => {
    return (items || []).sort((a, b) => {
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'items' implicitly has an 'any' type.
      const sortHandler = sortType === "CovidZone" ? zoneSort : stringSort;
      // sort the item based on the current sort type
      let result = sortHandler(a[sortType], b[sortType]);
      if (sortType == "CovidZone" && result === 0) {
        // sorting CovidZone and the zones are the same, perform an additional sort of the First Name
        result = stringSort(a["FirstName"], b["FirstName"]);
      } else {
        // adjust the direction of the sort, if necessary
        const direction = sortDirection === "ascending" ? 1 : -1;
        result *= direction;
      }

      return result;
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await api.Providers.getRoundingList();
      const items = result.items == null ? [] : result.items;
      setList(sortList(items));
      setInProgress(false);
    };
    setInProgress(true);
    fetchData();
  }, [props.updateList, sortType, sortDirection]);

  const onSortChange = (type, direction) => {
    if (type !== sortType) {
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'type' implicitly has an 'any' type.
      // sort type/column changed, change the type and reset the sort direction
      setSortType(type);
      setSortDirection("ascending");
    } else {
      // toggle sort direction
      setSortDirection(direction === "ascending" ? "descending" : "ascending");
    }
  };

  return (
    <div className="rounding-list dashboard-component">
      {!roundList || !!inProgress ? (
        <div className="loader-view">
          <Spinner />
        </div>
      ) : (
        <Table className="rounding-list-table">
          <TableHeader
            currentSortType={sortType}
            currentSortDirection={sortDirection}
            onSortChange={onSortChange}
          />
          <tbody>
            {(roundList || []).map((item, index) => {
              return (
                <TableRow
                  key={item.ID + index}
                  firstName={item.FirstName}
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'ID' does not exist on type 'never'.
                  lastName={item.LastName}
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'FirstName' does not exist on type 'never... Remove this comment to see the full error message
                  covidZone={item.CovidZone}
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'LastName' does not exist on type 'never'... Remove this comment to see the full error message
                  PCCMRN={item.PCCMRN}
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'CovidZone' does not exist on type 'never... Remove this comment to see the full error message
                  sex={item.Sex}
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'PCCMRN' does not exist on type 'never'.
                  dateOfBirth={item.DateOfBirth}
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'Sex' does not exist on type 'never'.
                  userID={item.ID}
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'DateOfBirth' does not exist on type 'nev... Remove this comment to see the full error message
                  setPatientZone={setPatientZone}
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'ID' does not exist on type 'never'.
                  removeFromList={removeFromList}
                  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: any; firstName: any; lastName: any; c... Remove this comment to see the full error message
                  viewPatient={viewPatient}
                />
              );
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
}

RoundingList.propTypes = {
  handleListChange: PropTypes.func,
  updateList: PropTypes.bool,
};

export default RoundingList;
