import React, { FC } from "react";
import { ResponsiveBar, BarCustomLayer, Layer, TooltipProp } from "@nivo/bar";
import moment from "moment-timezone";

import { VitalsBarGraphProps as VBGP } from "./VitalsGraph";

export interface VitalsBarGraphProps extends VBGP {}

const VitalsBarGraph: FC<VitalsBarGraphProps> = ({
  isExpanded,
  onClicked,
  yAxisRange,
  dataPoints,
  outOfRange,
  doubleValues,
  metricTypes,
  acceptableRange,
  baseLines,
  tickValues,
  unit,
  timeZone,
}) => {
  const styles = !!isExpanded
    ? {
        paddingTop: "10px",
        height: "270px",
        width: "100%",
        transition: "height, .7s",
        borderTop: "0.5px, solid, #7F91A8",
      }
    : { height: "80px", width: "100%", transition: "height, .7s" };

  const propsExpanded = !!isExpanded
    ? {
        padding: 0.5,
        enableGridX: true,
        axisLeft: {
          legend: !!unit ? unit : false,
          legendPosition: "middle",
          legendOffset: -45,
          tickSize: 0,
          tickPadding: 10,
          tickValues: tickValues,
        },
        axisBottom: {
          legend: false,
          tickSize: 0,
          tickPadding: 10,
        },
        margin: { top: 20, right: 0, bottom: 20, left: 50 },
      }
    : {
        padding: 0.15,
        enableGridX: false,
        axisLeft: null,
        axisBottom: null,
      };

  // custom toolTip
  const toolTipElement: TooltipProp = (props) => {
    const time = moment(props.data.timestamp).tz(timeZone).format("ddd MM/DD/YYYY h:mm a");
    return (
      <div className="graphTitle">
        <p>{time}</p>
        <p>
          {props.id === "value" ? metricTypes[0] : metricTypes[1]} : {props.value} {unit}
        </p>
      </div>
    );
  };

  // background rect layer
  const rectLayer: BarCustomLayer = (props) => {
    let fillColor: string;
    if (outOfRange) {
      fillColor = "rgba(255, 177, 177, .2)";
    } else if (!!isExpanded) {
      fillColor = "rgba(197,222,255,.3)";
    } else {
      fillColor = "rgba(242, 245, 249, 1)";
    }

    if (!acceptableRange || !acceptableRange.lowerLimit || !acceptableRange.upperLimit) {
      // don't draw a reference layer if acceptable range is unsopecified
      return null;
    }

    return (
      <g>
        <rect
          y={props.yScale(acceptableRange.upperLimit)}
          width={props.width}
          height={
            props.yScale(acceptableRange.lowerLimit) - props.yScale(acceptableRange.upperLimit)
          }
          fill={fillColor}
        />
        <line
          x1="0"
          y1={props.yScale(baseLines.firstValue)}
          x2={props.width}
          y2={props.yScale(baseLines.firstValue)}
          stroke="#94A4B9"
          strokeDasharray="4"
        />
        {!!doubleValues && (
          <line
            x1="0"
            y1={props.yScale(baseLines.secondValue || 0)}
            x2={props.width}
            y2={props.yScale(baseLines.secondValue || 0)}
            stroke="#94A4B9"
            strokeDasharray="4"
          />
        )}
      </g>
    );
  };

  // top, bottom lines
  const Lines: BarCustomLayer = (props) => {
    return (
      <g>
        <line
          x1="0"
          y1={props.yScale(yAxisRange.max)}
          x2={props.width}
          y2={props.yScale(yAxisRange.max)}
          stroke="#dddddd"
        />
        <line
          x1="0"
          y1={props.yScale(yAxisRange.min)}
          x2={props.width}
          y2={props.yScale(yAxisRange.min)}
          stroke="#dddddd"
        />
      </g>
    );
  };

  const layers: Layer[] = ["grid", Lines, rectLayer, "markers", "axes", "bars", "legends"];
  return (
    <div style={styles} onClick={() => !!onClicked && onClicked()}>
      <ResponsiveBar
        {...propsExpanded}
        enableGridY={false}
        maxValue={yAxisRange.max}
        minValue={yAxisRange.min}
        data={dataPoints}
        colors={
          outOfRange
            ? ["rgba(220, 69, 69, 1)", "rgba(253, 103, 103, 1)"]
            : ["rgba(74, 143, 231, 1)", "rgba(118, 173, 241, 1)"]
        }
        groupMode={!!doubleValues ? "grouped" : "stacked"}
        keys={!!doubleValues ? ["value", "secondValue"] : ["value"]}
        enableLabel={false}
        indexBy={(item) => moment(item.timestamp).format("MMM DD")}
        tooltip={toolTipElement}
        layers={layers}
      />
    </div>
  );
};

export default VitalsBarGraph;
