import React from "react";
import { Button } from "reactstrap";

export interface ModalPopupProps {
  visible: boolean;
  message: string;
  onClose?: () => void;
}

class ModalPopup extends React.Component<ModalPopupProps> {
  onClose = () => {
    !!this.props.onClose && this.props.onClose();
  };

  render() {
    const message = this.props.message;
    if (this.props.visible && message) {
      return (
        <div className="modal-overlay d-flex flex-column">
          <div className="ml-auto mr-auto provider-message-container d-flex flex-column justify-content-between">
            <h4>{message}</h4>
            <div className="mx-auto d-flex align-items-end" style={{ height: 80 }}>
              <Button color="primary" onClick={this.onClose}>
                OK
              </Button>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default ModalPopup;
