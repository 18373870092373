import React, { FC } from "react";
import PropTypes from "prop-types";
import BodyBackgroundColor from "react-body-backgroundcolor";

import Logo from "../images/Reliant-Logo_white.svg";
import { Badge } from "reactstrap";
import { isBetaSite } from "../utils";

const CenteredForm: FC = (props) => {
  return (
    <BodyBackgroundColor backgroundColor="#4889DE">
      <div>
        <div className="text-center d-flex justify-content-center centered-form-logo">
          <div className="loginLogoWrapper">
            <Logo />
            {isBetaSite &&
            <div className="beta-login-logo-badge">
              <Badge color="success" pill>
                Beta
              </Badge>
            </div>
            }
          </div>

        </div>
        <div className="text-center centered-form-container">{props.children}</div>
      </div>
    </BodyBackgroundColor>
  );
};

CenteredForm.propTypes = {
  children: PropTypes.element,
};

export default CenteredForm;
