import React, { Component } from "react";
import { connect } from "react-redux";

import ProfileIcon from "../images/ProviderProfileIcon.svg";
import ProfileIconActive from "../images/ProviderProfileClockedInIcon.svg";

const mapStateToProps = (state, ownProps) => {
  return {
    active: state.presence.clockedIn,
  };
};

const mapDispatchToProps = (dispatch) => ({});

class ProfileBubble extends Component {
  render() {
    if (this.props.active) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'active' does not exist on type 'Readonly... Remove this comment to see the full error message
      return <ProfileIconActive id="qa-profile-btn" className="active-icon" />;
    }
    return <ProfileIcon />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProfileBubble);
