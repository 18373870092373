import React from "react";
import CovidGreen from "../../images/CovidGreen.svg";
import CovidYellow from "../../images/CovidYellow.svg";
import CovidBlue from "../../images/CovidBlue.svg";

import CovidRed from "../../images/CovidRed.svg";
import CCMTag from "../../images/CCM.svg";
import RPMTag from "../../images/RPM.svg";

import styles from "./PatientSideBar.scss";

const PatientServicesTags = ({ consent, covidZone, CCM, RPM }) => {
  return (
    <div className="qa-patientsServicesTags">
      {covidZone === "Green" && (
        <span className={`${styles.patientServicesActive} qa-covidZoneGreenTag`}>
          <CovidGreen />
        </span>
      )}
       {covidZone === "Blue" && (
        <span className={`${styles.patientServicesActive} qa-covidZoneRedTag`}>
          <CovidBlue />
        </span>
      )}
      {covidZone === "Yellow" && (
        <span className={`${styles.patientServicesActive} qa-covidZoneYellowTag`}>
          <CovidYellow />
        </span>
      )}
      {covidZone === "Red" && (
        <span className={`${styles.patientServicesActive} qa-covidZoneRedTag`}>
          <CovidRed />
        </span>
      )}
      {(!covidZone || covidZone === "N/A" || covidZone=== "no zone") && (
        <span className={`${styles.patientServicesInactive} qa-covidZoneInactive`}>
          Covid Zone: None
        </span>
      )}
      {!!consent && !!CCM ? (
        <span className={`${styles.patientServicesActive} qa-ccmTag`}>
          <CCMTag />
        </span>
      ) : (
        <span className={`${styles.patientServicesInactive} qa-ccmInactive`}> CCM </span>
      )}

      {!!consent && !!RPM ? (
        <span className={`${styles.patientServicesActive} qa-rpmTag`}>
          <RPMTag />
        </span>
      ) : (
        <span className={`${styles.patientServicesInactive} qa-rpmInactive`}> RPM</span>
      )}
    </div>
  );
};

export default PatientServicesTags;
