import { Reducer } from "redux";
import { SELECT_PRACTICE_FOR_ENCOUNTERS_SEARCH, SELECT_PRACTICE_FOR_PATIENTS_SEARCH } from "../constants/actionTypes";

const defaultState = {
  selectedPractice: { name: "", id: "", nhFlag: false },
  patientSearchPractice: { name: "", id: "", nhFlag: false },
};

type PatientsTabState = {
  selectedPractice: { name: string; id: string; nhFlag: boolean };
  patientSearchPractice: { name: string; id: string; nhFlag: boolean };
};

const PatientsTabReducer: Reducer<PatientsTabState, any> = (
  state = defaultState,
  action,
): PatientsTabState => {
  switch (action.type) {
    case SELECT_PRACTICE_FOR_ENCOUNTERS_SEARCH:
      return {
        ...state,
        selectedPractice: action.practice,
      };
    case SELECT_PRACTICE_FOR_PATIENTS_SEARCH:
      return {
        ...state,
        patientSearchPractice: action.practice,
      }
    default:
      return state;
  }
};

export default PatientsTabReducer;
