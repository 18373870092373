import React from "react";

import { findFeature, featureSetAttributes } from "../PhysicalExam/Features";
import EntryBlock from "../PhysicalExam/EntryBlock";
import { GroupNoteEntry } from "../PhysicalExam/Notes";
import FeatureEntry from "../PhysicalExam/FeatureEntry";
import PropTypes from "prop-types";

const RxInteractions = ({ features, onChange, note, onNoteUpdate }) => {
  const rxInteractions = findFeature(
    features,
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'features' does not exist on type 'Props'... Remove this comment to see the full error message
    "rxInteractions",
    "RxInteractions",
    "RxInteractions",
  );

  const setAttribute = featureSetAttributes({ onChange });

  return (
    <EntryBlock title="Does this medication interact with your eating, drinking, or with other medications?">
      <div>
        <FeatureEntry
          {...{
            feature: rxInteractions,
            values: ["Yes", "No"],
            onChange: setAttribute,
            omitTitle: true,
            id:"qa-rxInteractions-entry-rxInteractions"

          }}
        />
      </div>
      <div>
        <GroupNoteEntry {...{ groupName: "RxInteractions", onChange: onNoteUpdate, note, id:"qa-rxInteractions-group-entry" }} />
      </div>
    {/* @ts-expect-error ts-migrate(2739) FIXME: Type '{ groupName: string; onChange: ((...args: an... Remove this comment to see the full error message */}
    </EntryBlock>
  );
};

RxInteractions.propTypes = {
  feature: PropTypes.array,
  onChange: PropTypes.func,
  note: PropTypes.string,
  onNoteUpdate: PropTypes.func,
};
export default RxInteractions;
