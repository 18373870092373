import React from "react";
import { Input, Row, Col } from "reactstrap";
import styled from "styled-components";
import PropTypes from "prop-types";

import SocialHistoryEdit from "./SocialHistoryEdit";
import CatalogBackedEditorView, { EditorHeader, EditorWrapper } from "./CatalogBackedEditorView";
import EditorView from "./EditorView";
import api from "../../../api";
import FamilyHealthHistory from "../../Encounter/FamilyHealthHistory";

const Card = styled.div`
  width: 100%;
  background-color: #f7f7f7;
  border-radius: 10px;
  position: relative;
  margin: 15px 0;
  padding: 20px;
`;

const Form = styled.div`
  background-color: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px 20px;
  margin-bottom: 50px;
`;

const StyledInput = styled(Input)`
  min-height: 160px !important;
  font-size: 14px;
`;

const ProviderNotesWrapper = styled(Card)`
  h2 {
    color: #4a8fe7;
    font-weight: bold;
    font-size: 18px;
  }
`;

function MedicalProfile({
  profile,
  medicalCatalog,
  surgicalCatalog,
  isEditing,
  onChange,
  restoreData,
  userID,
  patient,
  medicalHistory,
  ...props
}) {
  const medications = props.medications || [];
  const surgicalHistory = props.surgicalHistory || [];
  const allergies = props.allergies || [];

  const correctiveLenses = profile.correctiveLenses || {};

  // TODO: replace check from `company name` to `updated at` once supplied by backend
  let lenses: string[] = [];
  if (correctiveLenses.contacts) {
    lenses.push("contacts");
  }
  if (correctiveLenses.glasses) {
    lenses.push("glasses");
  }

  const mapStringsToKey = (items, catalog) => {
    let entries = items.map((k) => catalog.find((v) => v.labels.en === k)).filter(Boolean);
    return entries.map((l) => l.key);
  };

  const handleChangedKeyedValues = (field) => (catalog) => (keyValues, actionType) => {
    let other = profile[field].other ? profile[field].other.slice() : [];
    if (actionType === "create-option") {
      let otherValue = keyValues.pop();
      other.push(otherValue);
    }
    let keys = mapStringsToKey(keyValues || [], catalog || []);
    onChange(field)({ keys, other });
  };
  const handleChangeFamilyHistory = (field) => (newValue) => {
    onChange(field)(newValue)
  }

  const handleRemoveKeyedValues = (field) => (catalog) => (keyValues, otherValue) => {
    let other = profile[field].other ? profile[field].other.slice() : [];
    const indexOfOtherValue = other.indexOf(otherValue);
    if (indexOfOtherValue > -1) {
      other.splice(indexOfOtherValue, 1);
    }
    let keys = mapStringsToKey(keyValues || [], catalog || []);
    onChange(field)({ keys, other });
  };
  const updateProviderNotes = (e) => {
    const { name, value } = e.target;
    onChange(name)(value);
  };

  return (
    <Form>
      <Row>
        <Col md={{ size: 6 }}>
          <Row>
            <Col md={{ size: 12 }}>
              <ProviderNotesWrapper>
                <h2>HPI</h2>
                <StyledInput
                  type="textarea"
                  value={[profile.providerNotes]}
                  onChange={updateProviderNotes}
                  name="providerNotes"
                  placeholder="Enter provider notes"
                  className="chartEditProviderNotes"
                />
              </ProviderNotesWrapper>
            </Col>
            <Col md={{ size: 12 }}>
              <SocialHistoryEdit
                onChange={onChange("socialHistoryV2")}
                socialHistory={profile.socialHistoryV2}
                patient={patient}
                oldSocialHistory={restoreData.socialHistoryV2}
                onTobaccoTypeChange={onChange()}
                womensHealthProfile={profile.womensHealth}
                oldWomensHealthProfile={restoreData.womensHealth}
                onProfileChange={onChange("womensHealth")}
                oldCorrectiveLenses={restoreData.correctiveLenses}
                correctiveLenses={profile.correctiveLenses}
                onCorrectiveLensesChange={onChange("correctiveLenses")}
                className="chartEditSocialHistory"
              />
            </Col>
            <Col md={{ size: 12 }}>
              <CatalogBackedEditorView
                title="Past Medical History"
                data={medicalHistory}
                catalogEndpoint={api.Catalog.medicalHistory}
                values={profile.medicalHistoryKeys}
                items={profile.medicalHistoryKeys.keys || []}
                oldItems={restoreData.medicalHistoryKeys || {}}
                itemListSource={api.Catalog.medicalHistory}
                onChange={handleChangedKeyedValues("medicalHistoryKeys")}
                onRemove={handleRemoveKeyedValues("medicalHistoryKeys")}
                className="chartEditPastMedicalHistory"
              />
            </Col>
          </Row>
        </Col>

        <Col md={{ size: 6 }}>
          <Row>
            <Col md={{ size: 12 }}>
              <EditorView
                title="Medications"
                items={medications}
                data={medications}
                oldItems={restoreData.medications || []}
                itemListSource={api.MedicalProfile.medicationsSearch}
                onChange={onChange("medications")}
                userID={userID}
                className="chartEditMedications"
              />
            </Col>
            <Col md={{ size: 12 }}>
              <EditorView
                title="Allergies"
                items={allergies}
                data={allergies}
                oldItems={restoreData.allergies || []}
                itemListSource={api.Catalog.allergies}
                onChange={onChange("allergies")}
                userID={userID}
                className="chartEditAllergies"
              />
            </Col>
            <Col md={{ size: 12 }}>
              <CatalogBackedEditorView
                title="Surgical History"
                data={surgicalHistory}
                catalogEndpoint={api.Catalog.surgicalHistory}
                values={profile.surgicalHistoryKeys}
                items={profile.surgicalHistoryKeys.keys || []}
                oldItems={restoreData.surgicalHistoryKeys || {}}
                itemListSource={api.Catalog.surgicalHistory}
                onChange={handleChangedKeyedValues("surgicalHistoryKeys")}
                onRemove={handleRemoveKeyedValues("surgicalHistoryKeys")}
                className="chartEditSurgicalHistory"
              />
            </Col>
            <Col md={{ size: 12 }}>
              <EditorWrapper>
                <EditorHeader>
                  <h2>Family Medical History</h2>
                </EditorHeader>
                <div>
                  <FamilyHealthHistory
                    onProfileChanged={handleChangeFamilyHistory("FamilyMedicalHistory")}
                    profile={profile}
                    isPatientChart={true}
                  />
                </div>
              </EditorWrapper>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
}

MedicalProfile.propTypes = {
  profile: PropTypes.object,
  medicalCatalog: PropTypes.arrayOf(PropTypes.object),
  surgicalCatalog: PropTypes.arrayOf(PropTypes.object),
  isEditing: PropTypes.bool,
  onChange: PropTypes.func,
  restoreData: PropTypes.object,
  userID: PropTypes.string,
  patient: PropTypes.object,
  medicalHistory: PropTypes.array,
  props: PropTypes.object,
};
export default MedicalProfile;
