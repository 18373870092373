import React, { Component, FC } from "react";
import { IUserMedicalIntake } from "../../constants/Types";
import ContentBlock from "./ContentBlock";

export function boolToString(val) {
  if (val === null) {
    return "Unknown";
  }
  return val ? "Yes" : "No";
}

export function possiblyNullIntToString(val) {
  if (val == null) {
    return "Unknown";
  }
  return val;
}

const PneumoniaHistory: FC<{
  history: IUserMedicalIntake["allergyLungsHistory"];
}> = ({ history }) => {
  return (
    <ul>
      <li>
        Number episodes of pneumonia in lifetime:{" "}
        {possiblyNullIntToString(history.pneumoniaEpisodes)}
      </li>
    </ul>
  );
};

const AsthmaHistory: FC<{
  history: IUserMedicalIntake["allergyLungsHistory"]["asthmaHistory"];
}> = ({ history }) => {
  return (
    <div>
      <p></p>
      Asthma History
      <ul>
        <li>Lungs worsened by: {(history.lungsWorsenedBy || []).join(", ")}</li>
        <li>Inhaled Steroids: {boolToString(history.inhaledSteroids)}</li>
        {history.inhaledSteroids && (
          <ul>
            <li>Last date of inhaled steroids: {history.inhaledSteroidsDate}</li>
          </ul>
        )}
        <li>Systemic Steroids: {boolToString(history.systemicSteroids)}</li>
        {history.systemicSteroids && (
          <ul>
            <li>Last date of systemic steroids: {history.systemicSteroidsDate}</li>
            <li>
              Instances of systemic steroids in lifetime:{" "}
              {possiblyNullIntToString(history.systemicSteroidsCount)}
            </li>
          </ul>
        )}
        <li>
          Ever been to ER or hospitalized overnight for breathing issues:{" "}
          {boolToString(history.hospitalized)}
        </li>
        {history.hospitalized && (
          <ul>
            <li>Date of last time: {history.hospitalizedDate}</li>
            <li>Total times in lifetime: {possiblyNullIntToString(history.hospitalizedCount)}</li>
            <li>
              Ever admitted to ICU for breathing issues: {boolToString(history.intensiveCare)}
            </li>
          </ul>
        )}
      </ul>
    </div>
  );
};

const AsthmaControl: FC<{
  control: {
    asthmaControlPrevent: number;
    asthmaControlShortness: number;
    asthmaControlWake: number;
    asthmaControlInhaler: number;
    asthmaControlRating: number;
  };
}> = ({ control }) => {
  const allergyControlMap = {
    asthmaControlPrevent: [
      "All of the time",
      "Most of the time",
      "Some of the time",
      "A little of the time",
      "None of the time",
    ],
    asthmaControlShortness: [
      "2+ times per day",
      "1 time per day",
      "3-6 times per week",
      "1-2 times per week",
      "Not at all",
    ],
    asthmaControlWake: [
      "4+ nights per week",
      "2-3 nights per week",
      "Once per week",
      "1-2 nights per month",
      "Not at all",
    ],
    asthmaControlInhaler: [
      "3+ times per day",
      "1-2 times per day",
      "2-3 times per week",
      "1 time per week",
      "Not at all",
    ],
    asthmaControlRating: [
      "Not controlled at all",
      "Poorly controlled",
      "Somewhat controlled",
      "Well controlled",
      "Completely controlled",
    ],
  };

  const getAsthmaControlAnswer = (questionKey, answerKey) => {
    let answerString = "Unknown";
    if (answerKey > 0 && answerKey <= 5) {
      answerString = allergyControlMap[questionKey][answerKey - 1];
    }
    return answerString;
  };

  return (
    <div>
      <p></p>
      Asthma Control
      <ul>
        <li>
          How much time did your asthma keep you from getting things done at work, school or at
          home? {getAsthmaControlAnswer("asthmaControlPrevent", control.asthmaControlPrevent)}
        </li>
        <li>
          How often have you had shortness of breath?{" "}
          {getAsthmaControlAnswer("asthmaControlShortness", control.asthmaControlShortness)}
        </li>
        <li>
          How often did your asthma symptoms wake you from sleep?{" "}
          {getAsthmaControlAnswer("asthmaControlWake", control.asthmaControlWake)}
        </li>
        <li>
          How often did you use your rescue inhaler or nebulizer?{" "}
          {getAsthmaControlAnswer("asthmaControlInhaler", control.asthmaControlInhaler)}
        </li>
        <li>
          How would you rate your asthma control?{" "}
          {getAsthmaControlAnswer("asthmaControlRating", control.asthmaControlRating)}
        </li>
        <li>
          Asthma control score:{" "}
          {control.asthmaControlPrevent +
            control.asthmaControlShortness +
            control.asthmaControlWake +
            control.asthmaControlInhaler +
            control.asthmaControlRating}
        </li>
      </ul>
    </div>
  );
};

type Props = {
  lungHistory: IUserMedicalIntake["allergyLungsHistory"];
  visitType: string;
};

class LungAllergyHistory extends Component<Props> {
  render() {
    const history = this.props.lungHistory;
    return (
      <ContentBlock title={"Lung Allergy History"}>
        <ul>
          <li>History of pneumonia: {boolToString(history.pneumoniaHistory)}</li>
          {history.pneumoniaHistory && <PneumoniaHistory history={history} />}
          <li>Diagnosed with asthma or given inhalers: {boolToString(history.asthmaOrInhalers)}</li>
        </ul>
        {history.asthmaOrInhalers && (
          <AsthmaControl control={history.asthmaHistory.asthmaControl} />
        )}
        {history.asthmaOrInhalers && <AsthmaHistory history={history.asthmaHistory} />}
      </ContentBlock>
    );
  }
}

export default LungAllergyHistory;
