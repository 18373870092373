import React from "react";
import { Alert } from "reactstrap";

export interface ErrorListProps {
  errors?: string[] | null;
  className?: string;
}

class ErrorList extends React.Component<ErrorListProps> {
  render() {
    let errors = this.props.errors || [];
    errors = errors.filter((obj) => obj);
    if (errors.length > 0) {
      return (
        <Alert color="danger" className={this.props.className || ""}>
          <ul className="list-unstyled">
            {errors.map((error, i) => {
              return <li key={i}>{error}</li>;
            })}
          </ul>
        </Alert>
      );
    } else {
      return null;
    }
  }
}

export default ErrorList;
