import React from "react";
import { Button } from "reactstrap";
import CGXPDF from "../../images/SDPA_CGX.pdf";
import CardioPDF from "../../images/SDPA_Cardio.pdf";
import api from "../../api";
 
const callPdfAPI = (encounterID) => { 
  let theCurrentURL = window.location.href;
  let splitsBySlash = theCurrentURL.split("/"); 
  var fromPDFApiObj = api.Encounters.setDownloadPdfUrl(encounterID? encounterID : splitsBySlash[4]);
  fromPDFApiObj.then(
    response => window.open(response.record.apiData)
  );  
}

const PDFDownloadButton = ({ visitType, encounterID }) => {
  return (
    <Button  onClick={() =>callPdfAPI(encounterID)}  style={{ backgroundColor: "#4a8fe7", borderColor: "#4a8fe7" }}>
      Download Requisition Form
    </Button>
  );
};

export default PDFDownloadButton;