import React, { useState, useEffect } from "react";
import { Table } from "reactstrap";
import VitalsDateRange from "../VitalsDateRange";
import moment from "moment";
import Spinner from "../../images/Spinner.svg";
import { linepoints, barpoint } from "./PatientsGraphs/VitalsGraph";
import VitalsBarGraph from "./PatientsGraphs/VitalsBarGraph";
import VitalsLineGraph from "./PatientsGraphs/VitalsLineGraph";
import VitalsCard from "./VitalsCard";
import api from "../../api";
import { PROVIDER_TIME_ZONE } from "../../constants/Providers";
import styles from "./chart.scss";

const VITALS_LAYOUT = [
  {
    type: "riskAssessment",
    label: "Risk Assessment Score",
    graph: "bar",
    yRange: { min: 0, max: 65 },
    yTicks: [0, 30, 65],
    baseLines: { firstValue: 30 },
    acceptableRange: { lowerLimit: 17, upperLimit: 30 },
    metrics: ["Risk Score"],
  },
  {
    type: "temperature",
    label: "Temperature",
    graph: "line",
    yRange: { min: 95.0, max: 105.0 },
    yTicks: [95, 98.6, 105],
    baseLines: { firstValue: 98.6 },
    acceptableRange: { lowerLimit: 95.0, upperLimit: 100.4 },
    metrics: ["Temperature"],
  },
  {
    type: "heartrate",
    label: "Heart Rate",
    graph: "line",
    yRange: { min: 40, max: 120 },
    yTicks: [40, 80, 120],
    baseLines: { firstValue: 80 },
    acceptableRange: { lowerLimit: 60, upperLimit: 100 },
    metrics: ["Heart Rate"],
  },
  {
    type: "bloodPressure",
    label: "Blood Pressure",
    graph: "bar",
    graphExpanded: "line",
    yRange: { min: 40, max: 200 },
    yTicks: [40, 80, 120, 200],
    baseLines: { firstValue: 120, secondValue: 80 },
    acceptableRange: { lowerLimit: 60, upperLimit: 160 },
    limits: { systolic: { lower: 90, upper: 160 }, diastolic: { lower: 60, upper: 120 } },
    metrics: ["Systolic", "Diastolic"],
  },
  {
    type: "bloodSugar",
    label: "Blood Sugar",
    graph: "line",
    yRange: { min: 40, max: 350 },
    yTicks: [40, 100, 160, 220, 280, 350],
    baseLines: { firstValue: 99 },
    acceptableRange: { lowerLimit: 40, upperLimit: 99 },
    metrics: ["Blood Sugar"],
  },
  {
    type: "oxygenSaturation",
    label: "O2 Saturation",
    graph: "line",
    yRange: { min: 85, max: 100 },
    yTicks: [85, 90, 95, 100],
    baseLines: { firstValue: 95 },
    acceptableRange: { lowerLimit: 95, upperLimit: 100 },
    metrics: ["O2"],
  },
  {
    type: "respirations",
    label: "Respirations",
    graph: "line",
    yRange: { min: 10, max: 35 },
    yTicks: [10, 20, 25, 35],
    baseLines: { firstValue: 15 },
    acceptableRange: { lowerLimit: 12, upperLimit: 20 },
    metrics: ["Breaths/Min"],
  },
  {
    type: "weight",
    label: "Weight",
    graph: "line",
    yRange: { min: 0, max: 300 },
    yTicks: [],
    baseLines: {},
    acceptableRange: {},
    metrics: ["Weight"],
  },
  {
    type: "painLevel",
    label: "Pain Level",
    graph: "bar",
    yRange: { min: 0, max: 10 },
    yTicks: [0, 5, 10],
    baseLines: { firstValue: 5 },
    acceptableRange: { lowerLimit: 0, upperLimit: 1 },
    metrics: ["Pain Level"],
  },
];

export interface VitalsPanelPCCProps {
  userID: string;
  currentUser: {
    currentPracticeID: string;
  };
}

const VitalsPanelPCC = ({ userID, currentUser }: VitalsPanelPCCProps) => {
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [vitals, setVitals] = useState<any[]>([]);
  const [groupedObservations, setGroupedObservations] = useState({});
  const [latestObservations, setLatestObservations] = useState({});
  const [expandedVital, setExpandedVital] = useState("");
  const [timeZone, setTimeZone] = useState("America/New_York");

  useEffect(() => {
    setLoading(true);

    // get the latest observations
    let latestObservations = {};
    api.PCC.observationsLatest(userID)
      .then((data) => {
        const observations = data.observations || [];
        latestObservations = observations.reduce((result, entry) => {
          const type = entry.type || "";
          result[type] = entry;
          return result;
        }, {});

        // query the latest risk assessment, which should be for either today or yesterday
        const today = moment();
        const yesterday = moment(today).subtract(1, "day");
        return api.PCC.riskAssessment(
          userID,
          yesterday.format("YYYY-MM-DD"),
          today.format("YYYY-MM-DD"),
        );
      })
      .then((data) => {
        // get the latest assessment by finding the entry with .Date === "current-risk-assessment-score"
        const latestAssessment = (data.datapoints || []).find(
          (entry) => entry.Date === "current-risk-assessment-score",
        );
        if (!!latestAssessment) {
          latestObservations["riskAssessment"] = riskAssessmentToObservation(latestAssessment);
        }
      })
      .catch((error) => {
        // an error occurred while getting the latest observations
        console.log("VitalsPanelPCC::Mount::ERROR::", error);
      })
      .finally(() => {
        setLatestObservations(latestObservations);
        setVitals(VITALS_LAYOUT);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const loadVitals = () => {
      const startMoment = moment(startDate).startOf("day").utc();
      const endMoment = moment(endDate).endOf("day").utc();

      // query the api for the obserations in the date range
      let grouped = {};
      api.PCC.observationsForDates(
        userID,
        startMoment.format("YYYY-MM-DD"),
        endMoment.format("YYYY-MM-DD"),
      )
        .then((data) => {
          const observations = data.observations || [];
          // group observations by vital type
          grouped = observations.reduce((result, entry) => {
            const type = entry.type || "";
            const vital = result[type] || [];
            vital.push(entry);
            result[type] = vital;
            return result;
          }, {});

          const startWeightMoment = moment(endDate).subtract(7, "months");

          return api.PCC.observationsForDates(
            userID,
            startWeightMoment.format("YYYY-MM-DD"),
            endMoment.format("YYYY-MM-DD"),
            "weight",
          );
        })
        .then((data) => {
          const weightObservations = data.observations || [];
          if (weightObservations.length > 0) {
            // replace the grouped weight entries with the updated 7 months of data
            grouped["weight"] = weightObservations;
          }

          return api.PCC.riskAssessment(
            userID,
            startMoment.format("YYYY-MM-DD"),
            endMoment.format("YYYY-MM-DD"),
          );
        })
        .then((data) => {
          // use the data points with only valid dates (Date === "current-risk-assessment-score" for the current risk assessment score)
          const datapoints = (data.datapoints || []).filter(
            (entry) => (entry.Date || "").match(/^(\d{4})-(\d{2})-(\d{2})?/) != null,
          );
          const riskObservations = datapoints.map((datapoint) =>
            riskAssessmentToObservation(datapoint),
          );
          grouped["riskAssessment"] = riskObservations;
        })
        .catch((error) => {
          // error
          console.log("VitalsPanelPCC::loadVitals::ERROR::", error);
        })
        .finally(() => {
          // sort all the observations by date!
          Object.keys(grouped).forEach((key) => {
            grouped[key].sort((a, b) => {
              const aMoment = moment(a.recordedDate);
              const bMoment = moment(b.recordedDate);
              if (aMoment.isBefore(bMoment)) {
                return -1;
              } else if (aMoment.isAfter(bMoment)) {
                return 1;
              }
              return 0;
            });
          });

          setGroupedObservations(grouped);
          setLoading(false);
        });
    };

    setLoading(true);
    if (!!startDate && !!endDate) {
      // only load the vitals if the date range is set
      loadVitals();
    }
  }, [startDate, endDate]);

  useEffect(() => {
    const loadPractice = () => {
      api.Providers.listPractices()
        .then((data) => {
          const practices = data.items;
          const currentPractice = currentUser.currentPracticeID;

          const findState = (id) => {
            for (const key in practices) {
              if (practices[key].filter((mp) => mp.id === id).length > 0) {
                return key;
              }
            }
            return "America/New_York";
          };
          let check_mp = findState(currentPractice);
          for (const [key, value] of Object.entries(PROVIDER_TIME_ZONE)) {
            if (key === check_mp) {
              setTimeZone(value);
            }
          }
          // setLoading(false)
        })
        .catch((error) => {
          console.log("PROVIDER PRACTICE ERROR :: ", error);
        });
    };

    if (!!currentUser) {
      // setLoading(true)
      loadPractice();
    }
  }, []);

  const riskAssessmentToObservation = (riskAssessment) => {
    return {
      value: riskAssessment.score,
      unit: "",
      recordedDate: riskAssessment.Date,
      method: "none",
      type: "riskAssessment",
    };
  };

  const onDateRangeChange = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const createVitalsRow = (
    vital,
    latestObservation: any,
    observations,
    startDate,
    endDate,
    idx,
  ) => {
    if (!latestObservation && !observations) {
      // don't display a row if there is no data
      return null;
    }

    const createLinePoints = (vital, observations): linepoints[] => {
      const linePoints: linepoints[] = [];
      if (vital.type === "bloodPressure") {
        const pressure = (observations || []).reduce((result, entry) => {
          result.systolic = result.systolic || [];
          result.diastolic = result.diastolic || [];

          result.systolic.push({
            x: moment(entry.recordedDate).format("YYYY-MM-DDTHH:mm"),
            y: entry.systolicValue,
          });
          result.diastolic.push({
            x: moment(entry.recordedDate).format("YYYY-MM-DDTHH:mm"),
            y: entry.diastolicValue,
          });
          return result;
        }, {});

        linePoints.push({
          id: "Systolic",
          data: pressure.systolic || [],
        });
        linePoints.push({
          id: "Diastolic",
          data: pressure.diastolic || [],
        });
      } else {
        // format line points
        const data = (observations || []).map((entry) => {
          return {
            x: moment(entry.recordedDate).format("YYYY-MM-DDTHH:mm"),
            y: entry.value,
          };
        });
        linePoints.push({
          id: vital.label,
          data,
        });
      }

      return linePoints;
    };

    const createBarPoints = (vital, observations): barpoint[] => {
      // format bar points
      return (observations || []).map((entry) => {
        return {
          timestamp: moment(entry.recordedDate).format("YYYY-MM-DDTHH:mm"),
          value: vital.type === "bloodPressure" ? entry.systolicValue : entry.value,
          ...(vital.type === "bloodPressure" ? { secondValue: entry.diastolicValue } : {}),
        };
      });
    };

    const isOutOfRange = (type, latestObservation: any): boolean => {
      const vital = VITALS_LAYOUT.find((entry) => entry.type === type);
      if (!!vital) {
        const acceptableRange: any = vital.acceptableRange || { lowerLimit: 0, upperLimit: 0 };
        // handle special cases
        if (vital.type === "bloodPressure") {
          const limits: any = vital.limits || {};
          const systolic = limits.systolic;
          const diastolic = limits.diastolic;
          // blood pressure is special case where both values need to be outside the limit
          return (
            !!systolic &&
            (latestObservation.systolicValue < systolic.lower ||
              latestObservation.systolicValue > systolic.upper) &&
            !!diastolic &&
            (latestObservation.diastolicValue < diastolic.lower ||
              latestObservation.diastolicValue > diastolic.upper)
          );
        } else if (vital.type === "weight") {
          // weight is a special case that has no range
          return false;
        }

        // check the latest observation value is outside the acceptable range
        return (
          latestObservation.value < acceptableRange.lowerLimit ||
          latestObservation.value > acceptableRange.upperLimit
        );
      }
      return false;
    };

    const onVitalClick = (type) => {
      setExpandedVital(expandedVital === type ? "" : type);
    };

    const isExpanded = expandedVital === vital.type;
    const isWeight = vital.type === "weight";

    // determine the proper graph type, there may be a different graph type when expanded
    const graph = isExpanded ? vital.graphExpanded || vital.graph : vital.graph;
    // expanded graphs show a last 7 days of data points (weight shows last 7 months)
    const fromDate = isExpanded
      ? moment(endDate)
          .subtract(7, isWeight ? "months" : "days")
          .toDate()
      : startDate;

    // filter observations to only include the viewed date range
    const filteredObservations = (observations || []).filter((observation) =>
      moment(observation.recordedDate).isSameOrAfter(fromDate),
    );

    // fill in missing observations before and after any observation range
    // we can assume the observations are in ascending date order
    const precision = isWeight ? "month" : "day";
    const createEmptyObservation = (forDate) => ({
      value: null,
      systolicValue: null,
      diastolicValue: null,
      recordedDate: forDate.toISOString(),
      type: vital.type,
    });

    // add missing entries to the start of the observation range, if necessary
    let checkMoment = moment(fromDate);
    const firstObservation = filteredObservations[0] || {
      recordedDate: moment(endDate).endOf("day").toISOString(),
    };
    while (checkMoment.isBefore(moment(firstObservation.recordedDate), precision)) {
      filteredObservations.unshift(createEmptyObservation(checkMoment));
      checkMoment.add(1, precision);
    }

    // add missing entries to the end of the observation range, if necessary
    checkMoment = moment(endDate);
    const lastObservation = filteredObservations[filteredObservations.length - 1];
    while (checkMoment.isAfter(moment(lastObservation.recordedDate), precision)) {
      filteredObservations.push(createEmptyObservation(checkMoment));
      checkMoment.subtract(1, precision);
    }

    const dataPoints =
      graph === "line"
        ? createLinePoints(vital, filteredObservations)
        : createBarPoints(vital, filteredObservations);

    const latest = latestObservation || {};
    const unit = latest.unit || "";
    // weight vital has special display settings
    const value = latest.value ?? 0;
    const baseLines = isWeight ? { firstValue: value } : vital.baseLines;
    const yRange = isWeight
      ? {
          min: value < 50 ? 0 : value - 50,
          max: value + 50,
        }
      : vital.yRange;
    const yTicks = isWeight ? [value] : vital.yTicks;
    const customAxisBottom = isWeight ? { format: "%b %Y", tickValues: "every 1 months" } : null;
    const outOfRange = isOutOfRange(vital.type, latest);

    return (
      <tr key={`row-${idx}`} onClick={() => onVitalClick(vital.type)}>
        <td style={{ width: 300 }}>
          <div className={styles.details}>
            <VitalsCard
              title={vital.label}
              type={vital.type}
              data={latest}
              outOfRange={outOfRange}
              onSelect={onVitalClick}
              isExpanded={isExpanded}
              limits={{
                upper: vital.acceptableRange.upperLimit,
                lower: vital.acceptableRange.lowerLimit,
              }}
              timeZone={timeZone}
            />
          </div>
        </td>
        <td style={{ width: "auto" }}>
          <div className={styles.graph} style={{ position: "relative" }}>
            {!!isExpanded && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  right: 4,
                  width: 16,
                  borderBottom: "2px solid #7F91A8",
                }}
              ></div>
            )}
            <div>
              {graph === "bar" ? (
                <VitalsBarGraph
                  isExpanded={isExpanded}
                  doubleValues={vital.type === "bloodPressure"}
                  onClicked={() => onVitalClick(vital.type)}
                  dataPoints={dataPoints as barpoint[]}
                  metricTypes={vital.metrics}
                  outOfRange={outOfRange}
                  yAxisRange={{ ...yRange, min: 0 }}
                  tickValues={yTicks}
                  baseLines={baseLines}
                  acceptableRange={vital.acceptableRange}
                  unit={unit}
                  timeZone={timeZone}
                />
              ) : (
                <VitalsLineGraph
                  isExpanded={isExpanded}
                  onClicked={() => onVitalClick(vital.type)}
                  dataPoints={dataPoints as linepoints[]}
                  outOfRange={outOfRange}
                  yAxisRange={yRange}
                  tickValues={yTicks}
                  baseLines={baseLines}
                  acceptableRange={vital.acceptableRange}
                  unit={unit}
                  customAxisBottom={customAxisBottom}
                  timeZone={timeZone}
                />
              )}
            </div>
          </div>
        </td>
      </tr>
    );
  };

  return (
    <div className={styles.vitalsPage}>
      <div className={styles.vitalsRangeBar}>
        <VitalsDateRange fireChangeOnLoad={true} onDateRangeChange={onDateRangeChange} />
      </div>
      <div className={styles.vitalsObservationTable}>
        <Table style={{ marginBottom: 0 }}>
          <thead>
            <tr>
              <th style={{ width: 130 }}>Name</th>
              <th style={{ width: 110 }}>Date Recorded</th>
              <th style={{ width: 100 }}>Latest Value</th>
              <th></th>
            </tr>
          </thead>
        </Table>
        {!!loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ marginTop: 100 }}
          >
            <Spinner />
          </div>
        ) : (
          <div>
            <Table id="vitalsTable">
              <tbody>
                {vitals.map((entry, idx) =>
                  createVitalsRow(
                    entry,
                    latestObservations[entry.type],
                    groupedObservations[entry.type],
                    startDate,
                    endDate,
                    idx,
                  ),
                )}
              </tbody>
            </Table>
          </div>
        )}
      </div>
    </div>
  );
};

VitalsPanelPCC.propTypes = {};
export default VitalsPanelPCC;
