import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Label } from "reactstrap";
import { featureSetAttributes, featureSetNotes, findFeature } from "./Features";
import EntryBlock from "./EntryBlock";
import FeatureEntry from "./FeatureEntry";
import { GroupNoteEntry } from "./Notes";
import FeatureCheckBoxes from "./FeatureCheckBoxes";
import ContentBLock from "../Encounter/ContentBlock";

const quadrantOptions = [
  { label: "RUQ", value: "RUQ" },
  { label: "RLQ", value: "RLQ" },
  { label: "LUQ", value: "LUQ" },
  { label: "LLQ", value: "LLQ" },
  { label: "All Quadrants", value: "All Quadrants" },
];

const GastroEntry = ({
  features,
  onChange,
  onNoteUpdate,
  onCheckboxUpdate,
  note,
  isNursingHomeSite,
  onRemoveSection,
}) => {
  const [bSoundsTrue, setBSoundsTrue] = useState(false);
  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    if (features) {
      const bs = features.find((feat) => feat.key === "bowelSounds");
      if (!!bs && bs?.attributes?.includes("Absent")) {
        setBSoundsTrue(true);
      } else {
        setBSoundsTrue(false);
      }
    }
  }, [features]);

  const bowelSounds = findFeature(features, "bowelSounds", "Bowel sounds", "Gastrointestinal");
  const setAttribute = featureSetAttributes({ onChange });
  const onNoteChange = featureSetNotes({ onChange: onChange, feature: bowelSounds });
  const selected = bowelSounds.notes
    ? quadrantOptions.find((choice) => choice.label === bowelSounds.notes)
    : { value: "Select location", label: "Select location" };

  const setCheckBox = (target) => {
    onCheckboxUpdate(features, "Gastrointestinal", target.checked, target.value);
  };

  const anyChecked = (val) => {
    const foundIdx = (features || []).findIndex((e) => e.group === "Gastrointestinal");
    if (foundIdx < 0) {
      return false;
    }
    return features[foundIdx][val];
  };

  const selectChange = (choice) => {
    if (choice) {
      const nv = choice.value;
      onNoteChange(nv);
    }
  };

  const hasEntires = () => {
    const foundIdx = (features || []).findIndex((e) => e.group === "Gastrointestinal");
    if (foundIdx < 0) {
      return false;
    }
    return !!features[foundIdx].attributes;
  };

  const handleRemoveSection = () => {
    onRemoveSection("Gastrointestinal");
    setHideContent(true);
  };

  useEffect(() => {
    setHideContent(!hasEntires());
  }, []);

  if (hideContent) {
    return (
      <ContentBLock
        title="Gastrointestinal"
        titleOnly={true}
        className="hide-content"
        onClick={() => setHideContent(false)}
      />
    );
  }

  return (
    <EntryBlock title="Gastrointestinal"  className="large">
      <div>
        <FeatureEntry
          {...{
            feature: bowelSounds,
            values: ["Present", "Absent"],
            onChange: setAttribute,
            id: "qa-gastro-entry-bowelSounds",
          }}
        />

        {!!bSoundsTrue && (
          <Label className="w-100 mb-2">
            Absent from:
            <Select
              options={quadrantOptions}
              onChange={selectChange}
              placeholder="Select location"
              value={selected}
              calssName="bowelSounds"
              id="bowelSoundsSelect"
            />
          </Label>
        )}

        <FeatureCheckBoxes {...{ setCheckBox, anyChecked, isNursingHomeSite }} />
        <div className="remove" onClick={handleRemoveSection}>
          Remove section -
        </div>
      </div>
      <div>
        <GroupNoteEntry
          {...{
            inputClass: "gastro-notes",
            onChange: onNoteUpdate,
            groupName: "Gastrointestinal",
            note,
            placeholder: "Note",
            sampleNote: "",
            id: "qa-gastro-group-entry",
          }}
        />
      </div>
    </EntryBlock>
  );
};

export default GastroEntry;
