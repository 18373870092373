import React, { Component } from "react";
import { Button } from "reactstrap";

type Props = {
  data: any;
  onCancel: Function;
  onSave: Function;
  readOnly?: boolean;
  unsavedSections?: any;
  title?: string;
};

type State = {
  isEditing: boolean;
  requiredFields: any[];
  restoreData?: any;
  showRequiredFieldErrors?: boolean;
};

export interface ElementProps {
  isEditing: boolean;
  restoreData: any;
  showRequiredFieldErrors?: boolean;
  requiredFields: any[];
  setRequiredFields: Function;
}
export default class InfoEditWrapper extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { isEditing: false, requiredFields: [], restoreData: null };
    this.onClick = this.onClick.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onClick() {
    if (!this.state.isEditing && !this.props.readOnly) {
      this.setState({ isEditing: true, restoreData: this.props.data });
    } else {
      this.setState({ showRequiredFieldErrors: false }, () => {
        if (this.state.requiredFields && this.state.requiredFields.length > 0) {
          this.setState({ showRequiredFieldErrors: true });
          return;
        }
        this.setState({ isEditing: false, restoreData: null, showRequiredFieldErrors: false });
        if (this.props.onSave) {
          this.props.onSave();
        }
      });
    }
  }

  onCancel() {
    this.setState({ isEditing: false, showRequiredFieldErrors: false, requiredFields: [] });
    if (this.state.restoreData && this.props.onCancel) {
      this.props.onCancel(this.state.restoreData);
    }
  }

  setRequiredFields = (fields) => {
    this.setState({ requiredFields: fields });
  };

  render() {
    const { children, ...other } = this.props;

    const elemsProps: ElementProps = {
      isEditing: this.state.isEditing,
      restoreData: this.state.restoreData,
      showRequiredFieldErrors: this.state.showRequiredFieldErrors,
      requiredFields: this.state.requiredFields,
      setRequiredFields: this.setRequiredFields,
      ...other,
    };
    const childrenWithProps = React.Children.map(children, (child: any) =>
      React.cloneElement(child, elemsProps),
    );

    const buttonLabel = this.state.isEditing ? "Save" : "Edit";
    const cl = this.state.isEditing ? "edit-toggle save" : "edit-toggle edit";
    const editError =
      this.state.isEditing &&
      this.props.unsavedSections &&
      this.props.unsavedSections[this.props.title || ""]
        ? "info-edit-wrapper-error"
        : " ";
    return (
      <div className={`info-edit-wrapper content-block ${editError}`}>
        {/*  {!this.props.readOnly && (
          <div className="float-right">
            {this.state.isEditing && (
              <Button id="qa-encounter-edit-cancel-btn" color="link mr-1" onClick={this.onCancel}>
                Cancel
              </Button>
            )}
            <Button onClick={this.onClick} id="qa-encounter-edit-andsave-btn" className={cl}>
              {buttonLabel}
            </Button>
          </div>
        )} */}
        {childrenWithProps}
      </div>
    );
  }
}
