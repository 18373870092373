import React, { FC, useCallback, useEffect, useMemo, useState, useRef } from "react";
import { Alert, Button, Input } from "reactstrap";

import api from "../../api";
import ErrorList from "../ErrorList";
import CheckToggle from "../CheckToggle";
import styles from "./treatmentStatusEditModal.scss";
import { IConsentStatusDetails, IPccPatientProfile } from "../../constants/Types";
import { PointTooltipProps } from "@nivo/line";
import ButtonSpinner from "../ButtonSpinner";

type ConsentStatusModalProps = {
  setModal: () => void;
  userID: string;
  consent: boolean | null;
  setConsent: (consent: boolean) => void;
  setConsentDate: Function;

  CovidZone: string | undefined;
  consentDetails: IConsentStatusDetails;
  updateProfile: Function;
};
const CovidZones = {
  Green: "Green",
  Blue: "Blue",
  Yellow: "Yellow",
  Red: "Red",
};

const TreatmentStatusEditModal: FC<ConsentStatusModalProps> = ({
  setModal,
  userID,
  setConsentDate,
  CovidZone,
  consentDetails,
  updateProfile,
}) => {
  const [content, setContent] = useState(" ");
  const [error, setError] = useState<string[]>([]);
  const [initialConsent, setInitialConsent] = useState<boolean | null>(
    consentDetails?.consentStatus,
  );
  const [consentMain, setConsentMain] = useState<boolean | null>(consentDetails?.consentStatus);
  const [rpmConsent, setRpmConsent] = useState<boolean | null>(consentDetails?.rpm);
  const [initialRPMConsent, setInitialRPMConsent] = useState<boolean | null>(consentDetails?.rpm);
  const [ccmConsent, setCcmConsent] = useState<boolean | null>(consentDetails?.ccm);
  const [initialCCMConsent, setInitialCCMConsent] = useState<boolean | null>(consentDetails?.ccm);
  const [covidZone, setZone] = useState(CovidZone);
  const [isLoading, setIsLoading] = useState(false);

  const changeValue = (zone) => {
    setZone(zone);
  };

  useEffect(() => {
    if (!consentMain) {
      setRpmConsent(false);
      setCcmConsent(false);
      setContent("");
    }
  }, [consentMain]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      onSaveChanges();
    }
  };

  /**
   * Below 2 methods are replacement of inline oncChange handle
   * Reason: it needs to update content on click
   */
  const customCcmOnChangemHandler = () => {
    setCcmConsent(!ccmConsent);
    setContent("");
  };

  const customRpmOnChangemHandler = () => {
    setRpmConsent(!rpmConsent);
    setContent("");
  };

  const onSaveChanges = async () => {
    let newErr: string[] = [];

    if (!consentMain && (rpmConsent || ccmConsent)) {
      newErr.push("’Consent for Treatment’ must be checked to use consent to CCM or RPM");
      setError(newErr);
      if (!content) {
        newErr.push("Please enter a reason for status change.");
        setError(newErr);
        return;
      }
    }

    if (consentMain && (rpmConsent != initialRPMConsent || ccmConsent != initialCCMConsent)) {
      if (!content) {
        newErr.push("Please enter a reason for status change.");
        setError(newErr);
        return;
      }
    }

    if (consentMain != initialConsent) {
      if (!content) {
        newErr.push("Please enter a reason for status change.");
        setError(newErr);
        return;
      }
    }

    if (newErr.length === 0) {
      try {
        setIsLoading(true);
        await api.Providers.changeConsent({
          rpm: !!rpmConsent,
          ccm: !!ccmConsent,
          consentStatus: !!consentMain,
          consentReasn: content,
          userID: userID,
        });
        await api.Providers.changeCovidZone(userID, { covidZone: covidZone });
        await updateProfile();
        setModal();

        setError([]);
      } catch (error) {
        setError(["Error saving changes"]);
      }
    }
    setIsLoading(false);
  };
  return (
    <div className="modal-overlay">
      <div className={`ml-auto mr-auto ${styles.consentModal}`}>
        <div className={styles.titleContainer}>
          <h1 className={styles.title}>Edit Treatment Program Consent</h1>
        </div>
        <div className="d-flex justify-content-between">
          <div className={`${styles.blockContainer}`}>
            <h3>Treatment Programs</h3>
            <div className="d-flex justify-content-around flex-column flex-grow-1">
              <CheckToggle
                size="small"
                onChange={() => setConsentMain(!consentMain)}
                checked={!!consentMain}
              >
                <span>Consent to Treatment</span>
              </CheckToggle>
              <CheckToggle
                size="small"
                onChange={() => customCcmOnChangemHandler()}
                checked={!!ccmConsent}
              >
                <span>Consent to Chronic Care Management</span>
              </CheckToggle>
              <CheckToggle
                size="small"
                onChange={() => customRpmOnChangemHandler()}
                checked={!!rpmConsent}
              >
                <span>Consent to Remote Physiological Monitoring</span>
              </CheckToggle>
            </div>
          </div>

          <div className={`${styles.blockContainer} ml-4`}>
            <h3>COVID-19 Zone</h3>
            <div className="d-flex justify-content-around flex-column flex-grow-1">
              <div className="d-flex align-items-center">
                <label className={styles.radioBtnContainer}>
                  None
                  <Input
                    onChange={() => changeValue("N/A")}
                    type="radio"
                    name="covidZone"
                    checked={!covidZone || covidZone === "N/A" || covidZone === "no zone"}
                  />
                  <span className={styles.checkmark} />
                </label>
              </div>
              {Object.keys(CovidZones).map((zone, index) => {
                return (
                  <div
                    className="d-flex align-items-center"
                    id={`qa-status-covidZone-${index}`}
                    key={`covidZone-${index}`}
                  >
                    <label className={styles.radioBtnContainer}>
                      {zone}
                      <Input
                        onChange={() => changeValue(zone)}
                        type="radio"
                        name="covidZone"
                        checked={zone === covidZone}
                      />
                      <span className={styles.checkmark} />
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className={styles.textContainer}>
          <Input
            type="textarea"
            id="consentText"
            className={styles.consentTextArea}
            placeholder="Please indicate reason(s) for update(s)"
            onChange={(e) => setContent(e.target.value)}
            onKeyPress={handleKeyPress}
          />
        </div>
        <div className={!!error ? styles.errorTitle : ""}>
          {error.length > 0 &&
            error.map((error, i) => (
              <label className={styles.errorText} key={i}>
                {error}
              </label>
            ))}
        </div>
        <div className={styles.buttonContainer}>
          <Button className={styles.cancelEntry} onClick={setModal}>
            Cancel
          </Button>
          <Button className={styles.confirmChange} onClick={onSaveChanges} disabled={isLoading}>
            <ButtonSpinner inProgress={isLoading} /> Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TreatmentStatusEditModal;
