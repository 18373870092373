import React, { FC } from "react";
import { Label } from "reactstrap";

import BlockSection from "./BlockSection";
import InfoSelect from "./InfoSelect";
import YesNoToggle from "./YesNoToggle";
import { allergyHistory } from "../../constants/Encounter";
import { IAllergySocialHistory } from "../../constants/Types";

type Props = {
  socialHistory: IAllergySocialHistory;
  oldSocialHistory: IAllergySocialHistory;
  onAllergyHistoryChange: (field: string) => (newValue: any) => void;
};

const AllergyLivingHistoryEditor: FC<Props> = ({
  socialHistory,
  oldSocialHistory,
  onAllergyHistoryChange,
}) => {
  return (
    <BlockSection title={"Living"}>
      <div className="form-row">
        <div className="col-6 col-sm-4 mr-4">
          <Label>Residence building type:</Label>
          <div className="col-6 col-sm-10 pl-0">
            <InfoSelect
              value={socialHistory.allergyLivingHistory.residenceBuildingType}
              options={allergyHistory.residenceBuildingTypes}
              oldValue={oldSocialHistory.allergyLivingHistory.residenceBuildingType}
              onChange={onAllergyHistoryChange("residenceBuildingType")}
            />
          </div>
        </div>
        <div className="col-6 col-sm-4 mr-4">
          <Label>Residence construction year</Label>
          <div className="col-6 col-sm-10 pl-0">
            <InfoSelect
              value={socialHistory.allergyLivingHistory.residenceConstructionYear}
              options={allergyHistory.residenceConstructionYears}
              oldValue={oldSocialHistory.allergyLivingHistory.residenceConstructionYear}
              onChange={onAllergyHistoryChange("residenceConstructionYear")}
            />
          </div>
        </div>
        <div className="col-6 col-sm-3">
          <Label>Residence location</Label>
          <div className="col-6 col-sm-12 pl-0">
            <InfoSelect
              value={socialHistory.allergyLivingHistory.residenceLocation}
              options={allergyHistory.residenceLocations}
              oldValue={oldSocialHistory.allergyLivingHistory.residenceLocation}
              onChange={onAllergyHistoryChange("residenceLocation")}
            />
          </div>
        </div>
      </div>
      <div className="form-row mt-3">
        <div className="col-6 col-sm-4 mr-4">
          <Label>Plants in home</Label>
          <div className="col-6 col-sm-10 pl-0">
            <InfoSelect
              value={socialHistory.allergyLivingHistory.plantCount}
              options={allergyHistory.numberOfPlant}
              oldValue={oldSocialHistory.allergyLivingHistory.plantCount}
              onChange={onAllergyHistoryChange("plantCount")}
            />
          </div>
        </div>
        <div className="col-6 col-sm-4 pr-0">
          <Label>Home has mold/mildew/dampness</Label>
          <div className="pl-0">
            <YesNoToggle
              value={socialHistory.allergyLivingHistory.moldEtcPresence}
              oldValue={oldSocialHistory.allergyLivingHistory.moldEtcPresence}
              onChange={onAllergyHistoryChange("moldEtcPresence")}
            />
          </div>
        </div>
      </div>
      <div className="form-row mt-3">
        <div className="col-6 col-sm-4 mr-4">
          <Label>Residence heating:</Label>
          <div className="pl-0">
            <InfoSelect
              value={socialHistory.allergyLivingHistory.residenceHeatingType}
              options={allergyHistory.residenceHeatingTypes}
              oldValue={oldSocialHistory.allergyLivingHistory.residenceHeatingType}
              onChange={onAllergyHistoryChange("residenceHeatingType")}
            />
          </div>
        </div>
        <div className="col-6 col-sm-4 mr-4">
          <Label>Residence AC:</Label>
          <div className="pl-0">
            <InfoSelect
              value={socialHistory.allergyLivingHistory.residenceACType}
              options={allergyHistory.residenceACTypes}
              oldValue={oldSocialHistory.allergyLivingHistory.residenceACType}
              onChange={onAllergyHistoryChange("residenceACType")}
            />
          </div>
        </div>
        <div className="col-6 col-sm-3">
          <Label>Home has air filter/cleaner</Label>
          <div className="pl-0">
            <YesNoToggle
              value={socialHistory.allergyLivingHistory.airFilter}
              oldValue={oldSocialHistory.allergyLivingHistory.airFilter}
              onChange={onAllergyHistoryChange("airFilter")}
            />
          </div>
        </div>
      </div>
      <div className="form-row mt-3">
        <div className="col-6 col-sm-4 mr-4">
          <Label>Residence flooring main areas:</Label>
          <div className="pl-0">
            <InfoSelect
              value={socialHistory.allergyLivingHistory.flooringMainArea}
              options={allergyHistory.flooringTypes}
              oldValue={oldSocialHistory.allergyLivingHistory.flooringMainArea}
              onChange={onAllergyHistoryChange("flooringMainArea")}
            />
          </div>
        </div>
        <div className="col-6 col-sm-4">
          <Label>Residence flooring bedrooms:</Label>
          <div className="pl-0">
            <InfoSelect
              value={socialHistory.allergyLivingHistory.flooringBedroom}
              options={allergyHistory.flooringTypes}
              oldValue={oldSocialHistory.allergyLivingHistory.flooringBedroom}
              onChange={onAllergyHistoryChange("flooringBedroom")}
            />
          </div>
        </div>
      </div>
      <div className="form-row mt-3">
        <div className="col-6 col-sm-4 mr-4">
          <Label>Bed type:</Label>
          <div className="pl-0">
            <InfoSelect
              value={socialHistory.allergyLivingHistory.bedType}
              options={allergyHistory.bedTypes}
              oldValue={oldSocialHistory.allergyLivingHistory.bedType}
              onChange={onAllergyHistoryChange("bedType")}
            />
          </div>
        </div>
        <div className="col-6 col-sm-4">
          <Label>Mattress Age</Label>
          <div className="pl-0">
            <InfoSelect
              value={socialHistory.allergyLivingHistory.mattressAge}
              options={allergyHistory.beddingAge}
              oldValue={oldSocialHistory.allergyLivingHistory.mattressAge}
              onChange={onAllergyHistoryChange("mattressAge")}
            />
          </div>
        </div>
      </div>
      <div className="form-row mt-3">
        <div className="col-6 col-sm-4 mr-4">
          <Label>Pillow type:</Label>
          <div className="pl-0">
            <InfoSelect
              value={socialHistory.allergyLivingHistory.pillowType}
              options={allergyHistory.pillowTypes}
              oldValue={oldSocialHistory.allergyLivingHistory.pillowType}
              onChange={onAllergyHistoryChange("pillowType")}
            />
          </div>
        </div>
        <div className="col-6 col-sm-4 mr-4">
          <Label>Pillow Age:</Label>
          <div className="pl-0">
            <InfoSelect
              value={socialHistory.allergyLivingHistory.pillowAge}
              options={allergyHistory.beddingAge}
              oldValue={oldSocialHistory.allergyLivingHistory.pillowAge}
              onChange={onAllergyHistoryChange("pillowAge")}
            />
          </div>
        </div>
        <div className="col-6 col-sm-3">
          <Label>Feather comforter?</Label>
          <div className="pl-0">
            <YesNoToggle
              value={socialHistory.allergyLivingHistory.featherComforter}
              oldValue={oldSocialHistory.allergyLivingHistory.featherComforter}
              onChange={onAllergyHistoryChange("featherComforter")}
            />
          </div>
        </div>
      </div>
    </BlockSection>
  );
};

export default AllergyLivingHistoryEditor;
