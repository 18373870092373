import React, { FC } from "react";
import { Button } from "reactstrap";

import StartTimer from "./StartTimer";
import PDFButton from "../PDFButton";
import PDF from "../../images/pdf.svg";
import { IChartEntry, ITestResponse } from "../../constants/Types";

type Props = {
  testResults: Partial<ITestResponse> | IChartEntry;
  onConfirm: () => void;
};

const ChartResultConfirmation: FC<Props> = ({ testResults, onConfirm }) => {
  const url = testResults.pdfURL;
  const buttonText = testResults.description;

  return (
    <div className="dialog-container dialog-view">
      <div className="form-container">
        <div className="float-right confirmation-timer">
          <StartTimer onlyProgress={true} />
        </div>
        <h1>Review test results before proceeding!</h1>
        <div className="d-flex">
          <PDFButton url={url} showEditButton={false} style="pdf-button">
            <PDF /> {buttonText}
          </PDFButton>
        </div>

        <div className="d-flex">
          <span>Note: You may also access these labs on the patient chart</span>
        </div>
        <div className="mt-5 d-flex justify-content-center">
          <Button onClick={onConfirm} color="primary" className="ml-3">
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ChartResultConfirmation;
