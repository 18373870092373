import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import get from "lodash.get";
import moment from "moment";
import { Row, Col, Table } from "reactstrap";

import { ACTIVITY_SUMMARY } from "../../constants/actionTypes";
import "../../stylesheets/activityMonitor.scss";

import NavBar from "../Provider/NavBar";
import ActivityMonitorIcon from "../../images/ActivityMonitor.svg";
import api from "../../api";

const mapStateToProps = (state, ownProps) => {
  return {
    currentUser: state.common.currentUser,
    activitySummary: state.monitoring,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateSummary: (payload) => dispatch({ type: ACTIVITY_SUMMARY, payload }),
});

const ProviderCount = ({ count }) => {
  count = count || 0;
  let cl = "critical";
  if (count > 1) {
    cl = "warning";
  }
  if (count > 4) {
    cl = "sufficient";
  }
  return <td className={cl}>{count}</td>;
};

const TakingTest = ({ time }) => {
  let timeRemaining = "Any second now";
  const inFuture = time && time > moment().format();

  if (!time || time === "0001-01-01T00:00:00Z") {
    timeRemaining = "User is in intake";
  } else if (time && time !== "0001-01-01T00:00:00Z" && inFuture) {
    timeRemaining = moment(time).fromNow(true);
  }

  return (
    <div className="d-flex flex-column">
      <div className="time-remaining">{timeRemaining}</div>
      {inFuture && <div className="test-status">until available</div>}
    </div>
  );
};

const TimeWaiting = ({ time }) => {
  return (
    <div>
      <div className="time-waiting">{moment(time).fromNow(true)}</div>
      <div className="waiting-status">in waiting room</div>
    </div>
  );
};

const Encounter = ({ encounter }) => {
  return (
    <tr>
      <td>{encounter.demographic}</td>
      <td>{encounter.languages ? encounter.languages.join(", ") : []}</td>
      <td>{encounter.location}</td>
      <td>
        {encounter.readyAt && <TimeWaiting time={encounter.readyAt} />}
        {!encounter.readyAt && <TakingTest time={encounter.completionTime} />}
      </td>
    </tr>
  );
};
const PatientQueue = ({ encounters }) => {
  return (
    <Table className="patient-queue">
      <tbody>
        {encounters.map((v, idx) => (
          <Encounter key={idx} encounter={v} />
        ))}
      </tbody>
    </Table>
  );
};

const LocationSummary = ({ summary, location, ...props }) => {
  return (
    <div className="location-summary">
      <Table>
        <thead>
          <tr>
            <th>{location}</th>
            <th>English</th>
            <th>Spanish</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Adults</td>
            <ProviderCount count={get(summary, "adult.languageCount.english")} />
            <ProviderCount count={get(summary, "adult.languageCount.spanish")} />
          </tr>
          <tr>
            <td>Adolescents</td>
            <ProviderCount count={get(summary, "adolescent.languageCount.english")} />
            <ProviderCount count={get(summary, "adolescent.languageCount.spanish")} />
          </tr>
          <tr>
            <td>Peds</td>
            <ProviderCount count={get(summary, "child.languageCount.english")} />
            <ProviderCount count={get(summary, "child.languageCount.spanish")} />
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

const ActivitySummary = ({ updateSummary, activitySummary, currentUser }) => {
  const [states, setStates] = useState<string[]>([]);

  useEffect(() => {
    if (currentUser) {
      updateSummary(
        api.Monitoring.activitySummary({
          practiceID: currentUser.currentPracticeID,
        }),
      );
    }
  }, [currentUser]);

  useEffect(() => {
    api.MobileVersions.check().then((res) => {
      if (res.supportedStates && res.supportedStates != null) setStates(res.supportedStates);
    });
  }, []);

  const encounters = activitySummary.encounters || [];
  const summaries = activitySummary.locationSummaries;

  return (
    <div>
      <NavBar />
      <div className="dashboard-container qa-activityMonitorContainer">
        <div>
          <h4>
            {" "}
            <div className="header-icon float-left">
              <ActivityMonitorIcon />
            </div>
            Provider / Patient Online Activity{" "}
          </h4>
        </div>
        <div className="dashboard-component align-content-stretch">
          <Row>
            <Col md={5} className="provider-count-column">
              <h3 className="float-right">
                {" "}
                Clocked-in total: {activitySummary.totalProviders || 0}{" "}
              </h3>
              <h2> Provider Count </h2>
              <div className="d-flex flex-wrap align-items-center justify-content-between">
                {states.map((v) => (
                  <LocationSummary key={v} location={v} summary={summaries[v]} />
                ))}
              </div>
            </Col>
            <Col md={7} className="patient-queue-column">
              <h3 className="float-right">Waiting/Pending total: {encounters.length}</h3>
              <h2> Patient Queue </h2>
              <PatientQueue encounters={encounters} />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ActivitySummary);
