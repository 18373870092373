import React, { useEffect, useState } from "react";
import ContentBLock from "../Encounter/ContentBlock";
import { featureSetAttributes, findFeature, featureSetNotes } from "./Features";
import EntryBlock from "./EntryBlock";
import FeatureEntry from "./FeatureEntry";
import { GroupNoteEntry } from "./Notes";
import CheckSelect from "../CheckSelect";
import PillList from "../Encounter/PillList";
import FeatureCheckBoxes from "./FeatureCheckBoxes";
import PropTypes from "prop-types";
import Select from "react-select";
const BreathSoundsEntries = ({ onChange, features }) => {
  const breathSoundsOptions = [
    "Wheezes (RUL)",
    "Wheezes (RML)",
    "Wheezes (RLL)",
    "Wheezes (LUL)",
    "Wheezes (LLL)",
    "Crackles",
    "Rhonchi",
    "Absent / dull",
    "Other",
  ];
  const breathSounds = findFeature(features, "breathSounds", "Breath Sounds", "Chest");

  const filterbreathSoundsOptions = (list, options) => {
    return (list || []).filter((r) => options.includes(r));
  };

  const anyOptionsSelected = (list, options) => {
    if (!list) return false;
    for (const option in options) {
      if (list.includes(option)) return true;
    }
    return false;
  };

  const breathSoundsSelected = breathSounds.attributes.includes("Not CTAB");
  const filteredbreathSoundsOptions = filterbreathSoundsOptions(
    breathSounds.attributes,
    breathSoundsOptions,
  );

  const onRemove = (tmItem) => {
    onChange(
      breathSounds,
      breathSounds.attributes.filter((v) => v !== tmItem),
    );
  };

  const onSelect = (newValues) => {
    let as = breathSounds.attributes.filter((v) => !newValues.includes(v));
    as.push(...newValues);
    onChange(breathSounds, as);
  };

  const breathSoundsOptionsSelected = anyOptionsSelected(
    breathSounds.attributes,
    breathSoundsOptions,
  )
    ? "yes-no-toggle item-selected d-flex"
    : "yes-no-toggle  d-flex";

  const onYesNoChange = (feature, newValues) => {
    if (newValues.includes("CTAB")) {
      onChange(breathSounds, ["CTAB"]);
    } else {
      onChange(breathSounds, newValues);
    }
  };

  return (
    <>
      <FeatureEntry
        feature={breathSounds}
        values={["CTAB", "Not CTAB"]}
        onChange={onYesNoChange}
        id="qa-chest-entry-breathSounds"
      />

      {breathSoundsSelected && (
        <div className="attribute-container">
          <div className={breathSoundsOptionsSelected}>
            <CheckSelect
              options={breathSoundsOptions}
              selected={filteredbreathSoundsOptions}
              onChange={onSelect}
              label={"Select"}
            />
          </div>
          <div className="breathSounds-items">
            <PillList
              items={filterbreathSoundsOptions(breathSounds.attributes, breathSoundsOptions)}
              onRemove={onRemove}
            />
          </div>
        </div>
      )}
    </>
  );
};

const LungAllergyQuestions = ({ features, setAttribute }) => {
  const wheezing = findFeature(features, "wheezing", "Wheezing", "Chest");

  return (
    <React.Fragment>
      <FeatureEntry
        {...{
          feature: wheezing,
          values: ["No ", "Yes"],
          onChange: setAttribute,
          id: "qa-chest-entry-wheezing",
        }}
      />
    </React.Fragment>
  );
};

const lungSoundOptions = [
  { label: "RUL", value: "RUL" },
  { label: "RML", value: "RML" },
  { label: "RLL", value: "RLL" },
  { label: "LUL", value: "LUL" },
  { label: "LLL", value: "LLL" },
  { label: "All fields", value: "All fields" },
];

const ChestEntry = ({
  notes,
  features,
  onChange,
  onNoteChange,
  visitType,
  usedTytoCare = false,
  onCheckboxUpdate,
  isNursingHomeSite,
  onRemoveSection,
}) => {
  const [lungSoundsTrue, setLungSoundsTrue] = useState(true);
  const [wheezesTrue, setWheezesTrue] = useState(false);
  const [rhonciTrue, setRhonciTrue] = useState(false);
  const [diminishedTrue, setDiminishedTrue] = useState(false);
  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    if (features && isNursingHomeSite) {
      const ls = features.find((feat) => feat.key === "lungSounds");
      if (!!ls && !!ls.attributes && ls.attributes.includes("No")) {
        setLungSoundsTrue(false);
      } else {
        setLungSoundsTrue(true);
      }

      const we = features.find((feat) => feat.key === "wheezes");
      if (!!we && !!we.attributes && we.attributes.includes("Yes")) {
        setWheezesTrue(true);
      } else {
        setWheezesTrue(false);
      }

      const ro = features.find((feat) => feat.key === "rhonci");
      if (!!ro && !!ro.attributes && ro.attributes.includes("Yes")) {
        setRhonciTrue(true);
      } else {
        setRhonciTrue(false);
      }

      const dm = features.find((feat) => feat.key === "diminished");
      if (!!dm && !!dm.attributes && dm.attributes.includes("Yes")) {
        setDiminishedTrue(true);
      } else {
        setDiminishedTrue(false);
      }
    }
  }, [features]);

  const movement = findFeature(features, "chestMovement", "Movement", "Chest");
  const retractions = findFeature(features, "chestRetractions", "Retractions", "Chest");
  const accessory = findFeature(features, "accessoryMuscles", "Accessory muscle use", "Chest");
  const lungSounds = findFeature(features, "lungSounds", "Lung Sounds - Clear", "Chest");
  const wheezes = findFeature(features, "wheezes", "Wheezes", "Chest");
  const rhonci = findFeature(features, "rhonci", "Rhonci", "Chest");
  const diminished = findFeature(features, "diminished", "Diminished", "Chest");

  const setAttribute = featureSetAttributes({ onChange });
  const wzNoteChange = featureSetNotes({ onChange: onChange, feature: wheezes });
  const ronNoteChange = featureSetNotes({ onChange: onChange, feature: rhonci });
  const dimNoteChange = featureSetNotes({ onChange: onChange, feature: diminished });

  const isAllergies = visitType === "rash_and_allergy";
  const weSelected = wheezes.notes
    ? lungSoundOptions.find((choice) => choice.label === wheezes.notes)
    : { value: "Select location", label: "Select location" };
  const roSelected = rhonci.notes
    ? lungSoundOptions.find((choice) => choice.label === rhonci.notes)
    : { value: "Select location", label: "Select location" };
  const dimSelected = diminished.notes
    ? lungSoundOptions.find((choice) => choice.label === diminished.notes)
    : { value: "Select location", label: "Select location" };

  const setCheckBox = (target) => {
    onCheckboxUpdate(features, "Chest", target.checked, target.value);
  };

  const anyChecked = (val) => {
    const foundIdx = (features || []).findIndex((e) => e.group === "Chest");
    if (foundIdx < 0) {
      return false;
    }
    return features[foundIdx][val];
  };

  const hasEntires = () => {
    const foundIdx = (features || []).findIndex((e) => e.group === "Chest");
    if (foundIdx < 0) {
      return false;
    }
    return !!features[foundIdx].attributes;
  };

  const handleRemoveSection = () => {
    onRemoveSection("Chest");
    setHideContent(true);
  };

  useEffect(() => {
    setHideContent(!hasEntires());
  }, []);

  const wheezeChange = (choice) => {
    const wv = choice.value;
    wzNoteChange(wv);
  };

  const rhonciChange = (choice) => {
    const rv = choice.value;
    ronNoteChange(rv);
  };

  const diminishedChange = (choice) => {
    const dv = choice.value;
    dimNoteChange(dv);
  };

  if (hideContent) {
    return (
      <ContentBLock
        title="Chest"
        titleOnly={true}
        className="hide-content"
        onClick={() => setHideContent(false)}
      />
    );
  }

  return (
    <EntryBlock title="Chest" className="large">
      <div className="chest-entries">
        <FeatureEntry
          {...{
            feature: movement,
            values: ["Symmetric", "Asymmetric"],
            onChange: setAttribute,
            id: "qa-chest-entry-movement",
          }}
        />

        {!!isNursingHomeSite && (
          <>
            <FeatureEntry
              {...{
                feature: lungSounds,
                values: ["Yes", "No"],
                onChange: setAttribute,
                id: "qa-chest-entry-lungSounds",
              }}
            />
            {!lungSoundsTrue && (
              <>
                <FeatureEntry
                  {...{
                    feature: wheezes,
                    values: ["No", "Yes"],
                    onChange: setAttribute,
                    id: "qa-chest-entry-wheezes",
                  }}
                />
                {!!wheezesTrue && (
                  <Select
                    placeholder="Select location"
                    options={lungSoundOptions}
                    onChange={wheezeChange}
                    value={weSelected}
                    id="wheezeSelect"
                    className="mb-2"
                  />
                )}
                <FeatureEntry
                  {...{
                    feature: rhonci,
                    values: ["No", "Yes"],
                    onChange: setAttribute,
                    id: "qa-chest-entry-rhonci",
                  }}
                />
                {!!rhonciTrue && (
                  <Select
                    placeholder="Select location"
                    options={lungSoundOptions}
                    onChange={rhonciChange}
                    value={roSelected}
                    id="wheezeSelect"
                    className="mb-2"
                  />
                )}
                <FeatureEntry
                  {...{
                    feature: diminished,
                    values: ["No", "Yes"],
                    onChange: setAttribute,
                    id: "qa-chest-entry-diminished",
                  }}
                />
                {!!diminishedTrue && (
                  <Select
                    options={lungSoundOptions}
                    onChange={diminishedChange}
                    value={dimSelected}
                    id="diminishedSelect"
                    className="mb-2"
                  />
                )}
              </>
            )}
          </>
        )}

        <FeatureEntry
          {...{
            feature: retractions,
            values: ["No", "Yes"],
            onChange: setAttribute,
            className: "yes-no-toggle",
            id: "qa-chest-entry-retractions",
          }}
        />
        <FeatureEntry
          {...{
            feature: accessory,
            values: ["No", "Yes"],
            onChange: setAttribute,
            className: "yes-no-toggle",
            id: "qa-chest-entry-accessory",
          }}
        />
        {usedTytoCare && <BreathSoundsEntries onChange={setAttribute} features={features} />}
        {isAllergies && <LungAllergyQuestions features={features} setAttribute={setAttribute} />}
        <FeatureCheckBoxes {...{ setCheckBox, anyChecked, isNursingHomeSite }} />
        <div className="remove" onClick={handleRemoveSection}>
          Remove section -
        </div>
      </div>
      <GroupNoteEntry
        {...{
          groupName: "Chest",
          onChange: onNoteChange,
          note: notes["Chest"],
          id: "qa-chest-group-entry",
        }}
      />
    </EntryBlock>
  );
};

ChestEntry.propTypes = {
  notes: PropTypes.object,
  features: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  onNoteChange: PropTypes.func,
  visitType: PropTypes.string,
  usedTytoCare: PropTypes.bool,
  onCheckboxUpdate: PropTypes.func,
  isNursingHomeSite: PropTypes.bool,
};
export default ChestEntry;
