import React, { FC, useState } from "react";

import { mapKeysToString } from "../../Encounter/MedicationsTried";

import MedicalProfileView from "./MedicalProfileView";
import MedicalProfileEdit from "./MedicalProfileEdit";
import "../../../stylesheets/patient.scss";
import { IMedicalProfile, IMedicationKey, IEncounteredPatient } from "../../../constants/Types";

type Props = {
  profile: IMedicalProfile;
  medicalCatalog: IMedicationKey[] | null;
  surgicalCatalog: IMedicationKey[] | null;
  isEditing: boolean;
  onChange: (field: string) => (value: string) => void;
  restoreData: IMedicalProfile;
  userID: string;
  patient: IEncounteredPatient;
};

const MedicalProfile: FC<Props> = ({
  profile,
  medicalCatalog,
  surgicalCatalog,
  isEditing,
  onChange,
  restoreData,
  userID,
  patient,
}) => {
  const allergies = profile.allergies || [];
  let surgicalHistory = mapKeysToString(
    profile.surgicalHistoryKeys.keys || [],
    surgicalCatalog || [],
  );
  surgicalHistory = surgicalHistory.concat(profile.surgicalHistoryKeys.other || []);
  surgicalHistory = surgicalHistory.length == 0 ? ["None"] : surgicalHistory;

  let medicalHistory = mapKeysToString(profile.medicalHistoryKeys.keys || [], medicalCatalog || []);
  medicalHistory = medicalHistory.concat(profile.medicalHistoryKeys.other || []);
  medicalHistory = medicalHistory.length == 0 ? ["None"] : medicalHistory;
  const socialHistory = profile.socialHistoryV2;
  const correctiveLenses = profile.correctiveLenses || {};
  const womensHealth = profile.womensHealth;

  return (
    <>
      {isEditing ? (
        <MedicalProfileEdit
          medicalHistory={medicalHistory}
          medications={profile.medications}
          allergies={allergies}
          socialHistory={socialHistory}
          patient={patient}
          isEditing={isEditing}
          restoreData={restoreData}
          profile={profile}
          medicalCatalog={medicalCatalog}
          surgicalCatalog={surgicalCatalog}
          onChange={onChange}
          userID={userID}
          surgicalHistory={surgicalHistory}
        />
      ) : (
        <MedicalProfileView
          medicalHistory={medicalHistory}
          medications={profile.medications}
          allergies={allergies}
          socialHistory={socialHistory}
          profile={profile}
          patient={patient}
          surgicalHistory={surgicalHistory}
        />
      )}
    </>
  );
};


export default MedicalProfile;
