import { UPDATE_CURRENT_TIME, SET_IDLE_LOGOUT_TIME, AUTHENTICATE } from "../constants/actionTypes";
import { Reducer } from "redux";

const defaultState = {
  currentDate: new Date(),
  currentTime: new Date().getTime(),
};

type timeState = {
  currentDate: Date;
  currentTime: number;
  idleLogoutTime?: number;
};

const IDLE_TIMEOUT = 15 * 60000;

const timeReducer: Reducer<timeState, any> = (state = defaultState, action: any) => {
  switch (action.type) {
    case UPDATE_CURRENT_TIME:
      const date = new Date();
      return {
        ...state,
        currentDate: date,
        currentTime: date.getTime(),
      };
    case SET_IDLE_LOGOUT_TIME:
    case "@@router/LOCATION_CHANGE":
    case AUTHENTICATE:
      return {
        ...state,
        idleLogoutTime: new Date().getTime() + IDLE_TIMEOUT,
      };
    default:
      return state;
  }
};

export default timeReducer;
