import React, { FC } from "react";
import { Label } from "reactstrap";

import { Editor } from "./IllContacts";
import BlockSection from "./BlockSection";

export const HerpesIllContactsView = ({ diseases }) => {
  const herpesContact = diseases.Herpes
    ? diseases.Herpes[0] === "false"
      ? " No"
      : " Yes"
    : " Not specified";
  const coldSoreContact =
    diseases["Cold sore"] && diseases["Cold sore"] !== "false"
      ? ` ${diseases["Cold sore"]}`
      : " No";
  return (
    <div>
      <BlockSection title="Other Ill Contacts">
        <div>
          <Label>Contact with someone who has an oral cold sore?</Label>
          {coldSoreContact}
        </div>
        <div>
          <Label>Contact with a sexual partner with known or suspected genital herpes?</Label>
          {herpesContact}
        </div>
      </BlockSection>
    </div>
  );
};

type HerpesIllContactsEditorProps = {
  diseases: {
    [key: string]: string[];
  };
  oldDiseases: {
    [key: string]: string[];
  };
  onChanged: (value: any) => void;
};

export const HerpesIllContactsEditor: FC<HerpesIllContactsEditorProps> = ({
  diseases,
  oldDiseases,
  onChanged,
}) => {
  const herpes = diseases.Herpes;
  const oldHerpes = oldDiseases.Herpes;
  const coldSores = diseases["Cold sore"] ? diseases["Cold sore"] : "";
  const oldColdSores = oldDiseases["Cold sore"] ? oldDiseases["Cold sore"] : ["false"];

  const onChange = (relevantDisease) => (newValue) => {
    onChanged({
      illContacts: { diseases: { ...diseases, [relevantDisease]: newValue } },
    });
  };
  const onMakeNotApplicable = () => {
    onChanged({
      illContacts: {
        diseases: { Herpes: diseases.Herpes },
        notApplicable: true,
      },
    });
  };
  return (
    <div>
      <BlockSection title="Ill Contacts">
        <Label>Contact with someone who has an oral cold sore?</Label>
        <Editor
          disease={coldSores}
          oldDisease={oldColdSores}
          onChange={onChange("Cold sore")}
          relevantDisease={null}
          handleToggle={onMakeNotApplicable}
        />
        <Label>Contact with a sexual partner with known or suspected genital herpes?</Label>
        <Editor
          disease={herpes}
          oldDisease={oldHerpes}
          onChange={onChange("Herpes")}
          relevantDisease={"STD"}
          handleToggle={() => onChange("Herpes")(["false"])}
        />
      </BlockSection>
    </div>
  );
};
