import React, { useState, useEffect } from "react";
import ContentBLock from "../Encounter/ContentBlock";

import { findFeature, featureSetAttributes } from "../PhysicalExam/Features";
import EntryBlock from "../PhysicalExam/EntryBlock";
import { GroupNoteEntry } from "../PhysicalExam/Notes";
import FeatureEntry from "../PhysicalExam/FeatureEntry";
import FeatureCheckBoxes from "./FeatureCheckBoxes";
import PropTypes from "prop-types";

const NeckEntry = ({
  features,
  onChange,
  note,
  onNoteUpdate,
  onCheckboxUpdate,
  isNursingHomeSite,
  onRemoveSection,
}) => {
  const [hideContent, setHideContent] = useState(true);
  const rom = findFeature(features, "neckROM", "ROM", "Neck");
  const trachea = findFeature(features, "trachea", "Trachea", "Neck");
  const thyroid = findFeature(features, "thyroid", "Thyroid", "Neck");
  const setAttribute = featureSetAttributes({ onChange });

  const setCheckBox = (target) => {
    onCheckboxUpdate(features, "Neck", target.checked, target.value);
  };

  const anyChecked = (val) => {
    const foundIdx = (features || []).findIndex((e) => e.group === "Neck");

    if (foundIdx < 0) {
      return false;
    }
    return features[foundIdx][val];
  };

  const hasEntires = () => {
    const foundIdx = (features || []).findIndex((e) => e.group === "Neck");
    if (foundIdx < 0) {
      return false;
    }
    return !!features[foundIdx].attributes;
  };

  const handleRemoveSection = () => {
    onRemoveSection("Neck");
    setHideContent(true);
  };

  useEffect(() => {
    setHideContent(!hasEntires());
  }, []);

  if (hideContent) {
    return (
      <ContentBLock
        title="Neck"
        titleOnly={true}
        className="hide-content"
        onClick={() => setHideContent(false)}
      />
    );
  }

  return (
    <EntryBlock title="Neck"  className="large">
      <div>
        <FeatureEntry
          {...{
            feature: rom,
            values: ["Normal", "Abnormal"],
            onChange: setAttribute,
            id: "qa-neck-entry-rom-normal",
          }}
        />
        {isNursingHomeSite && (
          <FeatureEntry
            {...{
              feature: rom,
              values: ["Supple", "Rigid"],
              onChange: setAttribute,
              omitTitle: true,
              id: "qa-neck-entry-rom-supple",
            }}
          />
        )}
        <FeatureEntry
          {...{
            feature: trachea,
            values: ["Midline", "Deviated"],
            onChange: setAttribute,
            id: "qa-neck-entry-trachea",
          }}
        />
        <FeatureEntry
          {...{
            feature: thyroid,
            values: ["Normal", "Enlaged"],
            onChange: setAttribute,
            id: "qa-neck-entry-thyroid",
          }}
        />
        <FeatureCheckBoxes {...{ setCheckBox, anyChecked, isNursingHomeSite }} />
        <div className="remove" onClick={handleRemoveSection}>
          Remove section -
        </div>
      </div>
      <div>
        <GroupNoteEntry
          {...{ groupName: "Neck", onChange: onNoteUpdate, note, id: "qa-neck-group-entry" }}
        />
      </div>
    </EntryBlock>
  );
};

NeckEntry.propTypes = {
  features: PropTypes.array,
  onChange: PropTypes.func,
  note: PropTypes.string,
  onNoteUpdate: PropTypes.func,
  onCheckboxUpdate: PropTypes.func,
  isNursingHomeSite: PropTypes.bool,
};

export default NeckEntry;
