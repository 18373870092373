import React, { FC } from "react";
import { Label } from "reactstrap";

import { coronavirusFollowupSteps } from "../../constants/Diagnosis";
import { IProviderNote } from "../../constants/Types";

import CheckToggle from "../CheckToggle";

type Props = {
  note: Partial<IProviderNote>;
  onUpdate: <Key extends keyof IProviderNote>(key: Key, body: IProviderNote[Key]) => void;
  error: boolean;
};

const COVID19Followup: FC<Props> = ({ note, onUpdate, error }) => {
  const handleCoronaVirusFollowupChange = (field: string) => (value: boolean) => {
    if (!value) {
      const temp = note.coronavirusFollowupDetails || {};
      delete temp[field];
      onUpdate("coronavirusFollowupDetails", { ...temp });
    } else {
      onUpdate("coronavirusFollowupDetails", {
        ...note.coronavirusFollowupDetails,
        [field]: value,
      });
    }
    onUpdate("coronavirusFollowup", note.coronavirusFollowup);
  };

  const handleToggle = (toggle: boolean) => {
    if (!toggle) {
      onUpdate("coronavirusFollowupDetails", {});
    }
    onUpdate("coronavirusFollowup", toggle);
  };

  return (
    <div className={error ? "error" : ""}>
      Were the patient's symptoms and signs consistent with{" "}
      <a
        href="https://www.cdc.gov/coronavirus/2019-nCoV/hcp/clinical-criteria.html"
        target="_blank"
      >
        CDC criteria for evaluation and laboratory testing for COVID-19
      </a>
      ?
      <div>
        <div className="mt-3 d-flex">
          <input
            className="align-self-center"
            type="radio"
            checked={note.coronavirusFollowup === false}
            onChange={() => handleToggle(false)}
          />
          <Label className="ml-2 mb-0 pt-1">No</Label>
        </div>
        <div className="d-flex mt-1">
          <input
            className="align-self-center"
            type="radio"
            checked={note.coronavirusFollowup}
            onChange={() => handleToggle(true)}
          />
          <Label className="ml-2 mb-0 pt-1">Yes</Label>
        </div>
      </div>
      {note.coronavirusFollowup && (
        <div className="ml-3">
          {Object.keys(coronavirusFollowupSteps).map((step, index) => {
            return (
              <div className="mt-2 pb-2 d-flex" key={index}>
                <div className="align-self-center">
                  <CheckToggle
                    checked={
                      note.coronavirusFollowupDetails && note.coronavirusFollowupDetails[step]
                    }
                    onChange={handleCoronaVirusFollowupChange(step)}
                  />
                </div>
                <div>{coronavirusFollowupSteps[step]}</div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default COVID19Followup;
