import React, { FC, useState } from "react";
import { Button } from "reactstrap";
import moment from "moment";

import api from "../../api";
import styles from "./PatientSideBar.scss";
import CovidZoneDropdown from "../Rounding/CovidZoneDropdown";
import NewNoteSelect from "../NewNoteSelect";
import PillIcon from "../../images/DoseSpotIcon.svg";
import UploadIconWhite from "../../images/UploadIconWhite.svg";
import RightVector from "../../images/RightWhiteVector.svg";
import AddEntry from "./AddEntry";
import UploadFileModal from "./UploadFileModal";
import PrescriptionButton from "../Provider/PrescriptionButton";
import { hasPermission, PERMISSION_ADMIN, PERMISSION_SCRIBE } from "../../constants/Permissions";

// import ResyncIcon from "../../images/ResyncIcon.svg";
import ConsentToggle from "../ConsentToggleButton";
import OnHoverTitle from "./OnHoverTitle";
import PatientServicesTags from "./PatientServicesTags";
import ChartMenu from "./ChartMenu";

import {
  IAllergy,
  ICondition,
  IPractitioner,
  IPccPatientProfile,
  ICurrentUser,
  IConsentStatusDetails,
} from "../../constants/Types";
import NoteIcon from "../../images/PatientNoteIcon.svg";
import classNames from "classnames";
import ButtonWithLoader from "./ButtonWithLoader";
import { openInNewTab } from "../../utils";

type PCCPatientSideBarType = {
  adding: boolean;
  setAdding: Function;
  consent: boolean | null;
  setConsent: Function;
  fromActiveEncounter: boolean;
  userID: string;
  currentUser: ICurrentUser;
  profile: IPccPatientProfile;
  allergies: IAllergy[] | null;
  conditions: ICondition[] | null;
  defaultImage: string;
  initiateEncounter: (visitType: string) => void;
  handleBack: () => void;
  onChangeCovidZone: (covidZone: string) => void;
  physician: IPractitioner | null;
  loadEntries: (base: any, token?: string) => void;
  isPCCPatient: boolean;
  permissions: string[];
  onReSync: () => void;
  setConsentModal: () => void;
  consentDate: string | null;
  isHealthGorillaUser: string;
  setEditConsentModal: (consentModal: boolean) => void;
  isNursingHomeSite: boolean;
  consentDetails: IConsentStatusDetails;
  covidZone: string | undefined;
  viewHealthGorillaChart: () => void;
  isBtnPending: boolean;
};
const offsetOfTitle = 10;

const PCCPatientSideBar: FC<PCCPatientSideBarType> = ({
  adding,
  setAdding,
  consent,
  fromActiveEncounter,
  userID,
  currentUser,
  profile,
  allergies,
  conditions,
  defaultImage,
  initiateEncounter,
  handleBack,
  onChangeCovidZone,
  physician,
  loadEntries,
  isPCCPatient,
  permissions,
  onReSync,
  setConsentModal,
  consentDate,
  isNursingHomeSite,
  setEditConsentModal,
  consentDetails,
  covidZone,
  isHealthGorillaUser,
  viewHealthGorillaChart,
  isBtnPending,
}) => {
  const [adDirectivesConsents, setAdvanceDirectives] = useState<(string | null)[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [codeLoaded, setCodeLoaded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [prescriptionError, setPrescriptionError] = useState("");
  const [testID, setTestID] = useState("");
  const [testType, setTestType] = useState([]);
  const [mouseCoordinate, setMouseCoordinate] = useState<{
    x: number | string;
    y: number | string;
  } | null>(null);

  const startEncounter = (visitType) => {
    initiateEncounter(visitType);
  };

  const setPatientZone = async (userID, zone) => {
    !!onChangeCovidZone && onChangeCovidZone(zone);
  };
  const isAdmin = hasPermission(permissions, PERMISSION_ADMIN);
  const isScribe = hasPermission(permissions, PERMISSION_SCRIBE);

  const addEntry = () => {
    setAdding(false);
  };
  const onButtonsHover = (nativeEvent) => {
    if (!consent) {
      setMouseCoordinate({
        x: nativeEvent.offsetX + offsetOfTitle,
        y: nativeEvent.offsetY + offsetOfTitle,
      });
    }
  };

  async function getAdvancedDirectives() {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    try {
      const { advanceDirectivesConsents } = await api.PCC.advancedDirectives(profile.ID);

      if (advanceDirectivesConsents && advanceDirectivesConsents.length > 0) {
        const advanceDirectives = advanceDirectivesConsents.map((item) => item.description);
        setAdvanceDirectives(advanceDirectives);
      }
      setCodeLoaded(true);
      setIsLoading(false);
    } catch (e) {
      //ignore errors
    }
  }

  const fullName = `${profile.firstName} ${profile.lastName}`;
  const allergyNames = (allergies || [])
    .filter((allergy) => (allergy.clinicalStatus || "").toLowerCase() === "active")
    .map((allergy) => allergy.allergen);

  const primaryDiagnosis =
    conditions &&
    conditions.find(
      (condition) =>
        condition.clinicalStatus !== null &&
        condition.clinicalStatus.toLowerCase() === "active" &&
        condition.principalDiagnosis,
    );

  const statusDate = consentDate ? moment(consentDate).format("l") : "";

  const rpmFlag = !!consentDetails.rpm ? consentDetails.rpm : false;
  const ccmFlag = !!consentDetails.ccm ? consentDetails.ccm : false;

  const licenseCheck =
    !!currentUser &&
    !!currentUser.capabilities &&
    currentUser.capabilities.geographic.includes(profile.state);
  const iconColor = !consent || !licenseCheck ? "#7F91A8" : "white";

  return (
    <div className="d-flex justify-content-center">
      <>
        {adding && (
          <AddEntry
            userID={profile.ID}
            userName={fullName}
            onClose={() => setAdding(false)}
            currentUser={currentUser}
            loadEntries={loadEntries}
            isPCCPatient={isPCCPatient}
          />
        )}
        {!!profile && !!profile.ID && (
          <UploadFileModal
            visible={showModal}
            userID={profile.ID}
            userName={fullName}
            testID={testID}
            testType={testType}
            onClose={() => setShowModal(false)}
            loadEntries={loadEntries}
          />
        )}
      </>

      <div className={styles.profileContainer}>
        {!fromActiveEncounter && (
          <div className={styles.backButtonWrapper}>
            <Button id="qa-profileback-btn" onClick={handleBack} className={styles.backButton}>
              <RightVector /> Back
            </Button>
          </div>
        )}
        <div id="qa-patientphoto" className="d-flex justify-content-start mt-5 mb-3">
          <div className={styles.patientPhoto}>
            <img src={profile.picturePath || defaultImage} alt="Photo" />
          </div>
          <div className={styles.infoList}>
            <h2 className={`${isAdmin && styles.infoName} mb-2`}>
              {profile.firstName} {profile.lastName}
            </h2>
            <p>Patient ID: {profile.patientExternalId}</p>
            <p>Status: {profile.patientStatus}</p>
            <p>
              DOB: {moment(profile.birthDate).utc(false).format("MM/DD/YYYY")} (
              {moment().diff(profile.birthDate, "years", false)} years old)
            </p>
            <p className={styles.capitalize}>Sex: {profile.gender || ""}</p>
          </div>
          <ChartMenu
            isPCCPatient={isPCCPatient}
            onResync={onReSync}
            isAdmin={isAdmin}
            isNursingHomeSite={isNursingHomeSite}
            setConsentModal={setEditConsentModal}
          />
        </div>
        <div
          onMouseMove={(e) => onButtonsHover(e.nativeEvent)}
          className={`mb-3 pr-2 ${styles.buttonsContainer}`}
        >
          {mouseCoordinate && (
            <OnHoverTitle
              coordinate={mouseCoordinate}
              styles={styles.showTitleBlock}
              visible={!consent}
            >
              Need Patient Consent
            </OnHoverTitle>
          )}
          <div className={`${!consent && "noEvent"}`}>
            <NewNoteSelect
              isScribe={isScribe}
              disabled={!consent || !licenseCheck}
              startEncounter={startEncounter}
              setAdding={setAdding}
            />
            <div className={styles.buttonWrapper}>
              <PrescriptionButton
                disabled={!consent || !licenseCheck || isScribe}
                userID={profile.ID}
                style={styles.greyButton}
                setPrescriptionError={setPrescriptionError}
              >
                <PillIcon stroke={iconColor} />
                Write Rx
              </PrescriptionButton>
              <Button
                disabled={!consent}
                color="None"
                className={styles.blueButton}
                onClick={() => setShowModal(true)}
                id="qa-uploadfile-sidebar-btn"
              >
                <UploadIconWhite stroke={iconColor} className="mr-2" />
                Upload File
              </Button>
              {currentUser?.healthGorillaIsEnable && (
                <>
                  <ButtonWithLoader
                    isAlreadyExist={!!isHealthGorillaUser}
                    inProgress={isBtnPending}
                    classes={classNames(styles.newNoteBtn, "mt-3 mb-0", "w-100")}
                    onBtnClick={viewHealthGorillaChart}
                  >
                    <span>View in Health Gorilla</span>
                  </ButtonWithLoader>
                </>
              )}
            </div>
          </div>
        </div>
        <div className={styles.patientInfo}>
          <div className={styles.profileBlock}>
            <h2>Patient Services</h2>
            <PatientServicesTags
              consent={consent}
              covidZone={covidZone}
              RPM={rpmFlag}
              CCM={ccmFlag}
            />
          </div>
          <div className={styles.profileBlock}>
            {codeLoaded ? (
              <>
                <h2>Code Status</h2>
                <p>
                  {adDirectivesConsents.length > 0 ? adDirectivesConsents.join(", ") : " -"}
                </p>{" "}
              </>
            ) : (
              <>
                <h2>Code Status</h2>
                <a color="link" className={styles.codeStatusBtn} onClick={getAdvancedDirectives}>
                  View Code Status
                </a>
              </>
            )}
          </div>
          <div className={styles.profileBlock}>
            <h2>Primary Diagnosis</h2>
            <p>
              {primaryDiagnosis && `${primaryDiagnosis.icd10} ${primaryDiagnosis.icd10Description}`}
            </p>
          </div>
          <div className={styles.profileBlock}>
            <h2>Allergies </h2>
            <p className={styles.capitalize}>{(allergyNames || []).join(", ") || "-"}</p>
          </div>
          <div className={styles.profileBlock}>
            <h2>Primary Physican</h2>
            <p>
              {physician ? (
                <>
                  {physician.firstName} {physician.lastName},{" "}
                  {physician.cellPhone || physician.officePhone}
                </>
              ) : (
                "-"
              )}
            </p>
          </div>
          <div className={styles.profileBlock}>
            <h2>Location</h2>
            <p>
              {profile.unitDesc} {profile.roomDesc}-{profile.bedDesc}
            </p>
          </div>
          <div className={styles.profileBlock}>
            <h2>Date of Admission</h2>
            <p>{moment(profile.admissionDate).format("MM/DD/YYYY")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PCCPatientSideBar;
