import React, { useState, useEffect } from "react";

import { findFeature, featureSetAttributes } from "../PhysicalExam/Features";
import EntryBlock from "../PhysicalExam/EntryBlock";
import { GroupNoteEntry } from "../PhysicalExam/Notes";
import FeatureEntry from "../PhysicalExam/FeatureEntry";
import CheckSelect from "../CheckSelect";
import PillList from "../Encounter/PillList";
import FeatureCheckBoxes from "./FeatureCheckBoxes";
import PropTypes from "prop-types";
import ContentBLock from "../Encounter/ContentBlock";

const tmOptions = [
  "Unable to visualize landmarks",
  "No light reflex",
  "Abnormal color: Red",
  "Abnormal color: White",
  "Effusion absent",
  "Effusion present",
];

const newNote = [
  "Normal bony landmarks are visualized",
  "Visible cone of light ",
  "Pearly translucent or pink",
];

const TMEntries = ({ onChange, features, setGenerateNote }) => {
  const tm = findFeature(features, "TM", "TM", "Ears");

  const filterTMOptions = (list, options) => {
    return (list || []).filter((r) => options.includes(r));
  };

  const anyOptionsSelected = (list, options) => {
    if (!list) return false;
    for (const option in options) {
      if (list.includes(option)) return true;
    }
    return false;
  };

  const tmSelected = tm.attributes.includes("Abnormal");

  const filteredTMOptions = filterTMOptions(tm.attributes, tmOptions);

  const onRemove = (tmItem) => {
    onChange(
      tm,
      tm.attributes.filter((v) => v !== tmItem),
    );
  };

  const onSelect = (newValues) => {
    let as = tm.attributes.filter((v) => !newValues.includes(v));
    as.push(...newValues);
    onChange(tm, as);
  };

  const tmOptionsSelected = anyOptionsSelected(tm.attributes, tmOptions)
    ? "yes-no-toggle item-selected d-flex"
    : "yes-no-toggle  d-flex";

  const onYesNoChange = (feature, newValues) => {
    if (newValues.includes("Normal")) {
      onChange(tm, ["Normal"]);
      if (feature.key === "TM") {
        setGenerateNote(true);
      }
    } else {
      onChange(tm, newValues);
      if (feature.key === "TM") {
        setGenerateNote(false);
      }
    }
  };

  return (
    <>
      <FeatureEntry feature={tm} values={["Normal", "Abnormal"]} onChange={onYesNoChange} />

      {tmSelected && (
        <div className="attribute-container">
          <div className={tmOptionsSelected}>
            <CheckSelect
              options={tmOptions}
              selected={filteredTMOptions}
              onChange={onSelect}
              label={"Select"}
            />
          </div>
          <div className="tm-items">
            <PillList items={filterTMOptions(tm.attributes, tmOptions)} onRemove={onRemove} />
          </div>
        </div>
      )}
    </>
  );
};

const EarsEntry = ({
  features,
  onChange,
  onNoteUpdate,
  note,
  usedTytoCare = false,
  onCheckboxUpdate,
  isNursingHomeSite,
  onRemoveSection,
}) => {
  const [hideContent, setHideContent] = useState(true);
  const [generateNote, setGenerateNote] = useState(false);
  const external = findFeature(features, "externalEars", "External Ears", "Ears");
  const externalAuditory = findFeature(
    features,
    "externalAuditoryMeati",
    "External Auditory Meati",
    "Ears",
  );

  const cn = "d-flex flex-direction-column";

  const tympanic = findFeature(features, "tympanicMembranes", "Tympanic Membranes", "Ears");
  const setAttribute = featureSetAttributes({ onChange });
  let sampleNote = newNote.join("\n");
  useEffect(() => {
    if (note === sampleNote && usedTytoCare) {
      setGenerateNote(true);
    }
  }, []);

  const setCheckBox = (target) => {
    onCheckboxUpdate(features, "Ears", target.checked, target.value);
  };

  const anyChecked = (val) => {
    const foundIdx = (features || []).findIndex((e) => e.group === "Ears");
    if (foundIdx < 0) {
      return false;
    }
    return features[foundIdx][val];
  };

  const hasEntires = () => {
    const foundIdx = (features || []).findIndex((e) => e.group === "Ears");
    if (foundIdx < 0) {
      return false;
    }
    return !!features[foundIdx].attributes;
  };

  const handleRemoveSection = () => {
    onRemoveSection("Ears");
    setHideContent(true);
  };

  useEffect(() => {
    setHideContent(!hasEntires());
  }, []);

  if (hideContent) {
    return (
      <ContentBLock
        title="Ears"
        titleOnly={true}
        className="hide-content"
        onClick={() => setHideContent(false)}
      />
    );
  }

  return (
    <EntryBlock title="Ears"  className="large">
      <div className="ears-entry">
        <FeatureEntry
          {...{
            feature: external,
            values: ["Normal", "Inflamed"],

            onChange: setAttribute,
            id: "qa-ears-entry-external",
          }}
        />

        {isNursingHomeSite && (
          <FeatureEntry
            {...{
              feature: externalAuditory,
              values: ["Normal", "Exudates", "Edematous"],
              onChange: setAttribute,
              id: "qa-ears-entry-externalAuditory",
            }}
          />
        )}
        {isNursingHomeSite && (
          <FeatureEntry
            {...{
              feature: tympanic,
              style: { width: "250px" },
              values: ["Clear with normal light reflexes", "Erythematous with effusion"],
              onChange: setAttribute,
              id: "qa-ears-entry-tympanic",
            }}
          />
        )}
        {usedTytoCare && (
          <TMEntries
            onChange={setAttribute}
            features={features}
            setGenerateNote={setGenerateNote}
          />
        )}
        <FeatureCheckBoxes {...{ setCheckBox, anyChecked, isNursingHomeSite }} />
        <div className="remove" onClick={handleRemoveSection}>
          Remove section -
        </div>
      </div>
      <>
        <GroupNoteEntry
          {...{
            groupName: "Ears",
            generateNote,
            sampleNote: sampleNote,
            onChange: onNoteUpdate,
            note,
            id: "qa-ears-group-entry",
          }}
        />
      </>
    </EntryBlock>
  );
};

EarsEntry.propTypes = {
  features: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  note: PropTypes.string,
  onNoteUpdate: PropTypes.func,
  onCheckboxUpdate: PropTypes.func,
  usedTytoCare: PropTypes.bool,
  isNursingHomeSite: PropTypes.bool,
};
export default EarsEntry;
