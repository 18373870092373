import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import "./TableModal.scss";
import Octicon, { X } from "@githubprimer/octicons-react";
import { Row, Col } from "reactstrap";
import CheckToggle from "../CheckToggle";



const TableColumnsModal = ({ showModal, setShowModal, selectedColumns, setColumns }) => {
    const [selected, setSelected] = useState<[] | string[]>([]);
  
    useEffect(() => {
      setSelected(selectedColumns);
    }, [selectedColumns]);
    const handleCheck = (value: string) => {
      const list: string[] = [...selected];
  
      if (list.includes(value)) {
        let index = list.indexOf(value);
        list.splice(index, 1);
      } else {
        list.push(value);
      }
      setSelected(list);
    };
  
    const handleSave = () => {
      setColumns(selected);
      setShowModal(false);
    };
    if (!showModal) {
      // popup not visible
      return null;
    }
    return (
      <div className="modal-overlay">
        <div className="m-auto provider-management-modal">
          <div className="d-flex flex-row-reverse">
            <Button className="exit-btn" onClick={() => setShowModal(!showModal)}>
              <Octicon icon={X} />
            </Button>
          </div>
          <Row>
            <Col className="title-container " md="12">
              <h1 className="title">Visible Columns</h1>
            </Col>
          </Row>
  
          <div className="inputs-container d-flex w-100">
            <Row className="ml-5">
              <Col md="6">
                
                <div className="d-flex mb-3">
                  <CheckToggle
                    size={"small"}
                    onChange={() => handleCheck("providerName")}
                    checked={selected?.includes("providerName")}
                    className="encounter-modal-toggle"
                    disabled={false}
                  />
                  <span className="check-label">Provider</span>
                </div>
                <div className="d-flex mb-3">
                  <CheckToggle
                    size={"small"}
                    onChange={() => handleCheck("location")}
                    checked={selected?.includes("location")}
                    className="encounter-modal-toggle"
                    disabled={false}
                  />
                  <span className="check-label">Location</span>
                </div>
                <div className="d-flex mb-3">
                  <CheckToggle
                    size={"small"}
                    onChange={() => handleCheck("phone")}
                    checked={selected?.includes("phone")}
                    className="encounter-modal-toggle"
                    disabled={false}
                  />
                  <span className="check-label">Phone</span>
                </div>
                <div className="d-flex mb-3">
                  <CheckToggle
                    size={"small"}
                    onChange={() => handleCheck("status")}
                    checked={selected?.includes("status")}
                    className="encounter-modal-toggle"
                    disabled={false}
                  />
                  <span className="check-label">Status</span>
                </div>
                
                <div className="d-flex mb-3">
                  <CheckToggle
                    size={"small"}
                    onChange={() => handleCheck("practice")}
                    checked={selected?.includes("practice")}
                    className="encounter-modal-toggle"
                    disabled={false}
                  />
                  <span className="check-label">Practices</span>
                </div>
                <div className="d-flex mb-3">
                  <CheckToggle
                    size={"small"}
                    onChange={() => handleCheck("demographic")}
                    checked={selected?.includes("demographic")}
                    className="encounter-modal-toggle"
                    disabled={false}
                  />
                  <span className="check-label">Demographic</span>
                </div>
                <div className="d-flex mb-3">
                  <CheckToggle
                    size={"small"}
                    onChange={() => handleCheck("language")}
                    checked={selected?.includes("language")}
                    className="encounter-modal-toggle"
                    disabled={false}
                  />
                  <span className="check-label">Language</span>
                </div>
                <div className="d-flex mb-3">
                  <CheckToggle
                    size={"small"}
                    onChange={() => handleCheck("permissions")}
                    checked={selected?.includes("permissions")}
                    className="encounter-modal-toggle"
                    disabled={false}
                  />
                  <span className="check-label">Permissions</span>
                </div>
                <div className="d-flex mb-3">
                  <CheckToggle
                    size={"small"}
                    onChange={() => handleCheck("auditStatistics")}
                    checked={selected?.includes("auditStatistics")}
                    className="encounter-modal-toggle"
                    disabled={false}
                  />
                  <span className="check-label">Audit Statistics</span>
                </div>
              </Col>
              
            </Row>
          </div>
          <div className="d-flex justify-content-end mr-4 mt-2">
            <Button className="link" color="link" onClick={() => setShowModal(!showModal)}>
              Cancel
            </Button>
            <Button className="primary" color="primary" onClick={() => handleSave()}>
              Save
            </Button>
          </div>
        </div>
      </div>
    );
  };
  
  export default TableColumnsModal;