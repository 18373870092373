import React, { useState, useEffect, FC } from "react";
import { Input, Label } from "reactstrap";
import moment from "moment";

import "./PertinentMedications.scss";
import CheckToggle from "../CheckToggle";
import ContentBlock from "./ContentBlock";
import { IMedication } from "../../constants/Types";

type PertinentMedicationsType = {
  covid19Protocol?: boolean;
  pertinentMedicationsNotes?: string;
  covidProtocolChange: (value: boolean) => void;
  covidNoteChange: (value: string) => void;
  medications: IMedication[];
  onMedicationsChange: (value: IMedication[]) => void;
  notedMedications: IMedication[];
};
type MedicationItemType = {
  item: IMedication;
  onMedicationsChange: (value: IMedication[]) => void;
  isAllIncluded: boolean;
  notedMedications: IMedication[];
  checked: boolean;
};
const MedicationItem: FC<MedicationItemType> = ({
  item,
  onMedicationsChange,
  isAllIncluded,
  notedMedications,
  checked,
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    if (!checked) {
      setIsChecked(isAllIncluded);
    }
  }, [isAllIncluded]);

  const onCheckToggle = () => {
    if (checked) {
      let filtered = notedMedications.filter((element) => element.orderId !== item.orderId);
      if (filtered !== undefined) {
        onMedicationsChange([...filtered]);
      }
    } else if (!isAllIncluded) {
      notedMedications !== undefined
        ? onMedicationsChange([item, ...notedMedications])
        : onMedicationsChange([item]);
    }
    setIsChecked(!isChecked);
  };

  const description =
    item.description && item.generic
      ? `${item.description} (${item.generic})`
      : item.description && !item.generic
      ? item.description
      : !item.description && item.generic
      ? item.generic
      : "Not Recorded";

  const schedules =
    item.schedules && item.schedules[0].scheduleType && item.schedules[0].frequency
      ? `Directions: ${item.schedules[0].scheduleType}, ${item.schedules[0].frequency}`
      : "Directions: Not Recorded";
  const startDate = item.startDate
    ? `Start Date: ${moment(item.startDate).format("MM/DD/YYYY")}`
    : "Start Date: Not Recorded";
  const strength = item.strength
    ? `Dosage: ${item.strength} ${item.strengthUOM}`
    : "Dosage: Not Recorded";
  const endDate = item.endDate
    ? `End Date: ${moment(item.endDate).format("MM/DD/YYYY")}`
    : "End Date: n/a";

  return (
    <div className="med-item">
      <CheckToggle size="small" onChange={onCheckToggle} checked={isAllIncluded || checked} />
      <span onClick={onCheckToggle}>{`${description} - ${strength} - ${schedules} - ${startDate} - ${endDate}`}</span>
    </div>
  );
};

const PertinentMedications: FC<PertinentMedicationsType> = ({
  covid19Protocol,
  pertinentMedicationsNotes,
  covidProtocolChange,
  covidNoteChange,
  medications,
  onMedicationsChange,
  notedMedications,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isAllIncluded, setAllIncluded] = useState(false);
  const [medicationsArr, setMedicationsArr] = useState<IMedication[]>([]);

  useEffect(() => {
    setIsChecked(!!covid19Protocol);
    if (medications) {
      const isCheckedItems = new Set();
      if (notedMedications !== undefined) {
        notedMedications.forEach((item) => {
          isCheckedItems.add(item.orderId);
        });
      }
      const filteredMap = medications.map((item) => {
        item.checked = isCheckedItems.has(item.orderId);
        return item;
      });
      
      setMedicationsArr(filteredMap);

      let foundedItem = filteredMap.find((item) => item.checked === false);

      foundedItem === undefined ? setAllIncluded(true) : setAllIncluded(false);
    }
  }, [covid19Protocol, medications, notedMedications]);

  const changeChecked = (checked: boolean) => {
    let updatedArr = medicationsArr.map((item) => {
      item.checked = checked;
      return item;
    });
    setMedicationsArr(updatedArr);
  };

  const includeAll = () => {
    if (isAllIncluded) {
      changeChecked(!isAllIncluded);
      onMedicationsChange([]);
    } else {
      changeChecked(isAllIncluded);
      onMedicationsChange([...medications]);
    }
  };

  const onClick = (value: boolean) => {
    setIsChecked(!isChecked);
    covidProtocolChange(value);
  };

  const onNoteChange = (value: string) => {
    covidNoteChange(value);
  };
  const includeAllCheckBox = !!medicationsArr && medicationsArr.length > 0
  return (
    <ContentBlock title="Pertinent Medications and Supplements">
      <CheckToggle
        className="qa-encounter-covidprotocol-checkbox"
        onChange={() => onClick(!covid19Protocol)}
        checked={isChecked}
      >
        <p>
          Patient has been receiving the standard supportive care and supplements as per COVID-19
          protocol.
        </p>
      </CheckToggle>
      <h1 className="med-header">Active medications to include in note</h1>
      {!!medications ? (
        <>
          <div className="med-item-first">
            {includeAllCheckBox ? (
              <>
                <CheckToggle
                  className="qa-encounter-includeallmeds-btn"
                  size="small"
                  onChange={includeAll}
                  checked={isAllIncluded}
                />
                <h1 onClick={includeAll} className="mb-0">Select all</h1>
              </>
            ) : (
              <h1 className="mb-0 mt-2">None reported</h1>
            )}
          </div>
          {medicationsArr.map((item, idx) => (
            <MedicationItem
              checked={item.checked === undefined ? false : item.checked}
              key={`mdc-${idx}`}
              notedMedications={notedMedications}
              isAllIncluded={isAllIncluded}
              item={item}
              onMedicationsChange={onMedicationsChange}
            />
          ))}
          <div className="med-item-first">
            {includeAllCheckBox ? (
              <>
                <CheckToggle
                  className="qa-encounter-includeallmeds-btn"
                  size="small"
                  onChange={includeAll}
                  checked={isAllIncluded}
                />
                <h1 onClick={includeAll} className="mb-0">Select all</h1>
              </>
            ) : null}
          </div>
          <Label className="med-notes-header">
            <h1>Additional notes for Medications and Supplements</h1>
          </Label>
          <Input
            placeholder="Enter additional comments or corrections regarding Medications and Supplements"
            type="textarea"
            name="pertinentMedications"
            onChange={(e) => onNoteChange(e.target.value)}
            value={pertinentMedicationsNotes}
            id="qa-encounter-meds-freetext"
          />
        </>
      ) : (
        <p>None recorded</p>
      )}
    </ContentBlock>
  );
};

export default PertinentMedications;
