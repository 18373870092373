import React from "react";
import ContentBlock from "../Encounter/ContentBlock";
import PropTypes from "prop-types";

const EntryBlock = ({ children, title, className = "" }) => {
  return (
    <ContentBlock title={title} className={className}>
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message */}
      <div className="physical-exam-editor d-flex">
        <div className="attribute-section">{children[0]}</div>
        {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
        <div className="notes-section align-self-stretch flex-grow-1 ">{children[1]}</div>
      </div>
      {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
    </ContentBlock>
  );
};

EntryBlock.propTypes = {
  children: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

export default EntryBlock;
