import React, { useState, useEffect } from "react";
import { featureSetAttributes, findFeature, featureSetNotes } from "./Features";
import EntryBlock from "./EntryBlock";
import FeatureEntry from "./FeatureEntry";
import { GroupNoteEntry, NoteEntry } from "./Notes";
import RashEntry from "./RashEntry";
import ButtonGroupEntry from "./ButtonGroupEntry";
import FeatureCheckBoxes from "./FeatureCheckBoxes";
import PropTypes from "prop-types";
import ContentBLock from "../Encounter/ContentBlock";

const SkinAllergyQuestions = ({ features, setAttribute }) => {
  const erythematous = findFeature(features, "skinErythematous", "Erythematous", "Skin");
  const hypopigmented = findFeature(features, "skinHypopigmented", "Hypopigmented", "Skin");
  const hyperpigmented = findFeature(features, "skinHyperpigmented", "Hyperpigmented", "Skin");
  const macular = findFeature(features, "skinMacular", "Macular", "Skin");
  const papular = findFeature(features, "skinPapular", "Papular", "Skin");
  const vesicular = findFeature(features, "skinVesicular", "Vesicular", "Skin");
  const ulcerative = findFeature(features, "skinUlcerative", "Ulcerative", "Skin");
  const plaque = findFeature(features, "skinPlaque", "Plaque", "Skin");
  const nodular = findFeature(features, "skinNodular", "Nodular", "Skin");
  const bullous = findFeature(features, "skinBullous", "Bullous", "Skin");
  const blanching = findFeature(features, "skinBlancing", "Blanching", "Skin");
  const discharge = findFeature(features, "skinDischarge", "Discharge/Weeping", "Skin");
  const excoriations = findFeature(features, "skinExcoriations", "Excoriations", "Skin");
  const dermatographism = findFeature(features, "skinDermatographism", "Dermatographism", "Skin");

  return (
    <React.Fragment>
      <FeatureEntry
        {...{
          feature: erythematous,
          values: ["No ", "Yes"],
          onChange: setAttribute,
          id: "qa-skin-entry-erythematous",
        }}
      />
      <FeatureEntry
        {...{
          feature: hypopigmented,
          values: ["No ", "Yes"],
          onChange: setAttribute,
          id: "qa-skin-entry-hypopigmented",
        }}
      />
      <FeatureEntry
        {...{
          feature: hyperpigmented,
          values: ["No ", "Yes"],
          onChange: setAttribute,
          id: "qa-skin-entry-hyperpigmented",
        }}
      />
      <FeatureEntry
        {...{
          feature: macular,
          values: ["No ", "Yes"],
          onChange: setAttribute,
          id: "qa-skin-entry-macular",
        }}
      />
      <FeatureEntry
        {...{
          feature: papular,
          values: ["No ", "Yes"],
          onChange: setAttribute,
          id: "qa-skin-entry-papular",
        }}
      />
      <FeatureEntry
        {...{
          feature: vesicular,
          values: ["No ", "Yes"],
          onChange: setAttribute,
          id: "qa-skin-entry-vesicular",
        }}
      />
      <FeatureEntry
        {...{
          feature: ulcerative,
          values: ["No ", "Yes"],
          onChange: setAttribute,
          id: "qa-skin-entry-ulcerative",
        }}
      />
      <FeatureEntry {...{ feature: plaque, values: ["No ", "Yes"], onChange: setAttribute }} />
      <FeatureEntry
        {...{
          feature: nodular,
          values: ["No ", "Yes"],
          onChange: setAttribute,
          id: "qa-skin-entry-nodular",
        }}
      />
      <FeatureEntry
        {...{
          feature: bullous,
          values: ["No ", "Yes"],
          onChange: setAttribute,
          id: "qa-skin-entry-bullous",
        }}
      />
      <FeatureEntry
        {...{
          feature: blanching,
          values: ["Non blanching ", "Blanching"],
          onChange: setAttribute,
          id: "qa-skin-entry-blanching",
        }}
      />
      <ButtonGroupEntry
        {...{
          feature: discharge,
          attributes: ["None ", "Clear", "White", "Yellow", "Blood"],
          onChange: setAttribute,
          id: "qa-skin-entry-discharge",
        }}
      />
      <FeatureEntry
        {...{
          feature: excoriations,
          values: ["No ", "Yes"],
          onChange: setAttribute,
          id: "qa-skin-entry-excoriations",
        }}
      />
      <ButtonGroupEntry
        {...{
          feature: dermatographism,
          attributes: ["None ", "Mild", "Moderate", "Severe"],
          onChange: setAttribute,
          id: "qa-skin-entry-dermatographism",
        }}
      />
    </React.Fragment>
  );
};

const SkinEntry = ({
  title,
  features,
  note,
  onNoteUpdate,
  onFeatureUpdate,
  visitType,
  onCheckboxUpdate,
  isNursingHomeSite,
  onRemoveSection,
}) => {
  const [intactTrue, setIntactTrue] = useState(false);
  const [bruisesTrue, setBruisesTrue] = useState(false);
  const [tearTrue, setTearTrue] = useState(false);
  const [rashTrue, setRashTrue] = useState(false);
  const [hideContent, setHideContent] = useState(true);

  useEffect(() => {
    if (features && isNursingHomeSite) {
      const int = features.find((feat) => feat.key === "skinIntact");
      if (!!int && !!int.attributes && int.attributes.includes("No")) {
        setIntactTrue(true);
      } else {
        setIntactTrue(false);
      }
      const bru = features.find((feat) => feat.key === "bruises");
      if (!!bru && !!bru.attributes && bru.attributes.includes("Yes")) {
        setBruisesTrue(true);
      } else {
        setBruisesTrue(false);
      }
      const ras = features.find((feat) => feat.key === "skinRash");
      if (!!ras && !!ras.attributes && ras.attributes.includes("Yes")) {
        setRashTrue(true);
      } else {
        setRashTrue(false);
      }
      const tea = features.find((feat) => feat.key === "tear");
      if (!!tea && !!tea.attributes && tea.attributes.includes("Yes")) {
        setTearTrue(true);
      } else {
        setTearTrue(false);
      }
    }
  }, [features]);

  const rash = findFeature(features, "skinRash", "Rash", "Skin");
  const skinPetechiae = findFeature(features, "skinPetechiae", "Petechiae", "Skin");
  const jaundice = findFeature(features, "skinJaundice", "Jaundice", "Skin");
  const exanthem = findFeature(features, "skinExanthem", "Exanthem", "Skin");
  const hair = findFeature(features, "hair", "Hair", "Skin");
  const nails = findFeature(features, "nails", "Nails", "Skin");
  const skinIntact = findFeature(features, "skinIntact", "Skin intact", "Skin");
  const bruises = findFeature(features, "bruises", "Bruises", "Skin");
  const tear = findFeature(features, "tear", "Skin tear", "Skin");

  const setAttribute = featureSetAttributes({ onChange: onFeatureUpdate });
  const onIntactNoteChange = featureSetNotes({ onChange: onFeatureUpdate, feature: skinIntact });
  const onBruisesNoteChange = featureSetNotes({ onChange: onFeatureUpdate, feature: bruises });
  const onTearNoteChange = featureSetNotes({ onChange: onFeatureUpdate, feature: tear });
  const onRashNoteChange = featureSetNotes({ onChange: onFeatureUpdate, feature: rash });

  const isAllergies = visitType === "rash_and_allergy";

  const setCheckBox = (target) => {
    onCheckboxUpdate(features, "Skin", target.checked, target.value);
  };

  const anyChecked = (val) => {
    const foundIdx = (features || []).findIndex((e) => e.group === "Skin");
    if (foundIdx < 0) {
      return false;
    }
    return features[foundIdx][val];
  };

  const hasEntires = () => {
    const foundIdx = (features || []).findIndex((e) => e.group === "Skin");
    if (foundIdx < 0) {
      return false;
    }
    return !!features[foundIdx].attributes;
  };

  const handleRemoveSection = () => {
    onRemoveSection("Skin");
    setHideContent(true);
  };

  useEffect(() => {
    setHideContent(!hasEntires());
  }, []);

  if (hideContent) {
    return (
      <ContentBLock
        title="Skin"
        titleOnly={true}
        className="hide-content"
        onClick={() => setHideContent(false)}
      />
    );
  }

  return (
    <EntryBlock title={title} className="large">
      <div>
        {isAllergies && <SkinAllergyQuestions features={features} setAttribute={setAttribute} />}
        <FeatureEntry
          {...{
            feature: jaundice,
            values: ["No", "Yes"],
            onChange: setAttribute,
            id: "qa-skin-entry-jaundice",
          }}
        />
        <FeatureEntry
          {...{
            feature: exanthem,
            values: ["No", "Yes"],
            onChange: setAttribute,
            id: "qa-skin-entry-exanthem",
          }}
        />
        <FeatureEntry
          {...{
            feature: skinPetechiae,
            values: ["No", "Yes"],
            onChange: setAttribute,
            id: "qa-skin-entry-skinPetechiae",
          }}
        />
        <FeatureEntry
          {...{
            feature: hair,
            values: ["Normal", "Abnormal"],
            onChange: setAttribute,
            id: "qa-skin-entry-hair",
          }}
        />
        <FeatureEntry
          {...{
            feature: nails,
            values: ["Normal", "Abnormal"],
            onChange: setAttribute,
            id: "qa-skin-entry-nails",
          }}
        />
        {!isNursingHomeSite && <RashEntry {...{ onChange: setAttribute, rash }} />}
        {isNursingHomeSite && (
          <>
            <FeatureEntry
              {...{
                feature: skinIntact,
                values: ["Yes", "No"],
                onChange: setAttribute,
                id: "qa-skin-entry-skinIntact",
              }}
            />
            {!!intactTrue && (
              <NoteEntry
                {...{
                  inputClass: "skinIntact mb-2",
                  onChange: onIntactNoteChange,
                  groupName: skinIntact,
                  note: skinIntact.notes,
                  placeholder: "Describe location",
                  type: "",
                  sampleNote: "",
                  id: "qa-skin-noteEntry-skinIntact",
                }}
              />
            )}
            <FeatureEntry
              {...{
                feature: bruises,
                values: ["No", "Yes"],
                onChange: setAttribute,
                id: "qa-skin-entry-bruises",
              }}
            />
            {!!bruisesTrue && (
              <NoteEntry
                {...{
                  inputClass: "bruises mb-2",
                  onChange: onBruisesNoteChange,
                  groupName: bruises,
                  note: bruises.notes,
                  placeholder: "Describe location",
                  type: "",
                  sampleNote: "",
                  id: "qa-skin-noteEntry-bruises",
                }}
              />
            )}
            <FeatureEntry
              {...{
                feature: tear,
                values: ["No", "Yes"],
                onChange: setAttribute,
                id: "qa-skin-entry-tear",
              }}
            />
            {!!tearTrue && (
              <NoteEntry
                {...{
                  inputClass: "tear mb-2",
                  onChange: onTearNoteChange,
                  groupName: tear,
                  note: tear.notes,
                  placeholder: "Describe location",
                  type: "",
                  sampleNote: "",
                  id: "qa-skin-noteEntry-tear",
                }}
              />
            )}
            <FeatureEntry
              {...{
                feature: rash,
                values: ["No", "Yes"],
                onChange: setAttribute,
                id: "qa-skin-entry-rash",
              }}
            />
            {!!rashTrue && (
              <NoteEntry
                {...{
                  inputClass: "rash mb-2",
                  onChange: onRashNoteChange,
                  groupName: rash,
                  note: rash.notes,
                  placeholder: "Describe location",
                  type: "",
                  sampleNote: "",
                  id: "qa-skin-noteEntry-tear",
                }}
              />
            )}
          </>
        )}
        <FeatureCheckBoxes {...{ setCheckBox, anyChecked, isNursingHomeSite }} />
        <div className="remove" onClick={handleRemoveSection}>
          Remove section -
        </div>
      </div>
      <div>
        <GroupNoteEntry
          {...{
            inputClass: "skin-notes",
            onChange: onNoteUpdate,
            groupName: "Skin",
            note,
            id: "qa-skin-group-entry",
          }}
        />
      </div>
    </EntryBlock>
  );
};

SkinEntry.propTypes = {
  title: PropTypes.string,
  features: PropTypes.array,
  note: PropTypes.string,
  onNoteUpdate: PropTypes.func,
  onFeatureUpdate: PropTypes.func,
  visitType: PropTypes.string,
  onCheckboxUpdate: PropTypes.func,
  isNursingHomeSite: PropTypes.bool,
};

export default SkinEntry;
