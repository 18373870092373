import React from "react";
import { Button } from "reactstrap";

const View = (props) => {
  const flu = props.flu || "";
  const phrase = {
    none: "No flu shot, no FluMist",
    shot: "Flu shot",
    mist: "FluMist",
  }[flu.toLowerCase()];

  const content = phrase || "";

  return <div>{content}</div>;
};

const Editor = (props) => {
  const setValue = (v) => () => {
    if (props.onFieldChange) {
      props.onFieldChange("immunizationMethod")(v);
    }
  };
  const edited = props.method != props.oldMethod;
  const cl = edited ? "yes-no-toggle d-flex edited" : "yes-no-toggle d-flex";

  const method = (props.method || "").toLowerCase();
  const noClass = method === "none" ? "selected" : "";
  const mistClass = method == "mist" ? "ml-2 selected" : "ml-2";
  const shotClass = method == "shot" ? "ml-2 selected" : "ml-2";
  return (
    <div className={cl}>
      <Button className={noClass} outline onClick={setValue("none")}>
        No
      </Button>
      <Button className={mistClass} outline onClick={setValue("mist")}>
        FluMist
      </Button>
      <Button className={shotClass} outline onClick={setValue("shot")}>
        Flu shot
      </Button>
    </div>
  );
};

export default function FluImmunization(props) {
  const method = (props.fluImmunization || {}).immunizationMethod || "none";
  const oldMethod = (props.oldFluImmunization || {}).immunizationMethod || "none";
  const onFieldChange = (field) => (newValue) => {
    props.onChange({ ...props.fluImmunization, [field]: newValue });
  };

  if (props.isEditing && !props.readOnly) {
    return <Editor method={method} oldMethod={oldMethod} onFieldChange={onFieldChange} />;
  }
  return <View flu={method} />;
}
