import React, { useState, useEffect, useRef } from "react";
import { Action, Dispatch } from "redux";
import { connect } from "react-redux";
import { FormGroup, Input, Table, Button } from "reactstrap";
import moment from "moment";
import classNames from "classnames";
import debounce from "lodash.debounce";
import { push } from "connected-react-router";
import styled from "styled-components";

import { store } from "../../../store";
import { SET_IDLE_LOGOUT_TIME, SELECT_PRACTICE_FOR_ENCOUNTERS_SEARCH } from "../../../constants/actionTypes";
import {
  hasAnyPermission,
  PERMISSION_MEDICAL_DIRECTOR,
  PERMISSION_MANAGE,
  PERMISSION_SEE_PATIENTS,
  hasPermission,
  PERMISSION_SCRIBE,
} from "../../../constants/Permissions";
import styles from "./encounterSearch.scss";
import DownCaretBlue from "../../../images/DownCaretBlue.svg";
import PatientNoteIcon from "../../../images/PatientNoteIcon.svg";
import BillingIcon from "../../../images/BillingReceiptIcon.svg";
import Spinner from "../../../images/Spinner.svg";
import X from "../../../images/X.svg";
import { waitingRoomVisitTypeLookup } from "../../../constants/Encounter";
import api from "../../../api";
import PDFButton from "../../PDFButton";
import { IEncounterSearchItem, IlistPractice, INotConsentedUser } from "../../../constants/Types";
import PraticeFilter from "./PraticeFilter/PracticeFilter";
import { errorLogger } from "../../../utils";

const PAGE_SIZE = 20;

const VIEW_MINE = "viewMine";
const VIEW_ALL = "viewAll";
const VIEW_NEW = "viewNew";
type IViewName = typeof VIEW_MINE | typeof VIEW_ALL | typeof VIEW_NEW;

const RedDot = styled.span`
  width: 8px;
  height: 8px;
  background-color: #dc4545;
  border-radius: 8px;
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
  margin: 2px 0 0 2px;
  &:before {
    content: "";
    box-sizing: border-box;
  }
`;

const EncounterRow = ({
  encounter,
  canEditNote,
  switchPractice,
  currentPracticeID,
  currentView,
}) => {
  const visitDate = moment(encounter.dateOfVisit);
  const viewUser = (practiceID: string, userID: string) => {
    if (practiceID === currentPracticeID || !practiceID) {
      store.dispatch(push(`/patients/${userID}`));
    } else {
      switchPractice(practiceID, userID);
    }
  };
  //fallback for previous encounters
  const visitType = waitingRoomVisitTypeLookup[encounter.visitType] || encounter.visitType;
  return (
    <tr
      onClick={() => viewUser(encounter.practiceID, encounter.userID)}
      style={{ cursor: "pointer" }}
    >
      <td>{visitDate.format("MM/DD/YYYY")}</td>
      <td>
        <Button color="link" className={styles.patientLink} id="qa-patient-name-btn">
          {encounter.patientName}
        </Button>
      </td>
      <td>
        {visitType === "nursing_home"
          ? "Nursing Home Visit"
          : !!encounter.chiefComplaint
          ? encounter.chiefComplaint
          : visitType}
      </td>
      <td className={styles.clinicalServicesCol}>{encounter.location || ""}</td>
      {currentView === VIEW_ALL && <td>{encounter.providerName || ""}</td>}
      <td className={styles.clinicalServicesCol}>{encounter.medicalPracticeName || ""}</td>
      <td>
        <div className="d-flex justify-content-end qa-pdf-btn1">
          {encounter.receiptURL && (
            <PDFButton
              url={encounter.receiptURL}
              encounterKey={encounter.key}
              style="dashboard-button"
              width="33px"
              height="33px"
            >
              <BillingIcon />
            </PDFButton>
          )}
          <div className="ml-4 qa-pdf-btn2">
            <PDFButton
              url={encounter.noteURL}
              encounterKey={encounter.key}
              style="dashboard-button"
              showEditButton={true && canEditNote}
              width="33px"
              height="33px"
            >
              <PatientNoteIcon />
            </PDFButton>
          </div>
        </div>
      </td>
    </tr>
  );
};

const ConsentRow = ({
  item,
  setConsentForUser,
  switchPractice,
  currentPracticeID,
}: {
  item: INotConsentedUser;
  setConsentForUser: Function;
  switchPractice: Function;
  currentPracticeID: string;
}) => {
  const admissionDate = moment(item.admissionDate);
  const dob = moment(item.dob);

  const viewUser = (practiceID: string, userID: INotConsentedUser["userID"]) => {
    if (practiceID === currentPracticeID || !practiceID) {
      store.dispatch(push(`/patients/${userID}`));
    } else {
      switchPractice(practiceID, userID);
    }
  };

  return (
    <tr onClick={() => viewUser(item.practiceID, item.userID)} style={{ cursor: "pointer" }}>
      <td>
        <Button color="link" className={styles.patientLink} id="qa-newpatient-name-btn">
          {item.patientName}
        </Button>
      </td>
      <td>{dob.isValid() ? dob.format("MM/DD/YYYY") : "N/A"}</td>
      <td>{admissionDate.isValid() ? admissionDate.format("MM/DD/YYYY") : "N/A"}</td>
      <td>{item.practiceName || "None"}</td>
      <td>
        <div className="d-flex justify-content-end">
          <Button
            id="qa-consent-btn-yes"
            onClick={() => {
              setConsentForUser(item, true);
            }}
            className={styles.consentButton}
          >
            Consent to Treat
          </Button>
          <div className="ml-4">
            <Button
              id="qa-consent-btn-no"
              onClick={() => {
                setConsentForUser(item, false);
              }}
              className={styles.noConsentButton}
            >
              No Consent
            </Button>
          </div>
        </div>
      </td>
    </tr>
  );
};

const NewPatientsHeader = () => {
  return (
    <tr className={styles.tableHeader}>
      <td>
        <h3>Patient</h3>
      </td>
      <td>
        <h3>DOB</h3>
      </td>
      <td>
        <h3>Admission Date</h3>
      </td>
      <td>
        <h3>Practice</h3>
      </td>
      <td>&nbsp;</td>
    </tr>
  );
};

const MyVisitsHeader = () => {
  return (
    <tr className={styles.tableHeader}>
      <td>
        <h3>Date of Visit</h3>
      </td>
      <td>
        <h3>Patient Name</h3>
      </td>
      <td>
        <h3>Visit Type</h3>
      </td>
      <td>
        <h3>State</h3>
      </td>
      <td>
        <h3>Practice</h3>
      </td>
      <td>&nbsp;</td>
    </tr>
  );
};

const AllVisitsHeader = () => {
  return (
    <tr className={styles.tableHeader}>
      <td>
        <h3>Date of Visit</h3>
      </td>
      <td>
        <h3>Patient Name</h3>
      </td>
      <td>
        <h3>Visit Type</h3>
      </td>
      <td>
        <h3>State</h3>
      </td>
      <td>
        <h3>Provider</h3>
      </td>
      <td>
        <h3>Practice</h3>
      </td>
      <td>&nbsp;</td>
    </tr>
  );
};

const PaginationBlock = ({
  pageStart,
  totalCount,
  onChange,
}: {
  pageStart: number;
  totalCount: number;
  onChange: (val: number) => void;
}) => {
  const totalPages = totalCount / PAGE_SIZE;
  const currentPage = pageStart / PAGE_SIZE;

  let previousList: number[] = [];
  let nextList: number[] = [];
  let ci = pageStart;
  if (currentPage > 0) {
    while (previousList.length < 2 && ci > 0) {
      ci -= PAGE_SIZE;
      previousList.unshift(ci);
    }
  }
  ci = pageStart;
  if (currentPage < totalPages) {
    while (nextList.length + previousList.length < 4 && ci + PAGE_SIZE < totalCount) {
      ci += PAGE_SIZE;
      nextList.push(ci);
    }
  }

  return (
    <div className={styles.paginationBlock}>
      {previousList.map((i) => (
        <div key={i}>
          <Button color="link" onClick={() => onChange(i)}>
            {i / PAGE_SIZE + 1}
          </Button>
        </div>
      ))}
      <div className={styles.currentPage}>{currentPage + 1}</div>
      {nextList.map((i) => (
        <div key={i}>
          <Button color="link" onClick={() => onChange(i)}>
            {i / PAGE_SIZE + 1}
          </Button>
        </div>
      ))}
    </div>
  );
};

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value])
  return ref.current
}

function EncounterSearch({
  permissions,
  currentUser,
  setIdleTime,
  switchPractice,
  currentPracticeID,
  selectedPractice,
  practices,
  ...props
}) {
  if (!currentUser) {
    return (
      <div className="dashboard-component d-flex justify-content-center">
        <Spinner />
      </div>
    );
  }
  const seeAny = hasAnyPermission(permissions, PERMISSION_MEDICAL_DIRECTOR, PERMISSION_MANAGE);
  const seeMine = hasAnyPermission(permissions, PERMISSION_SEE_PATIENTS);
  const isScribe = hasPermission(permissions, PERMISSION_SCRIBE);
  const onlyManage = hasAnyPermission(permissions, PERMISSION_MANAGE) && permissions.length === 1;
  const isNursingHomeSite = !!(currentUser && currentUser.isNursingHomeSite);
  const initialTab = seeMine ? VIEW_MINE : isScribe ? VIEW_NEW : VIEW_ALL;

  const [patientName, setPatientName] = useState("");
  const [debouncedPatientName, setDebouncedPatientName] = useState("");
  const [currentView, setCurrentView] = useState(initialTab);
  const [inProgress, setInProgress] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageStart, setPageStart] = useState(0);
  const [filterOpen, setFilterOpen] = useState(false);
  const [items, setItems] = useState<IEncounterSearchItem[]>([]);
  const [notConsentedUsersAvailable, setNotConsentedUsersAvailable] = useState(false);
  const [notConsentSelectedUsers, setNotConsentSelectedUsers] = useState<INotConsentedUser[]>([]);
  const [pageToken, setPageToken] = useState("")

  
  const prevPageToken = usePrevious(pageToken)
  const prevPractice = usePrevious(selectedPractice)
  const fetchData = async () => {
    setInProgress(true);

    let providerID = currentView === VIEW_MINE ? currentUser.providerID : "";

    try {
      let result = await api.Records.search({
        pageSize: PAGE_SIZE,
        patientName: debouncedPatientName,
        pageStart,
        providerID,
        practiceID: selectedPractice.id,
      });
      setItems(result.items || []);

      setTotalCount(result.totalCount);
    } finally {
      setInProgress(false);
    }
  };

  useEffect(() => {
    if (currentView !== VIEW_NEW) fetchData();
  }, [debouncedPatientName, pageStart, currentUser, currentView, selectedPractice]);

  const fetchNotConsented = async (showProgress: boolean) => {
    // dont get consented for only manage user permission
    if (onlyManage) return Promise.resolve();

    if (showProgress) setInProgress(true);
    try {
        let result = await api.Providers.getNotConsentedUsers(selectedPractice?.id, pageToken);
        if (showProgress) setNotConsentSelectedUsers(result?.users || []);
        setPageToken(result?.nextPage)
        setNotConsentedUsersAvailable(result?.users?.length > 0);
    
    } catch (error) {
      errorLogger(error);
    } finally {
      if (showProgress) setInProgress(false);
    }
  };

  const onPracticeFilterChange = (practice: { name: string; id: string; nhFlag: boolean }) => {
    props.setSelectedPractice(practice);
  };
  // fetch current users initially to display red dot
  useEffect(() => {
    fetchNotConsented(true);
  }, []);

  useEffect(() => {
    if (selectedPractice != prevPractice || currentView !== VIEW_NEW ) fetchNotConsented(true);
  }, [selectedPractice,currentView]);

  const setConsentForUser = (user: INotConsentedUser, consent: boolean) => {
    // optimistic removal from the list
    const newUsers = notConsentSelectedUsers.filter((item) => item.userID !== user.userID);
    setNotConsentSelectedUsers(newUsers);
    setNotConsentedUsersAvailable(newUsers.length > 0);

    // setting consent on the API
    try {
      api.Providers.changeConsent({
        consentStatus: consent,
        consentReason: "",
        userID: user.userID,
      });
    } catch (error) {
      console.log("file: EncounterSearch.tsx::setConsentForUser::error::", error);
      // reload the whole list
      fetchNotConsented(true);
    }
  };

  const debouncedSetName = debounce(setDebouncedPatientName, 375);

  const changeView = (view: IViewName) => {
    if(view !== VIEW_NEW){
      setPageToken("")
    }
    setPageStart(0);
    setTotalCount(0);
    setCurrentView(view);
  };

  const handlePatientNameChange = (newName: string) => {
    setPatientName(newName);
    setPageStart(0);
    setTotalCount(0);
    debouncedSetName(newName);
    setInProgress(true);
    setIdleTime();
  };

  const handlePageChange = (ps: number) => {
    setPageStart(ps);
    setInProgress(true);
  };

  // for new patients tab
  const handleBackPage = () => {
    setPageToken(prevPageToken || "")
    fetchNotConsented(true)
  }

  const showNameClear = !!patientName;
  const canEditNote = !(permissions.indexOf("billing") > -1 && permissions.length === 1);

  return (
    <div className={classNames("dashboard-component", styles.encounterSearchContainer)}>
      <div className="d-flex">
        {seeMine && (
          <h1
            className={classNames(currentView === VIEW_MINE && styles.selected)}
            onClick={() => changeView(VIEW_MINE)}
            id="qa-my-recent-visits"
          >
            My Recent Visits
          </h1>
        )}
        {seeAny && (
          <h1
            className={classNames(currentView === VIEW_ALL && styles.selected)}
            onClick={() => changeView(VIEW_ALL)}
            id="qa-all-allrecentvisits-btn"
          >
            All Recent Visits
          </h1>
        )}
        {!onlyManage && (
          <h1
            className={classNames(currentView === VIEW_NEW && styles.selected)}
            onClick={() => changeView(VIEW_NEW)}
            id="qa-newpatients-btn"
          >
            New Patients
            {notConsentedUsersAvailable && <RedDot />}
          </h1>
        )}
        <div className="ml-auto d-flex">
            <div className={styles.seeMore}>
              <Button
                color="link"
                onClick={() => setFilterOpen(!filterOpen)}
                className={styles.seeMoreButton}
                id="qa-seemore-btn"
                disabled={currentView !== VIEW_NEW && !pageToken}
              >
                {filterOpen ? (
                  <>
                    See less&nbsp;
                    <DownCaretBlue className={styles.filterCaretOpen} />
                  </>
                ) : (
                  <>
                    See more&nbsp;
                    <DownCaretBlue />
                  </>
                )}
              </Button>{" "}
            </div>

          <PraticeFilter
            selectedPractice={selectedPractice}
            providerPractices={Object.keys(practices).map((el) => ({ state: el, practices: practices[el],}))}
            onPracticeSelect={onPracticeFilterChange}
          />
        </div>
      </div>

      {filterOpen && currentView !== VIEW_NEW && (
        <div className={styles.filterBar}>
          <FormGroup className="d-flex">
            <Input
              placeholder="Patient name..."
              value={patientName}
              onChange={(event) => handlePatientNameChange(event.target.value)}
              id="qa-newpatient-search-input"
            />
          </FormGroup>
          {showNameClear && (
            <>
              <div className={styles.filterIndicator}>&bull;</div>
              <Button
                color="link"
                className={styles.clearFilterButton}
                onClick={() => handlePatientNameChange("")}
              >
                <X />
              </Button>
            </>
          )}
          <div className="ml-auto">
            <PaginationBlock
              pageStart={pageStart}
              totalCount={totalCount}
              onChange={handlePageChange}
            />
          </div>
        </div>
      )}

      {filterOpen && currentView === VIEW_NEW && (
        <div className={`${styles.filterBar} ${styles.newFilter}`}>
            <button disabled={prevPageToken === pageToken} type="button" className={prevPageToken === pageToken ? `${styles.prevBtn} ${styles.prevBtnDis}`: styles.prevBtn } onClick={() => handleBackPage()}> &lt; Prev </button>
            <button disabled={!pageToken} type="button" className={!pageToken ? `${styles.nextBtn} ${styles.nextBtnDis}` : styles.nextBtn} onClick={() => fetchNotConsented(true)}>Next &gt;</button>
        </div>
      )}

      {inProgress ? (
        <div className="w-100 d-flex justify-content-center">
          <Spinner />
        </div>
      ) : (
        <Table className={styles.encounterTable}>
          <tbody>
            {currentView === VIEW_NEW ? (
              notConsentedUsersAvailable  ? (
                <>
                  <NewPatientsHeader />
                  {notConsentSelectedUsers.map((item, idx) => (
                    <ConsentRow
                      key={item.userID + idx}
                      item={item}
                      setConsentForUser={setConsentForUser}
                      switchPractice={switchPractice}
                      currentPracticeID={currentPracticeID}
                    />
                  ))}
                </>
              ) : (
                <p className={styles.info}>No new patients available.</p>
              )
            ) : (
              <>
                {currentView === VIEW_ALL ? <AllVisitsHeader /> : <MyVisitsHeader />}
                {items.map((item, idx) => (
                  <EncounterRow
                    key={item.userID + idx}
                    encounter={item}
                    canEditNote={canEditNote}
                    switchPractice={switchPractice}
                    currentPracticeID={currentPracticeID}
                    currentView={currentView}
                  />
                ))}
              </>
            )}
          </tbody>
        </Table>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  permissions: state.common.permissions,
  currentUser: state.common.currentUser,
  currentPracticeID: state.common.currentUser ? state.common.currentUser.currentPracticeID : "",
  selectedPractice: state.patientsTab.selectedPractice || {},
});

const matchDisaptchToProps = (dispatch: Dispatch<Action>) => ({
  setIdleTime: () => dispatch({ type: SET_IDLE_LOGOUT_TIME }),
  setSelectedPractice: (practice: { name: string; id: string; nhFlag: boolean }) =>
    dispatch({ type: SELECT_PRACTICE_FOR_ENCOUNTERS_SEARCH, practice }),
});

export default connect(mapStateToProps, matchDisaptchToProps)(EncounterSearch);
