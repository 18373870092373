import React, { FC } from "react";

type Props = {
  title?: string;
  childClassName?: string;
  bodyClassName?: string;
  error?: string;
};

const BlockSection: FC<Props> = (props) => {
  const childClass = `info-details ${props.childClassName}`;
  return (
    <div className={`block-section ${props.bodyClassName}`}>
      <div className="d-flex">
        <h1 className={`${props.title} block-title`}>{props.title}</h1>
        {props.error && (
          <h1 className="HPI ml-2" style={{ color: "red", fontSize: "12px" }}>
            {props.error}
          </h1>
        )}
      </div>
      <div className={childClass}>{props.children}</div>
    </div>
  );
};

export default BlockSection;
