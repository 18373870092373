import React, { useState } from "react";
import moment from "moment";
import { Button, Input } from "reactstrap";
import PropTypes from "prop-types";

import api from "../../api";
import styles from "./addEntry.scss";

export default function ViewEntry({ entry, userName, onClose, userID }) {
  const [provider, setProvider] = useState("Loading...");
  const [loaded, setLoaded] = useState(false);
  const [textContent, setTextContent] = useState("");

  const loadRecordDetails = async () => {
    if (entry.isPCCNote) {
      try {
        const details = await api.PCC.progressNoteDetails(`progress-note-${entry.progressNoteId}`,userID);
        const sections = details.sections || [];
        let text = sections.reduce((result, item) => {
          return result + `${item.name}:\n${"" + item.value}\n\n`;
        }, "");

        setProvider("");
        setTextContent(text);
      } catch (err) {
        setTextContent("ERROR: Failed to load Progress Notes from PointClickCare");
      }
    } else {
      let res = await api.Records.details(entry.createdBy);
      setProvider(res.name);
    }

    setLoaded(true);
  };

  if (!loaded) {
    loadRecordDetails();
  }

  return (
    <div className="modal-overlay">
      <div className={styles.modalContainer}>
        <h1>
          {userName} {entry.description}
        </h1>

        <div className="d-flex justify-content-between mt-2 mb-2">
          <div>{moment(entry.createdAt).format("MM/DD/YY hh:mm A")}</div>
          <div>{provider}</div>
        </div>

        <Input
          type="textarea"
          className={styles.viewOnly}
          rows={12}
          value={textContent || entry.content}
          readOnly
        />
        <div className={styles.buttonContainer}>
          <Button className="mr-2" onClick={onClose} color="link">
            Close
          </Button>
        </div>
      </div>
    </div>
  );
}

ViewEntry.propTypes = {
  entry: PropTypes.object,
  userName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
