import React, { Component, FC } from "react";
import update from "immutability-helper";
import { Form, Input, FormGroup, Label } from "reactstrap";

import {
  RESULT_ID_FLU_A,
  RESULT_ID_FLU_B,
  findResult,
  fluLabel,
} from "../../constants/TestConstants";
import { ComponentsProps } from "./ResultConfirmation";

type valueItem = {
  name?: string;
  value?: boolean | string;
  inconclusive?: boolean;
};

type CheckOptionProps = {
  current?: valueItem | null;
  result: string;
  onChange: FluFormGroupProps["onChange"];
  label: string;
  value: valueItem | null;
};

const CheckOption: FC<CheckOptionProps> = (props) => {
  const onChange = () => {
    if (props.onChange) {
      props.onChange(props.value);
    }
  };

  let checked = false;
  if (props.current && props.current.value == props.value?.value) {
    checked = true;
  }

  return (
    <FormGroup check>
      <Label check>
        <Input type="radio" name={props.result} checked={checked} onChange={onChange} />
        &nbsp;
        {props.label}
      </Label>
    </FormGroup>
  );
};

type FluFormGroupProps = {
  legend: string;
  value?: valueItem | null;
  result: string;
  onChange: (value?: valueItem | null) => void;
};

const FluFormGroup: FC<FluFormGroupProps> = (props) => {
  return (
    <FormGroup>
      <legend>{props.legend}</legend>
      <div className="test-confirmation-options">
        <CheckOption
          label="Positive"
          current={props.value}
          result={props.result}
          value={{ value: true }}
          onChange={props.onChange}
        />
        <CheckOption
          label="Negative"
          current={props.value}
          result={props.result}
          value={{ value: false }}
          onChange={props.onChange}
        />
        <CheckOption
          label="Inconclusive"
          current={props.value}
          result={props.result}
          value={{ inconclusive: true }}
          onChange={props.onChange}
        />
      </div>
    </FormGroup>
  );
};

type State = {
  invalid: boolean;
  set: boolean;
  results: valueItem[];
};

class FluConfirmation extends Component<ComponentsProps, State> {
  constructor(props: ComponentsProps) {
    super(props);
    this.state = {
      results: [],

      invalid: false,
      set: false,
    };

    this.onResultChange = this.onResultChange.bind(this);
  }

  onResultChange(result: string) {
    return (newValue) => {
      const idx = this.state.results.findIndex((r) => r.name === result);
      let newState;
      let nv = { name: result, ...newValue };
      if (idx > -1) {
        newState = update(this.state, { results: { [idx]: { $set: nv } } });
      } else {
        newState = update(this.state, { results: { $push: [nv] } });
      }

      newState.set = newState.results.length >= 2;
      newState.invalid = false;

      this.setState(newState);

      this.props.onChange(newState);
    };
  }

  render() {
    const algValue = fluLabel(this.props.result);
    const onInvalid = () => {
      const iv = { set: true, invalid: true, results: [] };
      this.setState(iv);

      this.props.onChange({ set: true, invalid: true, results: [] });
    };

    let a = this.state.invalid ? null : findResult(this.state.results, RESULT_ID_FLU_A);

    let b = this.state.invalid ? null : findResult(this.state.results, RESULT_ID_FLU_B);

    return (
      <div className="test-confirmation-form">
        {this.props.showAlgorithmResult && (
          <div className="algorithm-result">
            <div className="alg-title">Algorithm returned:</div>
            <div className="alg-value">{algValue}</div>
          </div>
        )}
        <div>
          <Form className="d-flex flex-column justify-content-center align-items-center">
            <div className="d-flex w-100">
              <div className="w-50">
                <FluFormGroup
                  legend="Flu A"
                  value={a}
                  onChange={this.onResultChange(RESULT_ID_FLU_A)}
                  result={RESULT_ID_FLU_A}
                />
              </div>
              <div className="w-50">
                <FluFormGroup
                  legend="Flu B"
                  value={b}
                  onChange={this.onResultChange(RESULT_ID_FLU_B)}
                  result={RESULT_ID_FLU_B}
                />
              </div>
            </div>

            <FormGroup check className="mt-4">
              <Label check>
                <Input
                  type="radio"
                  name="invalid"
                  checked={this.state.invalid}
                  onChange={onInvalid}
                />
                &nbsp; Image Invalid
              </Label>
            </FormGroup>
          </Form>
        </div>
      </div>
    );
  }
}

export default FluConfirmation;
