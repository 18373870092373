import React, { Component } from "react";
import FaxList from "../FaxList";
import RemoteList from "../../containers/RemoteList";
import PropTypes from "prop-types";

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'content' implicitly has an 'any' ... Remove this comment to see the full error message
import "../../stylesheets/faxList.scss";

const Bubble = ({ content }) => {
  if (!content || content === 0) {
    return null;
  }
  return <div className="exclamation-bubble">{content}</div>;
};

const DashboardFaxList = ({
  title,
  listName,
  currentUser,
  getItems,
  viewMode,
  count,
  ...props
}) => {
  const cl = count === 0 ? "dashboard-component fax-list disabled" : "dashboard-component fax-list";
  return (
    <div className={cl}>
      <h5>
        {title}
        <Bubble content={count} />
      </h5>
      <RemoteList listName={listName} getItems={getItems} currentUser={currentUser}>
        <FaxList viewMode={viewMode} />
      </RemoteList>
    </div>
  );
};

DashboardFaxList.propTypes = {
  title: PropTypes.string,
  listName: PropTypes.string,
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ viewMode: string | undefined; }' is not as... Remove this comment to see the full error message
  currentUser: PropTypes.object,
  getItems: PropTypes.func,
  viewMode: PropTypes.string,
  count: PropTypes.number,
};

export default DashboardFaxList;
