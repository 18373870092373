import React, { FC, useEffect, useMemo, useState } from "react";
import { Table, Button, Row, Col } from "reactstrap";
import Octicon, { Check } from "@githubprimer/octicons-react";
import moment from "moment";
import PatientNoteIcon from "../../images/PatientNoteIcon.svg";
import PillIcon from "../../images/PillIcon.svg";

import * as styles from "./EncounterList.scss";
import { EncounterSummaryListItem } from "../../constants/Types";

type EncounterListProps = {
  items: EncounterSummaryListItem[];
  onPDFClick: any;
  onLoadMore?: any;
  selectedMonth: any;
  match?: boolean;
};

const RELOAD_THRESHOLD = 100;

const EncounterList: FC<EncounterListProps> = ({
  items = [],
  onPDFClick,
  onLoadMore = () => {},
  selectedMonth,
  match,
}) => {
  const [loading, setLoading] = useState(false);

  const handlePageScroll = useMemo(
    () => async (e) => {
      if (loading) {
        return;
      }

      const target = e.target.scrollingElement;
      const scrollBottom = target.clientHeight + target.scrollTop;

      if (target.scrollHeight - scrollBottom < RELOAD_THRESHOLD) {
        setLoading(true);
        await onLoadMore();

        // wait a second before allowing another load
        await new Promise((r) => setTimeout(r, 1000));
        setLoading(false);
      }
    },
    undefined,
  );

  useEffect(() => {
    window.addEventListener("scroll", handlePageScroll);

    return () => window.removeEventListener("scroll", handlePageScroll);
  });

  return (
    <Table className="provider-list">
      <thead>
        <Row className="provider-header">
          <Col md="3">Date</Col>
          <Col md="3">Patient Info</Col>
          <Col className="col-md-3 d-flex justify-content-center">Audited?</Col>
          <Col md="3" />
        </Row>
      </thead>
      {selectedMonth === null && <div className="noVisitWarning">No visits this month</div>}
      <tbody>
        {items.map((item, idx) => (
          <Row key={idx}>
            <Col md="3">{moment(item.dateOfVisit).format("MM/DD/YY")}</Col>
            <Col md="3">
              <div className={styles.title}>
                {item.patientName} - {item.chiefComplaint || item.visitType}
              </div>
              <div className={styles.assessment}>{`${moment().diff(
                item.patientDateOfBirth,
                "years",
              )}yo ${item.patientGender} from ${item.medicalPracticeName}`}</div>
            </Col>
            <Col className="col-md-3 d-flex justify-content-center">
              {item.audited && <Octicon icon={Check} size="medium" className={styles.checkmark} />}
            </Col>
            <Col md="3">
              {item.prescribed && (
                <span className={styles.prescriptionTag}>
                  Rx
                  <PillIcon className={styles.prescriptionPill} />
                </span>
              )}
              {!!match && (
                <Button
                  color="primary"
                  className="round-button"
                  onClick={() => onPDFClick(item.key, item.auditNotes, item.noteURL, item.audited)}
                >
                  <PatientNoteIcon />
                </Button>
              )}
            </Col>
          </Row>
        ))}
      </tbody>
    </Table>
  );
};

export default EncounterList;
