import React, { FC, useState, useEffect } from "react";
import moment from "moment";
import Octicon, { TriangleLeft, TriangleRight } from "@githubprimer/octicons-react";

import "./VitalsDateRange.scss";

interface VitalsDateRangeProps {
  daysWindow?: number;
  daysIncrement?: number;
  fireChangeOnLoad?: boolean;
  onDateRangeChange?: (startDate: Date, endDate: Date) => void;
}

const VitalsDateRange: FC<VitalsDateRangeProps> = ({
  daysWindow = 30,
  daysIncrement = 5,
  fireChangeOnLoad = false,
  onDateRangeChange,
}) => {
  const dayMoment = (date?) => {
    return (!!date ? moment(date) : moment()).startOf("day");
  };
  const [endDate, setEndDate] = useState(dayMoment().toDate());
  const [beginDate, setBeginDate] = useState(
    dayMoment()
      .subtract(daysWindow, "days")
      .toDate(),
  );
  const DATE_FORMAT = "MM/DD/YYYY";

  const prevEnabled = dayMoment().isAfter(moment(beginDate));
  const nextEnabled = dayMoment().isAfter(moment(endDate));

  const onPrevClick = () => {
    if (!!prevEnabled) {
      const date = dayMoment(endDate).subtract(daysIncrement, "days");
      const newEndDate = date.toDate();
      const newBeginDate = date.subtract(daysWindow, "days").toDate();
      setEndDate(newEndDate);
      setBeginDate(newBeginDate);

      !!onDateRangeChange && onDateRangeChange(newBeginDate, newEndDate);
    }
  };

  const onNextClick = () => {
    if (!!nextEnabled) {
      const date = dayMoment(endDate).add(daysIncrement, "days");
      const newEndDate = date.toDate();
      const newBeginDate = date.subtract(daysWindow, "days").toDate();
      setEndDate(newEndDate);
      setBeginDate(newBeginDate);

      !!onDateRangeChange && onDateRangeChange(newBeginDate, newEndDate);
    }
  };

  useEffect(() => {
    if (!!fireChangeOnLoad) {
      !!onDateRangeChange && onDateRangeChange(beginDate, endDate);
    }
  }, []);

  return (
    <div className="dateRange">
      <div className="d-flex flex-row">
        <div
          className="arrowButton d-flex align-items-center"
          style={{ cursor: prevEnabled ? "pointer" : "default" }}
          onClick={onPrevClick}
        >
          <Octicon icon={TriangleLeft} className={prevEnabled ? "enabled" : "disabled"} />
        </div>
        <div className="dateText d-flex align-items-center">
          {moment(beginDate).format(DATE_FORMAT)}-{moment(endDate).format(DATE_FORMAT)}
        </div>
        <div
          className="arrowButton d-flex align-items-center"
          style={{ cursor: nextEnabled ? "pointer" : "default" }}
          onClick={onNextClick}
        >
          <Octicon icon={TriangleRight} className={nextEnabled ? "enabled" : "disabled"} />
        </div>
      </div>
    </div>
  );
};

export default VitalsDateRange;
