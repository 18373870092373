export const RESULT_ID_NITRITES = "nitrites";
export const RESULT_ID_LEUKOCYTES = "leukocytes";
export const RESULT_ID_STREP_A = "strep-a";
export const RESULT_ID_STREP = "strep";
export const RESULT_ID_FLU_A = "flu-a";
export const RESULT_ID_FLU_B = "flu-b";
export const RESULT_ID_COVID = "COVID-19";
export const RESULT_ID_COVID_CONTROL = "COVID-19-control";

export const NITRITES_POSITIVE = "+";
export const NITRITES_NEGATIVE = "-";

export const LUEKOCYTES_NEGATIVE = "-";
export const LEUKOCYTES_TRACE = "+-";
export const LEUKOCYTES_SMALL = "+";
export const LEUKOCYTES_MODERATE = "++";
export const LEUKOCYTES_LARGE = "+++";

export const nitritesLookup = {
  [NITRITES_POSITIVE]: "Positive",
  [NITRITES_NEGATIVE]: "Negative",
};

export const leukocytesLookup = {
  [LUEKOCYTES_NEGATIVE]: "Negative",
  [LEUKOCYTES_TRACE]: "Trace",
  [LEUKOCYTES_SMALL]: "Small",
  [LEUKOCYTES_MODERATE]: "Moderate",
  [LEUKOCYTES_LARGE]: "Large",
};

export function findResult(
  results: {
    name?: string;
    value?: boolean | string;
    inconclusive?: boolean;
  }[],
  resultId: string,
) {
  if (!results) {
    return null;
  }
  return results.find((r) => r.name === resultId);
}

export function strepLabel(results) {
  let v = findResult(results, RESULT_ID_STREP_A);
  if (!v) {
    v = findResult(results, RESULT_ID_STREP);
  }
  if (!v) {
    return "Unknown";
  }
  if (v.value) {
    return "Positive for Strep A";
  }
  if (v.inconclusive) {
    return "Inconclusive for Strep A";
  }

  return "Negative for Strep";
}

export function utiLabel(results) {
  const l = findResult(results, RESULT_ID_LEUKOCYTES);
  const n = findResult(results, RESULT_ID_NITRITES);

  let nValue = n ? n.value : "Unknown";
  let lValue = l ? l.value : "Unknown";
  nValue = n && n.inconclusive ? "Inconclusive" : nitritesLookup[String(nValue)] || nValue;
  lValue = l && l.inconclusive ? "Inconclusive" : leukocytesLookup[String(lValue)] || lValue;

  return `Leukocytes: ${lValue}, Nitrites: ${nValue}`;
}

export function fluLabel(results) {
  const a = findResult(results, RESULT_ID_FLU_A);
  const b = findResult(results, RESULT_ID_FLU_B);

  if (a && b && a.value && b.value) {
    return "Positive for Flu A & B";
  }
  if (a && a.value) {
    if (b && b.inconclusive) {
      return "Positive for Flu A, inconclusive for Flu B";
    }
    return "Positive for Flu A";
  }
  if (b && b.value) {
    if (a && a.inconclusive) {
      return "Positive for Flu B, inconclusive for Flu A";
    }
    return "Positive for Flu B";
  }

  if (a && b && a.inconclusive && b.inconclusive) {
    return "Inconclusive for Flu A & B";
  }

  return "Negative for Flu A & B";
}

export function covidLabel(results) {
  const c = findResult(results, RESULT_ID_COVID_CONTROL);
  const t = findResult(results, RESULT_ID_COVID);

  let cValue;
  let tValue;

  if (c && !c.value) {
    if (c && c.inconclusive) {
      cValue = "Inconclusive";
    } else {
      cValue = "Negative";
    }
  } else {
    cValue = "Positive";
  }

  if (t && !t.value) {
    if (t && t.inconclusive) {
      tValue = "Inconclusive";
    } else {
      tValue = "Negative";
    }
  } else {
    tValue = "Positive";
  }

  return `COVID-19: ${cValue} control line (C), ${tValue} test line (T)`;
}

const labelFunctionMap = {
  uti: utiLabel,
  flu: fluLabel,
  strep: strepLabel,
  covid: covidLabel,
};

export function ResultLabel(testType, results) {
  if (testType && results) {
    const l = labelFunctionMap[testType];
    if (l) {
      return l(results);
    }
  }
  return "";
}
