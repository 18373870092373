import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import get from "lodash.get";

import api from "../../api";
import NavBar from "../Provider/NavBar";
import DashboardIcon from "../../images/DashboardIcon.svg";
import PrescriptionErrors from "./PrescriptionErrors";
import DashboardFaxList from "./DashboardFaxList";
import UnsignedNotes from "./UnsignedNotes";
import { UNASSIGNED_FAX_LIST, ASSIGNED_FAX_LIST } from "../../constants/actionTypes";
import { FAX_VIEW_MODE_COMPLETE, FAX_VIEW_MODE_ASSIGN } from "../../constants/Fax";
import {
  PERMISSION_MANAGE,
  hasPermission,
  PERMISSION_SEE_PATIENTS,
  PERMISSION_SUPERVISING_PHYSICIAN,
  PERMISSION_SCRIBE,
} from "../../constants/Permissions";
import { IAssignFaxResponse, ICompleteFaxResponse, ICurrentUser } from "../../constants/Types";
import { IAppState } from "../../reducer";
import ProvidersToAudit from "./ProvidersToAudit";
import EMRNotifications from "./EMRNotifications";

type Props = {
  assignedFaxCount?: number;
  currentUser: ICurrentUser | null;
  history?: Object;
  location?: Object;
  match?: Object;
  permissions: Array<string>;
  unassignedFaxCount?: number;
};

type State = {
  toggle: boolean;
};

const mapStateToProps = (state: IAppState) => {
  return {
    permissions: state.common.permissions,
    currentUser: state.common.currentUser,
    assignedFaxCount: (get(state.componentData, `${ASSIGNED_FAX_LIST}.data.items`) || []).filter(
      (item: ICompleteFaxResponse) => !item.complete,
    ).length,
    unassignedFaxCount: (
      get(state.componentData, `${UNASSIGNED_FAX_LIST}.data.items`) || []
    ).filter((item: IAssignFaxResponse) => !item.assigned).length,
  };
};

const mapDispatchToProps = () => ({});

class ProviderDashboard extends Component<Props, State> {
  state = {
    toggle: false,
  };

  componentDidMount() {
    if (this.props.currentUser) {
      this.setState({
        toggle: this.props.currentUser.unsignedNotes || this.props.currentUser.overdueNotes,
      });
    }
  }

  componentDidUpdate(prevprops: Props) {
    if (!prevprops.currentUser && this.props.currentUser) {
      this.setState({
        toggle: this.props.currentUser.unsignedNotes || this.props.currentUser.overdueNotes,
      });
    }
  }

  unassignedFaxList() {
    return (
      <DashboardFaxList
        title="Faxes to forward"
        getItems={api.Faxes.unassignedFaxes}
        currentUser={this.props.currentUser}
        listName={UNASSIGNED_FAX_LIST}
        viewMode={FAX_VIEW_MODE_ASSIGN}
        count={this.props.unassignedFaxCount}
      />
    );
  }

  assignedFaxList() {
    return (
      <DashboardFaxList
        title="Faxes to address"
        getItems={api.Faxes.assignedFaxes}
        currentUser={this.props.currentUser}
        listName={ASSIGNED_FAX_LIST}
        viewMode={FAX_VIEW_MODE_COMPLETE}
        count={this.props.assignedFaxCount}
      />
    );
  }

  handleClick = () => {
    this.setState((state) => ({
      toggle: !state.toggle,
    }));
  };

  render() {
    const canManage = hasPermission(this.props.permissions, PERMISSION_MANAGE);
    const canSeePatients = hasPermission(this.props.permissions, PERMISSION_SEE_PATIENTS);
    const isSupervisingPhysician = hasPermission(
      this.props.permissions,
      PERMISSION_SUPERVISING_PHYSICIAN,
    );
    const isScribe = hasPermission(this.props.permissions, PERMISSION_SCRIBE);
    return (
      <div>
        <NavBar />
        <div className="dashboard-container">
          <h4>
            {" "}
            <div className="header-icon float-left">
              <DashboardIcon />
            </div>{" "}
            Dashboard{" "}
          </h4>
          <Row>
            <Col md={{ size: 8 }}>
              {isSupervisingPhysician && <ProvidersToAudit />}
              <UnsignedNotes isScribe={isScribe} />
            </Col>
            <Col md={{ size: 4 }}>
              <EMRNotifications />
              {canSeePatients && <PrescriptionErrors />}
              {canManage && this.unassignedFaxList()}
              {(canSeePatients || canManage) && this.assignedFaxList()}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProviderDashboard);
