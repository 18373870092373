import React, { FC, KeyboardEventHandler } from "react";
import AsyncCreatableSelect from "react-select/lib/AsyncCreatable";
import { ActionMeta } from "react-select/lib/types";

const stringToSelectItem = (s) => {
  return { value: s, label: s };
};
const selectItemToString = (so: { value: number | string; label: string }) => {
  return so.label;
};

const EmptyComponent = () => {
  return null;
};

type Props = {
  items?: string[] | null;
  itemListSource: (
    text: string,
    userID?: string,
  ) => { items: string[] } | Promise<{ items: string[] }>;
  placeholder: string;
  userID?: string;
  handleSelectChange: (keyValues: any, actionType?: any) => void;
};

const CatalogSelect: FC<Props> = (props) => {
  let items = props.items || [];
  const selectItems = items.map(stringToSelectItem);
  const itemListSource = props.itemListSource;
  const loadOptions = async (text: string) => {
    if (text!) {
      const result = await itemListSource(text, props.userID);
      return result.items.map(stringToSelectItem);
    }
  };

  const onSelectChange = (newItems, event: ActionMeta) => {
    const resultItems = newItems.map(selectItemToString);
    props.handleSelectChange(resultItems, event.action);
  };

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (event.keyCode === 13) {
      let newValue = items.slice();
      newValue.push((event.target as HTMLSelectElement).value);
      props.handleSelectChange(newValue);
    }
  };

  return (
    <AsyncCreatableSelect
      isMulti
      isClearable={false}
      defaultOptions={true}
      placeholder={props.placeholder}
      value={selectItems}
      loadOptions={loadOptions}
      onChange={onSelectChange}
      onKeyDown={handleKeyDown}
      backspaceRemovesValue={false}
      className="info-select"
      classNamePrefix="info-select"
      components={{
        IndicatorSeparator: EmptyComponent,
        MultiValueContainer: EmptyComponent,
        DropdownIndicator: EmptyComponent,
      }}
    />
  );
};

export default CatalogSelect;
