import React, { FC, ReactChild } from "react";
import { Button } from "reactstrap";
type Props = {
  url?: string,
  onClose: () => void,
  children?: boolean | ReactChild | null;
  prevVisitNote?: boolean
}
const PDFView:FC<Props> = ({ url, onClose, children, prevVisitNote }) => {
  if (!url) {
    return null;
  }
  const contentClass = children
    ? "pdf-content flex-grow-1 mb-1 with-controls"
    : "pdf-content flex-grow-1 mb-1";
  return (
    <div className="modal-overlay">
      <div className="pdf-container d-flex flex-column align-items-stretch justify-content-start">
        <div className="d-flex flex-row-reverse justify-content-between m-3">
          <Button onClick={onClose} id="qa-close-note" color="link">
            Close
          </Button>
          {prevVisitNote && <h3>Encounter Notes Viewer</h3>}
        </div>
        {url && (
          <div className={contentClass}>
            <object data={url} type="application/pdf">
              alt : <a href={url}>PDF here.</a>
            </object>
          </div>
        )}
        {children && <div className="pdf-controls">{children}</div>}
      </div>
    </div>
  );
};


export default PDFView;
