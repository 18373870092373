import React, { FC } from "react";
import { Button } from "reactstrap";

type Props = {
  visible: boolean,
  onCancel: () => void,
  onDelete: () => void,
}
const StrikeOutNote: FC<Props> = ({ visible, onCancel, onDelete, children }) => {
  if (!visible) {
    // popup not visible
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="sign-out-note ml-auto mr-auto">
        <h4 className="mb-2">Strike Out Note?</h4>
        <div className="center mb-3">
          <p>
            Are you sure you want to strike out this note? Your progress will be deleted and you will need to start
            over.
          </p>
        </div>
        {children}
        <div className="w-75 d-flex justify-content-around">
          <Button color="link" onClick={onCancel}>Cancel</Button>
          <Button color="primary" onClick={onDelete}>Strike Out Note</Button>
        </div>
      </div>
    </div>
  );
};
export default StrikeOutNote;
