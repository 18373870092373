import React from "react";
import Select from "react-select";

export const STATES = [
  { label: "AL - Alabama", value: "AL" },
  { label: "AK - Alaska", value: "AK" },
  { label: "AZ - Arizona", value: "AZ" },
  { label: "AR - Arkansas", value: "AR" },
  { label: "CA - California", value: "CA" },
  { label: "CO - Colorado", value: "CO" },
  { label: "CT - Connecticut", value: "CT" },
  { label: "DC - District of Columbia", value: "DC" },
  { label: "DE - Delaware", value: "DE" },
  { label: "FL - Florida", value: "FL" },
  { label: "GA - Georgia", value: "GA" },
  { label: "HI - Hawaii", value: "HI" },
  { label: "ID - Idaho", value: "ID" },
  { label: "IL - Illinois", value: "IL" },
  { label: "IN - Indiana", value: "IN" },
  { label: "IA - Iowa", value: "IA" },
  { label: "KS - Kansas", value: "KS" },
  { label: "KY - Kentucky", value: "KY" },
  { label: "LA - Louisiana", value: "LA" },
  { label: "ME - Maine", value: "ME" },
  { label: "MD - Maryland", value: "MD" },
  { label: "MA - Massachusetts", value: "MA" },
  { label: "MI - Michigan", value: "MI" },
  { label: "MN - Minnesota", value: "MN" },
  { label: "MS - Mississippi", value: "MS" },
  { label: "MO - Missouri", value: "MO" },
  { label: "MT - Montana", value: "MT" },
  { label: "NE - Nebraska", value: "NE" },
  { label: "NV - Nevada", value: "NV" },
  { label: "NH - New Hampshire", value: "NH" },
  { label: "NJ - New Jersey", value: "NJ" },
  { label: "NM - New Mexico", value: "NM" },
  { label: "NY - New York", value: "NY" },
  { label: "NC - North Carolina", value: "NC" },
  { label: "ND - North Dakota", value: "ND" },
  { label: "OH - Ohio", value: "OH" },
  { label: "OK - Oklahoma", value: "OK" },
  { label: "OR - Oregon", value: "OR" },
  { label: "PA - Pennsylvania", value: "PA" },
  { label: "RI - Rhode Island", value: "RI" },
  { label: "SC - South Carolina", value: "SC" },
  { label: "SD - South Dakota", value: "SD" },
  { label: "TN - Tennessee", value: "TN" },
  { label: "TX - Texas", value: "TX" },
  { label: "UT - Utah", value: "UT" },
  { label: "VT - Vermont", value: "VT" },
  { label: "VA - Virginia", value: "VA" },
  { label: "WA - Washington", value: "WA" },
  { label: "WV - West Virginia", value: "WV" },
  { label: "WI - Wisconsin", value: "WI" },
  { label: "WY - Wyoming", value: "WY" },
];

export default function StateSelector({ onChange, value, id }) {
  const selected = STATES.find((x) => x.value === value);

  const onSelect = (newValue) => {
    onChange({ target: { value: newValue.value } });
  };

  return (
    <Select
      value={selected}
      options={STATES}
      onChange={onSelect}
      backspaceRemovesValue={false}
      captureMenuScroll={false}
      className={id}
      id="qa-state-selector" 
    />
  );
}
